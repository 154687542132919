import { EventDtoTransformer } from "./EventDtoTransformer";
import TireStartSearchNormalizer from "../startSearch/TireStartSearchNormalizer";
export default class TireResultsNotFoundNormalizer {
    constructor() {
        this.tireStartSearchNormalizer = new TireStartSearchNormalizer();
    }
    normalize(element, category) {
        const startSearchEvent = this.tireStartSearchNormalizer.normalize(element, category);
        return EventDtoTransformer.transformFromStartSearchDto(startSearchEvent);
    }
    support(element, category) {
        return this.tireStartSearchNormalizer.support(element, category);
    }
}
