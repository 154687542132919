import StartAddToQuotationEvent from "./StartAddToQuotationEvent";
export default class StartAddToQuotationNormalizer {
    normalize(elements, origin) {
        const startAddToQuotationEvent = new StartAddToQuotationEvent(origin);
        startAddToQuotationEvent.productCollection.addProduct(elements);
        return startAddToQuotationEvent;
    }
    support(element, origin) {
        return true;
    }
}
