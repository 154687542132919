import ViewCampaignEvent from "./ViewCampaignEvent";
export default class ViewCampaignNormalizer {
    normalize(elements, origin) {
        let promoJson = $(elements).data('promo');
        return new ViewCampaignEvent(promoJson);
    }
    support(elements, origin) {
        return true;
    }
}
