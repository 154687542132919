import {initImgWithLazyLoading} from "./init-images-carousel";
import {applySortAndPrioRules, removeNonPriorisedEquipInGlobalFilter, updateBlocDispo} from "./pubtech-dispo-price";
import {loadReplacements} from "./pubtech-references-action";
import {updateListRefEquip} from "./pubtech-vehicule-affectation-origine";
import GaEventHandler from "./analytics/ga/GaEventHandler";

const LOADING_BLOC = $("#replacement-loading-message").html();

export function loadReplacementReferences() {
    const references = getReplacementReferences();
    const refeqs = Object.keys(references);
    if (!refeqs.length) {
        return false;
    }

    const refOe = references[refeqs[0]][0]['REFCON'];
    $.ajax({
        type: "POST",
        data: {
            references: JSON.stringify(references)
        },
        url: Routing.generate('autossimo_equivalent_references'),
        async: true,
        dataType: "json",
        beforeSend: function() {
            showLoadingMessage(refeqs);
        },
        success: function (data) {
            let codRefeqs = [];//NOSONAR
            for (const reference in data) {
                if (data[reference] !== null) {
                    if (typeof data[reference] === 'number') {
                        $(`#${data[reference]}`).closest('.origin-datas').remove();
                        continue;
                    }
                    const codRefeq = showReplacement(reference, data[reference]);
                    if (codRefeq !== null) {
                        codRefeqs.push(codRefeq);
                    }
                } else {
                    removeLoadingMessage(reference);
                }
            }
            applySortAndPrioRules(refOe);
            removeNonPriorisedEquipInGlobalFilter(true);
            initImgWithLazyLoading();
            loadReplacements(codRefeqs);
            GaEventHandler.viewItemList($('.origin-datas:visible'), 'pieces');
            updateNumberOfRefeq();
            updateListRefEquip();
        },
        error: function () {
            refeqs.forEach(reference => removeLoadingMessage(reference))
        }
    });

    return true;
}

/**
 * Extract replacement references from search results DOM.
 *
 * @returns {{}}
 */
function getReplacementReferences() {
    let references = {};//NOSONAR
    let $referencesBloc = $('.reference-oe-container:visible');
    if ($('.reference-oe-container').length === 1) {
        $referencesBloc = $('.reference-oe-container').eq(0)
    }
    if ($referencesBloc.length > 0) {
        $referencesBloc.find('.product-content:not(.no-replacement-available)').each(function () {
            const $replacementLink = $(this).find('.replaced-by');
            const availability = $(this).data('dispo');
            const dataProduct = $(this).data('product').split('-');
            const [manufacturerRef, productPosition] = dataProduct;
            const manufacturerRefCode = $(this).data('code-refco');
            const replacedCodRefeq = $(this).data('code-refeq');
            const replacedRefeq = $(this).data('refeq');
            const replacedCodeEquip = $(this).data('equip');
            const replacedGlobalStatus = $(this).data('global-status');
            const replacedStock = $(this).data('stock');
            const replacedProductStatus = $(this).data('product-status');
            if ($replacementLink.length > 0) {
                $replacementLink.each(function () {
                    if (!references.hasOwnProperty(replacedRefeq)) {
                        references[replacedRefeq] = [];
                    }
                    references[replacedRefeq].push({
                        'REPLACED_REFEQ_INFO': {
                            'CODREFEQ': replacedCodRefeq,
                            'REFEQ': replacedRefeq,
                            'CODEQU': replacedCodeEquip,
                            'STOCK': replacedStock,
                            'DISPO': availability,
                            'GLOBAL_STATUS': replacedGlobalStatus,
                            'PRODUCT_STATUS': replacedProductStatus,
                        },
                        'REFEQ': $(this).data('refeq'),
                        'CODEQU': $(this).data('equip'),
                        'REFCON': manufacturerRef,
                        'CODREFCO': manufacturerRefCode,
                        'PRODUCT_POSITION': productPosition
                    });
                });
            }
        });
    }

    return references;
}

function showLoadingMessage(references) {
    references.forEach(reference => {
        $(`.product-content[data-refeq="${reference}"]`)
            .parent()
            .addClass('replacement-loading')
            .prepend(LOADING_BLOC);
    })
}

function removeLoadingMessage(reference) {
    $(`.product-content[data-refeq="${reference}"]`)
        .removeClass('replacement-loading')
        .addClass('no-replacement-available')
        .parent()
        .find('.loading-layer').remove();
}

function showReplacement(reference, data) {
    const $newRow = $(data.html);
    const $oldRow = $(`.product-content[data-refeq="${reference}"]`).parent().parent();
    const codRefeq = $newRow.attr('data-codref');
    // If the replacement references is already listed, just remove the replaced one
    if ($(`#folder-part-id-${codRefeq}`).length > 0) {
        $oldRow.remove();

        return null;
    }
    $oldRow.replaceWith($newRow);
    const $productContent = $newRow.find('.product-content');
    $productContent.find('.update-in-progress').hide();
    $productContent.find('.dispo-price-bloc').show();
    updateBlocDispo($productContent, data.availability);
    const {CODEQU, LIBEQU, PRIO} = {... data.availability};
    if ($(`#selectEquip option[value="${CODEQU}"]`).length === 0) {
        $('#selectEquip').append(`<option data-pr="${PRIO}" data-content="${LIBEQU}" value="${CODEQU}">${LIBEQU}</option>`);
    }

    return codRefeq;
}

function updateNumberOfRefeq() {
    $('.choix-equipementiers').each(function(index, val){
        $(val).find('.refequip22:first-child, strong:eq(0)').html(
            $(val).closest('.results-container, .reference-produits-container').find('.origin-datas:not(.hide)').length
        )
    });
}
