import { EventDtoTransformer } from "./EventDtoTransformer";
import ChaineStartSearchNormalizer from "../startSearch/ChaineStartSearchNormalizer";
export default class ChaineResultsNotFoundNormalizer {
    constructor() {
        this.chaineStartSearchNormalizer = new ChaineStartSearchNormalizer();
    }
    normalize(element, category) {
        const startSearchEvent = this.chaineStartSearchNormalizer.normalize(element, category);
        return EventDtoTransformer.transformFromStartSearchDto(startSearchEvent);
    }
    support(element, category) {
        return this.chaineStartSearchNormalizer.support(element, category);
    }
}
