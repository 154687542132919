import StartSearchEvent from "./StartSearchEvent";
import Flash from '../../Flash';
import Context from "../../Context";
export default class PaintStartSearchNormalizerByEssentials {
    normalize(element, category) {
        const startSearchEvent = new StartSearchEvent();
        const dataLabel = element.data('label');
        startSearchEvent.catalog = 'peintures';
        if ($('.consommablesnav').hasClass('active')) {
            startSearchEvent.catalog = 'consommables';
        }
        startSearchEvent.searchType = 'recherche par essentiels';
        startSearchEvent.searchMarker = false;
        startSearchEvent.immatInput = false;
        startSearchEvent.searchTerms = dataLabel;
        startSearchEvent.consumablesPaintSubfamily = dataLabel;
        startSearchEvent.referenceType = '';
        startSearchEvent.referenceCode = '';
        startSearchEvent.markerName = '';
        Context.setLastConsumablesPaintSubfamily(startSearchEvent.consumablesPaintSubfamily);
        Flash.setPaintCachedStartSearchEvent(startSearchEvent);
        return startSearchEvent;
    }
    support(element, origin) {
        return 'paints_essentials' === origin;
    }
}
