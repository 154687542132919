import { FOLDER_COST } from "../../constants";
import { ProductFactory } from "../dto/product/ProductFactory";
import RemoveFromCartEvent from "./RemoveFromCartEvent";
export default class RemoveFromFolderCostNormalizer {
    normalize(elements, origin) {
        const removeFromCartEvent = new RemoveFromCartEvent(origin);
        const amplitudeDivs = $(elements)
            .closest('.folder-offcatalog,td,#popinFicheProduit')
            .find("div[id^='amplitude-']");
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            removeFromCartEvent.productCollection.addProduct(product);
        });
        return removeFromCartEvent;
    }
    support(element, origin) {
        return FOLDER_COST === origin;
    }
}
