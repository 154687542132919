import AddPartToQuotationEvent from "./AddPartToQuotationEvent";
import { ProductFactory } from "../dto/product/ProductFactory";
export default class AddPartToQuotationNormalizer {
    normalize(elements, origin) {
        const productString = elements.attr('data-amplitude');
        const product = ProductFactory.createFromJsonString(productString);
        const addPartToQuotationEvent = new AddPartToQuotationEvent(origin);
        addPartToQuotationEvent.productCollection.addProduct(product);
        return addPartToQuotationEvent;
    }
    support(elements, origin) {
        return true;
    }
}
