export function validStockCart(referencesDatas)
{
    let validStockInCart = false;
    $.ajax({
        type: "POST",
        data: {
            references: JSON.stringify(referencesDatas),
        },
        url: Routing.generate('autossimo_get_prices_before_send_order'),
        async: false,
        success: function (response) {
            if (response.status === 'success') {
                validStockInCart = true;
            } else {
                $('.popin .popinProductStatusAlert').html(response.dataResponse);
                $('div.popinProductStatusAlert').modal({backdrop: 'static', keyboard: false}, 'show');
            }
        }
    })

    return validStockInCart;
}

$(document).on('click', '#close-popin , .close-popin', function() {
    $(this).closest('.modal').hide();
    $('#popinTransmissionCommande').modal('hide');
});