export class ProductCollection {
    constructor() {
        this.products = [];
    }
    addProduct(product) {
        this.products.push(product);
        return this;
    }
    export() {
        const exportedProducts = [];
        for (const product of this.products) {
            exportedProducts.push(this.exportProduct(product));
        }
        return exportedProducts;
    }
    exportProduct(product) {
        return {
            'additional_info_available': product.additionalInfoAvailable,
            'availability': product.availability,
            'catalog': product.catalog,
            'cod_fam': product.codFam,
            'code_co': product.codeCo,
            'is_favorite': product.isFavorite,
            'is_recurring': product.isRecurring,
            'item_name': product.itemName,
            'oem_family': product.oemFamily,
            'oem_marker_name': product.oemMarkerName,
            'oem_reference_code': product.oemReferenceCode,
            'oem_subfamily': product.oemSubfamily,
            'picture_available': product.pictureAvailable,
            'promo_type': product.promoType,
            'purchase_price': product.purchasePrice,
            'quantity': product.quantity,
            'sale_price': product.salePrice,
            'item_reference_code': product.itemReferenceCode,
            'item_reference_id': product.itemReferenceId,
            'oem_name': product.oemName,
            'manufacturer_name': product.manufacturerName,
            'consumables_paint_rubrique': product.consumablesPaintRubrique,
            'consumables_paint_family': product.consumablesPaintFamily,
            'consumables_paint_subfamily': product.consumablesPaintSubfamily
        };
    }
}
