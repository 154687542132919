import CompleteOrderNormalizersResolver from './CompleteOrderNormalizersResolver';
import AbstractDataBuilder from "../../core/AbstractDataBuilder";
export default class CompleteOrderBuilder extends AbstractDataBuilder {
    static create() {
        if (undefined === this.builderInstance) {
            this.builderInstance = new this(new CompleteOrderNormalizersResolver());
        }
        return this.builderInstance;
    }
}
