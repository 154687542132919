export function initReperes() {

    /* Allumer éteindre puce et arbre */
    /* planche vers arbre */
    jQuery('div.piece_sousFamille span[name=reperes]').unbind('hover').hover(function () {
        var idRepere = $(this).attr('idRepere');
        jQuery('div [idRepereB=' + idRepere + ']').show();
    });
    jQuery('div.piece_sousFamille div[idRepereB]').unbind('mouseout').mouseout(function () {
//Il peut y avoir plusieurs puces d'allumées
        var idRepere = $(this).attr('idRepereB');
        jQuery('div [idRepereB=' + idRepere + ']').hide();
    });

    jQuery('div.piece_sousFamille area[shape=poly]').unbind('hover').hover(function () {
        var idRepere = $(this).attr('idRepereP');
        jQuery('div [idRepereB=' + idRepere + ']').show();
    });
    jQuery('div.piece_sousFamille area[shape=poly]').unbind('mouseout').mouseout(function () {
        var idRepere = $(this).attr('idRepereP');
        jQuery('div [idRepereB=' + idRepere + ']').hide();
    });

}

export function switchHideOnClickOutside(hideOnClickOutside) {
    $(".subfamily").data("OpenClose")["options"]["hideOnClickOutside"] = hideOnClickOutside;
}
