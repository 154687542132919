import { AMPLITUDE_CLASS } from "../../constants";
import AddToCartEvent from "./AddToCartEvent";
import { ProductFactory } from "../dto/product/ProductFactory";
export default class AddToCartNormalizer {
    normalize(elements, context) {
        const addToCartEvent = new AddToCartEvent(context.origin);
        const parentDiv = $(elements).closest(':has(.amplitude)');
        if (1 !== parentDiv.length) {
            return addToCartEvent;
        }
        const amplitudeDiv = $(parentDiv).find(AMPLITUDE_CLASS);
        if (1 !== amplitudeDiv.length) {
            return addToCartEvent;
        }
        const product = ProductFactory.createFromDataAttribute(amplitudeDiv);
        addToCartEvent.productCollection.addProduct(product);
        addToCartEvent.status = context.status;
        return addToCartEvent;
    }
    support(element, context) {
        return true;
    }
}
