export default class ApplyFiltersEvent {
    constructor() {
        this.catalog = '';
        this.filtersCategories = '';
        this.family = '';
        this.subfamily = '';
        this.markerName = '';
        this.manufacturerBrand = '';
        this.filtersValues = '';
    }
    export() {
        return {
            catalog: this.catalog,
            filters_categories: this.filtersCategories,
            family: this.family,
            subfamily: this.subfamily,
            marker_name: this.markerName,
            manufacturer_brand: this.manufacturerBrand,
            filters_values: this.filtersValues,
        };
    }
}
