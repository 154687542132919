import { getFamily, getSubFamily, getLibRep } from '../../utilities';
import ViewLaborTimeEvent from "./ViewLaborTimeEvent";
export default class ViewLaborTimeNormalizer {
    normalize(element, context) {
        const viewLaborTimeEvent = new ViewLaborTimeEvent();
        viewLaborTimeEvent.laborOrigin = 'view_item_list';
        viewLaborTimeEvent.family = getFamily();
        viewLaborTimeEvent.subfamily = getSubFamily();
        viewLaborTimeEvent.markerName = getLibRep();
        return viewLaborTimeEvent;
    }
    support(element, context) {
        return true;
    }
}
