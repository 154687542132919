import {getOilPrice, getPriceOil, updateOilPrice} from "./pubtech-oil";
import {getFicheProduitAjax, initDatePickerAjax, initTooltip} from "./pubtech-main-v2";
import {validateManualReference} from "./pubtech-devis-elemFactures";
import {callAutoData} from "./pubtech-devis-entretien";
require('owl.carousel/dist/owl.carousel.min');

var orginalTotalPiece = 0;
const TD_REFORIGINE = 'td.reforigine';
const TD_REFEQUIP = 'td.refequip';
const STEP5_REFEQUIP = '.step5.refequip';
const DE_REF = '.DEreference';
const TEXT_CENTER = 'text-center'
const TEXT_LOAD = '.text-load';
const ID_BILL_LINE = '#bill-line-';
const MODAL_EQ_REF = '.modal-equipref'
const REQUIRED_ERROR = '.required_error';

export function checkElemNonFact() {
    var item = $("table.DEstep4Bef.unbilled tbody tr");
    if (item.length > 0) {
        $('.DEelemFact').show();
    }
    if (item.length == 0) {
        $('.DEelemFact').hide();
    }
}
export function DEdelMO(line) {
    $(line).parent().parent().find("input,select").attr("disabled", "disabled");
    $(line).parent().parent().addClass("deleted");
    $(line).toggleClass('hide');
    $(line).parent().find(".DEactivMO").toggleClass('hide');
    recalculateTotals();
}

export function DEactiveMO(line) {
    $(line).parent().parent().find("input,select").removeAttr("disabled");
    $(line).parent().parent().removeClass("deleted");
    $(line).toggleClass('hide');
    $(line).parent().find(".DEdel").toggleClass('hide');
    recalculateTotals();
}

export function calculateSubTotal() {
    var sum = 0;
    $(".DEelementFacturee tbody tr:not(.free) input.DEprixVenteEF ").each(function () {
        var qteElem = $(this).closest('tr').find('.quant-cart');
        var qte = $(qteElem).length > 0 ? $(qteElem).find('input').attr('data-quantity') : null;
        var price = qte != null ? parseInt(qte) * parseFloat($(this).attr('data-value')) : parseFloat($(this).attr('data-value'));
        var n1 = price || 0;
        sum += n1;
    });
    var htToTTC = 1.2;
    var sumTtc = sum * htToTTC;
    $("#billingElementTotal span").html(sum.toFixed(2).replace('.', ',') + " €");
    $("#billingElementTotalNextStep span").html(sum.toFixed(2).replace('.', ',') + " €");
    $("#billingElementTotalTTC span").html(sumTtc.toFixed(2).replace('.', ',') + " €");
}

function calculateTotal() {
    var sum = 0;
    var val1 = $("#DEMainOeuvreTotal > span");
    var val2 = $("#billingElementTotal > span");
    if(val1.length === 0 || val2.length === 0) {
        return;
    }
    var n1 = parseFloat(val1.html().replace(',', '.')) || 0;
    var n2 = val2.length > 0 ? parseFloat(val2.html().replace(',', '.')) || 0 : 0;
    sum = n1 + n2;
    var htToTTC = 1.2;
    var sumTtc = sum * htToTTC;

    $('.inputElementTotal').val(sumTtc.toFixed(2).replace('.', ','));
    $('.priceTotal > span ').html(sum.toFixed(2).replace('.', ',') + " €");
    $('.priceTotalTTC > span').html(sumTtc.toFixed(2).replace('.', ',') + " €");
    $('#totalht').html(sum.toFixed(2).replace('.', ',') + " €");
}

export function backConfirmation() {
    $('.priceSubTotalBef').css('margin-top', 'inherit');

    $('#steps-picture').addClass('step-3');
    $('#steps-picture').removeClass('step-4');
    $('.step-3').addClass('active');

    $('.DEstep4Bef').show();
    $('.DEstep4Aft').hide();
    $('.DESG').hide();
    recalculateTotals();
}

function calculateMoTotal() {

    if ($("#DEMainOeuvreTotalOrigine").length <= 0 || $("#DEMainOeuvreTotalOrigine").is(":hidden")) {
        var subtotalHt = 0;
        $("#mo-table tbody tr:not(.deleted) div.pice-mo input").each(function () {
            var price = parseFloat($(this).attr('data-value'));
            subtotalHt = subtotalHt + price;
        });
        var htToTTC = 1.2;
        var subtotalTtc = subtotalHt * htToTTC;
        $("#DEMainOeuvreTotal span").html(subtotalHt.toFixed(2).replace('.', ',') + " €");
        $("#DEMainOeuvreTotalTTC span").html(subtotalTtc.toFixed(2).replace('.', ',') + " €");
    }
}

export function fillInPriceDispo(id, currentCodref) {
    var codequip = $('#equipementiers_' + id + ' select[data-id="' + currentCodref + '"]').find(":selected").attr('class');

    if (codequip == null) {
        codequip = $('#equipementiers_' + id + ' span').attr('class');
    }

    if (codequip == null) {
        codequip = $('#equipementiers_' + id + ' .mySelect').find(":selected").attr('class');
    }

    codequip = codequip.split(" ");
    codequip = codequip[0];


    var dispo = $('#bill-line-' + id).find('.' + codequip + ' .dispoLineEF');

    dispo = dispo.html();
    dispo = dispo.replace('pfpt', 'pfpt hide');

    $('#dispo-' + id).html(dispo);


    // fill in unit price
    var paht = $('#bill-line-' + id).find('.' + codequip + ' .prixAHT ').html().trim();

    if (paht == "--" || paht == 0) {
        paht = '0,00';
    }

    $('#purchase-' + id).html('<span>' + paht + '</span>  €');
    // fill in price in quantity data-code
    $('#quantity-' + id + ' > div > input').attr('data-price', paht);
    // fill in codrefeq
    var codrefeq = $('#bill-line-'+id+' .refequip .'+codequip+':visible .DEreference').attr('data-codrefeq');
    $('#quantity-' + id + ' > div > input').attr('codrefeq', 'qT-'+codrefeq);
    // fill in refequip
    var refequip = $('#bill-line-'+id+' .refequip .'+codequip+':visible .DEreference').html();
    if (refequip !== undefined) {
        refequip = refequip.trim();
    }
    $('#quantity-' + id + ' > div > input').attr('refeq', refequip);
    $('#quantity-' + id + ' > div > input').attr('codequ', codequip);

    var marge = parseFloat($('#piece-marge').val()) / 100;
    var originalPublicPriceFlag = $('.originalPublicPrice').val();
    var pvht = 0;
    if (originalPublicPriceFlag == 1) {
        pvht = parseFloat($(`tr#bill-line-${id} .${codequip} .originalPublicPriceValue`).val().replace(',', '.'));
    } else {
        pvht = (marge * parseFloat(paht)) + parseFloat(paht);
    }
    if (pvht == "--" || pvht == 0) {
        pvht = 0.00;
    }
    getPriceOil(id, pvht);
    recalculateTotals();
}

export function getRefEquip(myRefOrigin, myTd, myCodequipSelected, listcodrefeq, devisStep3, firstLoad) {

    if (devisStep3 === undefined) {
        devisStep3 = false;
    }
    if (firstLoad === undefined) {
        firstLoad = false;
    }

    var id = myTd.attr('data-id');
    var currentCodref = $(myRefOrigin).attr('data-codref');

    $('#selling-' + id).append('<div id="loadingLine' + id + '" class="oneLoadingLine" >Téléchargement ...</div>');

    var token = myRefOrigin.attr('data-codref') !== "--" ? "equipementier": "consommable";
    var codrefequip = myTd.attr('data-codrefeq');
    var codarbo = myTd.attr('data-codarbo');
    var priority = [];
    var elements = myTd.closest("tr[id^='bill-line']").find('.mySelect option:not(:first)');
    elements.each(function(index, value){
        priority.push($(value).data('codequip'));
    })
    myTd.html("");
    jQuery.ajax({
        type: "POST",
        data: {
            token: token,
            listcodrefeq: listcodrefeq,
            myCodequipSelected: myCodequipSelected,
            line: id,
            codrefequip: codrefequip,
            codarbo: codarbo,
            devisStep3: devisStep3,
            firstLoad : firstLoad,
            priority : priority
        },
        url: Routing.generate('autossimo_devis_constructeur_getting_ListRefEquipementiers'),
        async: true,
        dataType: "html",
        success: function (data) {
            if (data.indexOf('class="login-page"') !== -1) {
                // Redirect to the home page
                window.location.href = Routing.generate('autossimo_accueil');
            }
            else{
                myTd.html(data);
                myTd.parent().find('td.prixVente > input').removeAttr('disabled');
                var codequDispo = myTd.find('.available-codcodequ').attr('data-codequ');
                if (firstLoad) {
                    myTd.find('.available-codcodequ').closest("tr[id^='bill-line']")
                        .find('#mySelect option').each(function(index, value){
                        if ($(value).attr('data-codequip') === codequDispo) {
                            $(value).prop('selected', 'selected')
                        }
                    });
                }
                var refName = myTd.parent().find('.ref_name').text().trim();
                getPrice(refName, id, currentCodref);
            }
           
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            $(`#loadingLine${id}`).remove();
            console.log("Step4.js:getRefEquip(): Error: " + errorThrown + " ## status: " + textStatus);
        }
    });
}

function getPrice(refName, id, currentCodref) {
    var featureFlagOil = $('#oil_price_feature_flag').data('value');
    if(featureFlagOil === true) {
        if (refName !== 'Huile moteur') {
            fillInPriceDispo(id, currentCodref);
        }
        else {
            getOilPrice($('#mySelectOil'));
        }
    }
    else {
        fillInPriceDispo(id, currentCodref);
    }
    $(`#loadingLine${id}`).remove();
    initDatePickerAjax();
}

function calculateMarge() {
    var totalmargeservice = 0;
    var pourcentagemargeservice = 0;
    var totalPriceServiceHt = 0;
    var totalMargepiece = 0;
    var pourcentageMargepiece = 0;
    var totalPiece = 0;
    var hideMarge = true;
    var valSeuil = 0;
    var seuilMOTotal = 0;
    if ($('#DEMainOeuvreTotalOrigine').is(':visible') || $('#DEMainOeuvreTotalOrigine').hasClass('show_forfait')) {
        let prixForfait = $('#DEMainOeuvreTotal > span ').html().replace('  €', '');
        totalPriceServiceHt = parseFloat(prixForfait.replace(',', '.'));
    } else {
        $("#mo-table tbody tr").each(function (index) {
            var textTaux = $(this).find('.DEmyselect :selected').text();
            var priceHT = parseFloat($(this).find('.unit-price').attr('data-value'));
            var bareme = parseFloat($(this).find('.timehrs').val().replace(',', '.'));
            var tauxhoraire = priceHT / bareme;
            var valTaux = parseFloat($(this).find('.DEmyselect :selected').val());
            if ($(this).find('.unit-price').is(':not(:disabled)')) {
                if ($("#type-marge").val() == 1) {
                    valSeuil = (valTaux / (100 + parseFloat($('#coef-' + textTaux).val()))) * 100;
                } else {
                    valSeuil = parseFloat($('#seuil-' + textTaux).val());
                }
                seuilMOTotal = seuilMOTotal + (valSeuil * bareme);
                if (bareme > 0) {
                    if (isNaN(valSeuil) || valSeuil == 0) {
                        var valmarge = 0;
                    } else {
                        var valmarge = (tauxhoraire - valSeuil) * bareme;
                        hideMarge = false;
                    }
                    totalPriceServiceHt = totalPriceServiceHt + priceHT;
                    totalmargeservice = totalmargeservice + valmarge;
                }
            }
        });
    }
    if (totalPriceServiceHt == 0) {
        pourcentagemargeservice = 0;
    }else {
        //AD-2687
        pourcentagemargeservice = ((totalPriceServiceHt - seuilMOTotal) / totalPriceServiceHt)*100;
    }
    if (isNaN(totalmargeservice) || isNaN(totalPriceServiceHt) || isNaN(pourcentagemargeservice) || (totalPriceServiceHt == totalmargeservice)) {
        totalmargeservice = 0;
        pourcentagemargeservice = 0;
    }

    var totalMargepieceSansqte = 0;
    var totalpieceWithqte = 0;

    $("#elementFactureTable tbody tr").each(function (index) {
        if ($(this).find('.prixAchat').length > 0) {
            let textpurchase = $(this).find('.prixAchat').html().replace(' €', '');
            textpurchase = textpurchase.replace('<span>', '');
            textpurchase = textpurchase.replace('</span>', '');
            textpurchase = textpurchase.replace('L', '');
            textpurchase = textpurchase.trim();
            textpurchase = textpurchase.replace(',', '.');
            let purchase = parseFloat(textpurchase);

            if ($(this).find('.calculate2').is(':not(:disabled)') && !$(this).hasClass("free") && !$(this).hasClass("delete-line")) {
                let sale = parseFloat($(this).find('.calculate2').attr('data-value'));
                var qteElem = $(this).closest('tr').find('.quant-cart');
                if ($('#elementFactureTable').is(':visible')) {
                    // Cas étape 3
                    var qte = $(qteElem).length > 0 && $(qteElem).is(':visible') ? $(qteElem).find('input').attr('data-quantity') : 1;
                } else {
                    // Cas étape 4
                    var qte = $(qteElem).find('input').attr('data-quantity');
                }
                let marge = (sale - purchase) * qte;
                if (isNaN(marge) == false) {
                    totalMargepieceSansqte = totalMargepieceSansqte + (marge / qte);
                    totalMargepiece = totalMargepiece + marge;
                    totalPiece = totalPiece + purchase;
                    totalpieceWithqte = totalpieceWithqte + (purchase * qte);
                }
            }
        }
    });
    orginalTotalPiece = totalpieceWithqte;
    if (totalPiece == 0) {
        pourcentageMargepiece = 0;
    } else {
        pourcentageMargepiece = 100 * (totalMargepieceSansqte / totalPiece);
    }

    //ticket 2158 calcul de la marge si le total de piece = total marge
    if (totalMargepieceSansqte == totalPiece) {
        pourcentageMargepiece = 100;
    }
    //fin ticket 2158
    if (isNaN(pourcentageMargepiece)) {
        pourcentageMargepiece = '0,00';
    }
    var totalmarge = totalmargeservice + totalMargepiece;
    var pricemax = (totalpieceWithqte + totalPriceServiceHt - totalmargeservice) * 1.2;
    var priceHtWithoutEuros = $('#totalht').html().replace(',', '.').replace(' €', '');
    var tvaeuro = parseFloat($('.DESumTotal').val().replace(',', '.')) - parseFloat(priceHtWithoutEuros);
    //AD-2687
    var pourcentagetotalMarge = (totalmarge / (seuilMOTotal + orginalTotalPiece)) * 100;
    $("#margeservice").html(pourcentagemargeservice.toFixed(2).replace('.', ',') + '% / ' + totalmargeservice.toFixed(2).replace('.', ',') + ' €');
    $("#margepiece").html(pourcentageMargepiece.toFixed(2).replace('.', ',') + '% / ' + totalMargepiece.toFixed(2).replace('.', ',') + ' €');
    $("#margetotal").html(pourcentagetotalMarge.toFixed(2).replace('.', ',') + '% / ' + totalmarge.toFixed(2).replace('.', ',') + ' €');
    $("#pricemax").html(pricemax.toFixed(2));
    $("#tvaeuro").html(tvaeuro.toFixed(2).replace('.', ','));
    $("#original-total").val((totalPriceServiceHt + orginalTotalPiece + totalMargepiece).toFixed(2));
    if (hideMarge == true) {
        $("#margeservice").parent().find("span").hide();
    } else {
        $("#margeservice").parent().find("span").show();
    }
}

export function jq(myid) {
    var idReplace = myid.replace(/(:|\.|\[|\]|,|=|@)/g, "\\$1");
    return "[id='" + idReplace + "']";
}
$(document).on('click', '#selectRefeq:not(.select-pop-ref)', function () {
    var refOrigin = $(this).attr('data-codrefeq');
    var refequip = $(this).attr('data-REFEQ');
    var line = $(this).attr('data-line');
    var codrefequ = $(this).attr('data-codrefeq-equipement');
    var idItem = $(this).attr('data-id');

    var dispoFromPopin = $("#" + idItem + " .DEdisponibilite.refdispo").html();
    if ($(dispoFromPopin).find(".enable-jquery-datepicker.hasDatepicker").length > 0) {
        dispoFromPopin = $(dispoFromPopin).find(".enable-jquery-datepicker.hasDatepicker").removeAttr("id").removeClass("hasDatepicker").closest(".relative").get(0).outerHTML;
    }
    $("#dispo-" + line).html(dispoFromPopin);
    var inputQuantity = "quantityPopin-" + refequip + "-" + line;

    inputQuantity = $(jq(inputQuantity));

    // Define buying price
    var priceBuyingHTLine = $(inputQuantity).attr('data-buying-price');
    if (priceBuyingHTLine == "--" || priceBuyingHTLine == 0) {
        priceBuyingHTLine = '0,00';
    }
    // Define quantity
    var dataQuantityLine = $(inputQuantity).attr('data-quantity');
    var dataQuantityPriceLine = $(inputQuantity).attr('data-buying-price');
    $('#purchase-' + line + ' span').html(priceBuyingHTLine);
    var quantityInput = $('#quantity-' + line + ' div input');
    quantityInput.attr('data-quantity', dataQuantityLine);
    quantityInput.attr('codrefeq', 'qT-'+refOrigin);
    quantityInput.attr('refeq', refequip);
    quantityInput.attr('value', dataQuantityLine);
    quantityInput.attr('data-price', dataQuantityPriceLine);
    quantityInput.val(dataQuantityLine);

    var selectedCodEquip = idItem.split("-");
    var manufacturerRef = $(ID_BILL_LINE + line + ' ' + STEP5_REFEQUIP + ' .' + selectedCodEquip[2] + ' ' + DE_REF);
    // Check if there is a select element (that means we have more than one reference)
    var manufacturerRefSelect = manufacturerRef.find('select');
    if (manufacturerRefSelect.length !== 0) {
        manufacturerRefSelect.val(refequip);
    } else {
        manufacturerRef.html(refequip);
    }
    $(ID_BILL_LINE + line + ' .refequip').find('.DEPopinRefEqui').attr('id', 'DEreferences-' + refOrigin + '-' + line);
    $(ID_BILL_LINE + line + ' .refequip').find('.modal-equipref').attr('data-target', '#DEreferences-' + refOrigin + '-' + line);
    $(ID_BILL_LINE + line + ' .refequip').find('.modal-equipref').find(DE_REF).attr('data-codrefeq', refOrigin);

    // Define selling price
    var priceSellingHT = $(inputQuantity).attr('data-selling-price');
    if (priceSellingHT == "--" || priceSellingHT == 0) {
        priceSellingHT = '0,00';
    }
    priceSellingHT = priceSellingHT.toString().replace(',', '.');
    priceSellingHT = parseFloat(priceSellingHT);
    var refName = $('#bill-name-'.concat(line)).find('.ref_name').text().trim();
    var featureFlagOil = $('#oil_price_feature_flag').data('value');

    if(!(refName === 'Huile moteur' && featureFlagOil === true)) {
        $('#selling-' + line + ' .DEprixVenteEF').val(priceSellingHT.toFixed(2).replace('.', ','));
        $('#selling-' + line + ' .DEprixVenteEF').attr('data-value', priceSellingHT.toFixed(2).replace(',', '.'));
    }
    $(".DEPopinRefEqui").modal('hide');
    recalculateTotals();
    initDatePickerAjax();
});

export function selectRef(reforigin, selectedOrigin, refId, line) {
    $("#bill-line-" + line + " select.mySelect").each(function () {
        $(this).hide();
    });
    $("#ref-" + refId).html(reforigin);
    $('[data-id="' + selectedOrigin + '"]').show();
    $("#DErefOrigine" + refId).modal('hide');
    $("#bill-line-" + line + " td" + STEP5_REFEQUIP).html('');
    $("#bill-line-" + line + " td.step5.dispo").html('');
    $("#bill-line-" + line + " td.step5.prixAchat").html('');
    $("#bill-line-" + line + " td.step5.quantity").html('<div class="bill-action cart-action quant-cart" style="display:none;"><a href="#" class="plus">+</a><input type="number" min="1" step="0.01" value="1" class="value" codrefeq="" codequ="" refeq=""  data-price=""  typeligne="C" data-quantity="1"/><a href="#" class="minus">-</a></div>');
    $("#bill-line-" + line + " td.step5.reforigine").attr('data-codref', selectedOrigin);
    $('[data-id="' + selectedOrigin + '"]').prop('selectedIndex', 0);
    $("#bill-line-" + line + " td" + STEP5_REFEQUIP).removeClass('loaded');
}

$(document).on("keypress", '.DESumTotal, .DEprixVenteEF, .DEtb.unit-price, .DEtb.timehrs, .capacity_value.DECAP', function (event) {
    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which != 0) && (event.which != 8) && (event.which != 44 || $(this).val().indexOf(',') != -1) && (event.which < 48 || event.which > 57)) {
        event.preventDefault();
    }
});

$(document).on("keyup", '.DEtb.unit-price, .capacity_value.DECAP', function (event) {
    var value = $(this).val();
    $(this).val(value.replace('.', ','));

    if ($(this).val().indexOf(",") > 0) {
        var number = $(this).val().split(',');
        if (number[1].length > 2)
        {
            var unitPrice = parseFloat($(this).val().replace(',', '.'));
            $(this).val(unitPrice.toFixed(2));
        }
    }
    value = $(this).val();
    $(this).attr('data-value', value.replace(',', '.'));
    $(this).attr('value', value.replace('.', ','));
    $(this).val($(this).val().replace('.', ','));

    recalculateTotals();
});

$(document).on("focusout", '.DEprixVenteEF, .DEtb.unit-price, .DEtb.timehrs, .capacity_value.DECAP', function (event) {
    var priceValue = $(this).val().replace(',', '.');
    if ($(this).val().slice(-1) == ',') {
        var newVal = $(this).val().slice(0, -1);
        $(this).val(newVal);
    } else if ((priceValue == '') || isNaN(priceValue)) {
        $(this).val("0,00");
        $(this).attr('data-value', '0.00');
    }
});

$(document).on("blur", '.DESumTotal', function (event) {

    var minPriceMarge = $('#pricemax').html();
    var minPriceHt = (minPriceMarge / 120) * 100;
    var newTotal = parseFloat($(this).val().replace(',', '.'));
    var htToTTC = 1.2;
    var bareme = parseFloat($('.timehrs').val().replace(',', '.'));
    if (newTotal >= parseFloat($("#pricemax").html())) {
        // Modification du total HT
        $('#totalht').html((newTotal / htToTTC).toFixed(2).replace('.', ',') + " €");
        // Modification de la marge en euro et en pourcentage
        var margeEuro = (newTotal / htToTTC) - (minPriceHt);
        var seuilMOTotal = 0;
        var valSeuil = 0;
        $("#mo-table tbody tr").each(function (index) {
            var textTaux = $(this).find('.DEmyselect :selected').text();
            var valTaux = parseFloat($(this).find('.DEmyselect :selected').val());
            if ($("#type-marge").val() == 1) {
                valSeuil = (valTaux / (100 + parseFloat($('#coef-' + textTaux).val()))) * 100;
            } else {
                valSeuil = parseFloat($('#seuil-' + textTaux).val());
            }
            seuilMOTotal = seuilMOTotal + (valSeuil * bareme);
        });

        var margePourcentage = (margeEuro.toFixed(2) / (seuilMOTotal + orginalTotalPiece)) * 100;

        $('#margetotal').html(margePourcentage.toFixed(2).replace('.', ',') + '% / ' + margeEuro.toFixed(2).replace('.', ',') + ' €');

        // Modification de la TVA en euro
        $("#tvaeuro").html((newTotal - $('#totalht').html().replace(',', '.').replace(' €', '')).toFixed(2).replace('.', ','));
        $(this).val(newTotal.toFixed(2));
        $(this).val($(this).val().replace('.', ','));
    } else {
        $('.create_devis_error').hide();
        $('.convert_devis').hide();
        $('#modalValidateDevis').modal({keyboard: false});
        var originalTotalTtc = parseFloat($("#original-total").val()) * htToTTC;
        $(this).val(originalTotalTtc.toFixed(2).replace('.', ','));

        $('#totalht').html($("#original-total").val().replace('.', ',') + " €");

        var bareme = parseFloat($('.timehrs').val().replace(',', '.'));

        var margesService = $('#margeservice').html().split('/');
        var margeServiceEuro = margesService[1].replace(' ', '').replace('€', '').replace(',', '.');
        var margesPiece = $('#margepiece').html().split('/');
        var margePieceEuro = margesPiece[1].replace(' ', '').replace('€', '').replace(',', '.');

        var totalMargeEuro = parseFloat(margeServiceEuro) + parseFloat(margePieceEuro);
        var seuilMOTotal = 0;
        var valSeuil = 0;
        $("#mo-table tbody tr").each(function (index) {
            var textTaux = $(this).find('.DEmyselect :selected').text();
            var valTaux = parseFloat($(this).find('.DEmyselect :selected').val());
            if ($("#type-marge").val() == 1) {
                valSeuil = (valTaux / (100 + parseFloat($('#coef-' + textTaux).val()))) * 100;
            } else {
                valSeuil = parseFloat($('#seuil-' + textTaux).val());
            }
            seuilMOTotal = seuilMOTotal + (valSeuil * bareme);
        });

        var margePourcentage = (totalMargeEuro.toFixed(2) / (seuilMOTotal + orginalTotalPiece)) * 100;

        $('#margetotal').html(margePourcentage.toFixed(2).replace('.', ',') + '% / ' + totalMargeEuro.toFixed(2).replace('.', ',') + ' €');
        $("#tvaeuro").html((originalTotalTtc - $('#totalht').html().replace(',', '.').replace(' €', '')).toFixed(2).replace('.', ','));
    }

});

$(document).on("keyup", 'input.DEtb.timehrs', function () {
    var value = $(this).val();
    $(this).val(value.replace('.', ','));

    if ($(this).val().indexOf(",") > 0) {
        var number = $(this).val().split(',');
        if (number[1].length > 2)
        {
            var tempsBareme = parseFloat($(this).val().replace(',', '.'));
            $(this).val(tempsBareme.toFixed(2));
        }
    }
    var id = $(this).attr('id').replace('timehrs-', '');
    var action = $('#mySelect-' + id).val();

    if (value.length == 0) {
        value = '0,00';
    }
    if (value[value.length - 1] !== '.') {
        var tb = value.replace(',', '.');
        tb = parseFloat(tb);
        tb = tb.toFixed(2);
        var price = action * tb;
        $('#unit-price-mo-' + id).val(price.toFixed(2).replace('.', ','));
        $('#unit-price-mo-' + id).attr('data-value', price);
        recalculateTotals();
    }
});

$(document).on("focusout", '.DEtb.unit-price, input.DEtb.timehrs', function () {
    var value = $(this).val();

    value = value.replace(',', '.');
    value = parseFloat(value);
    value = value.toFixed(2);
    value = value.replace('.', ',');
    $(this).val(value);
});



$(document).on('change', 'select#select-ref-manufacturer', function () {
    var optionSelected = $(this).find('option:selected').attr('data-item');
    getPopinRefEquip($(this), optionSelected);
});

function getPopinRefEquipArgs(node) {
    var manufacturerRef = node.closest('.modal-equipref');
    var line = manufacturerRef.attr('data-line');

    return {
        manufacturerCode : manufacturerRef.attr('data-manufacturer-code'),
        margePiece : manufacturerRef.attr('data-marge-piece'),
        originalPublicPrice : manufacturerRef.attr('data-original-public-price'),
        line : line,
        listcodrefeq : $('#bill-line-'+line).find('[listcodrefeq]:visible').attr('listcodrefeq'),
        codref : $('#bill-line-'+line).find(TD_REFORIGINE).attr('data-codref'),
        codarbo : $('#bill-line-'+line).find(TD_REFEQUIP).attr('data-codarbo'),
    };
}

export function confirmation() {

    var nbElementFactureToZero = 0;
    var nbEquipNotSelected = 0;
    if ($('#elementFactureTable').get(0) !== 'undefined') {
        $('#elementFactureTable > tbody > tr').each(function () {
            if (!$(this).hasClass('free') && !$(this).hasClass('delete-line')) {
                if ($(this).find('.mySelect:visible').val() == 'Choisir un équipementier') {
                    nbEquipNotSelected++;
                    $(this).find('.mySelect').css('border-color', 'red');
                    return false;
                } else if (($(this).find('input.DEprixVenteEF').attr('data-value') == '0.00' || isNaN($(this).find('input.DEprixVenteEF').attr('data-value')) || $(this).find('input.DEprixVenteEF').attr('data-value') === '') && $(this).find('.mySelect:visible').val() != 'Choisir un équipementier') {
                    nbElementFactureToZero++;
                    $(this).find('input.DEprixVenteEF').css('border-color', 'red');
                    nbEquipNotSelected--;
                    $(this).find('.mySelect').css('border-color', '#e6e6e6');
                    return false;
                } else {
                    $(this).find('input.DEprixVenteEF').css('border-color', '#e6e6e6');
                    $(this).find('.mySelect').css('border-color', '#e6e6e6');
                }
            }
        });
    }

    if (nbElementFactureToZero === 0 && nbEquipNotSelected === 0) {
        calculateMarge();
        if ($('#steps-picture').hasClass('step-4')) {
            updateOilPrice($('#mySelectOil'));
            saveDevis(true, 5);
            return false;
        } else {

            $('#step3ValidError').addClass('hide');

            $('.priceSubTotalBef').css('margin-top', '-65px');

            $('#steps-picture').addClass('step-4');
            $('.step-4').addClass('active');

            $('.DEstep4Bef').hide();
            $('.DEstep4Aft').show();
            $('.DESG').show();
            recalculateTotals();
        }
    } else {
        if (nbElementFactureToZero > 0) {
            $('#step3ValidError').empty();
            $('#step3ValidError').append("<strong>Vous ne pouvez pas valider le devis d'entretien si une pièce ou un produit a un prix égal à 0. Veuillez le renseigner ou l'offrir</strong>");
        } else {
            $('#step3ValidError').empty();
            $('#step3ValidError').append("<strong>Vous ne pouvez pas valider le devis d'entretien sans avoir sélectionné d'équipementier. Veuillez en sélectionner un ou appliquer une référence manuelle</strong>");
        }
        $('#step3ValidError').removeClass('hide');
        $('html, body').animate({
            scrollTop: ($("div.row.DEstep4Bef").offset().top + 140)
        }, 2000);
    }
}

function MajListRefMan(response, tr) {
    $(tr).find('#refManDispo').html(response);
}

export function addRefManuelle(line, fomData) {
    var designation = $(fomData).find('.designation-ref').val();
    var equipementier = $(fomData).find('.equipementier-ref').val();
    var reference = $(fomData).find('.reference-ref').val();
    var prixht = $(fomData).find('.prixht-ref').val().replace(',', '.').trim();
    var ad_part_id = $(fomData).find('.AD_PART_ID-ref').val();
    var flagSaver = $('.toggle-on.active').length;
    var product = $(fomData).find('.refManuelleName-ref').val();
    var type = $(line).attr('type-objet') == 'Piece' ? 'Piece' : 'Liquide';
    var selectedRefMan = $(fomData).find('.refManDispo-ref option:selected');

    if ($(selectedRefMan).attr('id') == '') {
        if (designation === '' || equipementier === '' || reference === '' || prixht === '') {
            $('.erreurRefMan').removeClass('hidden');
        } else {
            if (flagSaver == 0) {
                $.ajax({
                    url: "/constructeur-add-reference-origine",
                    type: 'POST',
                    data: {
                        designation: designation,
                        equipementier: equipementier,
                        reference: reference,
                        prixht: prixht,
                        ad_part_id: ad_part_id,
                        flagSave: flagSaver,
                        product: product,
                        type: type,
                    },
                    success: function (json) {
                        if (json.reponse != 'error') {
                            modifLine(line, fomData);
                        } else {
                            alert('Erreur : veuillez corriger vos saisies et valider');
                        }
                    },
                    onFailure: function (data) {
                        alert('error: ' + data);
                    }
                });
            } else {
                modifLine(line, fomData);
            }
            $('.erreurRefMan').addClass('hidden');
        }
    } else {
        modifLine(line, fomData);
    }
}
function modifLine(line, fomData) {
    var selectedRefMan = $(fomData).find('#refManDispo option:selected');

    if ($(selectedRefMan).attr('id') == '') {
        var equipementier = $(fomData).find('.equipementier-ref').val();
        var reference = $(fomData).find('.reference-ref').val();
        var prixht = $(fomData).find('.prixht-ref').val().replace(',', '.').trim();
        if ($(line).hasClass('warning-ref')) {
            replaceWarningRefMan(line, prixht);
        }
        $(line).find('.reforigine').html("");

        $(line).find('.equipementiers').html("<span class=''>" + equipementier + "</span>");
        $(line).find('.refequip').html(reference);
        //$(line).find('.prixAchat').html(parseFloat(prixht).toFixed(2).replace('.', ',') + " €");
        recalculateTotals();
    } else {
        var equipementier = $(selectedRefMan).attr('code-EQUIP');
        var reference = $(selectedRefMan).attr('code-REF_EQUIP');
        var prixht = $(selectedRefMan).attr('code-PRICE_HT');
        if ($(line).hasClass('warning-ref')) {
            replaceWarningRefMan(line, prixht);
        }
        if ($(line).find('.DEredBloc.text').length > 0) {
            var dataId = $(line).attr("data-id");
            var staticReplaceWithTr = '<td id="dispo-' + dataId + '" class="step5 dispo"> </td><td id="quantity-' + dataId + '" class="step5 quantity"> </td><td id="purchase-' + dataId + '" class="step5 prixAchat"> </td>';
            $(line).find('.DEredBloc.text').replaceWith("<td class='reforigine'><span></span></td> <td class='equipementiers'><span>" + equipementier + "</span></td><td class='refequip'><span>" + reference + "</span></td>" + staticReplaceWithTr);
            $(line).removeClass('warning-ref');
            $(line).find('.DEnoref').removeClass('DEnoref');
        } else {
            $(line).find('.reforigine').html("");
            $(line).find('.equipementiers').html("<span class=''>" + equipementier + "</span>");
            $(line).find('.refequip').html("<span>" + reference + "</span>");
            //$(line).find('.prixAchat').html(parseFloat(prixht).toFixed(2).replace('.', ',') + " €");
            //$(line).find('.prixVente input').attr('data-value', prixht);
        }
        recalculateTotals();
    }
    $(fomData).find("input[type=text], textarea").val("");
    $(line).find('.prixVente > input').removeAttr('disabled');
}

function replaceWarningRefMan(line, prixAchat) {
    var rowID = $(line).attr('data-id');
    var rowAdPartId = $(line).attr('ad-part-id');
    var rowTypeObjet = $(line).attr('type-objet');
    var rowCapacity = parseFloat($(line).attr('capacity'));
    var rowCodArbo = parseInt($(line).find(STEP5_REFEQUIP).attr('data-codarbo'));
    var purchaseRefman = prixAchat;
    var pieceMarge = parseFloat($('#piece-marge').val()) / 100;
    var prixAchatHT = rowCapacity * purchaseRefman;
    var prixVenteHT = prixAchatHT * (1 + pieceMarge);

    $(line).removeClass('warning-ref');
    $(line).find('.DEconfigLine').removeClass('DEnoref');
    $(line).find('.DEredBloc.text').removeAttr('colspan');
    $(line).find('.DEredBloc.text').html();
    $(line).find('.DEredBloc.text').addClass('step5 reforigine').removeClass('DEredBloc text');

    var tdEquipementiers = '<td id="equipementiers_' + rowID + '" class="step5 equipementiers" data-id="' + rowID + '"></td>';
    var tdRefEquipementier = '<td class="step5 refequip" data-id="' + rowID + '" ad-part-id="' + rowAdPartId + '" type-objet="' + rowTypeObjet + '" capacites="' + rowCapacity + '" data-codarbo="' + rowCodArbo + '"></td>';
    var tdDispo = '<td class="step5 dispo" id="dispo-' + rowID + '"></td>';
    var tdQuantity = '<td class="step5 quantity" id="quantity-' + rowID + '"><div class="bill-action cart-action quant-cart"><a href="#" class="plus">+</a><input type="number" min="1" value="1" step="0.01" class="value input-quantity" codrefeq="" codequ="" refeq=""  data-price=""  typeligne="C" data-quantity="1"/><a href="#" class="minus">-</a></div> </td>';
    var tdPrixAchatHT = '<td class="step5 prixAchat" id="purchase-' + rowID + '" data-purchase-refman="' + purchaseRefman + '"><span>' + prixAchatHT.toFixed(2).replace('.', ',') + ' </span> €</td>';
    var htmlCapacity = '<input value="' + rowCapacity + '" class="capacity_value DECAP" type="text">';

    $(line).find('.step5.prixVente').attr('id', 'selling-' + rowID);
    $(line).find('.contenance .capacity_value.DECAP').replaceWith(htmlCapacity);
    $(line).find('.step5.reforigine:last').after(tdEquipementiers + tdRefEquipementier + tdDispo + tdQuantity + tdPrixAchatHT);

    var euro = '<div data-id="' + rowID + '" class="euro inline-block" id="bill-euro"></div>';
    var trash = '<div data-id="' + rowID + '" class="trush inline-block" id="bill-trash"></div>';
    $(line).find('.step5.myActions').html(euro + trash);

    $('#selling-' + rowID + ' .calculate2').removeAttr('disabled');
    $('#selling-' + rowID + ' .calculate2').val(prixVenteHT.toFixed(2).replace('.', ','));
    $('#selling-' + rowID + ' .calculate2').attr('data-value', prixVenteHT);
}

function DEdel(myVar) {
    $(myVar).find("input, select").toggleDisabled();
    $(myVar).css("color", "#ccc");
    $(myVar).css("cursor", "not-allowed");
    $(myVar).parent().parent().toggleClass('deleted');

    var myDEprinceMO = $(myVar).closest("tr").find(".prixVente");
    $(myDEprinceMO).find(".DEprixVenteEF").removeClass("calculate2");
    recalculateTotals();
}

function DEactive(myVar) {
    $(myVar).find("input,button,textarea,select").removeAttr('disabled');
    $(myVar).css("color", "inherit");
    $(myVar).css("cursor", "inherit");


    var myDEprinceMO = $(myVar).closest("tr").find(".prixVente");
    $(myDEprinceMO).find(".DEprixVenteEF").addClass("calculate2");
    recalculateTotals();
}

function addEditRefMan() {
    $('.addRefManuelle').show();
}
export function hideRefMan() {
    $('.addRefManuelle').hide();
}
export function hightlightEF() {
    $('.overlay').show();
    $('.DEelementFacturee tbody tr .name').addClass('modal-content');
    $('.DEelementFacturee tbody tr .name').css('box-shadow', 'inherit');
    $('.DEelementFacturee tbody tr .name.modal-content').css('z-index', '11');
    $('.DEelementFacturee tbody tr .name.modal-content').css('top', 'inherit');
    $('.overlay').css('z-index', '10');

    $('.DEelementFacturee tbody tr').each(function () {
        if ($(this).hasClass('delete-line') || $(this).hasClass('free')) {
            $(this).find('.addRefManuelle').addClass('hide');
        } else {
            $(this).find('.addRefManuelle').removeClass('hide');
        }
    });
    $('.DEelemFact tbody tr').each(function () {
        $(this).find('.addRefManuelle').addClass('hide');
    });
}

function redirectToTrackDevis(numDevis) {
    document.location.href = Routing.generate('autossimo_quotes_edit', {'quoteId': numDevis});
}

export function saveDevis(showPopin, step) {
    var urlSaveDevis = Routing.generate('autossimo_devis_constructeur_save_devis_new');
    var workForces = [];
    var pieces = [];
    var moIndex = 0;
    $("#mo-table tbody tr").each(function (index) {
        var deleted = 0;
        if ($(this).hasClass('deleted')) {
            deleted = 1;
        }

        workForces[index] = aWorkForces[index];
        workForces[index]['desc'] = $(this).find('.DEserviceME').text();
        if ($('.remove_forfaits').is(':visible') || $('#DEMainOeuvreTotalOrigine').hasClass('show_forfait')) {
            workForces[index]['remiseForfait'] = 100;
        } else {
            delete workForces[index]['remiseForfait'];
        }

        workForces[index]['taux'] = $(this).find('.DEmyselect :selected').text();
        workForces[index]['valTaux'] = $(this).find('.DEmyselect :selected').val();
        workForces[index]['priceHT'] = $(this).find('.unit-price').attr('data-value');
        workForces[index]['timehrs'] = $(this).find('.timehrs').val();
        workForces[index]['ssid'] = $(this).find('.ssid').attr('data-ssid');
        workForces[index]['adMessage'] = $(this).find('.advisory_message').attr('data-advisory_message');
        workForces[index]['deleted'] = deleted;
        workForces[index]['priceHTOriginal'] = (workForces[index]['timehrs']).replace(',', '.') * (workForces[index]['valTaux']).replace(',', '.');
        moIndex = moIndex + 1;
    });

    var forfait = '', forfaitVal = 0;
    if ($('.remove_forfaits').is(':visible') || $('#DEMainOeuvreTotalOrigine').hasClass('show_forfait')) {
        var forfait = $('.remove_forfaits label').text();
        var forfaitVal = $('#DEMainOeuvreTotal').text();
        forfaitVal = forfaitVal.substr(0, forfaitVal.indexOf('€')).replace(',', '.').trim() || 0;

        workForces[moIndex] = {};
        workForces[moIndex]['priceHT'] = forfaitVal;
        workForces[moIndex]['revision_name'] = "Forfait : " + forfait;
        workForces[moIndex]['service_advisory_message'] = "";
        workForces[moIndex]['service_interval_id'] = "";
        workForces[moIndex]['time_hrs'] = 0;
        workForces[moIndex]['desc'] = "Forfait : " + forfait;
        workForces[moIndex]['timehrs'] = 1;
        workForces[moIndex]['taux'] = 1;
        workForces[moIndex]['valTaux'] = forfaitVal;
        workForces[moIndex]['ssid'] = moIndex;
        workForces[moIndex]['adMessage'] = null;
        workForces[moIndex]['deleted'] = 0;
        workForces[moIndex]['priceHTOriginal'] = forfaitVal;
    }
    var MoTotal = $('#DEMainOeuvreTotalOrigine').text();
    MoTotal = MoTotal.substr(0, MoTotal.indexOf('€')).replace(',', '.').trim();


    var BillTotal = $('#billingElementTotal').text();
    BillTotal = BillTotal.substr(0, BillTotal.indexOf('€')).replace(',', '.').trim();

    var appelationVeh = $('.appelation_vehicule').text().trim();

    var pieces = [];
    $(".DEelementFacturee tbody tr").not('.warning-ref').each(function (index) {
        var key = $(this).attr('data-id');
        pieces[index] = {};
        pieces[index]['AdPartId'] = $(this).attr('ad-part-id').trim();
        pieces[index]['TypeObject'] = $(this).attr('type-objet').trim();
        pieces[index]['ClassObject'] = $(this).attr('class').trim();
        pieces[index]['TYPELIGNE'] = $(this).attr('data-typeligne');
        pieces[index]['Designation'] = $(this).find('td#bill-name-' + key + ' .ref_name').text().trim();
        if ($('#bill-line-' + key + ' .equipementiers select#mySelect :selected').length > 0) {
            pieces[index]['CODEQU'] = $('#bill-line-' + key + ' .equipementiers select#mySelect :selected').attr('data-codequip');
        } else {
            var codEquip = $('#bill-line-' + key + ' .equipementiers span');
            if ($(codEquip).attr('class') !== undefined) {
                pieces[index]['CODEQU'] = $(codEquip).attr('data-codequip');
            } else {
                pieces[index]['CODEQU'] = $(codEquip).text().trim();
            }
        }

        if ($('#bill-line-' + key + ' .equipementiers').length > 0) {
            if ($('#bill-line-' + key + '  .equipementiers select#mySelect :selected').length <= 0) {
                pieces[index]['NOMEQU'] = $('#bill-line-' + key + ' .equipementiers span').html().trim();
            } else {
                var optionSelected = $('#bill-line-' + key + '  .equipementiers select#mySelect :selected').html();
                if (optionSelected.indexOf('Choisir') === -1) {
                    pieces[index]['NOMEQU'] = optionSelected.trim();
                }
            }
        } else {
            pieces[index]['NOMEQU'] = "";
        }

        var codequ = pieces[index]['CODEQU'];
        var refName = $('#bill-name-'+key).find('.ref_name').text().trim();
        var featureFlagOil = $('#oil_price_feature_flag').data('value');

        var manufacturerRef = $('#bill-line-' + key + ' .refequip .DEreference');
        // Check if there is a select element (that means we have more than one reference)
        var manufacturerRefSelect = manufacturerRef.find('select');
        if (manufacturerRefSelect.length !== 0) {
            pieces[index]['REFEQ'] = manufacturerRefSelect.val();
        } else if (manufacturerRef.length > 0) {
            pieces[index]['REFEQ'] = $('#bill-line-' + key + ' .refequip').find('div[class="' + codequ + '"]').find('.DEreference').text().trim()
        } else {
            pieces[index]['REFEQ'] = $('#bill-line-' + key + ' .refequip').text().trim();
        }
        pieces[index]['CODREFEQ'] = manufacturerRef.length > 0
            ? $('#bill-line-' + key + ' .refequip').find('div[class="' + codequ + '"]').find('.DEreference').attr('data-codrefeq')
            : 0;
        pieces[index]['CODARBO'] = $('#bill-line-' + key + ' .refequip').attr('data-codarbo');

        var reforigin = '';
        if ($('#bill-line-' + key + ' .reforigine .modal_dErefOrigine').length > 0) {
            $('#bill-line-' + key + ' .reforigine .DErefOriginItem').each(function () {
                reforigin += $(this).find('.DEpieceOrigine').html().trim() + '/';
            });
            reforigin = reforigin.slice(0, -1);
        } else if (refName === 'Huile moteur' && featureFlagOil === true) {
            reforigin = $('#mySelectOil').val();
        } else {
            reforigin = $('#bill-line-' + key + ' ' + TD_REFORIGINE).html().trim();
        }

        pieces[index]['REFORIGINE'] = reforigin;

        pieces[index]['ENTONNOIR'] = $('#bill-line-' + key + ' .contenance .icon-entonnoir').length > 0
                ? $('#bill-line-' + key + ' .contenance .icon-entonnoir').attr('class')
                : null;
        pieces[index]['CAPACITY'] = $('#bill-line-' + key + ' .contenance .capacity_value').length > 0
                ? $(this).find('input.capacity_value').val()
                : 0;
        pieces[index]['QUANTITE'] = $('#bill-line-' + key + ' #quantity-' + key + ' .quant-cart').length > 0
                ? $('#bill-line-' + key + ' #quantity-' + key + ' input').val()
                : 0;
        pieces[index]['PRIXVENTEHT'] = $('#bill-line-' + key + ' input.DEprixVenteEF').attr('data-value') || 0;
        pieces[index]['PRIXACHAT'] = $('#bill-line-' + key + ' .prixAchat').html().trim().length > 0
                ? $('#bill-line-' + key + ' .prixAchat').html().substr(0, $('#bill-line-' + key + ' .prixAchat').html().indexOf('€')).trim().replace(',', '.')
                : 0;


        let operationsPiece = '';
        $('#bill-line-' + key + ' #operations-' + key + ' ul li').each(function (i) {
            operationsPiece += $(this).html() + "#";
        });
        pieces[index]['OPERATIONS'] = operationsPiece.slice(0, -1)
                ;

        if ($('#bill-line-' + key + ' #equipementiers_' + key).html().trim().length <= 0) {
            pieces[index]['EQUIPMENTIERS'] = '';
            pieces[index]['listCodRefEq'] = '';
        } else if ($('#bill-line-' + key + ' #equipementiers_' + key + ' select#mySelect :selected').length <= 0) {
            var equipmentier = {};
            equipmentier.k = $('#bill-line-' + key + ' #equipementiers_' + key + ' span').attr('class').trim();
            equipmentier.v = $('#bill-line-' + key + ' #equipementiers_' + key + ' span').html().trim();
            pieces[index]['EQUIPMENTIERS'] = JSON.stringify([equipmentier]);
            pieces[index]['listCodRefEq'] = $('#bill-line-' + key + ' #equipementiers_' + key + ' span').attr('listcodrefeq');
        } else {
            var equipmentier = '';
            var listcodref = '';
            $('#bill-line-' + key + ' .equipementiers select.mySelect').each(function (i) {
                var j = 0;
                var listEquip = [];
                $(this).find('option').each(function (i) {
                    var classDiv = $(this).attr('class');
                    if (typeof classDiv !== typeof undefined && classDiv !== false) {
                        var equipmentier = {};
                        equipmentier.k = $(this).attr('class').trim();
                        equipmentier.v = $(this).val().trim();
                        if ($('#bill-line-' + key + ' .mySelect').find(':selected').attr('class') == $(this).attr('class')) {
                            equipmentier.s = '1';
                        }

                        listEquip[j] = equipmentier;
                        j++;

                    }
                });
                equipmentier += JSON.stringify(listEquip) + "#";
                listcodref += $(this).attr('listcodrefeq') + "#";

            });

            pieces[index]['EQUIPMENTIERS'] = equipmentier.slice(0, -1);
            pieces[index]['listCodRefEq'] = listcodref.slice(0, -1);


        }

    });


    var sumTotalHt = $('#DEpriceTotalHs span').html().replace(',', '.').substr(0, $('#DEpriceTotalHs span').html().indexOf('€')).trim();
    var kilometrage = $('#km_devis').text().trim();
    var anciennety = $('#seniority_devis').text();
    var mid = $('.devis_mid').val();
    var ssid = $('.devis_ssid').val();
    var mileage = $('.devis_mileage').val();

    var sumTotal = step >= 4 ? $('.DESumTotal').val().replace(',', '.') : '';
    var originalSumTotal = step >= 4 ? $('#original-total').val() : '';


    jQuery.ajax({
        type: "POST",
        url: urlSaveDevis,
        data: {
            codmar: codmar,
            numord: numord,
            immat: immat,
            type: type,
            dmec: dmec,
            numOrds: numOrds,
            ktypnr: ktypnr,
            kilometrage: kilometrage,
            anciennety: anciennety,
            pieces: pieces,
            workForces: workForces,
            operations: aOperations,
            sumTotalHt: sumTotalHt,
            forfaitVal: forfaitVal,
            forfait: forfait,
            moTotal: MoTotal,
            billTotal: BillTotal,
            mid: mid,
            ssid: ssid,
            mileage: mileage,
            appelationVeh: appelationVeh,
            step: step,
            sumTotal: sumTotal,
            originalSumTotal: originalSumTotal,
            token : $('#__quote_confirm_token').val(),
        },
        dataType: 'json',
        beforeSend: function() {
            $('.spinner-confirmation-step').removeClass('hide').css('top', 0).css('right', '7px');
            $('.arrow-after.confirmation-step').hide();
            $('.required_error').css('display', 'none');
        },
        success: function (data) {
            if (data.step != 5) {
                if (data.numDevis) {
                    $('.numDevis').html(data.numDevis);
                    if (data.action == 'create') {
                        $('.create_devis').show();
                        $('.update_devis').hide();
                    } else if (data.action == 'edit') {
                        $('.create_devis').hide();
                        $('.update_devis').show();
                    }
                }
                if (showPopin) {
                    $('#modalSaveDevis').modal({keyboard: false});
                }
                updateOilPrice($('#mySelectOil'));
            } else {
                $('.convert_devis').show();
                $('.create_devis_error').hide();
                $('.marge-error').hide();
                $('#modalValidateDevis').modal({keyboard: false});
                $('#modalValidateDevis').on('shown.bs.modal', function () {
                    setTimeout(function () {
                        redirectToTrackDevis(data.numDevis)
                    }, 3000);
                })
            }
            $('.spinner-confirmation-step').addClass('hide');
            $('.arrow-after.confirmation-step').show();
        },
        onFailure: function (data) {
            console.log('error');
        },
        error: function(xhr){
            if (422 === xhr.status) {
                $(REQUIRED_ERROR).html(JSON.parse(xhr.responseText).message);
                $(REQUIRED_ERROR).show();
                jQuery('.content-bottom i').removeClass('fa fa-spinner fa-spin right-spinner').addClass('arrow-next-step');
            }
            $('.spinner-confirmation-step').addClass('hide');
            $('.arrow-after.confirmation-step').show();
        }
    });

}

export function recalculateTotals() {
    calculateSubTotal();
    calculateMoTotal();
    calculateTotal();
    if ($("#DEMainOeuvreTotalOrigine").length <= 0 || $("#DEMainOeuvreTotalOrigine").is(":hidden")) {
        $('#DEMainOeuvreTotalOrigine').html($("#DEMainOeuvreTotal span").html() + ' HT');
    }
    initDatePickerAjax();
}

var parseInput = function (val) {
    var floatValue = parseFloat(val);
    return isNaN(floatValue) ? '' : floatValue;
}

export function validerRevision() {
    jQuery.ajax({
        type: "POST",
        data: {
            aParts: window.aParts
        },
        url: window.urlpopinValiderRevision,
        async: true,
        dataType: "html",
        success: function (data) {
            $('.RevisionData').html(data);
            $('#elementFactureTable > tbody > tr').each(function () {
                if ($(this).find('td.DEredBloc').length !== 0) {
                    $(this).find('input.DEprixVenteEF').attr('disabled', 'disabled');
                } else {
                    var sellingPrice = $(this).find('input.DEprixVenteEF');
                    if (sellingPrice.attr('disabled') !== undefined &&
                        sellingPrice.attr('disabled').type() !== undefined
                    ) {
                        sellingPrice.removeAttr('disabled');
                    }
                }
            });

            $(document).on('click', 'a#operations-btn.confirm-devis', function () {
                confirmation();
            });
            $(document).on('click', 'img#edit-ref-manufacturer', function () {
                getPopinRefEquip($(this));
            });

            $(document).on("click", '.fileProductRef', function () {
                let codrefeq = $(this).attr('data-codrefeq');
                let deflag = $(this).attr('data-deflag');
                var url = Routing.generate('autossimo_fiche_produit_equipementier_light', {codrefeq: codrefeq, advertisingBannerId: 0, deflag: deflag});
                getFicheProduitAjax(url,$(this));
            });

            $(document).on('click', '.addRefManuelle', function () {
                $('.modal-backdrop').hide();
                //$('.modal-backdrop').css('z-index','inherit');
                $('.step5.name').css('z-index', 'inherit');
                $('.DEelementFacturee tbody tr .name').removeClass('modal-content');
                $(this).parent().find('.modal.fade').css('z-index', '11');
                var tmp = $(this).parent().find('.modal.fade');
                $(tmp).addClass('in');
                $(tmp).css('display', 'block');

                var tr = $(this).parent();
                var ad_part_id = $(this).closest('tr').attr('ad-part-id');
                $.ajax({
                    url: "/refman",
                    type: 'POST',
                    data: {
                        codRep: codRep,
                        ad_part_id: ad_part_id
                    },
                    success: function (response) {
                        MajListRefMan(response, tr);
                    },
                    onFailure: function (response) {
                        alert('error: ' + response);
                    }
                });
            });

            $(document).on('click', '#valider', function () {
                validateManualReference($(this));
            });

            $('a.revision-back.call-autodata').unbind('click');
            $(document).on('click', 'a.revision-back.call-autodata', function () {
                callAutoData(
                    Routing.generate('autossimo_devis_constructeur_service_schedule_datas', {
                            'sMid': $(this).attr('data-smid'),
                            'ssid': $(this).attr('data-ssid'),
                            'mileage': $(this).attr('data-mileage')
                        }
                    )
                );
            });
            $('div[id^=bill-trash-]').on('click', function () {
                deleteLineEquipment($(this));
            });
            initializeEquip();
        },
        onFailure: function (data) {
            alert('error');
        }
    });
}

function initializeEquip() {
    checkElemNonFact();
    selectEquipByDefault();
    $('tbody tr td.step5.equipementiers select').unbind('click');
    $('tbody tr td.step5.equipementiers select').change(function () {
        var element = $(this).find('option:selected');
        var codEquip = element.attr('data-codequip');
        if (typeof codEquip !== typeof undefined && codEquip !== false) {
            var disabled = $(this).find('option:first').attr('disabled');
            if (typeof disabled === typeof undefined) {
                $(this).find('option:first').attr('disabled', 'disabled');
            }
            var myTd = $(this).parent().parent().find("td.refequip");
            var myRefOrigin = $(this).parent().parent().find("td.reforigine");
            getRefEquip(myRefOrigin, myTd, codEquip, element.parent().attr("listcodrefeq"));
        }
    });

    $('tbody tr td.step5.equipementiers span').each(function () {
        getRefEquip(
            $(this).parent().parent().find("td.reforigine"),
            $(this).parent().parent().find("td.refequip"),
            $(this).attr("data-codequip"),
            $(this).attr("listcodrefeq"),
            true
        );
    });
    $('.toggle-condition .toggle').toggles({
        text: {
            'on': '',
            'off': ''
        },
        width: 50
    });
    // first calculate
    recalculateTotals();

    initTooltip();

    $(document).on('click', '#prev-step.revision-back.back-confirm.DEstep4Aft', function () {
        backConfirmation();
    });

    $(document).on('click', 'a.devis-save.DEstep4Aft', function () {
        saveDevis(true, 4);
    });

    $(document).on('click', '.blueLink.DElink', function () {
        hightlightEF();
    });

    $(document).on('click', '.devis-save.devis-save-step3', function () {
        saveDevis(true, 3);
    });

    $(document).on('change', '#prixVenteOil', function () {
        let oldPrice = $(this).data('oldprice');
        let currentPrice = $(this).data('value');
        if (oldPrice !== currentPrice) {
            $('#popin-confirmation').modal('toggle');
        }
    });

    $(document).on("click", "#popin-confirmation #popin-confirmation-cancel", function () {
        $('#popin-confirmation').modal('toggle');
        let prixVenteOil = $('#prixVenteOil')
        prixVenteOil.attr('data-value', prixVenteOil.data('oldprice'))
        prixVenteOil.val(prixVenteOil.data('oldprice'))
    });
}

function deleteLineEquipment(element) {
    var line = element.parent().parent();
    if ((line.hasClass('delete-line') == false && line.hasClass('free') == false) || (line.hasClass('delete-line') != false && line.hasClass('free') != false)) {
        var id = element.attr('data-id');
        $('#bill-line-' + id).toggleClass('delete-line');
        $('#bill-line-' + id).find("input:not(.enable-jquery-datepicker), select").toggleDisabled();
        $('#bill-line-' + id).find('.dispo-details').toggleClass('delete-line');
        $('#bill-line-' + id).find('.DEprixVenteEF').toggleClass('delete-line');
        $('#bill-line-' + id).find('.info-oil').toggleClass('delete-line');
        $('#bill-line-' + id).find('.modal-equipref').toggleClass('delete-line');
        $('#bill-line-' + id).find('.modal_dErefOrigine').toggleClass('delete-line');
        $('#bill-line-' + id).find('.quant-cart').toggleClass('delete-line');

        if (element.parent().parent().hasClass('free') == false) {
            element.parent().parent().addClass('free');
            element.attr('title', 'Restaurer');
            element.attr('data-original-title', 'Restaurer');
        } else {
            element.parent().parent().removeClass('free');
            element.attr('title', 'Supprimer');
            element.attr('data-original-title', 'Supprimer');
        }
        element.closest('tr').find('input.DEprixVenteEF').css('border-color', '#e6e6e6');
        element.closest('tr').find('.mySelect').css('border-color', '#e6e6e6');
        recalculateTotals();
    }
}

$(document).ready(function () {

    (function ($) {
        $.fn.toggleDisabled = function () {
            return this.each(function () {
                this.disabled = !this.disabled;
            });
        };
    })(jQuery);

    $(document).on('change', ".step5.reforigine select:not(#mySelectOil)", function () {
        var myClassNb = $(this).find('option:selected').attr("class");
        var mycodrefco = $(this).parent().parent().find(TD_REFORIGINE + " select option." + myClassNb);
        $(mycodrefco).attr('selected', 'selected');
        var AllEquip = $(this).parent().parent().find("td.equipementiers select");
        var AllRefequip = $(this).parent().parent().find(TD_REFEQUIP + " select");
        var myEquip = $(this).parent().parent().find("td.equipementiers select." + myClassNb);
        var myRefequip = $(this).parent().parent().find(TD_REFEQUIP + " select." + myClassNb);
        $(AllEquip).css("display", 'none');
        $(AllRefequip).css("display", 'none');
        $(myEquip).css("display", 'block');
        $(myRefequip).css("display", 'block');
    });

    $(document).on("keyup", '.DEMainOeuvreItem', function () {
            recalculateTotals();
    });
    $(document).on("keyup", '.DEprixVenteEF', function () {

        var value = $(this).val();
        $(this).val(value.replace('.', ','));

        if ($(this).val().indexOf(",") > 0) {
            var number = $(this).val().split(',');
            if (number[1].length > 2)
            {
                var prixDeVente = parseFloat($(this).val().replace(',', '.'));
                $(this).val(prixDeVente.toFixed(2).replace('.', ','));
            }
        }

        if (value[value.length - 1] !== '.') {
            var pvht = value.replace(',', '.');
            pvht = parseFloat(pvht);
            pvht = pvht.toFixed(2);


            $(this).attr('data-value', pvht);

            recalculateTotals();
        }

    });
    $(document).on("focusout", '.DEprixVenteEF', function () {

        var value = $(this).val();
        value = value.replace(',', '.');
        value = parseFloat(value);
        value = value.toFixed(2);
        value = value.replace('.', ',');
        $(this).val(value);

    });
    $(document).on('click', '#DEPricePieces', function () {
        calculateTotal();
    });

    $('.DESG').hide();

    $(document).on('click', '.info-oil.delete-line', function () {
        event.preventDefault();
        return false;
    });

    $(document).on('keyup', '.capacity_value', function () {
        var isRefManuelle = $(this).closest('tr').find('input[type="hidden"]').first().attr('data-ref-manuelle');
        if (isRefManuelle == 'oui') {
            var input = $(this).parent().find('.icon-autodata');
            if ($(input).hasClass('show-0') == false) {
                $(input).addClass('show-0');
            }
            var capacity = parseFloat($(this).val().replace(',', '.'));
            var pieceMarge = parseFloat($('#piece-marge').val()) / 100;
            var line = $(this).parent().parent();
            var dataId = $(line).attr("data-id");
            var appointData = ($('#appoint-' + dataId).length > 0) ? $('#appoint-' + dataId).val() : false;
            if (appointData !== false && appointData.indexOf('app') >= 0) {
                var prixAchat = capacity * (parseFloat($('#appoint').val()) / 100);
                var purchaseRefman = $(line).find('td.prixAchat').attr('data-purchase-refman');
                var prixAchatTotal = prixAchat * purchaseRefman;
                var prixVente = (prixAchatTotal * pieceMarge) + prixAchatTotal;
            } else {
                var purchaseRefman = $(line).find('td.prixAchat').attr('data-purchase-refman');
                var prixAchatTotal = capacity * purchaseRefman;
                var prixVente = prixAchatTotal * (1 + pieceMarge);
            }

            if (prixVente > 0 && prixAchatTotal > 0) {

                $('#selling-' + dataId + ' .calculate2').val(prixVente.toFixed(2).replace('.', ','));
                $('#selling-' + dataId + ' .calculate2').attr('data-value', prixVente.toFixed(2));
                $(line).find('.prixAchat').html('<span>' + parseFloat(prixAchatTotal).toFixed(2).replace('.', ',') + " </span> €");
                recalculateTotals();
            }
        }

    });
    $(document).on('click', '.refManuelle', function () {
        addEditRefMan();
    });

    $(document).on('click', '.overlay', function () {
        $('.overlay').hide();
        $('.addRefManuelle').hide();
        $('.step5.name').css('z-index', 'inherit');
    });
    if ($('.DErefMan').length > 0) {
        $(document).on('click', '.overlay', function () {
            $('.overlay').hide();
            $('.addRefManuelle').hide();
            $('.step5.name').css('z-index', 'inherit');
        });
        $('.DErefMan').hide();
    }

    $('.devis-fiche .modal-dialog .modal-content').css('top', '0px');

    $(document).mouseup(function (e)
    {
        var container = $('.DErefMan.modal.fade.in');
        if (!container.is(e.target) && container.has(e.target).length === 0 && $(container).length > 0)
        {
            container.hide();
            $('.overlay').hide();
            $('.addRefManuelle').hide();
        }
    });

    $(document).on('click', '.info-oil.delete-line', function (event) {
        event.preventDefault();
        return false;
    });
    $(document).on('keyup', '.capacity_value', function () {
        var input = $(this).parent().find('.icon-autodata');
        if ($(input).hasClass('show-0') == false) {
            $(input).addClass('show-0');
        }
    });

    $(document).on('change', '#mo-table select', function () {
        var id = $(this).attr('data-id');
        var timehrs = $('#timehrs-' + id).val();
        timehrs = timehrs.replace(',', '.');
        var total = this.value * timehrs;
        $('#unit-price-mo-' + id).val(total.toFixed(2).replace('.', ','));
        $('#unit-price-mo-' + id).attr('data-value', total);
        recalculateTotals();
    });

    $(document).on('click', '#forfaits', function () {
        $("div.pice-mo input").css('color', '#e6e6e6');
        $("div.pice-mo input").attr('disabled', 'disabled');
        var htToTTC = 1.2;
        var subtotalHt = parseFloat($(this).val());
        var subtotalTtc = subtotalHt * htToTTC;
        $("#DEMainOeuvreTotal span").html(subtotalHt.toFixed(2).replace('.', ',') + " €");
        $("#DEMainOeuvreTotalTTC span").html(subtotalTtc.toFixed(2).replace('.', ',') + " €");
        $("#DEMainOeuvreTotalOrigine").css('display', 'block');
        $("#DEMainOeuvreTotalOrigine").addClass('show_forfait');
        $(".remove_forfaits label").html($("#forfaits option:selected").attr('data-design'));
        $("#btnForfait").html('CHANGER FORFAITS');
        calculateTotal();
        $(".remove_forfaits").css('display', 'block');
        removeLineMO();
    });
    $(document).on('click', '.DEforfaitSelect', function () {

        $("div.pice-mo input").css('color', '#e6e6e6');
        $("div.pice-mo input").attr('disabled', 'disabled');
        $("input.DEtb").css('color', '#e6e6e6');
        $("input.DEtb").attr('disabled', 'disabled');
        $("select.DEmyselect").css('color', '#e6e6e6');
        $("select.DEmyselect").attr('disabled', 'disabled');
        var htToTTC = 1.2;
        var subtotalHt = parseFloat($(this).attr('data-pvht'));
        var subtotalTtc = subtotalHt * htToTTC;
        $("#DEMainOeuvreTotal span").html(subtotalHt.toFixed(2).replace('.', ',') + " €");
        $("#DEMainOeuvreTotalTTC span").html(subtotalTtc.toFixed(2).replace('.', ',') + " €");
        $("#DEMainOeuvreTotalOrigine").css('display', 'block');
        $("#DEMainOeuvreTotalOrigine").addClass('show_forfait');
        $("#btnForfait").html('CHANGER FORFAITS');
        $(".remove_forfaits label").html($(this).attr('data-design'));
        calculateTotal();
        $(".remove_forfaits").css('display', 'block');
        $('#DEforfait').modal('hide');
        removeLineMO();
    });

    $(document).on('click', '.remove_forfaits span', function () {
        $("div.pice-mo input").removeAttr('style');
        $("div.pice-mo input").removeAttr('disabled');
        $("input.DEtb").removeAttr('style');
        $("input.DEtb").removeAttr('disabled');
        $("select.DEmyselect").removeAttr('style');
        $("select.DEmyselect").removeAttr('disabled');
        $("#DEMainOeuvreTotalOrigine").removeClass('show_forfait');
        $("#DEMainOeuvreTotalOrigine").removeAttr('style');
        $("#btnForfait").html('Appliquer un forfait');
        recalculateTotals();
        $(".remove_forfaits").css('display', 'none');
        activeLineMO();
    });
    $(document).on('hide.bs.modal', '.DErefMan', function () {
        $('.overlay').click();
    });
});

function removeLineMO() {
    $(".mainOeuvre #mo-table tbody tr").each(function () {
        $(this).addClass('free');
        $(this).find('.timehrs').attr('disabled', 'disabled');
        $(this).find('.timehrs').css('color', '#ccc');
        $(this).find('.DEmyselect').attr('disabled', 'disabled');
        $(this).find('.DEdel').attr('onclick', '');
    });
}
function activeLineMO() {
    $(".mainOeuvre #mo-table tbody tr").each(function () {
        $(this).removeClass('free');
        $(this).find('.timehrs').removeAttr('disabled');
        $(this).find('.timehrs').css('color', '');
        $(this).find('.DEmyselect').removeAttr('disabled');
        $(this).find('.DEdel').attr('onclick', 'DEdelMO($(this));');
    });
}

// Rappel des erps à l'ouverture de la popin des refs equivalentes
function getPopinRefEquip(manufacturerRefNode, optionSelected=null) {
    var args = getPopinRefEquipArgs(manufacturerRefNode);
    var token = (args.codref === '--') ? 'consommable' : 'equipementier';
    var line = args.line;

    $.ajax({
        type: "POST",
        data: {
            codequip: args.manufacturerCode,
            listcodrefeq: args.listcodrefeq,
            codarbo: args.codarbo,
            line: line,
            token: token,
            margePiece: args.margePiece,
            originalPublicPrice: args.originalPublicPrice
        },
        url: Routing.generate('autossimo_devis_popin_refequip'),
        dataType: "html",
        beforeSend: function() {
            $(ID_BILL_LINE+line).find(TD_REFEQUIP).find(MODAL_EQ_REF).addClass('hide');
            $(ID_BILL_LINE+line).find(TD_REFEQUIP).find('i').removeClass('hide');
            $(ID_BILL_LINE+line).find(TD_REFEQUIP).find('i').parent().addClass(TEXT_CENTER);
            $(ID_BILL_LINE+line).find(TD_REFEQUIP).find(TEXT_LOAD).removeClass('hide');
            $(ID_BILL_LINE+line).find(TD_REFEQUIP).find('.popinRefEquip').empty();
        },
        success: function(data) {
            $(ID_BILL_LINE+line).find(TD_REFEQUIP).find(MODAL_EQ_REF).removeClass('hide');
            $(ID_BILL_LINE+line).find(TD_REFEQUIP).find('i').addClass('hide');
            $(ID_BILL_LINE+line).find(TD_REFEQUIP).find('i').parent().removeClass(TEXT_CENTER);
            $(ID_BILL_LINE+line).find(TD_REFEQUIP).find(TEXT_LOAD).addClass('hide');
            $(ID_BILL_LINE+line).find(TD_REFEQUIP).find('.popinRefEquip').append(data);
            if (optionSelected !== null) {
                $('#'+optionSelected+' #selectRefeq').click();
            } else {
                $(ID_BILL_LINE+line).find('.DEPopinRefEqui').modal('show');
            }
            $('.enable-jquery-datepicker').ready(function () {
                $('.enable-jquery-datepicker').datepicker();
            });
        },
        error: function() {
            $(ID_BILL_LINE+line).find(TD_REFEQUIP).find(MODAL_EQ_REF).removeClass('hide');
            $(ID_BILL_LINE+line).find(TD_REFEQUIP).find('i').addClass('hide');
            $(ID_BILL_LINE+line).find(TD_REFEQUIP).find('i').parent().removeClass(TEXT_CENTER);
            $(ID_BILL_LINE+line).find(TD_REFEQUIP).find(TEXT_LOAD).addClass('hide');
            alert('Une erreur est survenue. Merci de réessayer');
        }
    });
}

// AD-2593
// Présélection par défaut du 1er equipementier de la liste
// dans le cas où il n'y en a pas de présélectionné automatiquement
export function selectEquipByDefault() {
    $(".DEelementFacturee tbody tr").not('.warning-ref').each(function() {
        var hasSelect = ($(this).find('td.equipementiers > select').length > 0) ? true : false;
        var hasValueSelected = ($(this).find('td.equipementiers > select :selected').val() != 'Choisir un équipementier') ? true : false;
        if (hasSelect && !hasValueSelected) {
            $(this).find('td.equipementiers > select > option:nth-child(2)').attr('selected', 'selected');
            var optionSelected = $(this).find('td.equipementiers > select > option:nth-child(2)');
            var codEquip = optionSelected.attr('data-codequip');
            getRefEquip(
                $(this).find(TD_REFORIGINE),
                $(this).find(TD_REFEQUIP),
                codEquip,
                optionSelected.parent().attr('listcodrefeq'),
                false,
                true
            );
        }
    });
}
