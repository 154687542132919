import { AMPLITUDE_CLASS } from "../../constants";
import CompleteOrderEvent from "./CompleteOrderEvent";
import { ProductFactory } from "../dto/product/ProductFactory";
export default class CompleteOrderNormalizer {
    normalize(elements, origin) {
        const completeOrderEvent = new CompleteOrderEvent(elements, origin);
        const amplitudeDivs = $(AMPLITUDE_CLASS);
        if (0 === amplitudeDivs.length) {
            return completeOrderEvent;
        }
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            completeOrderEvent.productCollection.addProduct(product);
        });
        completeOrderEvent.orders = this.getOrders();
        const totalOrderAmount = completeOrderEvent.orders.reduce((accumulator, order) => {
            return accumulator + parseFloat(order.order_amount);
        }, 0);
        completeOrderEvent.totalOrdersAmount = Math.floor(totalOrderAmount * 100) / 100;
        return completeOrderEvent;
    }
    support(element, origin) {
        return true;
    }
    getOrders() {
        const orders = [];
        let table = $('#table-order-distri');
        if (table.length) {
            orders.push(this.getTableData(table, 'distri'));
        }
        table = $('#table-order-idg');
        if (table.length) {
            orders.push(this.getTableData(table, 'idg'));
        }
        table = $('#table-order-cora');
        if (table.length) {
            orders.push(this.getTableData(table, 'cora'));
        }
        table = $('#table-order-drop');
        if (table.length) {
            orders.push(this.getTableData(table, 'drop'));
        }
        table = $('#table-order-diproconnect');
        if (table.length) {
            orders.push(this.getTableData(table, 'diproconnect'));
        }
        return orders;
    }
    getTableData(table, type) {
        var _a;
        return {
            order_type: type,
            order_number: $(table).data('numcde'),
            order_amount: $(table).data('total-amount'),
            movex_transaction_id: (_a = $(table).data('movex-orno')) !== null && _a !== void 0 ? _a : null
        };
    }
}
