const PRICE_LEVEL_SELECT = 'select.selectPriceLevel';

export function getPromotionBand(value, refeq, modePrice) {
    let promo = "";
    var isFolderPage =  $('.folders-content').length;
    var isPackageAd =  $('#packages-ad-content').length;
    const typePromo = refeq.find('.promo_flash_24');
    if (value.PRICE_LEVEL && value.PRICE_LEVEL_DETAIL.PROMOTION_ID !== '' && isFolderPage !== 1 && isPackageAd !== 1) {
        refeq.find('.price-level-value').val('1');
        refeq.find('.price-level-action').removeClass("hidden");
        if (modePrice === true) {
            refeq.find('.price-level-bloc').removeClass("hidden");
        }
        refeq.find('.net-price-container').addClass("hidden");

        var promotionsLevel = value.PRICE_LEVEL_DETAIL.PROMOTIONS_LEVELS;
        var promotionLevelId = value.PRICE_LEVEL_DETAIL.PROMOTION_ID;

        promotionsLevel.forEach(function (item) {
            var option = document.createElement("option");
            $(option).html(item.priceHT.replace(".", ",").concat(" € <small> HT par ").concat(item.quantity).concat("</small>"));
            $(option).attr('value',item.quantity);
            $(option).attr('data-id', promotionLevelId )
            if (item.priceHT >= 100) {
                $(option).addClass('smaller');
            }
            refeq.find(PRICE_LEVEL_SELECT).append($(option));
        });

        var paht = value.PAHT.toString().replace('.', ',');

        // add basic price
        var optionBasic = document.createElement("option");
        checkWebPrice(value.WEB_PRICE, optionBasic, paht);
        $(optionBasic).attr('value',  1);
        $(optionBasic).attr('data-id', '9999' );
        if (paht >= 100 ) {
            $(optionBasic).addClass('smaller');
        }
        refeq.find(PRICE_LEVEL_SELECT).append($(optionBasic));
        refeq.find(PRICE_LEVEL_SELECT).val(1);

        $('.selectPriceLevel').selectpicker('refresh');

        var options = refeq.find(PRICE_LEVEL_SELECT);
        var input = refeq.find('div.cart-action input');

        input.val(options.val());
        input.attr('data-quantity', options.val());
        input.attr('value', options.val());
    } else if (value.WEB_PRICE) {
        refeq.find('.autossimo-price-action').removeClass("hidden");
    } else if (value.PROMO || value.PROMO_DROP) {
        refeq.find('.promo-band-action').removeClass("hidden");
    } else if (value.PROMO_AUTOSSIMO) {
        refeq.find('.promo-autossimo-action').removeClass("hidden");
    } else if (typePromo.length > 0) {
        typePromo.removeClass("hidden");
    }
}

function checkWebPrice(webPrice, optionBasic, paht)
{
    if (webPrice) {
        $(optionBasic).addClass('price-level-li-web-price');
        $(optionBasic).html(paht +" € <small> PRIX AUTOSSIMO </small>");
    } else {
        $(optionBasic).addClass('based');
        $(optionBasic).html(paht +" € <small> HT De base </small>");
    }
}


export function showPromoBands(references, type) {
    const priceMode = document
        .querySelector('#toggle_currency_mode')
        .checked;
    $.each(references, (index, value) => {
        const id = value.CODEQU + value.REF.replace('%20', ' ')
        const element = $(`[data-equiprefeq="${id}"]`);
        const tirePriceBloc = element.find('.tyre-price-bloc.portal-customer-bloc-distrib');
        const advertisingLabel = element.find('.advertising-banner-limitation-label');
        element.find('.promo-bands-container').removeClass('hidden');
        if (tirePriceBloc.length === 0 || tirePriceBloc.is(':visible')) {
            advertisingLabel.removeClass('hidden');
        }
    })
    if (type === 'cart') {
        $('.promo-bands-container').removeClass('hidden');
        $('.advertising-banner-limitation-label').removeClass('hidden');
    }
    if (priceMode) {
        $('.promo-bands-container > .promo-band:not(.hidden)').removeClass('showme');
    }
}