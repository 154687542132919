import MatchingResultsEvent from "./MatchingResultsEvent";
import { ProductFactory } from "../dto/product/ProductFactory";
export default class TireNormalizer {
    normalize(element, context) {
        const matchingResultsEvent = new MatchingResultsEvent();
        const isLoadingBlock = element.find('.compatible-loading-block');
        matchingResultsEvent.isLoading = isLoadingBlock && 0 !== isLoadingBlock.length;
        const amplitudeDivs = element.find('.containerDispo .amplitude');
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            matchingResultsEvent.productCollection.addProduct(product);
        });
        return matchingResultsEvent;
    }
    support(element, context) {
        return 'pneus' === context.catalog;
    }
}
