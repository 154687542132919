import { ProductCollection } from "../dto/product/ProductCollection";
export default class DisplayIamForOemEvent {
    constructor(origin) {
        this.oemReferenceCode = '';
        this.oemName = '';
        this.totalIamForThisOem = '';
        this.products = [];
        this.productCollection = new ProductCollection();
    }
    export() {
        return {
            oem_reference_code: this.oemReferenceCode,
            oem_name: this.oemName,
            total_iam_for_this_oem: this.totalIamForThisOem,
            products: this.productCollection.export(),
        };
    }
}
