export default class Flash {
    static get(key) {
        const result = sessionStorage.getItem(key);
        this.remove(key);

        return result;
    }

    static set(key, value) {
        sessionStorage.setItem(key, value);
    }

    static remove(key) {
        sessionStorage.removeItem(key);
    }

    static getViewItemResultOrigin() {
        return this.get('view_item_results_origin');
    }

    static setViewItemResultOrigin(value) {
        this.set('view_item_results_origin', value);
    }

    static isFiltredViewItemResult() {
        return 'true' === this.get('filtred_view_item_results');
    }

    static setFiltredViewItemResult(value) {
        this.set('filtred_view_item_results', value);
    }

    static setPieceCachedStartSearchEvent(startSearchEvent) {
        this.set('pieceCachedStartSearchEvent', JSON.stringify(startSearchEvent));
    }
    static getPieceCachedStartSearchEvent() {
        const obj = this.get('pieceCachedStartSearchEvent');
        if (undefined === obj) {
            return null;
        }

        return JSON.parse(obj);
    }

    static setPaintCachedStartSearchEvent(startSearchEvent) {
        this.set('paintCachedStartSearchEvent', JSON.stringify(startSearchEvent));
    }
    static getPaintCachedStartSearchEvent() {
        const obj = this.get('paintCachedStartSearchEvent');
        if (undefined === obj) {
            return null;
        }

        return JSON.parse(obj);
    }

    static setConsumableCachedStartSearchEvent(startSearchEvent) {
        this.set('consumableCachedStartSearchEvent', JSON.stringify(startSearchEvent));
    }
    static getConsumableCachedStartSearchEvent() {
        const obj = this.get('consumableCachedStartSearchEvent');
        if (undefined === obj) {
            return null;
        }

        return JSON.parse(obj);
    }
}
