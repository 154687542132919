import { majMontantPanier } from './pubtech-panier'
import { getFicheProduitAjax } from './autossimo-peintures-popins'
import { initImgWithLazyLoading, productImgLazyLoading } from "./init-images-carousel";
import {initOwlCarousel} from './carousel';
import {getConsumablesPrices} from "./autossimo-consumables";
import {updateBlocPrice, formatParamsForErpRequest, updateItemAvailability} from "./consumable-availability-price";
import {
    animatePanierButton,
    handleAjaxResult,
    handleProductExistsInCart,
    preAddToCartCheck,
    preAddToCartSingleProductDataCollect
} from "./pre-add-to-cart";
import $ from "jquery";
import {handleQuantityUpdate} from "./quantityManager";
import EventHandler from './analytics/amplitude/EventHandler';
import {showPromoBands} from './promotion-bands';
import GaEventHandler from './analytics/ga/GaEventHandler';

const SEARCH_FORM = '#searchForm';
const PREVIOUS_SLIDE = '.previousSlide';
const NEXT_SLIDE = '.nextSlide';
const BLOCK_PRODUCT = '.ref-results-items div.consumable-product'
const COUNT_FAVORITES = '.countFavorites';
const RESULTS_ITEMS = '.ref-results-items div.consumable-product[data-index="{i}"]';
const INDEX_PLACEHOLDER = '{i}';
const BLOCK_SLIDER = '.block-slider';
const BLOCK_SLIDER_FAVORITES = '.block-slider.favorites';
const SLIDER_PRODUCT = '.products-consumables .consumable.slider-product';
const FIRST_SLIDES_TO_LOAD = 2;
const DATA_SLIDE = 'data-slide';
const DATA_ITEMS_SLIDE = 'data-itemsperslide';
const SPAN_CART = 'a.cart-opener span.nbPanier';
const DATA_CODFAM = 'data-codfam';
const LIST_FAV_SUGG = '.list-fav-suggest';
const ANIMATED = 'animated fadeInRight';
const BLOCK_RESULTS = '.blocks-results';
const ARBO_FAMILY = '.arbo-family';
const BREADCRUM_FAM = '.breadcrumb_fam';
const DATA_INDEX = 'data-index';
const BREADCRUM_FAM_EMPTY = '.breadcrumb_fam_empty';
const END_OF_LIFE = 'END_OF_LIFE'
const OUT_OF_STORE = 'OUT_OF_STORE';

$(document).ready(function () {
    loadSliderAvailabilityPrice();

    $('.fabricants-list').click(function (event) {
        fabriquantLogoChanged($(event.target));
    });

    $('.abrasif_link').click(function(){
        $(this).closest(BLOCK_RESULTS).find('.abrasif').removeClass('hide').addClass(ANIMATED);
        $(this).closest(LIST_FAV_SUGG).addClass('hide');
    });

    $('.abrasif_title').click(function(){
        $(this).closest(BLOCK_RESULTS).find(LIST_FAV_SUGG).removeClass('hide').addClass(ANIMATED);
        $(this).closest('.abrasif').addClass('hide');
    });

    $('.consomable_link').click(function(){
        $(this).closest(BLOCK_RESULTS).find('.consomable_peinture').removeClass('hide').addClass(ANIMATED);
        $(this).closest(LIST_FAV_SUGG).addClass('hide');
    });

    $('.consomable_title').click(function(){
        $(this).closest(BLOCK_RESULTS).find(LIST_FAV_SUGG).removeClass('hide').addClass(ANIMATED);
        $(this).closest('.consomable_peinture').addClass('hide');
    });

    // breadcrumb peinture
    $(document).on('click', '.breadcrumb-head', function (event) {
        if (!$(this).hasClass('empty')) {
            $(this).closest('.select').find('.select-panel-options').toggle();
            $(this).closest('.select').find('.select-panel-options').removeClass('hide');
        }

        return false;
    });

    //select navarbo breadcrumb rubric option
    $(document).on('click', '.rub_item', function(){
        var button = this.childNodes[1];
        if(!$(button).hasClass('no-clic')){
            setBreadCrumbRub(button);
        }
        return false;
    });

    //select navarbo breadcrumb fam option
    $(document).on('click', '.fam_breadcrumb', function(){
        var button = this.childNodes[1];
        if(!$(button).hasClass('no-clic')){
            setBreadCrumbFam(button);
        }
        return false;
    });

    //select navarbo breadcrumb subfam option
    $(document).on('click', '.sfam_breadcrumb', function(){
        var button = this.childNodes[1];
        if(!$(button).hasClass('no-clic')){
            setBreadCrumbSfam(button);
        }
        return false;
    });

    // prevent click on a link
    $(document).on('click', '.no-clic', function(event){
        event.preventDefault();
        return false;
    });

    //show filters
    $(document).on('click', '#show-filters', function(){
        $('.ref-results-filters').removeClass('hide');
        $('#show-filters').addClass('hide');
        if ($('.filers-last').length > 0 ) {
            $('.more-criteria').removeClass('hide');
        }
        return false;
    });

    //hide filters
    $(document).on('click', '#hide-filters', function(){
        $('.ref-results-filters').addClass('hide');
        $('#show-filters').removeClass('hide');
        $('.filers-last').addClass('hide');
        $('.less-criteria').addClass('hide');
        if ($('.filers-last').length > 0 ) {
            $('.more-criteria').removeClass('hide');
        }
        return false;
    });

    //more criteria filter
    $(document).on('click', '.more-criteria', function(){
        $('.filers-last').removeClass('hide');
        $('.more-criteria').addClass('hide');
        $('.less-criteria').removeClass('hide');
        return false;
    });

    //less criteria filter
    $(document).on('click', '.less-criteria', function(){
        $('.filers-last').addClass('hide');
        $('.more-criteria').removeClass('hide');
        $('.less-criteria').addClass('hide');
        return false;
    });

    $(document).on('click', '.submit_filter', function(){
        submitFilteredCriteria();

        return false;
    });
    bindSelectFiltersChange();

    $(document).on('click', '.init-filter', function(){
        $(".ref-results-filters select option:first-child").prop("selected", true);
        $(".ref-results-filters select option").each(function () {
            $(this).removeClass('no-clic');
            $(this).prop('disabled', false);
        });
        submitFilteredCriteria();

        return false;
    });



    function submitFilteredCriteria() {
        var listFilter = [];
        $('.ref-results-filters select option:selected').each(function (index) {
            if ($(this).val()) {
                listFilter.push({'crit': $(this).closest('.filter-block').find('label').attr('data-crit'), 'val': $(this).val()});
            }
        });
        if (listFilter.length > 0) {
            var codarbo = filterCodArbo();
            $('.ref-results-items').attr('data-page', 1);
            $('.ajaxLoad').val(1);
            loadAsyncBlockRefs(codarbo, listFilter);
            $('.filters-data').val(JSON.stringify(listFilter));
        }
    }

    $(document).on('click', '.ajout-panier-peinture', function(e){
        e.preventDefault();
        $(this).prop('disabled', true);
        const path = $(this).data("path");
        const ref = $(this).data("ref");
        const codref = $(this).data("codref");
        const codarbo = $(this).data("codarbo");
        const availability = $(this).attr("data-availability");
        const refeq = $(this).data("refeq-cart");
        const manufacturerCode = $(this).data("manufacturer-code");
        const qte = $('#Q-' + codref).val();
        const typeLigne = "C";
        const date = $(this).attr("data-date");
        const advertisingBannerId = $(this).attr('data-advertising-banner-id');
        GaEventHandler.addToCart($(this), 'peinture');
        if('block' === $(this).parents('.v3_pricing').find('.availability-pricing:not(.mode) .bloc-contact-your-distributor.with-contact-us').css('display')) {
            EventHandler.addToCart($(e.target),'item_view_results',false);
            getPopin($(this), Routing.generate('autossimo_pop_in_available_on_demand'), 'popInAvailableOnDemand');
        } else {
            const data = {
                path: path,
                ref: ref,
                codref: codref,
                qte: qte,
                typeligne: typeLigne,
                date: date,
                codarbo: codarbo,
                availability: availability,
                refeq: refeq,
                manufacturerCode: manufacturerCode,
                advertisingBannerId: advertisingBannerId,
            }
            let considerDrophipping = false;
            if ($(this).attr('data-portal-tyre-drop') !== undefined && $(this).attr('data-portal-tyre-drop') === 'O') {
                considerDrophipping = true;
            }

            const preAddCheck = preAddToCartCheck(preAddToCartSingleProductDataCollect(data), false);
            
            if(handleAjaxResult(preAddCheck)){
                handleProductExistsInCart(preAddCheck, function(){
                    ajouterPanierPeintures(data, considerDrophipping).done(
                            (response) => EventHandler.addToCart($(e.target),'item_view_results',response.success)
                        )
                    }, 
                    $(this),
                    () => EventHandler.addToCart($(e.target),'item_view_results',false)
                )
            } else {
                ajouterPanierPeintures(data, considerDrophipping).done(
                    (response) => EventHandler.addToCart($(e.target),'item_view_results',response.success)
                )
                animatePanierButton($(this))
            }
        }
    });


    $('.init-div').hover(function () {
        $('.init-tooltip').removeClass('hide');
    }, function () {
        $('.init-tooltip').addClass('hide');
    });

    $('body').on('hidden.bs.modal', '.modal_peinture', function () {
        $('video').trigger('pause');
    });

    $(document).on('click', '.relative a.btn-blue.add', function () {
        $('#popin-print').modal();
    });

    $(document).on('click', 'div.search-consumables-block li.ui-menu-item', function() {
        var input = $("input[name='reference']");
        if (input.val() != "") {
            if ($(this).attr('data-refcom') !== undefined) {
                $('input[name=reffab]').val($(this).find('a').attr('data-reffab'));
                $('input[name=codfou]').val($(this).find('a').attr('data-codfou'));
                $('input[name=codref]').val($(this).find('a').attr('data-codref'));
                $('input[name=codarbo]').val($(this).find('a').attr('data-codarbo'));
            } else {
                $('input[name=reffab]').val($(this).val());
            }
        }
        $(SEARCH_FORM).submit();
    });
});

function loadSliderAvailabilityPrice() {
    const sliders = $(BLOCK_SLIDER).length;
    if (sliders > 0) {
        let elements = [];
        $(BLOCK_SLIDER).each(function () {
            const itemsperslide = parseInt($(this).attr(DATA_ITEMS_SLIDE));
            const start = 0;
            const limit = itemsperslide * FIRST_SLIDES_TO_LOAD;
            elements = elements.concat(getSlideElements($(this), start, limit));
        });
        productImgLazyLoading(elements);
        getPaintPrices(elements);
    }
}

function preloadNextSlideAvailabilityPrice(blocSlider) {
    const itemsperslide = parseInt(blocSlider.attr(DATA_ITEMS_SLIDE));
    const currentSlide = parseInt(blocSlider.attr(DATA_SLIDE));
    const start = itemsperslide * currentSlide;
    const limit = itemsperslide * (currentSlide+1);
    const elements = getSlideElements(blocSlider, start, limit);
    productImgLazyLoading(elements);
    getPaintPrices(elements);
}

function getSlideElements(blocSlider, start, limit) {
    let elements = []; //NOSONAR
    for (let i = start; i < limit; i++) {
        const slide = $(blocSlider.find(SLIDER_PRODUCT).get(i));
        if (slide.length > 0 && !slide.hasClass('priceLoaded') && !slide.hasClass('priceLoading')) {
            elements.push(slide);
        }
    }

    return elements;
}

function getPaintPrices(items){
    if (items.length === 0) {
        return;
    }
    let reffabData = [];//NOSONAR
    items.forEach( item => {
        reffabData.push(formatParamsForErpRequest(item));
    });
    $.ajax({
        type: 'POST',
        url: Routing.generate('autossimo_consumables_get_prices'),
        data: {
            references: JSON.stringify(reffabData)
        },
        async: true,
        beforeSend: function () {
            items.forEach( item => {
                item.addClass('priceLoading');
            });
        },
        success: function (data) {
            const updatedRef = data.data.results;
            const errorCode = data.data.error;
            const sliders = $(BLOCK_SLIDER).length;

            if (errorCode !== null && errorCode !== undefined) {
                const erpNOK = errorCode['erp-nok-distrib'];
                $('.erp_nok_distributor').attr('value', erpNOK);
            }

            if (Array.isArray(updatedRef) && updatedRef.length > 0) {
                const searchType = $('#search_type').data('type-search');
                const updatedRefCount = updatedRef.length;
                updatedRef.forEach((item) => {
                    const itemToUpdate = $(`.consumable[data-codref="${item.CODREF}"]`);
                    updateItemStatusAndPrice(item, itemToUpdate, searchType, updatedRefCount);
                });
            }
            if (sliders > 0) {
                $(BLOCK_SLIDER.concat(' .consumable-customer-price .public')).addClass('hidden');
                $(BLOCK_SLIDER.concat(' .same-price-ht-ttc')).addClass('hidden');
                $(BLOCK_SLIDER.concat(' .consumable-price')).addClass('slider-prices');
            }
            showPromoBands(updatedRef);
        }
    });
}

function updateItemStatusAndPrice(item, itemToUpdate, searchType, updatedRefCount, blockItemToUpdate = null) {
    if(
        getPromoType(itemToUpdate) === "flash_24" &&
        item["DISPO"]  === "A" &&
        isPriced(item)
    ) {
        setAvailable(itemToUpdate);
    };

    if (item.PRODUCT_STATUS === END_OF_LIFE) {
        updateItemAvailability(itemToUpdate, searchType, updatedRefCount, blockItemToUpdate);
    } else {
        itemToUpdate.each(function () {
            $(this).removeClass('priceLoading');
            updateBlocPrice($(this), item, true);
            $(this).find('.price-conso').removeClass("hidden");
            if (item.PRODUCT_STATUS === OUT_OF_STORE) {
                handleQuantityUpdate($(this), item.STOCK);
            }
        });
    }
}


function bindSelectFiltersChange() {
    $('.ref-results-filters select.select_filter').on('change', function () {
        var listFilter = [];
        $('.ref-results-filters select option:selected').each(function (index) {
            if ($(this).val()) {
                listFilter.push({'crit': $(this).closest('.filter-block').find('label').attr('data-crit'), 'val': $(this).val()});
            }
        });

        var codarbo = filterCodArbo();
        $('.ref-results-filters select.select_filter option').each(function () {
            $(this).removeClass('no-clic');
            $(this).removeAttr("disabled");

        });
        updateAsyncFilters(codarbo, listFilter);
    })
}

var scroller = {
    lockvalue: true,
    init: function () {
        $(window).scroll(function () {
            if (true === scroller.lockvalue) {
                if ($(window).scrollTop() == $(document).height() - $(window).height()) {
                    scroller.lock();
                    loadPageTable(afterLoadPage);
                }
            }
        });
    },
    lock: function () {
        scroller.lockvalue = false;
    },
    unlock: function () {
        scroller.lockvalue = true;
    }
};

function afterLoadPage() {
    scroller.unlock();
    // datepicker pour disponibilité
    $('.enable-jquery-datepicker').datepicker($.datepicker.regional[ "fr" ]);
    $(document).on('click', '.enable-jquery-datepicker + .icon-calendar-orange', function () {
        $(this).prev().focus();
        return false;
    });
}

export function initAutocompletePneus() {
    $("#pneusAutocomplete").autocomplete({
        source: function (request, response) {
            var element = $(this.element);
            var previousRequest = element.data( "jqXHR" );
            if( previousRequest ) {
                // a previous request has been made.
                // though we don't know if it's concluded
                // we can try and kill it in case it hasn't
                previousRequest.abort();
            }
            element.data( "jqXHR", $.ajax({
                url: Routing.generate('autossimo_autocompletion_pneus_ajax'),
                data: request,
//                dataType: "html",
                type: "POST",

                success: function (data) {
                    const autocompleteUi = $('.autocomplete-ui');
                    autocompleteUi.show();
                    autocompleteUi.html(data);
                    const hasResults = autocompleteUi.find('#autocomplete-tires-result').data('has-results');
                    if (0 === hasResults) {
                        EventHandler.resultsNotFound($('#formpneu'), 'pneus');
                    }
                }
            }));
        },
        minLength: 6
    });
}

function fabriquantLogoChanged($clicked) {
    // selectionner le fabricant
    $('.fabricants-list').find('.fabricant').each(function () {
        $(this).removeClass('active');
        var code = $(this).closest('.fabricant').attr('codfou');
        $('#logo-' + code).val('off');
    });
    $clicked.closest('.fabricant').addClass('active');
    // met à jour le champ caché pour les données de provider
    var codfou = $clicked.closest('.fabricant').attr('codfou');
    $('#logo-' + codfou).val('on');

    $('.result-table').find('tbody').empty();
    $('.result-table').attr('page', 1);
    loadPageTable(afterLoadPage);
}

function retrievePostData() {
    const filters = jQuery('.filter-form').serializeArray();
    var codarbo = jQuery('#codarbo').val();
    var search = jQuery('#autocomplete').val();
    var sort = jQuery('#globalsort').val();

    // cas de logos fabricants
    var logos = jQuery('.filter-logo').serializeArray();
    logos.forEach(function (logo) {
        if (logo.value === 'on') {
            filters.push(logo);
        }
    });

    var data = {};
    data['filters'] = filters;
    data['codarbo'] = codarbo;
    data['search'] = search;
    data['sort'] = sort;

    return JSON.stringify(data);
}

function loadPageTable(afterLoad) {

    var page = parseInt($('.result-table').attr('page'));

    // no loading if complete
    if (page == 0) {
        return true;
    }

    const ajaxUrl = '/peintures/resultats-page-table/' + page;
    $('.result-table').attr('page', page + 1);

    const params = retrievePostData();
    const scrolltdata = $('.jscroll-table').data('jscrollt');
    if (typeof (scrolltdata) !== 'undefined') {
        scrolltdata.nextHref = '/peintures/resultats-page-table/2';
    }

    // display loading image
    var loadingHtml = '<tr class="even green loadingpage"><td colspan="6"><div class="loading-block green">'
        .concat('<img src="/build/images/loading.gif" height="32" width="32" alt="" />Chargement</div></td></tr>');
    $('tbody:last-child').append(loadingHtml);
    // retrieve page
    jQuery.ajax({
        type: 'POST',
        url: ajaxUrl,
        data: {paramValues: params},
        success: function (html) {
            // destroy loading image
            $('.loadingpage').remove();
            // add retrieved html content
            $('tbody:last-child').append(html);
            if (typeof (afterLoad) === "function") {
                afterLoad();
            }
        }
    });
}

function setBreadCrumbRub(selector) {
    var aListFam = [];
    var sCodArbo = $(selector).attr('data-codarbo');
    var sLibRub = $(selector).html();

    //Get list fam associated to selected rub
    $('ul.select-options-fam li a').each(function (index) {
        if (($(this).attr('data-codarbo')).substr(0, 2) == sCodArbo.substr(0, 2)) {
            $(this).parent().removeClass('hide');
            if (aListFam.length <= 0) {
                $(this).parent().addClass('selected');
            } else {
                $(this).parent().removeClass('selected');
            }
            if($(this).attr('data-sumprod') == 0){
                $(this).addClass('no-clic');
            }else{
                $(this).removeClass('no-clic');
            }
            aListFam.push({'CODARBO': $(this).attr('data-codarbo'), 'LIBRUB': $(this).html()});
        } else {
            $(this).parent().addClass('hide');
        }
    })
    if (aListFam.length <= 0) {
        $(ARBO_FAMILY).find(BREADCRUM_FAM).addClass('hide');
        $(ARBO_FAMILY).find(BREADCRUM_FAM_EMPTY).removeClass('hide');
        $(ARBO_FAMILY).find(BREADCRUM_FAM_EMPTY).parent().addClass('empty');
        $(ARBO_FAMILY).find('ul.select-options-fam').addClass('hide');

    }
    else {
        // show fam list options
        $(ARBO_FAMILY).find('.select-options-fam').show();
        $(ARBO_FAMILY).find('.select-options-fam').removeClass('hide');

        //unset selected fam item
        $(ARBO_FAMILY).find(BREADCRUM_FAM_EMPTY).removeClass('hide')
        $(ARBO_FAMILY).find(BREADCRUM_FAM).addClass('hide')
        $(ARBO_FAMILY).find(BREADCRUM_FAM_EMPTY).parent().addClass('empty');
    }
    //hide sfam list
    $('.arbo-sub-family').find('.breadcrumb_sfam').addClass('hide');
    $('.arbo-sub-family').find('.breadcrumb_sfam_empty').removeClass('hide');
    $('.arbo-sub-family').find('.breadcrumb_sfam_empty').parent().addClass('empty');
    $('.arbo-sub-family').find('ul.select-options-sfam').addClass('hide');

    // set selected rub item && close list options
    $(selector).closest('.select-panel-options').hide();
    $(selector).closest('.select').find('.breadcrumb_rub').attr('data-codrub', sCodArbo.substr(0, 2));
    $(selector).closest('.select').find('.breadcrumb_rub').html(sLibRub);
    $(selector).closest('ul').find('li.selected').removeClass('selected');
    $(selector).parent().addClass('selected');

    if (aListFam.length <= 0) {
        $('.ref-results-items').attr('data-page',1);
        $('.ajaxLoad').val(1);
        const codArboFilter = filterCodArbo();
        loadAsyncBlockRefs(codArboFilter);
        refreshFilters(codArboFilter, []);
    }
}


function setBreadCrumbFam(selector) {
    const aListSfam = [];
    const sCodArbo = $(selector).attr('data-codarbo');
    const sLibFam = $(selector).html();
    const substrCodArbo = 2;
    $('ul.select-options-sfam li a').each(function (index) {
        if (($(this).attr('data-codarbo')).substr(0, 4) == sCodArbo.substr(0, 4)) {
            $(this).parent().removeClass('hide');
            if (aListSfam.length <= 0) {
                $(this).parent().addClass('selected');
            } else {
                $(this).parent().removeClass('selected');
            }
            if($(this).attr('data-sumprod') == 0){
                $(this).addClass('no-clic');
            }else{
                $(this).removeClass('no-clic');
            }
            aListSfam.push({'CODARBO': $(this).attr('data-codarbo'), 'LIBRUB': $(this).html()});
        } else {
            $(this).parent().addClass('hide');
        }
    })
    if (aListSfam.length <= 0) {
        $('.arbo-sub-family').find('.breadcrumb_sfam').addClass('hide');
        $('.arbo-sub-family').find('.breadcrumb_sfam_empty').removeClass('hide');
        $('.arbo-sub-family').find('.breadcrumb_sfam_empty').parent().addClass('empty');
        $('.arbo-sub-family').find('ul.select-options-sfam').addClass('hide');
    }
    else {
        // show sfam list options
        $('.arbo-sub-family').find('.select-options-sfam').show();
        $('.arbo-sub-family').find('.select-options-sfam').removeClass('hide');

        //unset selected sfam item
        $('.arbo-sub-family').find('.breadcrumb_sfam_empty').removeClass('hide')
        $('.arbo-sub-family').find('.breadcrumb_sfam').addClass('hide')
        $('.arbo-sub-family').find('.breadcrumb_sfam_empty').parent().addClass('empty');
    }
    // set selected fam item
    $(selector).closest('.select').find(BREADCRUM_FAM_EMPTY).addClass('hide');
    $(selector).closest('.select').find(BREADCRUM_FAM).removeClass('hide');
    $(selector).closest('.select').find(BREADCRUM_FAM_EMPTY).parent().removeClass('empty');
    $(selector).closest('.select').find(BREADCRUM_FAM).attr(DATA_CODFAM, sCodArbo.substr(substrCodArbo, substrCodArbo));
    $(selector).closest('.select').find(BREADCRUM_FAM).html(sLibFam);
    // close list options/ add selected class to current item
    $(selector).closest('.select-panel-options').hide();
    $(selector).closest('ul').find('li.selected').removeClass('selected');
    $(selector).parent().addClass('selected');

    if (aListSfam.length <= 0) {
        $('.ref-results-items').attr('data-page',1);
        $('.ajaxLoad').val(1);
        const codArboFilter = filterCodArbo();
        loadAsyncBlockRefs(codArboFilter);
        refreshFilters(codArboFilter, []);
    }
}

function setBreadCrumbSfam(selector) {
    const sCodArbo = $(selector).attr('data-codarbo');
    const sLibSfam = $(selector).html();
    // hide list sfam options
    $(selector).closest('.select-panel-options').hide();

    // set selected sfam item
    $(selector).closest('.select').find('.breadcrumb_sfam').removeClass('hide');
    $(selector).closest('.select').find('.breadcrumb_sfam').parent().removeClass('empty');
    $(selector).closest('.select').find('.breadcrumb_sfam').attr('data-codsfam', sCodArbo.substr(4, 2));
    $(selector).closest('.select').find('.breadcrumb_sfam').html(sLibSfam);
    $(selector).closest('.select').find('.breadcrumb_sfam_empty').addClass('hide');
    $(selector).closest('ul').find('li.selected').removeClass('selected');
    $(selector).parent().addClass('selected');
    $('.ref-results-items').attr('data-page',1);
    $('.ajaxLoad').val(1);
    const codArboFilter = filterCodArbo();
    loadAsyncBlockRefs(codArboFilter);
    refreshFilters(codArboFilter, []);
}

function refreshFilters(codArbo, filters) {
    jQuery.ajax({
        type: 'POST',
        url: Routing.generate('autossimo_peintures_refresh_filters'),
        data: {
            codArbo: codArbo,
            filters :  JSON.stringify(filters)
        },
        success: function (html) {
            jQuery('.products-filters').html(html);
            $('#show-filters').removeClass('hide');
            if($('.ref-results-filters').length <= 0){
                $('#show-filters').addClass('hide');
            }
            bindSelectFiltersChange();
        }
    });
}

function updateAsyncFilters(codArbo, filters) {
    jQuery.ajax({
        type: 'POST',
        url: Routing.generate('autossimo_peintures_update_filters'),
        data: {
            codArbo: codArbo,
            filters: filters
        },
        success: function (result) {
            var arrayKeys = Object.keys(result['result']);
            $('.ref-results-filters select.select_filter option').each(function () {
                const crit = $(this).closest('.filter-block').find('label').attr('data-crit');
                if (jQuery.inArray(crit, arrayKeys) !== -1) {
                    if ($(this).val() != 'null' && jQuery.inArray($(this).val(), result['result'][crit]['values']) == -1) {
                        $(this).addClass('no-clic');
                        $(this).attr('disabled', 'disabled');
                    }
                }
                else if (arrayKeys.length > 0 && jQuery.inArray(crit, arrayKeys) == -1 && $(this).val() != 'null') {
                    $(this).addClass('no-clic');
                    $(this).attr('disabled', 'disabled');
                }
            })
        }
    });
}

function loadAsyncBlockRefs(codArbo,filters) {
    var page = $('.ref-results-items').attr('data-page');
    jQuery.ajax({
        type: 'POST',
        url: Routing.generate('autossimo_peintures_load_refs'),
        data: {
            codArbo: codArbo,
            filters :  JSON.stringify(filters),
            page: page,
            multi_results: false,
            loadFromSession : false
        },
        success: function (html) {
            $('.ref-results-items').html(html);
            var nbProducts = $('.products_count').length > 0 ? $('.products_count').val() : 0;
            var nbFab = $('.fab_count').length > 0 ? $('.fab_count').val() : 0;
            if(nbFab > 1){
                $('.manufacturer_sort').removeClass('hidden');
            }else{
                $('.manufacturer_sort').addClass('hidden');
            }
            $('.nb_refs').html(nbProducts);
            $('.nb_refs_init').html(nbProducts);
            const favProdCount = $('.favProd_count').val();
            $('.nb_fav_init').html(favProdCount);
            $('#display_suggestion').removeAttr('checked', 'checked');
            initImgWithLazyLoading();
            getConsumablesPrices();
            initOwlCarousel();
        }
    });
}

function filterCodArbo (){
    const codrub = $('.breadcrumb_rub').attr('data-codrub');
    const codfam = $(BREADCRUM_FAM_EMPTY).hasClass('hide') ? $(BREADCRUM_FAM).attr(DATA_CODFAM) : $('.breadcrumb_fam_empty ').attr('data-codfam');
    const codsfam = $('.breadcrumb_sfam_empty').hasClass('hide') ? $('.breadcrumb_sfam ').attr(DATA_CODFAM) : $('.breadcrumb_sfam_empty ').attr('data-codsfam');

    return codrub + codfam + codsfam;
}

export function ajouterPanierPeintures(parameters, considerDrophipping = false) {
    parameters.codarbo = parameters.codarbo || '';
    const dataToSend = preAddToCartSingleProductDataCollect(parameters, false, considerDrophipping)
    return jQuery.ajax({
        type: "POST",
        data: dataToSend[0],
        url: parameters.path,
        async: false,
        success: function (data) {
            majMontantPanier(data['nbarticles'], data['montant']);
        },
    });
}

$(document).on('click', '#close_layer', function(){
    $('.overlay').trigger('click');
});

//Affiche la fiche produit peinture
$(document).on("click", ".layout_peinture .consumable-sheet-links", function(e) {
    e.preventDefault();
    const popin = $(this);
    getFicheProduitAjax(popin.attr('data-product-sheet'));
});

export function addMultipleProductsCard(url, element) {
    $(SPAN_CART).attr('data-last', $(SPAN_CART).html());
    const data = {products: {}, fromParts: 0};// datas pour ajax

    $.each($('input:checkbox[class=item-product]:checked'), function (index, value) {
        const codref = $(this).attr('data-codref');
        data.products[codref] = {
            codrefco: $(this).attr('data-ref'),
            refeq: $(this).attr('data-ref'),
            availability: $(this).attr('data-availability'),
            manufacturerCode: $(this).attr('data-manufacturer-code'),
            codrefeq: $(this).attr('data-codref'),
            typeligne: 'C',
            qte: $('#Q-' + $(this).attr('data-codref')).val(),
        };
    });
    const preAddCheck = preAddToCartCheck(data.products, false)
    if(handleAjaxResult(preAddCheck)){
        handleProductExistsInCart(preAddCheck, function(){
            addMultipleProductsCardAjax(data)
        }, element)
    } else {
        addMultipleProductsCardAjax(data)
        animatePanierButton($(this))
    }

    function addMultipleProductsCardAjax(dataToSend){
        if(!$.isEmptyObject(dataToSend.products)){
            $.ajax({
                type: 'POST',
                data: dataToSend,
                url: url,
                success: function(r) {
                    $(SPAN_CART).html(r['nbarticles']);// màj nb articles
                },
                error: function() {
                    $('span.ajaxError').show();
                },
                complete: function() {// remet icone ds ts cas
                    $('.btn-cart span').css('display', 'inline-block');
                    $('.btn-cart img.spinner').hide();
                    $('.add-multiple-refs-to-cart').prop('disabled', false);
                }
            });
        }
    }
}

function sortRefBy(data, sort, order) {
    const sortDiv = [];
    $(data).each(function (index, value) {
        let valueSort = $(this).attr('data-' + sort);
        if (sort === 'price') {
            if (valueSort == '' || valueSort == 0 || valueSort == '--') {
                valueSort = '0';
            }
            valueSort = parseFloat(valueSort);
        }
        sortDiv.push({key: index, value: valueSort});
    });

    if (order === 'desc') {
        sortDiv.sort(function (a, b) {
            if (a.value > b.value) {
                return -1;
            }
            else if (a.value < b.value) {
                return 1;
            }
            return 0;
        });
    }else {
        sortDiv.sort(function (a, b) {
            if (a.value < b.value) {
                return -1;
            }
            else if (a.value > b.value) {
                return 1;
            }
            return 0;
        });
    }
    return sortDiv;
}

function sortDispo(data, order) {
    const sortDiv = [];
    let dispoToDisplay = [];
    if (order === 'asc') {
         dispoToDisplay = ['D','C','P','F','E','A','N'];
    } else {
         dispoToDisplay = ['N','A','E','F','P','C','D'];
    }
    for (const available of dispoToDisplay) {
        $(data).each(function(index, value){
            const valueSort = $(this).attr('data-dispo').slice(0,1);
            if (valueSort === available) {
                sortDiv.push({key: index, value: valueSort });
            }
        });
    }

    return sortDiv;
}

$(document).on("click", '.ref-results-controls .arrows', function () {
    const data = $('.data-row');
    let dataTri = $(this).find('a').attr('data-tri');
    dataTri = dataTri.split("_");
    const [sort, order] = dataTri;
    let divList = [];
    if (sort === 'dispo' || sort === 'availability') {
        divList = sortDispo(data, order);
    } else {
        divList = sortRefBy(data, sort, order);
    }

    divList.forEach(function (value) {
        $('.ref-results-items[data-page]').first().append($(data[value.key]));
    });
    $('.ref-results-items[data-page]').each(function (ind,val) {
        if($(val).html().trim() == ''){
            $(val).remove();
        }
    })
    return false
});

$(document).on("click", NEXT_SLIDE, function(e) {
    e.preventDefault();
    const $parentBlock = $(this).parents(BLOCK_SLIDER);
    const currentSlide = parseInt($parentBlock.attr(DATA_SLIDE));
    $parentBlock.attr(DATA_SLIDE, currentSlide + 1);
    const itemsPerSlide = parseInt($parentBlock.attr(DATA_ITEMS_SLIDE));
    const count = parseInt($parentBlock.find(BLOCK_PRODUCT).not('.hidden').last().attr(DATA_INDEX));
    const countFav = $parentBlock.find(COUNT_FAVORITES).text().match(/(\d)+/g)[0];
    $parentBlock.find(BLOCK_PRODUCT).addClass('hidden');
    for (let i = count + 1; i <= count + itemsPerSlide; i++) {
        if ($parentBlock.find(RESULTS_ITEMS.replace(INDEX_PLACEHOLDER, i)).length > 0) {
            $parentBlock.find(RESULTS_ITEMS.replace(INDEX_PLACEHOLDER, i)).removeClass('hidden');
        }
    }
    if (count + 1 >= itemsPerSlide) {
        $parentBlock.find(PREVIOUS_SLIDE).removeClass('disabled');
    }
    checkModePriceSlider($parentBlock);
    if (count + itemsPerSlide + 1 >= parseInt(countFav)) {
        $(this).addClass('disabled');
        return false;
    }
    preloadNextSlideAvailabilityPrice($parentBlock);
    return true;
});

$(document).on("click", PREVIOUS_SLIDE, function(e) {
    e.preventDefault();
    const $parentBlock = $(this).parents(BLOCK_SLIDER);
    const currentSlide = parseInt($parentBlock.attr(DATA_SLIDE));
    const maxCountProduct = 5;
    $parentBlock.attr(DATA_SLIDE, currentSlide - 1);
    const count = parseInt($parentBlock.find(BLOCK_PRODUCT).not('.hidden').attr(DATA_INDEX));
    $parentBlock.find(BLOCK_PRODUCT).addClass('hidden');
    for (let i = count - 1; i >= count - maxCountProduct; --i) {
        $parentBlock.find(RESULTS_ITEMS.replace(INDEX_PLACEHOLDER, i)).removeClass('hidden');
    }
    if (count <= maxCountProduct) {
        $parentBlock.find(PREVIOUS_SLIDE).addClass('disabled');
        $parentBlock.find(NEXT_SLIDE).removeClass('disabled');
    } else {
        $parentBlock.find(PREVIOUS_SLIDE).removeClass('disabled');
        $parentBlock.find(NEXT_SLIDE).removeClass('disabled');
    }
    checkModePriceSlider($parentBlock);
});

function checkModePriceSlider($parentBlock)
{
    const modePrice = $('#toggle_currency_mode:hidden:first').prop('checked');
    const consumablePrice = $parentBlock.find(".consumable-price.v3_pricing");
    if(modePrice) {
        consumablePrice.find(".net-price-container").removeClass('showme');
    } else {
        consumablePrice.find(".net-price-container").addClass('showme');
    }
}

/**
 * Remove item when a favorite product is deleted and adapt the slider display.
 */
export function removeRefNonFavFromPaintSlider(idFavorite) {
    const maxCountProduct = 5;
    const parentBlock = $(BLOCK_SLIDER_FAVORITES);
    let rowDom = parentBlock.find(idFavorite);
    let countFav = parseInt(parentBlock.find(COUNT_FAVORITES).text().match(/(\d)+/g)[0]);
    parentBlock.find(COUNT_FAVORITES).html('('.concat(countFav - 1).concat(')'));
    while (!rowDom.hasClass('data-row')) {
        rowDom = rowDom.parent();
    }
    let index = parseInt(rowDom.attr(DATA_INDEX))+ 1;
    rowDom.remove();
    /** Case of deleting the last element in a page slider  */
    if ( 0 === index%maxCountProduct) {
        const nextElementHidden = parentBlock.find(
            BLOCK_PRODUCT.concat('.hidden').concat('[').concat(DATA_INDEX).concat('=').concat(index).concat(']')
        );
        /** Check if the next element is hidden */
        if (nextElementHidden.length > 0) {
            nextElementHidden.removeClass('hidden');
            decrementIndexOfElements(index, countFav, parentBlock);
        }
    } else if (parentBlock.find(BLOCK_PRODUCT).not('.hidden').length <= maxCountProduct) {
        /** Case of deleting an element from the beginning or the middle of the slider */
        const element = parentBlock.find(BLOCK_PRODUCT).not('.hidden').last();
        /** Check the last element in slider if is displaying to show the next element from the next page */
        if (element.length > 0) {
            const indexLastElement = parseInt(element.attr(DATA_INDEX)) + 1;
            parentBlock.find(
                BLOCK_PRODUCT.concat('.hidden').concat('[').concat(DATA_INDEX).concat('=').concat(indexLastElement).concat(']')
            ).removeClass('hidden');
            decrementIndexOfElements(index, countFav, parentBlock);
        } else {
            /** Showing the products in the previous slider in the case of deleting the last element in a page slider*/
            index -= 1;
            for (let i = index - 1  ; i >= index - maxCountProduct ; --i) {
                parentBlock.find(
                    BLOCK_PRODUCT.concat('.hidden').concat('['.concat(DATA_INDEX).concat('=').concat(i).concat(']'))
                ).removeClass('hidden');
            }
        }
    }
    /** Check slider button */
    if (parentBlock.find(BLOCK_PRODUCT).length > 0) {
        const count =  parseInt(parentBlock.find(BLOCK_PRODUCT).not('.hidden').first().attr(DATA_INDEX));
        countFav = parseInt(parentBlock.find(COUNT_FAVORITES).text().match(/(\d)+/g)[0]);
        checkButton(count, countFav);
    } else {
        parentBlock.find('.previousSlide').addClass('disabled');
    }
    /** Update the price block for the product that does not yet have a price when deleting the favorites */
    preloadNextSlideAvailabilityPrice(parentBlock);
}

/**
 * Function to decrement index of elements in the case of deleting a favorite product.
 */
function decrementIndexOfElements(index, countFav, parentBlock)
{
    for (let i = index; i < countFav; ++i) {
        const element = parentBlock.find(BLOCK_PRODUCT.concat('['.concat(DATA_INDEX).concat('=').concat(i).concat(']')));
        element.attr(DATA_INDEX, i - 1);
    }
}

export function refreshFavoriteSlide() {
    const route = $('.sub-nav-container-consumables').length > 0 ?
        'autossimo_recurrent_favorites_consu' :
        'autossimo_recurrent_and_favorites';
    $.ajax({
        type: 'GET',
        data : {slide: 'favorites'},
        url: Routing.generate(route),
        async: false,
        success: function (data) {
            $(BLOCK_SLIDER_FAVORITES).replaceWith(data);
            loadSliderAvailabilityPrice();
        },
    });
}

function checkButton(count, countFav) {
    const $parentBlock = $(BLOCK_SLIDER_FAVORITES);
    const maxCountProduct = 5;

    if (count < maxCountProduct ) {
        $parentBlock.find(PREVIOUS_SLIDE).addClass('disabled');
        if (count + maxCountProduct < countFav) {
            $parentBlock.find(NEXT_SLIDE).removeClass('disabled');
        } else {
            $parentBlock.find(NEXT_SLIDE).addClass('disabled');
        }
    } else {
        $parentBlock.find(PREVIOUS_SLIDE).removeClass('disabled');
        if (count + maxCountProduct < countFav) {
            $parentBlock.find(NEXT_SLIDE).removeClass('disabled');
        } else {
            $parentBlock.find(NEXT_SLIDE).addClass('disabled');
        }
    }
}

function getPromoType(itemEl) {
    return "promo" in itemEl[0].dataset ? itemEl[0].dataset.promo : null;
}

function isPriced(item) {
    return Number(item["PAHT"]) ? true : false;
}

function setAvailable(availabilityEl) {
    const availabilityTextElement = $(availabilityEl).find(
        "#blocDispoConso > .dispoA.with-contact-us > span[class*=text-description][class*=available-on-order]"
    );
    availabilityTextElement.parent().find(":not(.available-on-order)").attr("style", "display: none !important");
    availabilityTextElement.html(`<i class="fa fa-check-circle float-left mR-3"></i> Disponible`);
}