import StartSearchEvent from "./StartSearchEvent";
import SearchTypeMapper from "./SearchTypeMapper";
import Flash from '../../Flash';
import Context from "../../Context";
export default class PaintStartSearchNormalizer {
    normalize(element, origin) {
        const startSearchEvent = new StartSearchEvent();
        const searchType = element.find('#select-type').text();
        startSearchEvent.catalog = 'peintures';
        if ('Recherche par mots-clés' === searchType) {
            startSearchEvent.searchTerms = element.find('input.search-consumables').val();
        }
        else {
            // recherche par arboressence
            const searchTerms = [];
            const subfamily = element.find('#selected-subfamily > span').text().trim();
            searchTerms.push(element.find('#selected-heading > span').text().trim());
            searchTerms.push(element.find('#selected-family > span').text().trim());
            searchTerms.push(subfamily);
            startSearchEvent.searchTerms = searchTerms.join(' | ');
            startSearchEvent.consumablesPaintSubfamily = subfamily;
            Context.setLastConsumablesPaintSubfamily(startSearchEvent.consumablesPaintSubfamily);
        }
        startSearchEvent.searchMarker = false;
        startSearchEvent.immatInput = false;
        startSearchEvent.referenceType = '';
        startSearchEvent.referenceCode = '';
        startSearchEvent.markerName = '';
        startSearchEvent.searchType = SearchTypeMapper.get(searchType);
        Flash.setPaintCachedStartSearchEvent(startSearchEvent);
        return startSearchEvent;
    }
    support(element, origin) {
        return 'paints' === origin;
    }
}
