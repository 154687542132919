import { AMPLITUDE_CLASS } from "../../constants";
import { ProductFactory } from "../dto/product/ProductFactory";
import RemoveFromCartEvent from "./RemoveFromCartEvent";
export default class RemoveFromCartDetailsNormalizer {
    normalize(elements, origin) {
        const removeFromCartEvent = new RemoveFromCartEvent(origin);
        const parentDiv = $(elements).closest('article');
        if (1 === parentDiv.length) {
            const amplitudeDiv = $(parentDiv).find(AMPLITUDE_CLASS);
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            removeFromCartEvent.productCollection.addProduct(product);
            return removeFromCartEvent;
        }
        const amplitudeDivs = $('article ' + AMPLITUDE_CLASS);
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            removeFromCartEvent.productCollection.addProduct(product);
        });
        return removeFromCartEvent;
    }
    support(element, origin) {
        return 'cart_details' === origin;
    }
}
