import { exportVehicles } from "../../utilities";
export default class ViewVehicleTechnicalDataEvent {
    constructor() {
        this.vehicles = [];
        this.technicalOrigin = '';
    }
    export() {
        return {
            technical_origin: this.technicalOrigin,
            vehicles: exportVehicles(this.vehicles),
        };
    }
}
