import { FOLDER_COST, AMPLITUDE_CLASS } from "../../constants";
import AddToCartEvent from "./AddToCartEvent";
import { ProductFactory } from "../dto/product/ProductFactory";
export default class AddToCartFromFolderNormalizer {
    normalize(elements, context) {
        const addToCartEvent = new AddToCartEvent(context.origin);
        $('td .folder-chosen').each(function (index, value) {
            const amplitudeDiv = $(value).closest('td').find(AMPLITUDE_CLASS);
            if (1 === amplitudeDiv.length) {
                const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
                addToCartEvent.productCollection.addProduct(product);
            }
        });
        const offCatalogClass = ".ref-oe-container.bloc-reference.folder-offcatalog";
        $(offCatalogClass + ' .folder-chosen').each(function (index, value) {
            const amplitudeDiv = $(value).closest(offCatalogClass).find(AMPLITUDE_CLASS);
            if (1 === amplitudeDiv.length) {
                const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
                addToCartEvent.productCollection.addProduct(product);
            }
        });
        addToCartEvent.status = context.status;
        return addToCartEvent;
    }
    support(element, context) {
        return FOLDER_COST === context.origin;
    }
}
