import { AMPLITUDE_CLASS } from "../../constants";
import { ProductFactory } from "../dto/product/ProductFactory";
import ViewCartDetailsEvent from "./ViewCartDetailsEvent";
export default class ViewCartDetailsNormalizer {
    normalize(elements, origin) {
        const viewCartDetails = new ViewCartDetailsEvent(origin);
        const amplitudeDivs = $('.section_cart--body ' + AMPLITUDE_CLASS);
        amplitudeDivs.each(function (index, amplitudeDiv) {
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            viewCartDetails.productCollection.addProduct(product);
        });
        return viewCartDetails;
    }
    support(element, origin) {
        return true;
    }
}
