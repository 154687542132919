import DefaultNormalizer from "./DefaultNormalizer";
import ByModelNormalizer from "./ByModelNormalizer";
import AbstractNormalizersResolver from "../../core/AbstractNormalizersResolver";
export default class NormalizersResolver extends AbstractNormalizersResolver {
    register() {
        return [
            new ByModelNormalizer(),
            new DefaultNormalizer(),
        ];
    }
}
