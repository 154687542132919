import EventHandler from "./analytics/amplitude/EventHandler";

const DELETE_SEARCH = '.delete-search';
const AUTOCOMPLETE_UI = '.autocomplete-ui';
const ACTIVE_BTN = 'active-btn';
const BTN_EXPRESS = '#btnExpress span';
const ICON_SEARCH = '#btnExpress .icon-search-form-lg';
const AUTOCOMPLETE_UL= '.autocomplete-ui-consumables > ul';
const NUMBER_REFERENCE = 'data-nb-references';
const NUMBER_FAMILY = 'data-nb-family';
let inputAutocompletion = '#consumablesAutocomplete';
const requestAutocomplete = [];

$(document).ready(function () {
    const paintPage = '#paintAutocomplete';
    let inputID = 'consumablesAutocomplete';
    const isPaint = $(paintPage).length !== 0;
    if (isPaint) {
        inputAutocompletion = paintPage;
        inputID = 'paintAutocomplete';
    }

    $('form:not(.home_search_bar) #btnExpress').bind('click', function () {
        initializeData();
        return false;
    });

    $(inputAutocompletion).on('keypress', function (event) {
        if (
            event.which === 13 &&
            $(AUTOCOMPLETE_UL).attr(NUMBER_REFERENCE) > 0 &&
            $(AUTOCOMPLETE_UL).attr(NUMBER_FAMILY) === '0'
        ) {
            event.preventDefault();
            $('#searchType').val('reference');
            $('#codeArboOfSelectedFamily').val($(inputAutocompletion).val());

            $('#searchForm').submit();
        } else if (event.which === 13) {
            return false;
        }
        return true;
    });

    $(DELETE_SEARCH).bind('click', function () {
        emptySearch();
        return false;
    });

    $(inputAutocompletion).bind('keyup', function (event) {
        var dimconsumableVal = document.getElementById(inputID).value;
        // Hide Autocompletion resultat when the input is empty.
        if ( dimconsumableVal === '') {
            $('.autocomplete-ui-consumables').hide();
        }
    });

    var delay = (function () {
        var timer = 0;
        return function (callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();

    $(inputAutocompletion).on("keyup", function () {
        deleteFade($(this));

        // Make a request after tapping 3 letters and more.
        delay(function () {

            if ($(inputAutocompletion).val().length < 3) {
                $(ICON_SEARCH).removeClass(ACTIVE_BTN);
            } else {
                requestAutocomplete.push(jQuery.ajax({
                    type: 'POST',
                    url: Routing.generate('autossimo_consumables_autocompletion_ajax'),
                    data: {
                        label: $(inputAutocompletion).val(),
                        type: isPaint ? 'paint' : null,
                    },
                    beforeSend : function() {
                        $(BTN_EXPRESS).addClass('loader');
                    },
                    success: function (data) {
                        $(AUTOCOMPLETE_UI).show();
                        $(AUTOCOMPLETE_UI).html(data);
                        displayIconSearch(data);
                        handleNotFoundEvent(data);
                        // Hide loader
                        $(BTN_EXPRESS).removeClass('loader')
                    }
                }));
            }
        }, 800);
    });
});

export function emptySearch()
{
    if ($('#tireReferenceAutocomplate').length) {
        $('#tireReferenceAutocomplate').val('');
    }
    $(inputAutocompletion).val('');
    $(DELETE_SEARCH).hide();
    $(AUTOCOMPLETE_UI).hide();
    $(ICON_SEARCH).removeClass(ACTIVE_BTN);
    // Hide loader
    $(BTN_EXPRESS).removeClass('loader');
}

function initializeData()
{
    if ($(AUTOCOMPLETE_UL).attr(NUMBER_REFERENCE) >= 0 && $(AUTOCOMPLETE_UL).attr(NUMBER_FAMILY) <= 0) {
        $('#searchType').val('reference');
        $('#codeArboOfSelectedFamily').val($(inputAutocompletion).val());
        $('#searchForm').submit();
    }
}

export function deleteFade(element)
{
    if (element.val().length > 0) {
        $(DELETE_SEARCH).fadeIn(300);
    } else {
        $(DELETE_SEARCH).fadeOut(300);
    }
}

export function displayIconSearch(data)
{
    if (parseInt($(data).attr(NUMBER_REFERENCE)) > 0 && parseInt($(data).attr(NUMBER_FAMILY)) === 0 ) {
        $(ICON_SEARCH).addClass(ACTIVE_BTN);
    } else {
        $(ICON_SEARCH).removeClass(ACTIVE_BTN);

    }
}

function handleNotFoundEvent(data) {
    if (0 !== parseInt($(data).attr(NUMBER_REFERENCE)) || 0 !== parseInt($(data).attr(NUMBER_FAMILY))) {
        return;
    }

    EventHandler.handleConsumableAndPaintEvent();
    if ($('.peinturesnav').hasClass('active')) {
        const searchPaintBlock = $('#search-paints-block');
        EventHandler.resultsNotFound(searchPaintBlock, 'paints');
    } else if ($('.consommablesnav').hasClass('active')) {
        const searchConsumablesBlock = $('#search-consumables-block');
        EventHandler.resultsNotFound(searchConsumablesBlock, 'consumable');
    } else {
        console.warn('Something is wrong ...');
    }
}

export function cancelRequest()
{
    requestAutocomplete.forEach(function(request) {
        request.abort()
    })
}
