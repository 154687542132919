import { ProductCollection } from "../dto/product/ProductCollection";
export default class AddToCartEvent {
    constructor(origin) {
        this.status = false;
        this.origin = origin;
        this.productCollection = new ProductCollection();
    }
    setStatus(status) {
        this.status = status;
    }
    export() {
        const products = this.productCollection.export();
        if (0 === products.length) {
            return null;
        }
        return {
            cart_add_origin: this.origin,
            status: this.status,
            products: products,
        };
    }
}
