import { AMPLITUDE_CLASS } from "../../constants";
import { ProductFactory } from "../dto/product/ProductFactory";
import ViewItemPictureEvent from "./ViewItemPictureEvent";
export default class ViewItemPictureNormalizer {
    normalize(element, origin) {
        const viewItemPictureEvent = new ViewItemPictureEvent(origin);
        const parentDiv = $(element).closest(':has(.amplitude)');
        if (1 !== parentDiv.length) {
            return viewItemPictureEvent;
        }
        const amplitudeDiv = $(parentDiv).find(AMPLITUDE_CLASS);
        if (1 !== amplitudeDiv.length) {
            return viewItemPictureEvent;
        }
        const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
        viewItemPictureEvent.catalog = product.catalog;
        viewItemPictureEvent.productCollection.addProduct(product);
        return viewItemPictureEvent;
    }
    support(element, origin) {
        return true;
    }
}
