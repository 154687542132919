import ViewItemPictureNormalizersResolver from "./ViewItemPictureNormalizersResolver";
import AbstractDataBuilder from "../../core/AbstractDataBuilder";
export default class ViewItemPictureDataBuilder extends AbstractDataBuilder {
    static create() {
        if (undefined === this.builderInstance) {
            this.builderInstance = new this(new ViewItemPictureNormalizersResolver());
        }
        return this.builderInstance;
    }
}
