import PdfComparativeQuotationNormalizersResolver from './PdfComparativeQuotationNormalizersResolver';
import AbstractDataBuilder from "../../core/AbstractDataBuilder";
export default class PdfComparativeQuotationBuilder extends AbstractDataBuilder {
    static create() {
        if (undefined === this.builderInstance) {
            this.builderInstance = new this(new PdfComparativeQuotationNormalizersResolver());
        }
        return this.builderInstance;
    }
}
