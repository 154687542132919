import { EventDtoTransformer } from "./EventDtoTransformer";
import ConsumablesStartSearchNormalizer from "../startSearch/ConsumablesStartSearchNormalizer";
import Flash from "../../Flash";
export default class ConsumableResultsNotFoundNormalizer {
    constructor() {
        this.consumablesStartSearchNormalizer = new ConsumablesStartSearchNormalizer();
    }
    normalize(element, category) {
        let startSearchEvent = Flash.getConsumableCachedStartSearchEvent();
        if (null === startSearchEvent) {
            startSearchEvent = this.consumablesStartSearchNormalizer.normalize(element, category);
        }
        return EventDtoTransformer.transformFromStartSearchDto(startSearchEvent);
    }
    support(element, category) {
        return this.consumablesStartSearchNormalizer.support(element, category);
    }
}
