import UserData from "./UserData";
export default class IdentifyUserNormalizer {
    normalize(dataUser, origin) {
        const userData = new UserData();
        userData.userAccountMail = dataUser['emailrep'];
        userData.codeRep = dataUser['codrep'];
        userData.codeCo = dataUser['codrepCom'];
        userData.codeDistrib = dataUser['codeDistrib'];
        userData.codeErp = dataUser['codErp'];
        userData.erpName = dataUser['usererp'];
        userData.packageDistrib = dataUser['packageDistrib'];
        userData.typeDistrib = dataUser['distribType'];
        return userData;
    }
    support(element, origin) {
        return true;
    }
}
