import StartSearchEvent from "./StartSearchEvent";
export default class StartSearchByModelNormalizer {
    normalize(element, category) {
        const startSearchEvent = new StartSearchEvent();
        startSearchEvent.catalog = 'pieces';
        startSearchEvent.searchType = 'recherche par modele';
        startSearchEvent.searchMarker = false;
        startSearchEvent.immatInput = false;
        startSearchEvent.searchTerms = '';
        startSearchEvent.consumablesPaintSubfamily = '';
        startSearchEvent.referenceType = '';
        startSearchEvent.referenceCode = '';
        startSearchEvent.markerName = '';
        return startSearchEvent;
    }
    support(element, category) {
        return 'searchByModel' === category;
    }
}
