import StartSearchEvent from "./StartSearchEvent";
import { is_immatriculation } from "../../../pubtech-main-v2";
import SearchTypeMapper from "./SearchTypeMapper";
import { getSelectedFamily } from "../../utilities";
import Flash from "../../Flash";
export default class StartSearchNormalizer {
    normalize(element, category) {
        // jquery elements
        const searchInputElement = element.find('input#search');
        const immatSpanElement = element.find('.jsSearchImmatriculation');
        // const/vars
        const immat = immatSpanElement.text();
        const inputSearchValue = searchInputElement.val();
        const startSearchEvent = new StartSearchEvent();
        let fullSearchTerms;
        startSearchEvent.catalog = 'pieces';
        startSearchEvent.searchType = element.find('#select-choice').text();
        if (['Recherche Express', 'Immat.'].includes(startSearchEvent.searchType)) {
            fullSearchTerms = this.handleImmat(startSearchEvent, immat, inputSearchValue);
        }
        else if ('Multi Réf Equipementier' === startSearchEvent.searchType) {
            fullSearchTerms = this.handleMultiRefEquipementier(element);
        }
        else {
            fullSearchTerms = inputSearchValue;
        }
        this.handleReferencesAndFamily(startSearchEvent, inputSearchValue);
        startSearchEvent.searchTerms = fullSearchTerms;
        startSearchEvent.searchType = SearchTypeMapper.get(startSearchEvent.searchType);
        Flash.setPieceCachedStartSearchEvent(startSearchEvent);
        return startSearchEvent;
    }
    support(element, category) {
        return 'pieces' === category;
    }
    handleMultiRefEquipementier(element) {
        let searchTerms = [];
        element.find('input.ref-equipmentier').each((i, reference) => {
            if ('' !== $(reference).val()) {
                searchTerms.push($(reference).val());
            }
        });
        return searchTerms.join(', ');
    }
    handleReferencesAndFamily(startSearchEvent, inputSearchValue) {
        var _a, _b, _c, _d;
        const foundItem = getSelectedFamily(inputSearchValue);
        if (null === foundItem) {
            startSearchEvent.searchMarker = false;
            return;
        }
        const typeRefMapping = [];
        typeRefMapping['re'] = 'IAM';
        typeRefMapping['ro'] = 'OEM';
        startSearchEvent.searchMarker = true;
        startSearchEvent.referenceType = (_a = typeRefMapping[foundItem.typeRef]) !== null && _a !== void 0 ? _a : '';
        startSearchEvent.markerName = (_b = foundItem.family) !== null && _b !== void 0 ? _b : '';
        if ('Résultats références' === foundItem.category) {
            startSearchEvent.referenceCode = (_c = foundItem.label) !== null && _c !== void 0 ? _c : '';
        }
        else {
            startSearchEvent.referenceCode = (_d = foundItem.ref) !== null && _d !== void 0 ? _d : '';
        }
    }
    handleImmat(startSearchEvent, immat, inputSearchValue) {
        const searchTerms = [];
        if ('' !== immat.trim()) {
            searchTerms.push(immat);
            if (false !== is_immatriculation(immat + ' ')) {
                startSearchEvent.immatInput = true;
            }
        }
        if ('' !== inputSearchValue.trim() && false !== is_immatriculation(inputSearchValue + ' ')) {
            startSearchEvent.immatInput = true;
            searchTerms.push(immat);
        }
        searchTerms.push(inputSearchValue);
        return searchTerms.join(' ');
    }
}
