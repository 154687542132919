import { ProductCollection } from "../dto/product/ProductCollection";
export default class PdfQuotationEvent {
    constructor() {
        this.productCollection = new ProductCollection();
    }
    export() {
        return {
            products: this.productCollection.export(),
        };
    }
}
