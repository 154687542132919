import { GA_CLASS, ATTR_ITEM_AVAILABILITY, ATTR_PRICE_TARIF, ATTR_QUANTITY, ATTR_PRICE, ATTR_PRICE_PROMO, DATA_ITEM_AVAILABILITY, DATA_PRICE_TARIF, DATA_QUANTITY, DATA_PRICE, DATA_PRICE_PROMO } from "../../../constants";
/**
 * update attributes after ajax response
 */
export class ItemAttributes {
    static fromPrices(item, values) {
        const price = values.sale_price !== undefined ? values.sale_price.replace(',', '.') : "--.--";
        const priceTarif = values.purchase_price !== undefined ? values.purchase_price.replace(',', '.') : "--.--";
        const availability = values.availability !== undefined ? values.availability : "--";
        const quantity = values.quantity;
        const pricePromo = values.REMISE !== undefined ? values.REMISE.replace(',', '.') : "--.--";
        const codref = $(item).data('codref');
        const gaDiv = $(item).find('#ga-' + codref);
        $(gaDiv).attr(ATTR_PRICE, price).data(DATA_PRICE, price);
        $(gaDiv).attr(ATTR_PRICE_TARIF, priceTarif).data(DATA_PRICE_TARIF, priceTarif);
        $(gaDiv).attr(ATTR_QUANTITY, quantity).data(DATA_QUANTITY, quantity);
        $(gaDiv).attr(ATTR_ITEM_AVAILABILITY, availability).data(DATA_ITEM_AVAILABILITY, availability);
        $(gaDiv).attr(ATTR_PRICE_PROMO, pricePromo).data(DATA_PRICE_PROMO, pricePromo);
    }
    static fromCheckDispo(parent, result) {
        const delaiSimplifie = result.DELAI_SIMPLIFIE !== undefined ? result.DELAI_SIMPLIFIE : "--";
        const parentDiv = $(parent).closest(':has(.ga)');
        const gaDiv = $(parentDiv).find(GA_CLASS);
        $(gaDiv).attr(ATTR_ITEM_AVAILABILITY, delaiSimplifie).data(DATA_ITEM_AVAILABILITY, delaiSimplifie);
        const quantity = $(parent).val().toString();
        $(gaDiv).attr('data-quantity', quantity).data('quantity', quantity);
    }
    static fromLoadPrice(item, value) {
        const price = value.PVHT !== undefined ? value.PVHT.replace(',', '.') : "--.--";
        const priceTarif = value.PAHT !== undefined ? value.PAHT.replace(',', '.') : "--.--";
        const availability = value.DELAI_SIMPLIFIE !== undefined ? value.DELAI_SIMPLIFIE : "--";
        const quantity = value.QUANTITE;
        const pricePromo = value.REMISE !== undefined ? value.REMISE.replace(',', '.') : "--.--";
        const parentDiv = $(item).closest(':has(.ga)');
        const gaDiv = $(parentDiv).find(GA_CLASS);
        $(gaDiv).attr(ATTR_PRICE, price).data(DATA_PRICE, price);
        $(gaDiv).attr(ATTR_PRICE_TARIF, priceTarif).data(DATA_PRICE_TARIF, priceTarif);
        $(gaDiv).attr(ATTR_QUANTITY, quantity).data(DATA_QUANTITY, quantity);
        $(gaDiv).attr(ATTR_ITEM_AVAILABILITY, availability).data(DATA_ITEM_AVAILABILITY, availability);
        $(gaDiv).attr(ATTR_PRICE_PROMO, pricePromo).data(DATA_PRICE_PROMO, pricePromo);
    }
    static fromQuoteGetPrice(element, result) {
        const gaDiv = $(element).closest('td').find('.ga');
        if (1 === $(gaDiv).length) {
            $(gaDiv).attr(ATTR_ITEM_AVAILABILITY, result.delai_simplifie).data(DATA_ITEM_AVAILABILITY, result.delai_simplifie);
        }
    }
    static fromLayerGetPrice(gaDiv, result) {
        if (1 === $(gaDiv).length) {
            $(gaDiv).attr(ATTR_ITEM_AVAILABILITY, result.delai_simplifie).data(DATA_ITEM_AVAILABILITY, result.delai_simplifie);
        }
    }
    static updateLayerAvailabilityForGoogleAnalytics(element, gaDiv) {
        const hasQuotationAccess = $('#quotation_access').val();
        if (hasQuotationAccess == 1) {
            const data = {
                codequ: $(element).attr('data-equip'),
                refeq: $(element).attr('data-refeq'),
                typeligne: $(element).attr('data-typeline'),
                qte: $(gaDiv).attr('data-quantity'),
                codfam: $(gaDiv).attr('data-cod-fam')
            };
            return $.ajax({
                url: Routing.generate('autossimo_quotes_get_availabilty'),
                type: "POST",
                data: data,
                async: false,
                success: function (result) {
                    ItemAttributes.fromLayerGetPrice(gaDiv, result);
                }
            });
        }
    }
}
