import $ from "jquery";
const POPIN_MULTIPLE = '#popinTempsBaremes .multiple-repairer-times';

$(document).ready(function () {
    $('#CancelReAddToCart').on('click', function () {
        hideModal()
    })
})

// get single piece data for single piece add to cart
export function preAddToCartSingleProductDataCollect(cartParams, source, considerDrophipping = false) {
    const data = {
        codrefco: cartParams.codrefco ?? cartParams.ref,
        codrefeq: cartParams.codrefeq ?? cartParams.codref,
        codarbo: cartParams.codarbo,
        typeligne: cartParams.typeligne,
        qte: cartParams.qte,
        fromParts: (cartParams.typeligne === 'R' ? 1 : 0),
        date: cartParams.date,
        manufacturerCode: cartParams.manufacturerCode,
        availability: cartParams.availability,
        description: cartParams.description,
        refeq: cartParams.refeq,
        vehiculeImmat: cartParams.vehiculeImmat,
        advertisingBannerId: cartParams.advertisingBannerId ?? '',
    }
    if (cartParams.paramDropshipping) {
        data.flag_dropshipping = cartParams.paramDropshipping.flag_dropshipping;
        data.siret_dropshipping = cartParams.paramDropshipping.siret_dropshipping;
    } else if (considerDrophipping) {
        data.flag_dropshipping = 'O';
        data.siret_dropshipping = '';
    } else {
        data.flag_dropshipping = 'N';
        data.siret_dropshipping = '';
    }
    if(source){
        data.from = source;
    }
    return [data];
}

// get multiple pieces data for multiple pieces add to cart
export function preAddToCartMultiProductDataCollect(list) {
    const data = [];
    list.each(function (index, element) {
        const codrefeq = $(element).attr('data-codrefeq');
        const codrefco = $(element).attr('data-codrefco');
        const codarbo = $(element).attr('data-codarbo');
        const qte = $(element).attr('data-qte');
        const type = $(element).attr('data-product-type');
        const manufacturerReference = $(element).attr('data-manufacturerReference');
        const manufacturerCode = $(element).attr('data-manufacturerCode');
        const manufacturerName = $(element).attr('data-manufacturerName');
        const description = $(element).attr('data-description');
        const availability = $(element).attr('data-availability');
        const refeq = $(element).attr('data-refeq-cart');
        const immat = $(element).attr('data-immat');
        const tva = $(element).attr('data-tva');
        const advertisingBannerId = $(element).attr('data-advertising-banner-id');
        const price = $(element).attr('data-price').replace('.', '').replace(',', '.');
        data.push({
            codrefco: codrefco,
            codarbo: codarbo,
            codrefeq: codrefeq,
            typeligne: type,
            qte: qte,
            manufacturerReference: manufacturerReference,
            manufacturerCode: manufacturerCode,
            codequ: manufacturerName,
            design: description,
            availability: availability,
            refeq: refeq,
            immat: immat,
            tx: tva,
            prix: price,
            advertisingBannerId: advertisingBannerId,
        });
    });
    return data;
}

// make ajax call to check if pieces already added to cart
export function preAddToCartCheck(data, recheckDispo) {
    if (data.length === 0) {
        return false;
    }
    let result;
    $.ajax({
        url: Routing.generate('autossimo_panier_pre_add_check'),
        data: {'products': data, 'recheckDispo': recheckDispo},
        async: false,
        type: "POST",
        success: function (response) {
            result = response;
        },
        error: function (error) {
            console.log(error)
        }
    });
    return result;
}

/**
 * Add check for repair times before adding products to cart
 */
export function preAddToCartPunchout(productId, qte, source)
{
    let result = true;
    const codfam = $("span[data-modal-target='popinTempsBaremes']").attr('data-codfam');
    const isGranted = $("span[data-modal-target='popinTempsBaremes']").attr('data-have-time-scales-role') === '1';
    if (isGranted) {
        $.ajax({
            url: Routing.generate('autossimo_panier_pre_add_check_repair_time'),
            async: false,
            data: {'codfam': codfam},
            type: "POST",
            success: function (response) {
                if (false === response.addToCart) {
                    const element = $(".add-cart-piece[data-product-id=".concat(productId).concat("]"));
                    $('#popinTempsBaremes').html(response.result);
                    $(POPIN_MULTIPLE).addClass('preAddCheck');
                    $(POPIN_MULTIPLE).attr('data-product-id', productId);
                    $(POPIN_MULTIPLE).attr('data-quantity', qte);
                    $(POPIN_MULTIPLE).attr('data-source', source);
                    $('#popinTempsBaremes .multiple-repairer-times .modal-body .alertMessage')
                        .html('Veuillez sélectionner la bonne option, afin de récupérer le temps barémé correspondant dans votre outil de gestion');
                    $('#popinTempsBaremes').modal('show');
                    $(element).prop('disabled', false);
                    result =  false;
                }
            },
            error: function (error) {
                console.log(error)
            }
        });
    }

    return result;
}

// set single piece or multiple pieces modal message
function setPopupMessage(multiple) {
    if (multiple) {
        $('.warning-text .single').hide()
        $('.warning-text .multiple').show()
    } else {
        $('.warning-text .multiple').hide()
        $('.warning-text .single').show()
    }
}

// add event listener with custom callback to confirm button
function bindActionToConfirmButton(callback, elm) {
    $('#ConfirmReAddToCart').off('click').on('click', function () {
        const resultCallBack = callback();
        hideModal()
        if (resultCallBack || undefined === resultCallBack) {
            if (elm) {
                animatePanierButton(elm)
            }
        }
    })
}

// add event listener with custom callback to cancel button
function bindActionToCancelButton(callback) {
    $('#CancelReAddToCart').off('click').on('click', function () {
        callback()
        hideModal()
    })
}

// add pieces references to modal body
function setRefs(refs) {
    $('#popinConfirmAddCart .products-refs').html(refs.join())
}

// repeated piece(s) in cart main handler
export function handleProductExistsInCart(data, addCallback, button, cancelCallback) {
    $('#popinConfirmAddCart').modal('show')
    const refs = getRefs(data)
    setRefs(refs)
    setPopupMessage(refs.length !== 1)
    bindActionToConfirmButton(addCallback, button)
    if (cancelCallback) {
        bindActionToCancelButton(cancelCallback)
    }
    $(button).prop('disabled', false);
}

// get repeated pieces references
function getRefs(data) {
    const refs = []
    data.forEach(function (item) {
        if (item.orderLine !== null) {
            refs.push(item.refeq)
        }
    })
    return refs
}

// check if piece(s) are repeated from ajax response
export function handleAjaxResult(data) {
    return data.some((elm) => (elm.orderLine !== null));
}

// hide modal
function hideModal() {
    $('#popinConfirmAddCart').modal('hide')
    $('.btn-cart img.spinner').hide()
}

// collect add to cart button data to animate it
export function animatePanierButton(elm) {
    if ($(elm).closest('.folder-disabled-btn').length > 0) {
        return ;
    }
    const newVersion = $(elm).closest('.basket').length;
    const consumableBasket = $(elm).closest('.consumable-basket');
    const tireBasket = $(elm).closest('.tire-basket');
    const pricingPart = $(elm).closest('.v3-pricing-part');
    const hasBasketElement = consumableBasket.length || tireBasket.length || pricingPart.length;
    const produit = $(elm).parents('tr').attr('data-product');
    hideShowPanierButton(elm, produit, newVersion, hasBasketElement);
}

// animate add to cart button
function hideShowPanierButton(elm, produit, newVersion, hasBasketElement){
    if ($(elm).hasClass('add-multiple-refs-to-cart')) {
        return false
    }
    const timeout = 5000;
    const btnCartSelectedClass = 'btn-cart-selected'
    $(elm).fadeOut(function () {
        $(elm).removeClass('btn-cart').addClass(btnCartSelectedClass).html('<span></span>');
        $(elm).parents('tr').siblings(`tr.valid-panier[data-product="${produit}"]`).show();
        setTimeout(function () {
            let html = "";
            if ($(".ps-pricing-block").length > 0) {
                html = 'PANIER';
                $(elm).removeClass(btnCartSelectedClass).addClass('btn-cart').html(html);
            } else {
                html = '<span style="white-space:nowrap; padding-left: 20px;">AJOUTER AU PANIER</span>';
                if (newVersion || hasBasketElement) {
                    html = '<i class="picto-panier"></i> PANIER';
                } else if($(elm).hasClass('see22')) {
                    html = 'Mettre la pièce au panier et voir avec votre distributeur';
                }
                $(elm).removeClass(btnCartSelectedClass).addClass('btn-cart').html(html);
                $(`.produits tr.valid-panier[data-product="${produit}"]`).fadeOut();
            }
            if ($(elm).hasClass('portal-customer-tyre-cart')) {
                $(elm).find('.picto-panier').removeClass('picto-panier')
            }
            $(elm).prop('disabled', false);
        }, timeout);
        $(elm).fadeIn();
    });
}
