import Flash from './Flash';
class Context {
}
Context.setLastConsumablesPaintSubfamily = (value) => {
    Flash.set('lastConsumablesPaintSubfamily', value);
};
Context.getLastConsumablesPaintSubfamily = () => {
    return Flash.get('lastConsumablesPaintSubfamily');
};
export default Context;
