import { AMPLITUDE_CLASS, IMMEDIATE_ORDER } from "../../constants";
import { ProductFactory } from "../dto/product/ProductFactory";
import StartOrderEvent from "./StartOrderEvent";
export default class StartOrderForFolderNormalizer {
    normalize(elements, origin) {
        const startOrderEvent = new StartOrderEvent(origin);
        const offCatalogClass = ".folder-offcatalog:has(.remove-part-from-cart),.folder-offcatalog:has(.choice-piece:checked)";
        let amplitudeDivs = $(offCatalogClass).find(AMPLITUDE_CLASS);
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            startOrderEvent.productCollection.addProduct(product);
        });
        const choosenClass = "td:has(.remove-part-from-cart),td:has(.choice-piece:checked)";
        amplitudeDivs = $(choosenClass).find(AMPLITUDE_CLASS);
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            startOrderEvent.productCollection.addProduct(product);
        });
        return startOrderEvent;
    }
    support(element, origin) {
        return IMMEDIATE_ORDER === origin;
    }
}
