export default class ViewCartLayerEvent {
    constructor(origin) {
        this.origin = origin;
    }
    export() {
        return {
            cart_view_origin: this.origin,
        };
    }
}
