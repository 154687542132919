import PaintStartSearchNormalizer from "./PaintStartSearchNormalizer";
import StartSearchEvent from "./StartSearchEvent";
export default class ChaineStartSearchNormalizer extends PaintStartSearchNormalizer {
    normalize(element, origin) {
        const startSearchEvent = new StartSearchEvent();
        startSearchEvent.catalog = 'chaine';
        startSearchEvent.searchType = 'snow_chain';
        startSearchEvent.searchTerms = this.getSearchTerms(element);
        startSearchEvent.searchMarker = false;
        startSearchEvent.immatInput = false;
        startSearchEvent.referenceType = '';
        startSearchEvent.referenceCode = '';
        startSearchEvent.markerName = '';
        return startSearchEvent;
    }
    support(element, origin) {
        return 'chaine' === origin;
    }
    getSearchTerms(element) {
        if ($(element).hasClass('result'))
            return $(element).data('dimchaine');
        if ($(element).hasClass('reference')) {
            let searchTerms = [];
            $(element).find('span').each((i, v) => {
                searchTerms[i] = $(v).text();
            });
            return searchTerms.join(' | ');
        }
        return $('#chainsSocksAutocomplete').val();
    }
}
