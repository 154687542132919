import { AMPLITUDE_CLASS } from "../../constants";
import { ProductFactory } from "../dto/product/ProductFactory";
import PdfComparativeQuotationEvent from "./PdfComparativeQuotationEvent";
export default class PdfComparativeQuotationNormalizer {
    normalize(elements, origin) {
        const pdfComparativeQuotationEvent = new PdfComparativeQuotationEvent();
        const amplitudeDivs = $(AMPLITUDE_CLASS);
        const propositionDivs = $('.proposition-right-block tr[data-quoteline-id]');
        if (0 === amplitudeDivs.length) {
            return pdfComparativeQuotationEvent;
        }
        $(propositionDivs).each(function (index, propositionDivs) {
            let quotelineId = $(propositionDivs).data('quoteline-id');
            let amplitudeDiv = $('.quote-line-form tr[data-quoteline-id="' + quotelineId + '"] ' + AMPLITUDE_CLASS);
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            pdfComparativeQuotationEvent.productCollection.addProduct(product);
        });
        pdfComparativeQuotationEvent.quotationNumber = $('.proposition-right-block').length;
        return pdfComparativeQuotationEvent;
    }
    support(element, origin) {
        return true;
    }
}
