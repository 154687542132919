import { ResultsNotFoundEvent } from "./resultsNotFoundEvent";
export class EventDtoTransformer {
    static transformFromStartSearchDto(startSearchEvent) {
        const resultsNotFoundEvent = new ResultsNotFoundEvent();
        resultsNotFoundEvent.catalog = startSearchEvent.catalog;
        resultsNotFoundEvent.immatInput = startSearchEvent.immatInput;
        resultsNotFoundEvent.searchType = startSearchEvent.searchType;
        resultsNotFoundEvent.searchTerms = startSearchEvent.searchTerms;
        resultsNotFoundEvent.markerName = startSearchEvent.markerName;
        resultsNotFoundEvent.referenceType = startSearchEvent.referenceType;
        resultsNotFoundEvent.referenceCode = startSearchEvent.referenceCode;
        resultsNotFoundEvent.searchMarker = startSearchEvent.searchMarker;
        resultsNotFoundEvent.consumablesPaintSubfamily = startSearchEvent.consumablesPaintSubfamily;
        return resultsNotFoundEvent;
    }
}
