import { checkCons, getFamily, getSubFamily, getLibRep } from '../../utilities';
import Flash from '../../Flash';
import ViewItemResultsEvent from './ViewItemResultsEvent';
import { ProductFactory } from "../dto/product/ProductFactory";
export default class PieceViewItemResultsNormalizer {
    normalize(blockDataLayer, catalog) {
        var _a, _b, _c, _d, _e, _f, _g;
        const origin = (_a = Flash.getViewItemResultOrigin()) !== null && _a !== void 0 ? _a : '';
        const viewItemResultsEvent = new ViewItemResultsEvent();
        const totalIamResults = $('.nb-ref-equi-total');
        let totalOemResults = $('.nb-ref-origine');
        if (0 === totalOemResults.length) {
            totalOemResults = $('.nb-ref-oem');
        }
        viewItemResultsEvent.origin = origin !== null && origin !== void 0 ? origin : '';
        viewItemResultsEvent.catalog = (_b = checkCons(catalog)) !== null && _b !== void 0 ? _b : '';
        viewItemResultsEvent.family = (_c = getFamily()) !== null && _c !== void 0 ? _c : '';
        viewItemResultsEvent.subfamily = (_d = getSubFamily()) !== null && _d !== void 0 ? _d : '';
        viewItemResultsEvent.markerName = (_e = getLibRep()) !== null && _e !== void 0 ? _e : '';
        viewItemResultsEvent.filtersApplied = this.isFilterApplied();
        viewItemResultsEvent.totalIamResults = (_f = totalIamResults.text()) !== null && _f !== void 0 ? _f : '';
        viewItemResultsEvent.totalOemResults = (_g = totalOemResults.text()) !== null && _g !== void 0 ? _g : '';
        const amplitudeDivs = blockDataLayer.find('.amplitude');
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            viewItemResultsEvent.productCollection.addProduct(product);
        });
        return viewItemResultsEvent;
    }
    support(element, catalog) {
        return 'pieces' === catalog;
    }
    isFilterApplied() {
        const selectedEquip = $('#selectEquip').val();
        return $('.criteres').hasClass('active') ||
            (null !== selectedEquip && undefined !== selectedEquip && '' !== selectedEquip);
    }
}
