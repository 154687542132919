import ApplyFiltersEvent from './ApplyFiltersEvent';
export default class TiresApplyFiltersNormalizer {
    normalize(element, context) {
        var _a, _b;
        const applyFiltersEvent = new ApplyFiltersEvent();
        applyFiltersEvent.catalog = (_a = context.catalog) !== null && _a !== void 0 ? _a : '';
        const mapping = {
            // tires tab
            'marque': 'brand',
            'gamme': 'range',
            'type de vehicule': 'vehicle type',
            'saison': 'season',
            'charge': 'load',
            'vitesse': 'speed',
            'runflat': 'runflat',
            // chains tab
            'manufacturer_type': 'equipment type',
            'vehicle_type': 'vehicle type',
            'manufacturer': 'brand',
        };
        applyFiltersEvent.filtersCategories = (_b = mapping[element.data('type').toLowerCase()]) !== null && _b !== void 0 ? _b : 'NOT FOUND';
        const values = element.find('option:selected');
        const filterValues = [];
        if (values && values.length) {
            values.each((i, option) => {
                filterValues.push($(option).text().replace(/[\r\n]/gm, '').trim());
            });
            applyFiltersEvent.filtersValues = filterValues.join(', ');
        }
        return applyFiltersEvent;
    }
    support(element, context) {
        return ['pneus', 'chaine'].includes(context.catalog);
    }
}
