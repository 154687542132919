import { ProductCollection } from "../dto/product/ProductCollection";
export default class PdfComparativeQuotationEvent {
    constructor() {
        this.productCollection = new ProductCollection();
        this.quotationNumber = 0;
    }
    export() {
        return {
            products: this.productCollection.export(),
            quotation_number: this.quotationNumber,
        };
    }
}
