import PaintStartSearchNormalizer from "../startSearch/PaintStartSearchNormalizer";
import { EventDtoTransformer } from "./EventDtoTransformer";
import Flash from "../../Flash";
export default class PaintResultsNotFoundNormalizer {
    constructor() {
        this.paintStartSearchNormalizer = new PaintStartSearchNormalizer();
    }
    normalize(element, category) {
        let startSearchEvent = Flash.getPaintCachedStartSearchEvent();
        if (null === startSearchEvent) {
            startSearchEvent = this.paintStartSearchNormalizer.normalize(element, category);
        }
        return EventDtoTransformer.transformFromStartSearchDto(startSearchEvent);
    }
    support(element, category) {
        return this.paintStartSearchNormalizer.support(element, category);
    }
}
