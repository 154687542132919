export default class UserData {
    constructor() {
        this.userAccountMail = '';
        this.codeRep = '';
        this.codeCo = '';
        this.codeDistrib = '';
        this.codeErp = '';
        this.erpName = '';
        this.packageDistrib = '';
        this.typeDistrib = '';
    }
    export() {
        return this;
    }
}
