import { init, track, setUserId, identify, Identify } from '@amplitude/analytics-browser';
import { FeatureFlag } from "../FeatureFlag";
import { lowercaseObjectValues } from "../utilities";
class AmplitudeProcessor {
    static init() {
        if (true === AmplitudeProcessor.isInit) {
            return;
        }
        const key = sessionStorage.getItem('amp');
        const userId = sessionStorage.getItem('userId');
        if (null !== key && null !== userId) {
            init(key, '', {
                serverZone: 'EU',
                minIdLength: 0,
            });
            setUserId(userId);
            AmplitudeProcessor.isInit = true;
        }
    }
    static track(eventName, eventProperties) {
        AmplitudeProcessor.init();
        if (FeatureFlag.isEnabled()) {
            const lowerCasedValues = lowercaseObjectValues(eventProperties);
            track(eventName, lowerCasedValues);
        }
    }
    static identify(userData) {
        AmplitudeProcessor.init();
        if (!FeatureFlag.isEnabled()) {
            return;
        }
        const lowerCasedUserData = lowercaseObjectValues(userData);
        const event = new Identify();
        event.setOnce('user_account_mail', lowerCasedUserData.userAccountMail);
        event.setOnce('code_rep', lowerCasedUserData.codeRep);
        event.setOnce('code_co', lowerCasedUserData.codeCo);
        event.setOnce('code_distrib', lowerCasedUserData.codeDistrib);
        event.setOnce('code_erp', lowerCasedUserData.codeErp);
        event.setOnce('erp_name', lowerCasedUserData.erpName);
        event.setOnce('package_distrib', lowerCasedUserData.packageDistrib);
        event.setOnce('type_distrib', lowerCasedUserData.typeDistrib);
        identify(event);
    }
}
AmplitudeProcessor.isInit = false;
export { AmplitudeProcessor };
