import { ProductCollection } from "../dto/product/ProductCollection";
export default class MatchingResultsEvent {
    constructor() {
        this.productCollection = new ProductCollection();
    }
    export() {
        return {
            is_loading: this.isLoading,
            products: this.productCollection.export(),
        };
    }
}
