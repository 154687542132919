import {initDatePickerAjax, getFicheProduitAjax, openWindow} from '../pubtech-main-v2';
import {loadReplacements} from '../pubtech-references-action';
import {updateBlocAvailabilityPrice} from '../pubtech-dispo-price';
import {multiAddToCartAjax} from '../autossimo-cart';
import {getFicheVehiculeAjax} from '../pubtech-fiche-vehicule';
import {alertPackage} from '../pubtech-popin';
import {getProductSheet} from '../autossimo-consumables';
import {handleAffectedImageError} from "../imageErrors";
import {handleAjaxResult, handleProductExistsInCart, preAddToCartCheck} from "../pre-add-to-cart";
import {initImgWithLazyLoading} from "../init-images-carousel";
import $ from 'jquery';
import EventHandler from '../analytics/amplitude/EventHandler';
import GaEventHandler from '../analytics/ga/GaEventHandler';
import { FOLDER_COST } from '../analytics/constants';

const adContentId = '#packages-ad-content';
const resumePiecesId = '#pieces-resume-mode';
const editPiecesId = '#pieces-edit-mode';
const confirmationStepClass = '.confirmation-step';
const classManufacturerReference = '.bloc-manufacturer-reference';
const classLineResult = '.reference-produits-container.reference-origine-results';
const classSubResult = '.result.sub_result';
const classLineReference = '.equiv-product-td';
const confirmationStepContent = '#confirmation-step-content';
const generateQuoteLoader = '#generate-quote-loader';
const SERVICE_BLOCK = "li.service-block";
const LOADER_BLOCK = '.loaderBlock';
const PACKAGE_NAME = '.package-name';
const SERVICE_ID = 'service-id';
const AFFICHER_MASQUER = '.message:first';
const CLASS_LIENREFRORE_WAITING = '.lienRefRoRe .waiting img';
const CLASS_LINKDISPLAYIAMREFERENCES = '.hide_detail.linkDisplayIAMReferences';
const SPINNER_PACKAGE_AD = '.spinner-package-ad';
const TITLE_FILTRES = '.title.filtres-equip';


$(document).on('click', '.productsheet-link', function(event) {
    event.preventDefault();
    EventHandler.viewItemDetails($(this), 'item_view_results_product_button');
    GaEventHandler.selectItem($(this),'pieces');
});

$(document).on('click', '.p-description.pointer', function(event) {
    event.preventDefault();
    EventHandler.viewItemDetails($(this), 'item_view_result_item_name');
    GaEventHandler.selectItem($(this),'pieces');
});

$(document).on('click', '.p-description.pointer, .productsheet-link', function(event) {
    event.stopImmediatePropagation();

    if ($(this).hasClass('consumable-product')) {
        getProductSheet($(this));
    } else  {
        getFicheProduitAjax($(this).attr('data-product-file-ajax'), this);
    }

});

$(document).on('click', '.goToStep', function () {
    switchBetweenSteps($(this));
});

$(document).on('click', '.linkDisplayIAMReferences', function () {
    hideDisplayIAMReferences($(this));
});

$(document).on('click', '.packages-ad .choice-piece, .ps-ad-packages .choice-piece', function () {
    clickChoicePiece($(this));
});

$('.filter-option.pull-left').on('DOMSubtreeModified', function () {
    calculateNumberReferences($(this));
});
// add onClick listener for adding to cart  and creating quote buttons in confirmation step
$('#pkg-add-cart').on('click', function (e) {
    e.preventDefault()
    confirmationAddToCart();
});
$('#pkg-add-quote').on('click', function () {
    confirmationAddToQuote($(this));
});

function switchBetweenSteps(element)
{
    var step = element.data('step');
    switch (step) {
        case 'vehicule':
            goToVehiculeStep();
            break;
        case 'package':
            goToPackageStep();
            break;
        case 'pieces':
            const serviceBlock = element.closest(SERVICE_BLOCK);
            goToPiecesStep(serviceBlock);
            break;
        case 'confirmation':
            goToConfirmationStep(element);
            break;
    }
}

function hideDisplayIAMReferences(element, fromPopup = false) {
    var piecesBloc = element.closest(classLineResult);
    var referenceChosen = piecesBloc.find('.chosen').length;
    if( false === fromPopup ) {
        if (referenceChosen > 0) {
            piecesBloc.find('.origin-equivalence.origin-datas:not(:has(.chosen))').toggle();
            piecesBloc.find('.result.sub_result:not(:has(.chosen))').toggle();
            piecesBloc.find(TITLE_FILTRES).toggle();
        } else {
            piecesBloc.find(classSubResult).toggle();
        }
    } else if( fromPopup === true && piecesBloc.find(AFFICHER_MASQUER).html() === 'AFFICHER' ) {
        piecesBloc.find('.origin-equivalence.origin-datas:not(:has(.chosen))').toggle();
        piecesBloc.find('.result.sub_result:not(:has(.chosen))').toggle();
        piecesBloc.find(TITLE_FILTRES).toggle();
        piecesBloc.find(classSubResult).toggle();
        piecesBloc.find('.message').html('AFFICHER');
        piecesBloc.find(CLASS_LIENREFRORE_WAITING).removeClass('rotated_180');
        piecesBloc.find(CLASS_LINKDISPLAYIAMREFERENCES).hide();
    }
    if( false === fromPopup ){
        if (piecesBloc.find(AFFICHER_MASQUER).html() === 'AFFICHER') {
            loadSpinnerResult(piecesBloc);
            piecesBloc.find('.message').html('MASQUER');
            piecesBloc.find(CLASS_LIENREFRORE_WAITING).addClass('rotated_180');
            piecesBloc.find(CLASS_LINKDISPLAYIAMREFERENCES).show();
        } else {
            piecesBloc.find(SPINNER_PACKAGE_AD).addClass('hidden')
            piecesBloc.find('.message').html('AFFICHER');
            piecesBloc.find(CLASS_LIENREFRORE_WAITING).removeClass('rotated_180');
            piecesBloc.find(CLASS_LINKDISPLAYIAMREFERENCES).hide();
        }
        piecesBloc.find('.loader-filter-result-piece').toggle();
    }

}

function loadSpinnerResult(piecesBloc)
{
    const spinner = piecesBloc.find(SPINNER_PACKAGE_AD.concat('.sucessLoad')).length > 0;
    const result = piecesBloc.find(classSubResult);
    if (!spinner) {
        result.hide();
        result.addClass('result-show');
        piecesBloc.find(SPINNER_PACKAGE_AD).removeClass('hidden')
    } else {
        result.show();
        piecesBloc.find(SPINNER_PACKAGE_AD).addClass('hidden')
    }
}

function clickChoicePiece(element) {
    var elementLabelChoice = '.btn-edit label';

    var buttonElement = element.closest('.btn-edit');
    var elementEquipmentId = '#quote-data-'.concat(buttonElement.data('codrefeq'));
    var piecesBloc = element.closest(classLineResult);
    if ($(elementEquipmentId).length > 0) {
        piecesBloc = $(elementEquipmentId).closest(classLineResult);
    }
    var fromProductSheet = element.closest('.btn-edit').hasClass('product-sheet');

    if (buttonElement.hasClass('chosen')) {
        buttonElement.removeClass('chosen');
        buttonElement.find("input").prop("checked", false);
        buttonElement.find("label").html('Choisir');
        $('.validate-pieces .goToStep').addClass('disabled');
        if(fromProductSheet){
            $(elementEquipmentId).closest(classLineReference).find('.btn-edit').removeClass('chosen');
            $(elementEquipmentId).closest(classLineReference).find(elementLabelChoice).html('Choisir');
            $(elementEquipmentId).closest(classLineReference).find('.btn-edit input').prop("checked", false);
        }
        if (piecesBloc.find(AFFICHER_MASQUER).html() === 'AFFICHER') {
            piecesBloc.find('.origin-equivalence.origin-datas').show();
            piecesBloc.find(TITLE_FILTRES).show();
            piecesBloc.find(classSubResult).hide();
        }
    } else {
        piecesBloc.find('.chosen').removeClass('chosen');
        piecesBloc.find(".btn-edit.choice-piece label").html('Choisir');
        piecesBloc.find("input").prop("checked", false);
        buttonElement.addClass('chosen');
        buttonElement.find("input").prop("checked", true);
        buttonElement.find("label").html('Produit Choisi');
        if(fromProductSheet){
            $(elementEquipmentId).closest(classLineReference).find('.btn-edit').addClass('chosen');
            $(elementEquipmentId).closest(classLineReference).find(elementLabelChoice).html('Produit Choisi');
            $(elementEquipmentId).closest(classLineReference).find('.btn-edit input').prop("checked", true);
        }
        var numberPiecesToChoose = $(classLineResult).length;
        var numberPiecesChosen = $(classLineResult).find('.chosen').length;
        if (numberPiecesChosen === numberPiecesToChoose ) {
            $('.validate-pieces .goToStep').removeClass('disabled');
        }
        if(element.parents('#popinFicheProduit').length > 0){
            hideDisplayIAMReferences(piecesBloc.find('.linkDisplayIAMReferences'), true);
        }

    }
}

function goToVehiculeStep() {
    $.ajax({
        type: 'GET',
        url: Routing.generate('autossimo_packages_ad_choice_vehicule'),
        async: true,
        dataType: 'html',
        success: function (data) {
            $(adContentId).html(data);
            activateStep(1);
        },
        onFailure: function (data) {
            displayMessageError();
        }
    });
}

function goToPackageStep() {
    $('#package-resume-mode').addClass('showme');
    $('.select-pieces-step').addClass('showme');
    $('#package-edit-mode').removeClass('showme');
    $(confirmationStepClass).addClass('showme');
    $(LOADER_BLOCK).removeClass('showme');
    $(editPiecesId).remove();
    $(resumePiecesId).html('');
    $(resumePiecesId).addClass('showme');

    activateStep(2);
}

/*
 * Validate the package step and active choice pieces step
 */
function goToPiecesStep(serviceBlock) {
    if (serviceBlock.length > 0) {
        // clear last selected package
        $(SERVICE_BLOCK).each(function() {
            $(this).removeData('selected');
        });
        // set selected package
        $(SERVICE_BLOCK).removeData('selected');
        serviceBlock.data('selected', 'selected');
    }
    var selectorPiecesStep = $('.select-pieces-step');
    $(resumePiecesId).html('');
    $(resumePiecesId).addClass('showme');
    $(confirmationStepClass).addClass('showme');
    selectorPiecesStep.removeClass('showme');
    activateStep(3);

    if ($(editPiecesId).length > 0) {
        $(editPiecesId).toggle();
    } else {
        //hide Edition package block and display the resume block
        const packageName = serviceBlock.find(PACKAGE_NAME).first().html();
        let packagePrice = serviceBlock.find('.package-price').first().html();
        let totalForfait = 0.0;

        $('.total-forfait').each(function(){
            if ( $(this).data('serviceId') === serviceBlock.data('serviceId')){
                totalForfait = $(this).first().html();
            }
        });

        packagePrice = totalForfait === 0.0 ? packagePrice : totalForfait;
        $('#package-resume-mode .type-package .resume-content').html(packageName);
        $('#package-resume-mode .price-package .resume-content').html(packagePrice);
        $('#package-resume-mode').removeClass('showme');
        $('#package-edit-mode').addClass('showme');

        const serviceId = serviceBlock.data(SERVICE_ID);
        $.ajax({
            type: 'GET',
            url: Routing.generate('autossimo_packages_ad_choice_pieces', {
                serviceId: serviceId,
            }),
            async: true,
            dataType: 'html',
            success: function (data) {
                $(LOADER_BLOCK).addClass('showme');
                selectorPiecesStep.append(data);
                handleAffectedImageError();
                $('img.lazy-loading.affected-img').each(function(){
                    $(this).attr('src', $(this).attr('data-src'));
                    $(this).removeAttr('data-src');
                })
                initializeList();
                initDatePicker();
                loadReplacements();
                updateBlocAvailabilityPrice(true);
                initImgWithLazyLoading();
            },
            error: function () {
                $(LOADER_BLOCK).addClass('showme');
                displayMessageError();
            },
            onFailure: function () {
                displayMessageError();
            }
        });
    }
}

/**
 * Call of an action which will log the loading time of the parts.
 */
export function logPackageLoadTime()
{
    $.ajax({
        url: Routing.generate('autossimo_log_load_package'),
        dataType: 'json'
    });
}

function goToConfirmationStep(element) {

    if (!element.hasClass('disabled')) {
        var piecesList = {};
        var total = 0;

        $(".reference-produits-container.reference-origine-results .choice-piece.chosen").each(function (index) {
            piecesList[index] = {};

            piecesList[index]['refEquipmentId'] = $(this).data('codrefeq');
            piecesList[index]['refManufacturerId'] = $(this).data('codrefco');
            piecesList[index]['typeLine'] = $(this).data('linetype');
            piecesList[index]['availability'] = $(this).attr('data-availability');
            piecesList[index]['refeq'] = $(this).attr('data-refeq-cart');
            piecesList[index]['manufacturerCode'] = $(this).attr('data-manufacturer-code');
            piecesList[index]['date'] = '';

            piecesList[index]['refManufacturerCode'] = $(this).closest(classManufacturerReference).find('.num-unique.blue_text span').html();
            var lineRefeq = $(this).closest(classLineReference).find('div:first-child');

            if(lineRefeq.length > 0 ){
                var price = lineRefeq.find('.net-price-rate-ttc').html();
                price = price.split(" ")[0];
                piecesList[index]['price'] = price;
                piecesList[index]['equipLabel'] = lineRefeq.data('libequip');
                piecesList[index]['equipCode'] = lineRefeq.data('manufacturer-code');
                piecesList[index]['refEquipmentCode'] = lineRefeq.data('refeq');
                piecesList[index]['designation'] = lineRefeq.data('designation');
                price = price.replace(",", ".");

                if ($.isNumeric( price )) {
                    total += parseFloat(price);
                }
            }else{
                piecesList[index]['price'] = '--,--';
                piecesList[index]['equipLabel'] = '';
                piecesList[index]['equipCode'] = '';
                piecesList[index]['refEquipmentCode'] = '';
                piecesList[index]['designation'] = $(this).closest(classManufacturerReference).find('.value-header-manufacturer span').html();
            }
        });
        if (total === 0) {
            total = '--,--';
        }
        $(editPiecesId).toggle();
        $.ajax({
            type: 'GET',
            url: Routing.generate('autossimo_packages_ad_confirmation', {
                pieces: piecesList,
                total: total,
            }),
            async: true,
            dataType: 'html',
            success: function (data) {
                activateStep(4);
                $(resumePiecesId).removeClass('showme');
                $(confirmationStepClass).removeClass('showme');
                $(resumePiecesId).html(data);
            },
            onFailure: function (data) {
                displayMessageError();
            }
        });
    }

}

function activateStep(step) {

    $('#steps-picture').addClass('step-'+step);
    $('.step-'+step).addClass('active');
    for (var i = 4; i > step; i--){
        $('#steps-picture').removeClass('step-'+i);
        $('.step-'+i).removeClass('active');
    }
}

function displayMessageError(){
    $('#popin-message').parent().show();
    $('#popin-message').modal({
        backdrop: true
    });
}

function initializeList() {
    var numberReferences = $(classManufacturerReference).length;
    if (numberReferences === 1) {
        $('.loader-filter-result-piece').toggle();
        $(classSubResult).hide();
        $(classSubResult).addClass('result-show');
        $(SPINNER_PACKAGE_AD).removeClass('hidden')
    }
    $('.hide_detail.white_background').addClass('linkDisplayIAMReferences');
    $(".filtreEquipementiers").each(function (index) {
        const map = {};
        const equimenetsList = $(this).find('#equipementiers');
        equimenetsList.children("option").each(function () {
            if (map[this.value]) {
                $(this).remove();
            }
            map[this.value] = true;
        });
        equimenetsList.selectpicker('refresh');
    });
}

function confirmationAddToCart()
{
    const serviceBlock = getPackageInfoFromDOM();
    const vehicleInfoNode = $('#vehicle-detailed-info');
    let immat = vehicleInfoNode.data('immat');
    let typeMine = vehicleInfoNode.data('type-mine');
    let vin = vehicleInfoNode.data('vin');
    const typeSearch = $(".vehicle-info .resume-header").eq(0).text().toLowerCase();
    if ($.inArray(typeSearch, ['immat', 'immat-express']) !== -1) {
        typeMine = '';
        vin = '';
    } else if ($.inArray(typeSearch, ['vin', 'par_modele_vin']) !== -1) {
        typeMine = '';
        immat = '';
    } else if ($.inArray(typeSearch, ['mine', 'par_modele_mine']) !== -1) {
        immat = '';
        vin = '';
    }
    const data = {
        packageName: $.trim(serviceBlock.find(PACKAGE_NAME).first().html()),
        packageServiceId: serviceBlock.data(SERVICE_ID),
    };
    const vehicleInfo = {
        codmar: vehicleInfoNode.data('codmar'),
        numord: vehicleInfoNode.data('numord'),
        libmar: $('#vehicleLibmar').text(),
        libmod: $('#vehicleLibmod').text(),
        immat: immat,
        typeMine: typeMine,
        vin: vin,
    };
    const products = getProductsChoosen();
    
    GaEventHandler.addToCart('', FOLDER_COST);
    data.products = products;
    data.vehicleInfo = vehicleInfo;
    const preAddCheck = preAddToCartCheck(data.products, false);
    if(handleAjaxResult(preAddCheck)){
        handleProductExistsInCart(preAddCheck, function(){
            multiAddToCartAjax(data);
        }, null)
    } else {
        multiAddToCartAjax(data);
    }
}

function initDatePicker()
{
    $('.containerDispo span.id-btn-panier').each(function (index) {
        const dataSpan = $(this).data('id-btn-panier');
        $(this).closest('.product-content').find('.btn-edit.choice-piece').addClass(dataSpan);
    });
    initDatePickerAjax();
}

export function calculateNumberReferences(element)
{
    setTimeout(function(){
        if (element.closest(classSubResult).is(":visible")) {
            const numberReferencesEquipment = element.closest(classManufacturerReference).find('tr.origin-datas').not('.hide').length;
            const numberReferencesManufacturer = element.closest(classLineResult).find('tr.origin-datas').not('.hide').length;
            element.closest(classLineResult).find('.nbReferencesManufacturer').html(numberReferencesManufacturer);
            element.closest(classManufacturerReference).find('.nbReferencesEquipment').html(numberReferencesEquipment);
        }
    }, 200);
}

$(document).on('click', '.vehicle-detail .get-vehicle-link', function(event) {
    if ($(this).attr('data-have-vehicle-record-role') !== '1') {
        alertPackage();
        event.preventDefault();
    } else {
        const params = {
            codmar: $(this).attr('data-codmar'),
            numord: $(this).attr('data-numord'),
            immat: $(this).attr('data-immat'),
            typerecherche: $(this).attr('data-search-type')
        };
        const vehicleFileUrl = Routing.generate('autossimo_popin_fiche_vehicule', params);
        getFicheVehiculeAjax(vehicleFileUrl);
    }
});

$(document).on('click', '.open-popin-immat', function() {
    openWindow(Routing.generate('autossimo_popin_carte_grise', {immat: $(this).attr('data-immat')}));
});

$(document).on('click', '.open-popin-vin', function() {
    openWindow(Routing.generate('autossimo_popin_carte_grise_vin', {vin: $(this).attr('data-vin')}));
});

function confirmationAddToQuote(element)
{
    GaEventHandler.addToQuotation($('.chosen'), 'multiple-favorites');
    const serviceBlock = getPackageInfoFromDOM();
    const products = getProductsChoosen();
    const vehicleInfoNode = $('#vehicle-detailed-info');
    let immat = vehicleInfoNode.data('immat');
    let typeMine = vehicleInfoNode.data('type-mine');
    let vin = vehicleInfoNode.data('vin');
    const vehicleInfo = {
        codmar: vehicleInfoNode.data('codmar'),
        numord: vehicleInfoNode.data('numord'),
        libmar: $('#vehicleLibmar').text(),
        libmod: $('#vehicleLibmod').text(),
        immat: immat,
        typeMine: typeMine,
        vin: vin,
    };
    const data = {
        CHAINE: vehicleInfoNode.data('appelation'),
        CODMAR: vehicleInfoNode.data('codmar'),
        CODMOD: vehicleInfoNode.data('codmod'),
        NUMORD: vehicleInfoNode.data('numord'),
        IMMATVEH: vehicleInfoNode.data('immat'),
        TYPE_MINE: vehicleInfoNode.data('type-mine'),
        KTYPNR: vehicleInfoNode.data('ktypnr'),
        NUMORDS: vehicleInfoNode.data('numords'),
        DMEC: vehicleInfoNode.data('mec'),
        NUMSERIE: vehicleInfoNode.data('numserie'),
        PACKAGE: $.trim(serviceBlock.find(PACKAGE_NAME).first().html()),
        PRODUCTS: products,
        SERVICEID: serviceBlock.data(SERVICE_ID),
        SOURCE: element.data('source'),
        vehicleInfo : vehicleInfo,
    };
    $.ajax({
        type: 'POST',
        url: Routing.generate('autossimo_packages_ad_create_quote'),
        data: data,
        async: true,
        beforeSend: function () {
            $(confirmationStepContent).addClass('showme');
            $(generateQuoteLoader).removeClass('showme');
        },
        success: function (result) {
            // redirect to quote edit page for the newly created quote
            window.location.replace(result);
        },
        onFailure: function () {
            displayMessageError();
            $(generateQuoteLoader).addClass('showme');
            $(confirmationStepContent).removeClass('showme');
        }
    });
    return true;
}

function getPackageInfoFromDOM()
{
    // get package info
    let serviceBlock = null;
    $(SERVICE_BLOCK).each(function () {
        if (typeof $(this).data('selected') !== 'undefined'){
            serviceBlock = $(this);
        }
    });
    return serviceBlock;
}

function getProductsChoosen()
{
    const products = [];
    let nomConstructeur = '';

    $('.chosen-product').each(function () {
        // if equipementier === NON DISPONIBLE we use the product label from pieces summary in DOM
        if ($(this).find('td:eq(2)').text().toUpperCase().indexOf('NON DISPONIBLE') !== -1){
            nomConstructeur = $(this).find('td:eq(0)').text();
        }else{
            const lineRefco = $(
                '.reference-produits-container.reference-origine-results .choice-piece.chosen[data-codrefeq="'+
                $(this).data('codrefeq')+
                '"]'
            ).closest(classManufacturerReference);
            nomConstructeur = lineRefco.find('.value-header-manufacturer:first span').html();
        }
        products.push({
            codrefco: $(this).data('codrefco'),
            codrefeq: $(this).data('codrefeq'),
            typeligne: $(this).data('linetype'),
            // always 1 for packages
            qte: 1,
            flag_dropshipping: 'N',
            siret_dropshipping: '',
            date: $(this).data('date'),
            refeq: $.trim($(this).find('td:eq(3)').text().replace(/Non disponible/gi, '')),
            refcon: $.trim($(this).find('td:eq(1)').text()),
            nomcon: $.trim(nomConstructeur),
            availability: $(this).attr('data-availability'),
            manufacturerCode: $(this).attr('data-manufacturer-code')
        });
    });
    return products;
}
