import AbstractNormalizersResolver from "../../core/AbstractNormalizersResolver";
import PieceViewItemResultsNormalizer from "./PieceViewItemResultsNormalizer";
import TireViewItemResultsNormalizer from "./TireViewItemResultsNormalizer";
import ConsumableViewItemResultsNormalizer from "./ConsumableViewItemResultsNormalizer";
export default class ViewItemResultsNormalizersResolver extends AbstractNormalizersResolver {
    register() {
        return [
            new PieceViewItemResultsNormalizer(),
            new TireViewItemResultsNormalizer(),
            new ConsumableViewItemResultsNormalizer(),
        ];
    }
}
