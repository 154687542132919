import PdfQuotationNormalizersResolver from './PdfQuotationNormalizersResolver';
import AbstractDataBuilder from "../../core/AbstractDataBuilder";
export default class PdfQuotationBuilder extends AbstractDataBuilder {
    static create() {
        if (undefined === this.builderInstance) {
            this.builderInstance = new this(new PdfQuotationNormalizersResolver());
        }
        return this.builderInstance;
    }
}
