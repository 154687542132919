import { AMPLITUDE_CLASS } from "../../constants";
import { ProductFactory } from "../dto/product/ProductFactory";
import StartOrderEvent from "./StartOrderEvent";
export default class StartOrderNormalizer {
    normalize(elements, origin) {
        const startOrderEvent = new StartOrderEvent(origin);
        const parentDiv = $(elements).closest('#layerPanier');
        if (0 === parentDiv.length) {
            return startOrderEvent;
        }
        const amplitudeDivs = $(parentDiv).find(AMPLITUDE_CLASS);
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            startOrderEvent.productCollection.addProduct(product);
            // const amplitudeDivParentDiv = $(amplitudeDiv).closest('.lineItem');
            // ProductAttributes.updateLayerAvailabilityForAmplitude(amplitudeDivParentDiv, amplitudeDiv).done(() => {
            //     const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            //     startOrderEvent.productCollection.addProduct(product);
            // });
        });
        return startOrderEvent;
    }
    support(element, origin) {
        return true;
    }
}
