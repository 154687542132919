import { priceFormatter } from "./formatter";

const $ = require("jquery");
import { sndcmd } from './pubtech-panier'
import { animatePanierButton } from "./pre-add-to-cart";
import Flash from "./analytics/Flash";
import { IMMEDIATE_ORDER } from "./analytics/constants";
import GaEventHandler from "./analytics/ga/GaEventHandler";

const popinSelector = '#packages_popin';
const popinUnitCommand = "#unit-order-shipping-cost";

$(document).ready(function () {
    // hide popin on click anywhere but inside popin itself
    $(document).on('click', '.choose_vehicle > .modal-dialog', function (et) {
        if (et.target.id === "packages_popin" || $(et.target).closest('#packages_popin').length) {
            return;
        }

        $(popinSelector).modal('hide');
    });

    $('.return_condition').on('click', function (e) {
        e.stopPropagation();
        $('#return_subject_condition').modal('show');
    });
});

/** JS file created for new functions related to cart operations as pubtech-panier is too big */
export function multiAddToCartAjax(data) {
    $.ajax({
        type: 'POST',
        data: data,
        url: Routing.generate('autossimo_multi_add_to_cart'),
        success: function (r) {
            $('a.cart-opener span.nbPanier').html(r['nbarticles']);// update article count
        },
        error: function () {
            $('span.ajaxError').show();
        },
        complete: function () { // re-display icon anyways
            $('.btn-cart span').css('display', 'inline-block');
            $('.btn-cart img.spinner').hide();
            animatePanierButton($('.btn-cart'))
        }
    });
}

function popinTransmissionCommande(numcde, refcde, messagerep, csrfToken, path, pathOk, pathCart, type, layer, basketSendOrderCostShipping, isUnitCommandDropShipping)
{
    $(popinSelector).modal('show');
    $('#popinTransmissionCommande').modal('hide');
    $('#packages_popin_send').on('click', function () {
        if (isUnitCommandDropShipping) {
            basketSendOrderCostShipping();
        } else {
            sndcmd(numcde, refcde, messagerep, path, pathOk, pathCart, type, csrfToken, layer);
        }
        $(popinSelector).modal('hide');
    });

    $('#packages_popin_cancel').on('click', function () {
        $(popinSelector).modal('hide');
    });
}

/**
 * check cart contains package lines then prompt user before sending
 */
function sendOrder(numcde, refcde, messagerep, csrfToken, path, pathOk, pathCart, type, layer) {
    $("#packages_popin_send").unbind('click');
    $("#packages_popin_cancel").unbind('click');
    $("#basket-send-order-cost-shipping").unbind('click');
    const isInstantOrder = $('#popinFolderInstantOrder:visible').length > 0 ? true : false;

    $.ajax({
        type: 'GET',
        url: Routing.generate('autossimo_cart_check_before_send_order', { numCde: numcde }),
        dataType: 'html',
        data: {
            isInstantOrder: isInstantOrder
        },
        success: function (data) {
            const result = JSON.parse(`${data}`);
            const isCartContainsPackageLines = result['isCartContainsPackageLines'];
            const isUnitCommandDropShipping = result['isUnitCommandDropShipping'];
            const unitCommandDropShippingCost = result['unitCommandDropShippingCost'];

            const basketSendOrderCostShipping = () => {
                $(popinUnitCommand).find('#shipping-cost').html(priceFormatter(unitCommandDropShippingCost));
                // Hide order transmission popin
                $('#popinTransmissionCommande').modal('hide');
                $(popinUnitCommand).modal('show');
                $('#basket-send-order-cost-shipping').on('click', function () {
                    sndcmd(numcde, refcde, messagerep, path, pathOk, pathCart, type, csrfToken, layer);
                    $(popinUnitCommand).modal('hide');
                });
            };

            if ($('#collectBasket').is(':checked') && (1 === $('.section_cart--body').data('collectAtTheCounter'))) {
                popinTransmissionCommande(numcde, refcde, messagerep, csrfToken, path, pathOk, pathCart, type, layer, basketSendOrderCostShipping, isUnitCommandDropShipping);
            } else if (!isCartContainsPackageLines && !isUnitCommandDropShipping) {
                // defined in pubtech-panier.js
                sndcmd(numcde, refcde, messagerep, path, pathOk, pathCart, type, csrfToken, layer);
            } else if (isUnitCommandDropShipping && !isCartContainsPackageLines) {
                basketSendOrderCostShipping();
            } else if (isCartContainsPackageLines) {
                popinTransmissionCommande(numcde, refcde, messagerep, csrfToken, path, pathOk, pathCart, type, layer, basketSendOrderCostShipping, isUnitCommandDropShipping);
            }
        },
        error: function (error) {
            if (403 === error.status) {
                alertForbidden();
            }
        }
    });
}



$(document).on('click', '.basket-send-order', function (e) {
    if ($(this).is('[disabled]')) {
        e.preventDefault();
        return;
    }
    if ($(this).prev('.action-open-popin:not(.action-open-popin-message-produit)').length > 0) {
        GaEventHandler.beginCheckout($('.section_cart--body-item'),'cart-details');
        GaEventHandler.checkoutProgress($('.section_cart--body-item'),'cart-details');
    }
    const layer = $(this).data('layer');
    const layerSuffix = layer === true ? '_layer' : '';
    const csrfToken = $('#send_order__token' + layerSuffix).val();
    const messagerep = $('#send_order_messagerep' + layerSuffix).val();
    const refcde = $('#send_order_refcde' + layerSuffix).val();
    sendOrder(
        $(this).data('order'),
        refcde,
        messagerep,
        csrfToken,
        $(this).data('url'),
        $(this).data('url-ok'),
        $(this).data('url-cart'),
        $(this).data('type'),
        layer
    )
})
$(document).on('click', '#validerTransmission', function () {
    GaEventHandler.checkoutProgress($('.pricing_cart_layout--body .lineItem'),'cart-layer');
})

$(document).on('click', '.section_cart--header .basket-send-order', function () {
    Flash.set('complete_order_origin','cart_details');
})

$(document).on('click', '#folder-instant-order', function () {
    Flash.set('complete_order_origin',IMMEDIATE_ORDER);
})

$(document).on('click', '.pricing_cart_layout--footer--submit > a.action-open-popin-ajax', function () {
    Flash.set('complete_order_origin','cart_layout');
})

/**
 * hide confirmation popin and reset redirection urls
 */
function hidePackagePopin() {
    $(popinSelector).modal('hide');
}
