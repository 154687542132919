import { AMPLITUDE_CLASS } from "../../constants";
import ViewCampaignDetailEvent from "./ViewCampaignDetailEvent";
import { ProductFactory } from "../dto/product/ProductFactory";
export default class ViewCampaignDetailNormalizer {
    normalize(elements, origin) {
        const viewCampaignDetail = new ViewCampaignDetailEvent($('.produits.portal-promo-header').data('promo'));
        const amplitudeDivs = $(AMPLITUDE_CLASS);
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            viewCampaignDetail.productCollection.addProduct(product);
        });
        return viewCampaignDetail;
    }
    support(elements, origin) {
        return true;
    }
}
