import AddToCartEvent from "./AddToCartEvent";
import { ProductFactory } from "../dto/product/ProductFactory";
export default class AddToCartFromQuoteNormalizer {
    normalize(elements, context) {
        const addToCartEvent = new AddToCartEvent(context.origin);
        let amplitudeDivs = $('table.quotes-result--results-list .amplitude');
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            addToCartEvent.productCollection.addProduct(product);
        });
        addToCartEvent.status = context.status;
        return addToCartEvent;
    }
    support(element, context) {
        return "devis entretien" === context.origin;
    }
}
