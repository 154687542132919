import ViewCampaignNormalizersResolver from "./ViewCampaignNormalizersResolver";
import AbstractDataBuilder from "../../core/AbstractDataBuilder";
export default class ViewCampaignDataBuilder extends AbstractDataBuilder {
    static create() {
        if (undefined === this.builderInstance) {
            this.builderInstance = new this(new ViewCampaignNormalizersResolver());
        }
        return this.builderInstance;
    }
}
