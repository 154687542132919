import {sndcmd, transmissionFinalized, transmissionInProgress} from "../pubtech-panier";

const $ = require("jquery");
const POP_IN_ADVERTISING_BANNER_WARNING = "#pop_in_advertising_banner_warning";
const POP_IN_SEND_ORDER = "#popinTransmissionCommande";
const POP_IN_UNIT_ORDER_SHIPPING_COST = "#unit-order-shipping-cost";
const POP_IN_ORDER_VALIDATION_PROMO = "#pop_in_order_validated_promo";
const LOADER_CART_IN_PROGRESS = ".loaderCartInProgress";
const LOADER_CART_IN_PROGRESS_SCROLL = ".loaderCartInProgressScroll";
const LOADER_TRANSMIT_SCROLL = '#loaderTransmissionScroll';
const LOADER_SCROLL = '.loaderScroll';
const HIDE = 'hide';

/**
 * Check if exist invalid promotions in cart
 */
export function isValidPromotionsInCart(numCde, dataSndCmd)
{
    let isValid = true;

    // Show the loader of checking the cart
    $(LOADER_CART_IN_PROGRESS).removeClass(HIDE);
    $(LOADER_CART_IN_PROGRESS_SCROLL).removeClass(HIDE);

    if ($(LOADER_TRANSMIT_SCROLL).length > 0) {
        $(LOADER_TRANSMIT_SCROLL).hide();
    }
    if ($(LOADER_SCROLL).length > 0) {
        $(LOADER_SCROLL).hide();
    }
    // Hide items when transmission is in progress OK
    transmissionInProgress();

    $.ajax({
        type: 'GET',
        url: Routing.generate('autossimo_cart_check_promotions_in_cart', { numCde: numCde }),
        dataType: 'html',
        async: false,
        success: function (data) {
            // The results if exist references in cart associated to invalid promotion then display popIn
            const results = JSON.parse(`${data}`);

            // Check if the status is not valid, then display the alert for invalid promotions
            if (!results.status) {
                // Set the is valid to false
                isValid = false;

                // Hide the pop in on unit order shipping cost
                $(POP_IN_UNIT_ORDER_SHIPPING_COST).modal(HIDE);

                // Hide order transmission pop in
                $(POP_IN_SEND_ORDER).modal(HIDE);

                // Hide advertising banner warning pop in
                $(POP_IN_ADVERTISING_BANNER_WARNING).modal(HIDE);

                // Call the alert function to display the warning pop in
                alertInvalidPromotion(results, dataSndCmd);
            }
        },
        fail: function () {
            // Hide the loader of checking the cart
            hideLoaderCartInProgress();

            // Show items when transmission is finalized and hide the scroll loader
            transmissionFinalized();
        }
    });

    return isValid;
}

/**
 * Display alert for invalid promotion
 */
function alertInvalidPromotion(results, dataSndCmd) {
    let referencesInvalid = '';
    let title = '';
    let messageBeforeListReferences = '';
    let messageWishModifyOrSendOrder = '';
    const numLines = results.numLines;
    const titlePromoValue = results.titlePromo;
    const referencesInCart = results.references;
    const quantity = results.quantityPurchase;
    const popIn = $(POP_IN_ADVERTISING_BANNER_WARNING);

    $.each(referencesInCart, (index, value) => {
        let referenceBloc = '';

        // Define the content of reference bloc
        referenceBloc = referenceBloc.concat('<a class="tooltips popin-tooltips col-xs-7 content-popin-tooltips tooltips-designationRef"> <span class="content-popin-tooltips">')
            .concat(value.designationRef).concat('</span></a><div class="col-xs-7 no-padding pL-15 text-overflow-ellipsis designation">')
            .concat(value.designationRef).concat('</div>')
            .concat('<a class="tooltips popin-tooltips col-xs-4 left-38-percent content-popin-tooltips tooltips-supplier"><span class="content-popin-tooltips">')
            .concat(value.supplier).concat('</span> </a><div class="col-xs-4 no-padding text-overflow-ellipsis blue_text pL-15">').concat(value.supplier)
            .concat('</div><a class="tooltips popin-tooltips col-xs-3 left-56-percent content-popin-tooltips tooltips-reference"> <span class="content-popin-tooltips">')
            .concat(value.reference).concat('</span></a><div class="col-xs-3 text-overflow-ellipsis no-padding gray-text reference-bloc"> REF: ')
            .concat(value.reference).concat('</div>')
            .concat('<div class="col-xs-1 text-overflow-ellipsis text-bold no-padding quantity-in-cart"> x').concat(value.quantityInCart).concat('</div>')

        // Switch between cases for quantity is less / higher than minimum purchase reference quantity
        if (results.case === 'case_5_promotion_quantity_higher_than_minimum_purchase_ref') {
            referenceBloc = referenceBloc.concat('<div class="bloc-number-validated-promo"><br> permet de profiter <span class="quantity-in-cart">')
                .concat(value.numberValidatedPromo).concat('</span> fois de la promotion, il en manque <span class="quantity-in-cart">')
                .concat(value.missingQuantity).concat('</span> pour en profiter une fois de plus</div>')
        }
        referencesInvalid = referencesInvalid.concat("<div class='row promo-reference-bloc'>").concat(referenceBloc).concat('</div>');
    });

    const isSpecialOffer = (results.promo_type !== undefined && 'classics' === results.promo_type);
    const label = true === isSpecialOffer ? 'l&apos;Offre spéciale' : 'l&apos;offre Promo Flash 24h';
    const staticMessageWishModify = 'Souhaitez-vous transmettre votre commande avec cette/ces r&eacute;f&eacute;rence(s) ou modifier votre panier ?';
    const staticMessageBeforeListRef = 'D&eacute;sol&eacute;, votre commande de la/des r&eacute;f&eacute;rence(s) suivante(s)';
    const staticMessagePromoInformation = true === isSpecialOffer ?
        'Information Offre Spéciale : ' : 'Information promotion flash : ';

    const titlePrefix = (true === isSpecialOffer) ? 'Promotion Offre spéciale' : 'Promotion flash';
    // Switch in case
    switch (results.case) {
        case 'case_1_not_exist_promo':
        case 'case_2_no_longer_access':
            title = 'Promotion : L&apos;offre n&apos;est plus disponible';
            messageBeforeListReferences = staticMessageBeforeListRef.concat(' ne permet plus de participer &agrave; l\'offre promo.');
            messageWishModifyOrSendOrder = staticMessageWishModify;
            break;
        case 'case_3_promotion_expired_period':
            title = titlePrefix + ' : La p&eacute;riode de l&apos;offre est d&eacute;pass&eacute;e';
            messageBeforeListReferences = staticMessageBeforeListRef.concat(' arrive trop tard et ne permet plus de participer &agrave; '+label+' "')
                .concat(titlePromoValue).concat('"');
            messageWishModifyOrSendOrder = staticMessageWishModify;
            break;
        case 'case_4_promotion_sales_number_reached_and_time_exceed':
            title = (staticMessagePromoInformation).concat(titlePromoValue);
            messageBeforeListReferences = staticMessageBeforeListRef.concat(' arrive trop tard et ne permet plus de participer &agrave; '+label+' " ')
                .concat(titlePromoValue).concat('"');
            messageWishModifyOrSendOrder = staticMessageWishModify;
            break;
        case 'case_5_promotion_quantity_less_than_minimum_purchase_ref':
            title = (staticMessagePromoInformation).concat(titlePromoValue);
            messageBeforeListReferences = ('Pour profiter de '+label+' "').concat(titlePromoValue)
                .concat('", la quantit&eacute; minimum d&apos;achat <span class="min-quantity">doit &ecirc;tre de ').concat(quantity)
                .concat('</span>.<br> Elle n&apos;est pas atteinte pour la/les r&eacute;f&eacute;rence(s) suivante(s): ');
            messageWishModifyOrSendOrder = 'Souhaitez-vous modifier votre panier ou transmettre votre panier ?';
            break;
        case 'case_5_promotion_quantity_higher_than_minimum_purchase_ref':
            title = (staticMessagePromoInformation).concat(titlePromoValue);
            messageBeforeListReferences = ('Pour profiter plusieurs fois de '+label+' "')
                .concat(titlePromoValue)
                .concat('", <br> la quantit&eacute; d&apos;achat <span class="min-quantity">doit &ecirc;tre un multiple de ').concat(quantity)
                .concat('</span>. Elle n&apos;est pas atteinte pour la/les r&eacute;f&eacute;rence(s) suivante(s):');
            messageWishModifyOrSendOrder = 'Souhaitez-vous modifier votre panier ou transmettre votre panier ?';
            break;
        default:
            break;
    }

    // Set the content for pop up
    popIn.find('.advertising-banner-warning-title').html(title);
    popIn.find('.detail-content-info-before-list-reference').html(messageBeforeListReferences);
    popIn.find('.content-warning-references-with-promo').html(referencesInvalid);
    popIn.find('.message-wish-modify-or-send-order').html(messageWishModifyOrSendOrder);

    const sendOrderBtn = $('#send-order-removing-promo');
    sendOrderBtn.attr('data-advertising-banner-id', results.idPromo);
    sendOrderBtn.attr('data-numCde',  results.numCde);
    sendOrderBtn.attr('data-numLines', numLines);
    sendOrderBtn.attr('data-refcde', dataSndCmd.refcde);
    sendOrderBtn.attr('data-messagerep', dataSndCmd.messagerep);
    sendOrderBtn.attr('data-path', dataSndCmd.path);
    sendOrderBtn.attr('data-pathOk', dataSndCmd.pathOk);
    sendOrderBtn.attr('data-pathCart', dataSndCmd.pathCart);
    sendOrderBtn.attr('data-type', dataSndCmd.type);
    sendOrderBtn.attr('data-layer', dataSndCmd.layer);
    sendOrderBtn.attr('data-csrfToken', dataSndCmd.csrfToken);

    $('#element-advertising-banner-id').val(results.idPromo)
    $('#element-numCde').val(results.numCde)
    $('#element-numLines').val(numLines)
    $('.shipping.cart-block').attr('title', '');

    // Show modal of invalid promotion
    popIn.modal('show');
}

$(document).on('click', '#send-order-removing-promo', function(e) {
    e.preventDefault();
    const idPromo = $(this).attr('data-advertising-banner-id');
    const numCde =  $(this).attr('data-numCde');
    const numLines =  $(this).attr('data-numLines');
    const refcde = $(this).attr('data-refcde');
    const messagerep = $(this).attr('data-messagerep');
    const path = $(this).attr('data-path');
    const pathOk = $(this).attr('data-pathOk');
    const pathCart = $(this).attr('data-pathCart');
    const type = $(this).attr('data-type');
    const layer = $(this).attr('data-layer');
    const csrfToken = $(this).attr('data-csrfToken');

    $.ajax({
        type: 'GET',
        url: Routing.generate('autossimo_remove_promotion_from_cart',
            { numCde: numCde , idPromo: idPromo, numLines: numLines}),
        dataType: 'html',
        async: false,
        success: function (results) {
            // The results if exist references in cart associated to invalid promotion then display popIn
            const returnedResults = JSON.parse(`${results}`);

            // Check if updated lines is succeeded
            if (returnedResults.status_remove_promotion_from_carte) {
                sndcmd(numCde, refcde, messagerep, path, pathOk, pathCart, type, csrfToken, layer);
            } else {
                // Hide the loader of checking the cart
                hideLoaderCartInProgress();
            }
        },
        fail: function () {
            // Hide the loader of checking the cart
            hideLoaderCartInProgress();
        }
    });
});

$(document).on('click', '.close-alert-validated-promo', function (event) {
    const href = $(this).attr('href');
    if (href === '#') {
        event.preventDefault();
    }

    $(POP_IN_ORDER_VALIDATION_PROMO).css("display", "none");
    $(POP_IN_ADVERTISING_BANNER_WARNING).modal('hide');

    // Hide the loader of checking the cart
    hideLoaderCartInProgress();

    // Show items when transmission is finalized and hide the scroll loader
    transmissionFinalized();
});

$(document).on('click', '.update-line-in-cart', function (event) {
    event.preventDefault();
    const href = $(this).attr('href');
    const advertisingBannerId = $('#element-advertising-banner-id').val();
    const numCde = $('#element-numCde').val();
    const numLines = $('#element-numLines').val();

    $.ajax({
        type: 'GET',
        url: Routing.generate('autossimo_remove_counting_number_promotion',
            { numCde: numCde , idPromo: advertisingBannerId, numLines: numLines}),
        dataType: 'html',
        async: false,
        success: function (results) {
            const data = JSON.parse(`${results}`);
            if (data.status_remove_counting_number_of_promotion) {
                if (href === '/panier') {
                    location.href = Routing.generate('autossimo_panier');
                } else {
                    $(POP_IN_ADVERTISING_BANNER_WARNING).css("display", "none");
                    $(POP_IN_ORDER_VALIDATION_PROMO).modal(HIDE);

                    // Hide the loader of checking the cart
                    hideLoaderCartInProgress();

                    // Show items when transmission is finalized and hide the scroll loader
                    transmissionFinalized();
                }
            } else {
                alert(data.status_remove_counting_number_of_promotion)
            }
        }
    });
});

function hideLoaderCartInProgress() {
    // Hide the loader of checking the cart
    $(LOADER_CART_IN_PROGRESS).addClass(HIDE);
    $(LOADER_CART_IN_PROGRESS_SCROLL).addClass(HIDE);
}
