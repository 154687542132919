import { checkBandAlert, getSearchTerm } from "../../../manage-ga";
import { BLOCK, inputSearch, PATH } from "../../constants";
import { checkCons, getSearchType, getVehiclesData } from "../../utilities";
import ViewVehicleResultsEvent from "./ViewVehicleResultsEvent";
export default class DefaultNormalizer {
    normalize(blockDataLayer, category) {
        if (0 === $(BLOCK).length) {
            return null;
        }
        const vehicles = getVehiclesData(blockDataLayer);
        const searchType = getSearchType(blockDataLayer);
        const viewVehicleResultsEvent = new ViewVehicleResultsEvent();
        viewVehicleResultsEvent.catalog = checkCons(category);
        viewVehicleResultsEvent.searchType = searchType;
        viewVehicleResultsEvent.resultsMultipleVehicles = $(BLOCK).length > 1 && !checkBandAlert();
        viewVehicleResultsEvent.immatInput = inputSearch.hasClass('has-immatriculation');
        viewVehicleResultsEvent.searchTerms = getSearchTerm(blockDataLayer);
        viewVehicleResultsEvent.vehicles = vehicles;
        return viewVehicleResultsEvent;
    }
    support(blockData, category) {
        return PATH.indexOf('pieces/resultats') >= 0 || PATH.indexOf('pieces/resultats-recherche') >= 0;
    }
}
