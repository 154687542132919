import AbstractDataBuilder from "../../core/AbstractDataBuilder";
import DisplayIamForOemNormalizersResolver from "./DisplayIamForOemNormalizersResolver";
export default class DisplayIamForOemDataBuilder extends AbstractDataBuilder {
    static create() {
        if (undefined === this.builderInstance) {
            this.builderInstance = new this(new DisplayIamForOemNormalizersResolver());
        }
        return this.builderInstance;
    }
}
