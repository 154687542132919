import EventHandler from "../analytics/amplitude/EventHandler";
import { FOLDER_COST } from "../analytics/constants";
import GaEventHandler from "../analytics/ga/GaEventHandler";

const classSubResult = '.result.sub_result';
const disabled = 'disabled';
const addPartsToCartBtn = '#add-parts-to-cart';
const refOeContainer = '.ref-oe-container';
const folderNbIamRefs = '.folder-nb-iam-refs';
const iamReferencesBlock = '.iam-references-block';
const toggleDisplayRefOe = '.toggle-display-ref-oem.is-hide';
const referenceOe = 'reference-oe';
const folderLoaderBlock = '.folder-loader-block';
const popinInstantOrder = '#popinFolderInstantOrder';
const iamSelectBtnSelector = '.btn-edit.choice-piece';
const dataSelected = 'data-selected';
export const selectedRefsSelector = '#selected-refs';
export const instantOrderBtn = '#folder-instant-order';
export const nbPartsInCart ='a.cart-opener span.nbPanier';
export const dataQuantityLabel = 'data-quantity';
export const dataNumLineOrder = 'data-num-line-order';
export const filtresEquip = '.filtres-equip';
export const quantityAvailability = '.quantity-availability';
export const folderCheckboxContainer = '.folder-checkbox-container';
export const productSheetLink = '.productsheet-link, .p-description';
export const originData = '.origin-equivalence.origin-datas';
export const dataFolderID = 'data-folder-id';
export const hide = 'hide';

export function clickChoicePiece(element, firstLoad) {
    var textLabel = 'CHOISIR';
    var buttonElement = element.closest('.btn-edit');
    var originDatas = element.closest('.origin-equivalence.origin-datas');
    var folderChosen = 'folder-chosen';
    var refIam = originDatas.data('codrefeq');
    var selected = JSON.parse($(selectedRefsSelector).attr(dataSelected));
    const refOe = originDatas.find('[id*="quote-data-"]').data('codrefco');
    if (!firstLoad && !buttonElement.hasClass('disabled') && !buttonElement.hasClass('offcatalog')) {
        var checkbox = buttonElement.find('input');
        checkbox.prop('checked', !checkbox.prop('checked'));
        if (checkbox.prop('checked')) {
            checkbox.parents(iamReferencesBlock).find('.folder-chosen input').prop('checked', false);
            checkbox.parents(iamReferencesBlock).find('.folder-chosen label').text('CHOISIR');
            checkbox.parents(iamReferencesBlock).find('.folder-chosen').removeClass(folderChosen);
            buttonElement.toggleClass(folderChosen, true);
            textLabel = 'RÉFÉRENCE CHOISIE';
            selected.inCatalog[refOe] = refIam;
        } else {
            delete selected.inCatalog[refOe];
            buttonElement.toggleClass(folderChosen, false);
        }
    } else if ($.inArray(
        originDatas.attr('data-equip'),
        ['CORA', 'PGS', 'DIMA']) > -1 ||
        (
            originDatas.attr('data-dispo') === 'A' && originDatas.attr('data-price') === '--,--'
        )
    ) {
        textLabel = 'CHOIX INDISPONIBLE'
        buttonElement.addClass('disabled', false);
        delete selected.inCatalog[refOe];
    }
    $(selectedRefsSelector).attr(dataSelected, JSON.stringify(selected));
    buttonElement.find('label').text(textLabel);
    enableAddToCart();

}

// update selected refs
export function updateSelectedRefs()
{
    const clickedRefs = $('.folder-chosen .choice-piece');
    const selected = {'inCatalog': {}, 'offCatalog': {}};
    clickedRefs.each(function (index, value) {
        const refData = $(value).parents('.folder-chosen');
        if (refData.hasClass('offcatalog')) {
            const refOe = refData.parents('.ref-oe-container.folder-offcatalog').data(referenceOe);
            selected.offCatalog[refOe] = String(refOe);
        } else {
            selected.inCatalog[refData.data('codrefco')] = refData.data('codrefeq');
        }
    });
    $(selectedRefsSelector).attr('data-selected', JSON.stringify(selected));
}

// keep selected refs across page updates
export function displaySelectedRefs(removeFromCart = false)
{
    var selected = JSON.parse($(selectedRefsSelector).attr(dataSelected));
    for (const product in selected.inCatalog) {
        clickChoicePiece($(`.btn-edit.choice-piece[data-codrefeq=${selected.inCatalog[product]}]:not(.folder-chosen)`));
    }
}

export function disabledQuantityBlockProductSheet(element, isCoraNotAvailable) {
    if (isCoraNotAvailable) {
        element.prop('readonly', true);
        element.siblings('.minus, .plus').addClass('disabled');
    }
}

export function enableAddToCart() {
    var count = 0;
    var addedToCart = false;
    $(refOeContainer).each(function(index, value){
        var caseOne = $(value).find(folderNbIamRefs).length === 0 &&
            $(value).find('.folder-checkbox-container > input').prop('checked') &&
            !$(value).find('.folder-checkbox-container > input').prop('readonly');
        var caseTwo = $(value).find(folderNbIamRefs).length > 0 &&
            $(value).find('.choice-piece:checked').length > 0;
        if (caseOne || caseTwo) {
            count++;
        }
        // if in cart enable instant order button
        if ($(value).find('.remove-part-from-cart').length > 0) {
            addedToCart = true;
        }
    });
    $(addPartsToCartBtn).toggleClass('disable-anchor', count === 0);
    $(instantOrderBtn).toggleClass(disabled, count === 0 && addedToCart === false);
}

export function getRefOpened()
{
    var referencesOpened = [];
    var refOpened = $(refOeContainer).find(classSubResult.concat(':visible'));
    refOpened.each(function(index, value){
        referencesOpened.push($(value).closest(refOeContainer).attr('data-codrefco'));
    });

    return referencesOpened;
}

export function disableMinusQuantity(element){
    element.parents('.cart-action').find('.minus').addClass('disabled');
}

export function redirectIfTokenExpired(url, params) {
    window.location.href = `${url}?${$.param(params)}`;
}

// auto select first available iam reference
export function autoSelectFirstIamReference(referenceOE)
{
    var selected = JSON.parse($(selectedRefsSelector).attr(dataSelected));
    // select first iam
    referenceOE.find(iamReferencesBlock).each(function (index, iamBlock) {
        // avoid ordered refs
        if (referenceOE.data('status') !== 'ORDERED' && referenceOE.data('in-cart') === 0) {
            // remove previous selection
            clickChoicePiece($(iamBlock).find('.btn-edit.choice-piece.folder-chosen:not(.disabled)'));
            // select first available iam
            var refIam = $(iamBlock).find('.btn-edit.choice-piece')
                .filter('[data-availability="D"],[data-availability="E"]').eq(0);
            if (refIam.data('codrefeq') !== undefined) {
                selected.inCatalog[referenceOE.data('codrefco')] = refIam.data('codrefeq');
            }
            clickChoicePiece(refIam);
        }
    });
    $(selectedRefsSelector).attr(dataSelected, JSON.stringify(selected));
}

// if only 1 IAM found & it is available then auto select it
export function autoSelectOnlyIamReference(referenceOE)
{
    var selected = JSON.parse($(selectedRefsSelector).attr(dataSelected));
    // select first iam
    referenceOE.find(iamReferencesBlock).each(function (index, iamBlock) {
        if (referenceOE.data('status') !== 'ORDERED' && referenceOE.data('in-cart') === 0 ) {
            // remove previous selection
            clickChoicePiece($(iamBlock).find('.btn-edit.choice-piece.folder-chosen:not(.disabled)'));
            var iamSelectBtns = $(iamBlock).find(iamSelectBtnSelector);
            if (iamSelectBtns.length === 1 &&
                iamSelectBtns.filter('[data-availability="D"],[data-availability="E"]').length === 1) {
                clickChoicePiece(iamSelectBtns.eq(0));
                referenceOE.find(toggleDisplayRefOe).click();
                if (iamSelectBtns.eq(0).data('codrefeq') !== undefined) {
                    selected.inCatalog[referenceOE.data('codrefco')] = iamSelectBtns.eq(0).data('codrefeq');
                }
            }
        }
    });
    $(selectedRefsSelector).attr(dataSelected, JSON.stringify(selected));
}

// check if is body work IAM
export function manageFolderDiscount(updatedRef) {
    jQuery.ajax({
        type: "POST",
        data: {
            references: JSON.stringify(updatedRef.map(function (value, index) {
                return value.CODREFEQ;
            })),
        },
        url: Routing.generate('is_iam_bodywork_batch'),
        async: true,
        success: function (data) {
            for (const ref of updatedRef) {
                // apply only to bodywork products
                if (!data[ref.CODREFEQ]) {
                    continue;
                }
                displayFolderDiscount(ref);
            }
        }
    });
}

export function processAddToCart(element, isInstantOrder = false)
{
    GaEventHandler.addToCart(element,FOLDER_COST);
    const folderID = element.attr(dataFolderID);
    const dmsFolderID = element.attr('data-dms-folder-id');
    addFolderPartsToCart(folderID, dmsFolderID, getProductsChoosen(), isInstantOrder);
}

export function errorCallback(url, params, error) {
    if (error.status === 0) {
        redirectIfTokenExpired(url, params)
    }
}

function addFolderPartsToCart(id, dmsFolderId, data, isInstantOrder) {
    const url = Routing.generate('autossimo_add_folder_parts_to_cart');
    const totalHt = data.reduce(function (r, b) {
        return r + b.priceHt*parseInt(b.qte);
    }, 0);
    const params = {
        folderID : id,
        dmsFolderID : dmsFolderId,
        products : data,
        referenceOpened : getRefOpened(),
        totalHt: totalHt
    }
    $.ajax({
        url:  url,
        type: "POST",
        data: params,
        beforeSend: function () {
            $(folderLoaderBlock).addClass('d-inline-block');
            $(folderLoaderBlock).removeClass('hidden');
            $(addPartsToCartBtn).addClass('hidden');
            $(instantOrderBtn).addClass(disabled);
        },
        success: function (dataSuccess) {
            EventHandler.addToCart(null,FOLDER_COST, true);
            
            $(nbPartsInCart).html(dataSuccess['NBARTICLES']);
            $(dataSuccess['content']).find(refOeContainer).each(function(index, oeReference){
                if (parseInt($(oeReference).attr('data-nb-iam-refs')) === 0) {
                    $('#'.concat($(oeReference).attr('id'))).html($(oeReference).html());
                }
            });
            $(dataSuccess['content']).find(originData).each(function(index, iamReference){
                updateIamReferenceContent(iamReference);
            });
            if (isInstantOrder) {
                $(popinInstantOrder).replaceWith(dataSuccess.instantOrderContent);
                $(popinInstantOrder).modal('toggle');
            }
            
        },
        complete: function (xhr) {
            if (xhr.status !== 0) {
                $(folderLoaderBlock).addClass('hidden');
                $(addPartsToCartBtn).removeClass('hidden');
            }
            enableAddToCart();
            displaySelectedRefs();
        },
        error: function(error) {
            EventHandler.addToCart(null,FOLDER_COST, false);
            errorCallback(url, params, error)
        }
    });
}

function displayFolderDiscount(ref)
{
    if (ref.REMISEPOURCENT !== '--' && ref.REMISEPOURCENT !== 0.0) {
        var discountContainer = $(`.discount-container[data-codrefeq="${ref.CODREFEQ}"]`);
        discountContainer.find('.remise-pourcent').text(`${ref.REMISEPOURCENT}%`);
        if (ref.WEB_PRICE && ref.ADDED_DISCOUNT === null) {
            discountContainer.find('.remise-pourcent').addClass('remise-red');
        }
        if (ref.ADDED_DISCOUNT !== null) {
            discountContainer.find('.discount-complementary').text(
                `+${ref.ADDED_DISCOUNT}%`
            )
            if (!ref.WEB_PRICE) {
                discountContainer.find('.discount-complementary').addClass('discount-black');
            }
            if (ref.THIRD_DISCOUNT !== null) {
                discountContainer.find('.discount-complementary-2').text(
                    `+${ref.THIRD_DISCOUNT}%`
                )
                if (!ref.WEB_PRICE) {
                    discountContainer.find('.discount-complementary-2').addClass('discount-black');
                }
            }
        }
        $('.discount-label').text(
            'Remise' + ((!ref.WEB_PRICE) && ref.PRICE_LEVEL ? ' de base' : '')
        );
        discountContainer.removeClass('hidden');
    }
}

function getProductsChoosen()
{
    var products = [];
    // in catalog
    $('.folder-chosen:not(.offcatalog)').each(function () {
        if ($(this).closest('.origin-datas.hide').length === 0) {
            products.push({
                codrefco: $(this).data('codrefco'),
                codrefeq: $(this).data('codrefeq'),
                typeligne: $(this).data('linetype'),
                design: $(this).closest(originData).data('design'),
                supplier: $(this).closest(originData).data('libequip'),
                priceHt: $(this).closest(refOeContainer).data('price'),
                qte: $(this).closest(refOeContainer).attr(dataQuantityLabel),
                date: $(this).data('date'),
                refeq: $(this).closest(originData).data('refequip'),
                refcon: $(this).closest(refOeContainer).data(referenceOe),
                availability: $(this).closest('.containerDispo').data('dispo'),
                vehiculeImmat: $(this).closest(refOeContainer).attr('data-vehicule-immat')
            });
        }
    });

    return products;
}

function updateIamReferenceContent(iamReference) {
    var id = '#'.concat($(iamReference).attr('id'));
    var dataStatusLabel = 'data-status';
    var productSheetLinkElement = $(iamReference).find('.productsheet-link');
    var productSheetLinkDesc = $(id).closest(originData).find(productSheetLink);

    // Get identifier for ANG Access
    if ($('.has-ang-access').length > 0) {
        const specialCharacters = [' ', '*', '/', '.', '-', '`'];
        let refCon = $(iamReference).attr('data-refcon');
        let refEquip = $(iamReference).attr('data-refequip');

        // Remove special Characters
        for (const value of specialCharacters) {
            refCon = refCon.replace(value,'');
            refEquip = refEquip.replace(value,'');
        }

        // Update identifier
        id = '.folder-part-'.concat(refCon).concat('-').concat(refEquip);
    }

    if ($(iamReference).attr('data-nb-iam-ref-in-cart') > 0) {
        if ($(iamReference).attr(dataStatusLabel) === 'IN_CART' || $(iamReference).attr(dataStatusLabel) === 'ORDERED') {
            $(id).find(quantityAvailability).html($(iamReference).find(quantityAvailability).html());
            $(id).find('.dispo-container-part').addClass(hide);
            $(id).closest(refOeContainer).find(folderCheckboxContainer).html(
                $(iamReference).closest(refOeContainer).find(folderCheckboxContainer).html()
            );
        } else  {
            $(id).addClass(hide);
        }
        $(id).closest(refOeContainer).find(folderNbIamRefs).addClass(hide);
        $(id).closest(refOeContainer).find(filtresEquip).addClass(hide);
        productSheetLinkDesc.attr(dataStatusLabel, productSheetLinkElement.attr(dataStatusLabel));
        productSheetLinkDesc.attr(dataQuantityLabel, productSheetLinkElement.attr(dataQuantityLabel));
        productSheetLinkDesc.attr(dataNumLineOrder, productSheetLinkElement.attr(dataNumLineOrder));
        productSheetLinkDesc.attr('data-num-order', productSheetLinkElement.attr('data-num-order'));
        productSheetLinkDesc.attr('data-type-order', productSheetLinkElement.attr('data-type-order'));
        productSheetLinkDesc.attr('data-date-order', productSheetLinkElement.attr('data-date-order'));
    } else {
        $(id).closest(refOeContainer).find(folderNbIamRefs).removeClass(hide);
        $(id).closest(refOeContainer).find(filtresEquip).removeClass(hide);
    }

}
