import {getPromotionBand} from "./promotion-bands";
import {switchModeDisplayConsumable} from "./pubtech-main-v2";
import {formatAvailability} from "./manage-ga";
import {updateEndOfLifeMessage} from "./consumable-functions";
import { ProductAttributes } from "./analytics/amplitude/dto/product/ProductAttributes";
import { ItemAttributes } from "./analytics/ga/dto/item/ItemAttributes";

const PRODUCT_CONSU = '.products-consumables';
const DATA_AVAILABILITY= 'data-availability';
const AVAILABILITY_ECPF= '.dispoECPF';
const AVAILABILITY_E= '.DispoE';
const SIMPLIFIED_DELAY= '.simplified_delay';
const FIXED_NUMBER = 2;
const OUT_OF_STORE = 'OUT_OF_STORE';

export function updateBlocPrice(itemToUpdate, value, updateHidden = false) {
    const erpNokDistributor = $('.erp_nok_distributor').val();

    const priceHtSelector = $(itemToUpdate).find('.ht-price');
    const priceTtcSelector = $(itemToUpdate).find('.ttc-price');

    let pvht = checkAndFormatPriceOrRemise(value.PVHT);
    let pvttc = checkAndFormatPriceOrRemise(value.PVTTC);
    let paht = checkAndFormatPriceOrRemise(value.PAHT);
    let remise = checkAndFormatPriceOrRemise(value.REMISEPOURCENT);
    let remiseComplementary = checkAndFormatPriceOrRemise(value.ADDED_DISCOUNT);
    let remiseComplementary2 = checkAndFormatPriceOrRemise(value.THIRD_DISCOUNT);

    if ($.isNumeric(pvttc)) {
        pvttc = Number(pvttc).toFixed(FIXED_NUMBER);
        pvttc = pvttc.toString();
        pvttc = pvttc.replace(".", ",");
    }
    paht = paht.toString().replace(".", ",");
    pvht = pvht.toString().replace(".", ",");
    remise = $.isNumeric(parseInt(remise)) ? parseInt(remise) + ' %' : '-- %';
    remiseComplementary = $.isNumeric(parseInt(remiseComplementary)) ? parseInt(remiseComplementary) + ' %' : '-- %';
    remiseComplementary2 = $.isNumeric(parseInt(remiseComplementary2)) ? parseInt(remiseComplementary2) + ' %' : '-- %';

    const result = checkPriceFull(pvht, pvttc, paht, erpNokDistributor);
    const htPriceFull = result['htPriceFull'];
    const ttcPriceFull = result['ttcPriceFull'];

    if (paht === '0,00' || paht === '0'){
        paht = '--,--';
    }

    $(itemToUpdate).attr('data-price', value.PVHT);

    priceHtSelector.text(htPriceFull + ' € HT');
    priceHtSelector.removeClass('hidden');

    priceTtcSelector.text(ttcPriceFull + ' € TTC');
    priceTtcSelector.removeClass('hidden');
    const modePrice = $('#toggle_currency_mode:hidden:first').prop('checked');

    const priceNetHtSelector = $(itemToUpdate).find('.net-price-rate-ttc');
    const RemiseSelector = $(itemToUpdate).find('.remise-pourcent');
    const RemiseComplementarySelector = $(itemToUpdate).find('.discount-complementary');
    const RemiseComplementarySelector2 = $(itemToUpdate).find('.discount-complementary-2');

    priceNetHtSelector.text(paht + ' € HT');
    const publicPrice = $(itemToUpdate).find('.consumable-customer-price .public');
    pricesSwitchManage(value.PVHT, value.PAHT, publicPrice, modePrice);

    if (remise && remise.indexOf('-') > -1) {
        RemiseSelector.parent().hide();
    } else {
        RemiseSelector.text(remise);
        if (remiseComplementary.indexOf('-') === -1){
            RemiseComplementarySelector.text('+' + remiseComplementary);
        }
        if (remiseComplementary2.indexOf('-') === -1){
            RemiseComplementarySelector2.text('+' + remiseComplementary2);
        }
    }
    getPromotionBand(value, $(itemToUpdate), modePrice);

    $(itemToUpdate).addClass('priceLoaded');
    if ($(itemToUpdate).find('#pvttc-'+value.CODREF).length > 0) {
        $(itemToUpdate).find('#pvttc-'+value.CODREF).val(String(value.PVTTC).replace(',', '.'))
    } else {
        $(itemToUpdate).append(
            '<input id="pvttc-' + value.CODREF + '" type="hidden" value="' +
            String(value.PVTTC).replace(',', '.') + '"/>'
        )
    }
    //update availability
    updateBlocDispo($(itemToUpdate), value);

    if($(PRODUCT_CONSU).length) {
        switchModeDisplayConsumable(modePrice, updateHidden);
    }
    const values = {
        sale_price: htPriceFull ,
        purchase_price: paht,
        quantity: value.QUANTITE,
        availability:value.DELAI_SIMPLIFIE
    };
    ProductAttributes.fromPrices(itemToUpdate,values);
    ItemAttributes.fromPrices(itemToUpdate,values);
}

export function formatParamsForErpRequest(reference){
    const codref      = reference.attr('data-codref');
    const codarbo     = reference.attr('data-codarbo');
    const reffab      = reference.attr('data-reffab');
    const codfam      = reference.attr('data-codfam');
    const codsfam     = reference.attr('data-codsfam');
    const codfou      = reference.attr('data-manufacturer');
    const qte         = reference.find('input[data-quantity]').attr('data-quantity');
    const diametre    = reference.attr('data-diametre');

    return {
        "CODREF"    : codref,
        "CODARBO"   : codarbo,
        "REFFAB"    : reffab,
        "CODFOU"    : codfou,
        "REF"       : reffab,
        "QUANTITE"  : qte,
        "CODFAM"    : codfam,
        "CODSFAM"   : codsfam,
        'DIAMETRE'  : diametre
    };
}

function checkAndFormatPriceOrRemise(itemToFormat){
    let formatedItem = itemToFormat;
    if (/^[0-9]*\.[0-9]{3,}$/.test(itemToFormat)) {
        formatedItem = formatPriceAndRemise(itemToFormat);
    }
    // AD-3773: adjust to new formatting
    if (itemToFormat === '--') {
        formatedItem = '--,--';
    }

    return formatedItem;
}

function formatPriceAndRemise(price) {
    const priceFormated = price.toFixed(FIXED_NUMBER);

    return parseFloat(priceFormated);
}

function updateBlocDispo(refeq, value) {
    const availability = value.DISPO;
    const erpNokDistributor = $('.erp_nok_distributor').val();
    const codarbo = refeq.attr('data-codarbo');

    refeq.attr('data-dispo',  availability);
    refeq.attr(DATA_AVAILABILITY,  availability);
    refeq.find('.ajout-panier-peinture').attr(DATA_AVAILABILITY,  availability);

    const blocDispo = refeq.find('.consumable-detail-availability');
    const blockAvailability = $('.products-consumables--line').length > 0 ? '.mode-line' : ' .mode-grid';
    const blocDispoAvailable = refeq.find('.consumable-detail-availability'.concat(blockAvailability));

    const isAttachmentDepositDelay = value.DELAY_FROM_ATTACHEMENT;

    const dispo = (availability).length > 1 ? (availability).slice(0,1) : availability;
    let availabilityValue = checkAvailibilities(dispo, blocDispo, isAttachmentDepositDelay, value, blocDispoAvailable);
    const noPrices = checkNoPrices(value.PVHT);
    if (noPrices) {
        blocDispo.find('.dispoD').addClass('hidden');
        blocDispo.find(AVAILABILITY_ECPF).addClass('hidden');
        blocDispo.find(AVAILABILITY_E).addClass('hidden');
        blocDispo.find('.dispoA').addClass('hidden');
        if (erpNokDistributor === 'erp-distrib-nok') {
            blocDispo.find('.dispo-price-service-unavailable').removeClass('hidden');
            availabilityValue = blocDispoAvailable.find('.dispo-price-service-unavailable').text();
        } else {
            blocDispo.find('.bloc-contact-your-distributor').removeClass('hidden');
            availabilityValue = blocDispoAvailable.find('.bloc-contact-your-distributor').find('.available-on-demand').length > 0 ?
                blocDispoAvailable.find('.bloc-contact-your-distributor').find('.available-on-demand').text() :
                blocDispoAvailable.find('.bloc-contact-your-distributor').children(':not(.tooltips)').text();
        }
    }

    if (value.FLAG_PFPT) {
        refeq.find('.pfpt').removeClass('hidden');
    } else {
        refeq.find('.pfpt').addClass('hidden');
    }

    availabilityValue = formatAvailability(availabilityValue);
    blocDispo.find('#blocDispoConso').attr('data-available-value', availabilityValue);
    updateStoreBlock(refeq, value);
}

function updateStoreBlock(refeq, value)
{
    const blockOutOfStock = refeq.find('.out-of-store');
    if (value.STOCK !== 0 && canDisplayLimitedStockMsg(value)) {
        if (!isTyre(value) ||
            (isTyre(value) && (value.PRODUCT_STATUS_DROP === undefined || value.MULTIPLE_LINES === true))) {
            blockOutOfStock.find('.nbr-of-store').html(value.STOCK);
        } else if ((undefined !== value.IS_DROP  && true === value.IS_DROP)) {
            blockOutOfStock.find('.content-store').html('Stock limité');
            blockOutOfStock.find('.content-store').addClass('text-center');
        }
        refeq.find('.consumable-detail-name').addClass('mB-12');
        blockOutOfStock.removeClass('hideStore');
        blockOutOfStock.removeClass('hidden');
    } else {
        blockOutOfStock.addClass('hideStore');
        blockOutOfStock.removeClass('hidden');
    }    
}

function isTyre(value)
{
    return value.CODARBO === '120101';
}

function hideAllAvailibilities(bloc){
    bloc.find('.dispoD').addClass('hidden');
    bloc.find('.dispoA').addClass('hidden');
    bloc.find(AVAILABILITY_ECPF).addClass('hidden');
    bloc.find(AVAILABILITY_E).addClass('hidden');
    bloc.find(AVAILABILITY_E).find('.tooltips').addClass('hidden');
}

function checkNoPrices(price)
{
    return (price === "0,00" || price === "0" || price === 0 || price === "--" || price === "--,--");
}

function checkAvailibilities(dispo, blocDispo, isAttachmentDepositDelay, value, blocDispoAvailable)
{
    let availabilityValue;
    switch (dispo) {
        case 'D':
            hideAllAvailibilities(blocDispo);
            blocDispo.find('.dispoD').removeClass('hidden');
            availabilityValue = blocDispoAvailable.find('.dispoD').text();
            if (isAttachmentDepositDelay !== true) {
                blocDispo.find(SIMPLIFIED_DELAY).addClass('hidden');
                blocDispo.find('.tooltips').addClass('hidden');
            } else {
                blocDispo.find(SIMPLIFIED_DELAY).text(value.DELAI_SIMPLIFIE);
                blocDispo.find('.delay').text(value.DELAI);
            }
            break;
        case 'C':
        case 'P':
        case 'F':
            hideAllAvailibilities(blocDispo);
            blocDispo.find(AVAILABILITY_ECPF).removeClass('hidden');
            availabilityValue = blocDispoAvailable.find(AVAILABILITY_ECPF).text();
            blocDispo.find(SIMPLIFIED_DELAY).text(value.DELAI_SIMPLIFIE);
            blocDispo.find('.delay').text(value.DELAI);
            break;
        case 'E':
            hideAllAvailibilities(blocDispo);
            availabilityValue = blocDispoAvailable.find(AVAILABILITY_E).text();
            checkDelayValue(value.DELAI, blocDispo);
            blocDispo.find(AVAILABILITY_E).removeClass('hidden');
            break;
        case 'A':
            hideAllAvailibilities(blocDispo);
            availabilityValue = blocDispoAvailable.find('.dispoA').find('.available-on-order').length > 0 ?
                blocDispoAvailable.find('.dispoA').find('.available-on-order').text() :
                blocDispoAvailable.find('.dispoA').text();
            blocDispo.find('.dispoA').removeClass('hidden');
            break;
    }

    return availabilityValue;
}

function checkDelayValue(delay, blocDispo) {
    if (delay !== '--' || delay !== '' || delay != null) {
        blocDispo.find(AVAILABILITY_E).find('.tooltips').removeClass('hidden');
        blocDispo.find(AVAILABILITY_E).find('.delay').text(delay);
    }
}

function checkPriceFull(pvht, pvttc, paht, erpNokDistributor)
{
    let htPriceFull = 0;
    let ttcPriceFull = 0;
    if (pvht !== '0,00' && pvttc !== '0,00') {
        htPriceFull = pvht;
        ttcPriceFull = pvttc;
    } else if (((paht === '0,00' || paht === '0') ||
        ((pvht === '0,00' || pvht === '0') && (pvttc === '0,00' || pvttc === '0')))) {
        htPriceFull = '--,--';
        ttcPriceFull = '--,--';
    }

    if (erpNokDistributor === 'erp-distrib-nok') {
        htPriceFull = '--,--';
        ttcPriceFull = '--,--';
    }

    return {
        'htPriceFull' : htPriceFull,
        'ttcPriceFull' : ttcPriceFull
    }
}

function pricesSwitchManage(pvhtValue, pahtValue, publicPrice, modePrice)
{
    if (pvhtValue.toString().replace('.', ',') !== pahtValue.toString().replace('.', ',')) {
        publicPrice.removeClass('same-price-ht-ttc');
        if (modePrice === true) {
            publicPrice.removeClass('hidden');
        }
    } else {
        publicPrice.addClass('same-price-ht-ttc');
    }
}

export function updateItemAvailability(itemToUpdate, searchType, updatedRefCount, blockItemToUpdate = null) {
    blockItemToUpdate = blockItemToUpdate ?? itemToUpdate;
    if (
        ['consumablePage', 'paintPage'].includes(searchType) ||
        (['reference', 'snow_chain'].includes(searchType) && updatedRefCount === 1)
    ) {
        updateEndOfLifeMessage(itemToUpdate);
        $(itemToUpdate).find('.consumable-detail-availability .availability-pricing.mode-grid').remove();
        $(itemToUpdate).find('.consumable-price.v3_pricing .price-conso').remove();
        $(itemToUpdate).find('.consumable-basket .content-bloc-basket-conso').remove();
        $(itemToUpdate).find('.consumable-detail.isotech-icon').remove();
        $(itemToUpdate).find('.consumable-inner').css({'padding-bottom':'32px'});
    } else {
        blockItemToUpdate.hide().addClass('end-of-life');
        $('.nbr-item strong').text(parseInt($('.nbr-item strong').text()) - 1);
    }
}

export function canDisplayLimitedStockMsg(reference) {
    return reference.PRODUCT_STATUS === OUT_OF_STORE || reference.PRODUCT_STATUS_DROP === OUT_OF_STORE;
}
