import { ProductCollection } from "../dto/product/ProductCollection";
export default class CompleteOrderEvent {
    constructor(status, origin) {
        this.status = status;
        this.origin = origin;
        this.productCollection = new ProductCollection();
    }
    export() {
        return {
            origin: this.origin,
            status: this.status,
            orders: this.orders,
            total_orders_amount: this.totalOrdersAmount,
            products: this.productCollection.export(),
        };
    }
}
