import StartSearchNormalizer from "../startSearch/StartSearchNormalizer";
import { EventDtoTransformer } from "./EventDtoTransformer";
import { getVehiclesData, getGADataBlock } from "../../utilities";
import Flash from "../../Flash";
export default class ResultsNotFoundNormalizer {
    constructor() {
        this.startSearchNormalizer = new StartSearchNormalizer();
    }
    normalize(element, category) {
        const blockDataLayer = getGADataBlock();
        const vehicles = getVehiclesData(blockDataLayer);
        if (0 !== vehicles.length) {
            return null;
        }
        let startSearchEvent = Flash.getPieceCachedStartSearchEvent();
        if (null === startSearchEvent) {
            startSearchEvent = this.startSearchNormalizer.normalize(element, category);
        }
        return EventDtoTransformer.transformFromStartSearchDto(startSearchEvent);
    }
    support(element, category) {
        return this.startSearchNormalizer.support(element, category);
    }
}
