import {refreshDispo, refreshForCart} from "./pubtech-panier";
import {toggleBlocDispoPriceToResults, updateDispoPriceBloc} from "./pubtech-dispo-price";

const SELECT_PRICE_LEVEL = '.selectPriceLevel';
const CONTAINER_DISPO = '.containerDispo';
const PRICEWEB = 'price-level-li-web-price';
const FILTER_OPTION = '.filter-option';
const AUTOSSIMO_PRICE = 'PRIX AUTOSSIMO';

$(document).ready(function (){
    $(SELECT_PRICE_LEVEL).removeClass('invisible');

    $(document).on('change', 'input[type=radio][name=priceLevel]', function() {
        const quantity = this.value;
        const inputQuantity = $('div.containerDispo.ps-price').find('.cart-action .input-quantity');

        inputQuantity.val(quantity);
        inputQuantity.attr('data-quantity', quantity);
        inputQuantity.attr('value', quantity);

        //Search equipment
        refreshDispo(inputQuantity);
    });

    $(SELECT_PRICE_LEVEL).selectpicker({
        showIcon: true,
    });

    $(SELECT_PRICE_LEVEL).on('loaded.bs.select', function() {
        $(this).find('> button').removeAttr('title')
    });
    $(SELECT_PRICE_LEVEL).on('changed.bs.select', function() {
        $(this).find('> button').removeAttr('title')
    });

    $('div.selectPriceLevel span.bs-caret').attr('class','caret');
    $('.selectPriceLevel span').each(function (index,value) {
        //check if price is grather than 100
        const price = $(value).text().split('€')[0];
        const highPrice = 100;
        if (parseFloat(price) >= highPrice) {
            $(this).addClass('smaller');
        }
        if ($(value).html().indexOf('small') === -1 ) {
            $(value).html($(value).text().replace('----','<small>') + '</small>');
            if ($(value).html().includes(AUTOSSIMO_PRICE)) {
                $(value).addClass(PRICEWEB);
            }
        }
    });
    $(document).on('change','div.selectPriceLevel', function () {
        selectPriceLevel($(this));
        if ($(this).find(':selected').html().includes(AUTOSSIMO_PRICE)) {
            $(this).find(FILTER_OPTION).addClass(PRICEWEB);
        } else {
            $(this).find(FILTER_OPTION).removeClass(PRICEWEB);
        }
        $(this).find(FILTER_OPTION).html($(this).find(':selected').html().replace('----','<small>' )+ '</small>');
    })
})

/*
    Change quantity value & update dispo when we select a price Level.
 */
function selectPriceLevel(element) {
    const quantity = $(element).find('select').val();
    let inputQuantity = $(element).closest('div.containerDispo').find('.cart-action .input-quantity');
    const blockQuantity = $(element).closest('.section_cart--body-item').find('.custom-cart-action .cart-plus');
    inputQuantity = inputQuantity.length === 0 ?
        $(element).closest('div.row').find('.custom-cart-action .input-quantity') :
        inputQuantity;
    inputQuantity.val(quantity);
    inputQuantity.attr('data-quantity', quantity);
    inputQuantity.attr('value', quantity);

    const referenceToUpdate = $(element).closest('div.containerDispo');
    const searchBloc = referenceToUpdate.find('center.relative');
    const cartPricing = $(element).closest('section.section_cart');
    if(searchBloc.length === 0 && cartPricing.length !== 1) {
        // Search Express
        updateDispoPriceBloc(referenceToUpdate);
        toggleBlocDispoPriceToResults(referenceToUpdate);
    } else if (cartPricing.length === 1) {
        refreshDispo(inputQuantity);
        refreshForCart(blockQuantity);
    } else {
        //Search equipment and cart page
        refreshDispo(inputQuantity);
    }
}

/*
    Selected / checked  the right value of price level when we change the quantity .
 */
export function getPriceLevel(elem, newQuantity) {
    //verify if price level is activate or not
    const isPriceLevel = $(elem).closest(CONTAINER_DISPO).find('.price-level-bloc');
    const priceLevelSelect = $(elem).closest(CONTAINER_DISPO).find(SELECT_PRICE_LEVEL);
    const priceLevelSelectCart = $(elem).closest('.section_cart--body-item--info_price').find('.price-level-bloc:not(.noPriceLevel) .selectPriceLevel');
    const priceLevelSelectDetail = $(elem).closest('.productDetail').find('.price-level-bloc');
    if (
        (priceLevelSelectDetail.length > 0 || priceLevelSelect.length > 0 || priceLevelSelectCart.length > 0 )
        && !isPriceLevel.is(':hidden')
    ) {
        //for checkbox in product Sheet
        getPriceLevelChecked(newQuantity);

        if (!$('#popinFicheProduit').is(":visible") && !$('#popinProductSheet').is(':visible')) {
            //for options in result page
            getPriceLevelSelected(elem, newQuantity);

            let filterOption = $(elem).closest(CONTAINER_DISPO).find('.selectPriceLevel .filter-option');
            const containerDispo = filterOption.length === 0 ? 'div.row' : '.containerDispo';
            filterOption = filterOption.length === 0 ?
                $(elem).closest('div.row').find('.selectPriceLevel .filter-option') :
                filterOption;
            filterOption.html(
                $(elem).closest(containerDispo).find(':selected').html().replace('----', '<small>') + '</small>'
            );
        }
    }
}

/*
    Selected the right value of price level promotion when we change quantity.
 */
function getPriceLevelSelected(elem, newQuantity)
{
    let options = $(elem).closest(CONTAINER_DISPO).find('.selectPriceLevel option');
    const select = options.length === 0 ? $(elem).closest('div.row').find(SELECT_PRICE_LEVEL) :
        $(elem).closest(CONTAINER_DISPO).find(SELECT_PRICE_LEVEL);
    options = options.length === 0 ?
        $(elem).closest('div.row').find('.selectPriceLevel option') :
        options;

    for (let j = 1; j < options.length ;j++) {
        if (parseInt(newQuantity) >= parseInt(options.get(j).value) &&
            parseInt(newQuantity) < parseInt(options.get(j-1).value)) {
            select.val(options.get(j).value);
            break;
        } else if (parseInt(newQuantity) >= parseInt(options.get(0).value)) {
            select.val(options.get(0).value);
            break;
        }
    }

    if (select.find(':selected').html().includes(AUTOSSIMO_PRICE)) {
        select.find(FILTER_OPTION).addClass(PRICEWEB);
    } else {
        select.find(FILTER_OPTION).removeClass(PRICEWEB);
    }
}

/*
    Checked the right value of price level promotion when we change quantity in product sheet.
 */
function getPriceLevelChecked(newQuantity)
{
    const values = $('.ps-price-block .price-level-bloc input[name=priceLevel]');
    const blockInput = '.ps-price-block .price-level-bloc input[name=priceLevel][value=';
    for (let k = 1; k < values.length ;k++) {
        if (parseInt(newQuantity) >= parseInt(values.get(k).value) &&
            parseInt(newQuantity) < parseInt(values.get(k-1).value)) {
            $(blockInput.concat(values.get(k).value+']')).attr('checked','checked');
            $(blockInput.concat(values.get(k).value)+'] + label.price-level-label').click();
            break;
        } else if (parseInt(newQuantity) >= parseInt((values.get(0).value))) {
            $(blockInput.concat(values.get(0).value+']')).attr('checked','checked');
            $(blockInput.concat(values.get(0).value)+'] + label.price-level-label').click();
            break;
        }
    }
}
