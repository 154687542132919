import StartOrderForFolderNormalizer from "./StartOrderForFolderNormalizer";
import StartOrderNormalizer from "./StartOrderNormalizer";
import AbstractNormalizersResolver from "../../core/AbstractNormalizersResolver";
export default class StartOrderNormalizersResolver extends AbstractNormalizersResolver {
    register() {
        return [
            new StartOrderForFolderNormalizer(),
            new StartOrderNormalizer(),
        ];
    }
}
