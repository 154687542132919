/*** Fonction de chargement des images en lazy-loading
 * @Param : (optional) jQuery object containing img to lazy-load
 ***/
import {initOwlCarousel} from "./carousel";

const DATA_CAROUSEL = 'data-carousel';

export function initImgWithLazyLoading(element, imageLoaded) {
    var elementsToLoad = !element ? $('img[data-src].lazy-loading') : element.find('img[data-src].lazy-loading');
    var loaded = imageLoaded === undefined ? false : true;
    elementsToLoad.each(loadImage);
    if(loaded === true) {
        element.each(function(){
            $(this).addClass('imageLoaded');
        })
    }
}

export function productImgLazyLoading(elements) {
    elements.forEach(element => {
        var elementsToLoad = element.find('img[data-src].lazy-loading');
        elementsToLoad.each(loadImage);
        element.addClass('imageLoaded');
    });
}

function loadImage(i, element) {
    $(element).attr('src', $(element).attr('data-src'));
    element.onload = function() {
        $(element).removeAttr('data-src');
    };
}

$(document).on('click', '.zoom-button-piece:not(.affected-image-failed)', function () {
    var thumbnails = $(this).parent();
    thumbnails.children('.zoom-img-content').html('');
    $.each(thumbnails.attr(DATA_CAROUSEL).split(';'), function(index, link) {
        if (link !== '') {
            var closeLink = '<a href="javascript:void(0)"> <span class="close-zoom-img">X</span>';
            var zoomLink = '<img class="zoom-imgs hidden" src="'+link;
            zoomLink = zoomLink.concat('"/></a>');
            thumbnails.children('.zoom-img-content').append(closeLink + zoomLink);
        }
    });
});

/*** Fonction d'initialisation du thumbnail sur un produit
 * et du handler pour le l'init des img et du carousel d'une fiche produit
 ***/
export function initThumbnailsAndFPHandlerResults(idPopin) {
    var myIdPopin = !idPopin ? '#popinFicheProduit' : idPopin;
    /* On lance la fonction pour le chargement des vignettes en lazy-loading */

    if($('.products-consumables').length) {
        var node = $(".consumable:not(.hidden):not(.imageLoaded)");
        initImgWithLazyLoading(node, true);
    } else {
        initImgWithLazyLoading();
    }

    if (!idPopin && $('.folder-glass-makers').length === 0) {
        initOwlCarousel();
    }

    /* Initialisation du handler d'init du carousel pour la fiche produit */
    $(document).on('show.bs.modal', myIdPopin, function (e) {
        //Si pas d'image, on initialise l'image par défaut
        if($(myIdPopin).find('.lazy-loading').length !== 0){
            initImgWithLazyLoading();
        }
        //Et dans tous les cas on initialise le carousel (uniquement de la vignette)
        initOwlCarousel($(myIdPopin).find('.productDetail').first());
    });
}

/*** Fonction d'initialisation du handler
 * pour le l'init des img et du carousel d'une fiche produit
 ***/
export function initFPHandlerResults(idPopin) {
    var myIdPopin = !idPopin ? '#popinFicheProduit' : idPopin;
    /* Initialisation du handler d'init du carousel pour la fiche produit */
    $(document).on('show.bs.modal', myIdPopin, function () {
        //Si pas d'image, on initialise l'image par défaut
        if($(myIdPopin).find('.lazy-loading').length !== 0){
            initImgWithLazyLoading();
        }
        //Et dans tous les cas on initialise le carousel (uniquement de la vignette)
        initOwlCarousel($(myIdPopin).find('.productDetail').first());
    });
}

