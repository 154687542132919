import { AMPLITUDE_CLASS } from "../../constants";
import { ProductFactory } from "../dto/product/ProductFactory";
import CompareQuotationEvent from "./CompareQuotationEvent";
export default class CompareQuotationNormalizer {
    normalize(elements, origin) {
        const compareQuotationEvent = new CompareQuotationEvent();
        const amplitudeDivs = $(AMPLITUDE_CLASS + '[data-catalog="pneus"]');
        if (0 === amplitudeDivs.length) {
            return compareQuotationEvent;
        }
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            compareQuotationEvent.productCollection.addProduct(product);
        });
        return compareQuotationEvent;
    }
    support(element, origin) {
        return true;
    }
}
