import {updateBlocAvailabilityPrice} from "../pubtech-dispo-price";
const hasAngAccess = '.has-ang-access';
function updateBlocAvailabilityPriceFolder(performLog, referenceOe) {
    if ($('#folder-result').length === 0 && $('.origin-datas .containerDispo').length > 0) {
        updateBlocAvailabilityPrice(performLog, referenceOe);
    }
}

export function loadAvailabilityPriceFolder(referencesOe) {
    referencesOe.each(function(index , value) {
        let containOrderedReferenceIAM = false;
        if ($(hasAngAccess).length === 0 ||
            ($(hasAngAccess).length && $(value).attr('data-status') !== 'ORDERED')) {
            // Check if reference OE contains ordered reference IAM => then not call the availability price
            $.each($(`#table-content-${$(value).attr('data-reference-oe')}, #table-content`).find('tr'), function () {
                if ($(this).attr('data-status') === 'ORDERED' && $(hasAngAccess).length > 0) {
                    containOrderedReferenceIAM = true;
                }
            });
            if (containOrderedReferenceIAM === false) {
                updateBlocAvailabilityPriceFolder(false, $(value));
            }
        }
    })
}

export function togglePrice(priceMode) {
    $('.purchase-price-ht').toggleClass('hidden', !priceMode);
    $('.total-purchase-price-ht').toggleClass('hidden', !priceMode);
    $('.total-complet').toggleClass('hidden', !priceMode);
    $('.sale-price-ttc').toggleClass('hidden', priceMode);
    $('.total-sale-price-ttc').toggleClass('hidden', priceMode);
    $('.total-client').toggleClass('hidden', priceMode);
}
