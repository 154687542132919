import ResultsNotFoundNormalizer from "./ResultsNotFoundNormalizer";
import PaintResultsNotFoundNormalizer from "./PaintResultsNotFoundNormalizer";
import TireResultsNotFoundNormalizer from "./TireResultsNotFoundNormalizer";
import ConsumableResultsNotFoundNormalizer from "./ConsumableResultsNotFoundNormalizer";
import AbstractNormalizersResolver from "../../core/AbstractNormalizersResolver";
import ChaineResultsNotFoundNormalizer from "./ChaineResultsNotFoundNormalizer";
export default class ResultsNotFoundNormalizersResolver extends AbstractNormalizersResolver {
    register() {
        return [
            new ResultsNotFoundNormalizer(),
            new PaintResultsNotFoundNormalizer(),
            new TireResultsNotFoundNormalizer(),
            new ConsumableResultsNotFoundNormalizer(),
            new ChaineResultsNotFoundNormalizer(),
        ];
    }
}
