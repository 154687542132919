import StartSearchNormalizer from "./StartSearchNormalizer";
import PaintStartSearchNormalizer from "./PaintStartSearchNormalizer";
import PaintStartSearchNormalizerByEssentials from "./PaintStartSearchNormalizerByEssentials";
import TireStartSearchNormalizer from "./TireStartSearchNormalizer";
import ConsumablesStartSearchNormalizer from "./ConsumablesStartSearchNormalizer";
import AbstractNormalizersResolver from "../../core/AbstractNormalizersResolver";
import StartSearchByModelNormalizer from "./StartSearchByModelNormalizer";
import ChaineStartSearchNormalizer from "./ChaineStartSearchNormalizer";
export default class StartSearchNormalizersResolver extends AbstractNormalizersResolver {
    register() {
        return [
            new StartSearchNormalizer(),
            new StartSearchByModelNormalizer(),
            new PaintStartSearchNormalizer(),
            new PaintStartSearchNormalizerByEssentials(),
            new TireStartSearchNormalizer(),
            new ConsumablesStartSearchNormalizer(),
            new ChaineStartSearchNormalizer(),
        ];
    }
}
