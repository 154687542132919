import { VehicleFactory } from "../dto/vehicle/VehicleFactory";
import ViewVehicleResultsEvent from "./ViewVehicleResultsEvent";
export default class ByModelNormalizer {
    normalize(blockDataLayer, context) {
        const vehicles = [];
        const elements = blockDataLayer;
        if (0 === elements.length) {
            return null;
        }
        elements.each(function (index, value) {
            vehicles.push(VehicleFactory.createFromDataModel($(value).find('.name a')));
        });
        const viewVehicleResultsEvent = new ViewVehicleResultsEvent();
        viewVehicleResultsEvent.catalog = 'pieces';
        viewVehicleResultsEvent.searchType = 'recherche par modele';
        viewVehicleResultsEvent.resultsMultipleVehicles = elements.length > 1;
        viewVehicleResultsEvent.immatInput = false;
        viewVehicleResultsEvent.searchTerms = null;
        viewVehicleResultsEvent.vehicles = vehicles;
        return viewVehicleResultsEvent;
    }
    support(blockDataLayer, context) {
        return 'searchModel' === context;
    }
}
