import ApplyFiltersNormalizer from "./ApplyFiltersNormalizer";
import AbstractNormalizersResolver from "../../core/AbstractNormalizersResolver";
import TiresApplyFiltersNormalizer from "./TiresApplyFiltersNormalizer";
export default class ApplyFiltersNormalizersResolver extends AbstractNormalizersResolver {
    register() {
        return [
            new ApplyFiltersNormalizer(),
            new TiresApplyFiltersNormalizer(),
        ];
    }
}
