import { ProductAttributes } from "./analytics/amplitude/dto/product/ProductAttributes";
import GaEventHandler from "./analytics/ga/GaEventHandler";
import {removeRefNonFavFromPaintSlider, refreshFavoriteSlide} from "./pubtech-peintures";

const C_INPUT_ERROR_TEXT = '.input-error-text'
const FAV_BUTTON = '#favButtonFp';
const POPIN_FAV_SUPP_LIST = '#popin-fav-supp-list';
const CHECKED_ITEM = '.item-product:checked';
const ERROR_MSG = 'Une erreur est survenue lors de l\'ajout à la liste de favoris.';
const STAR_FAV_ENABLED= 'star-favorites-enabled';
const POPIN_RENAME_LIST = '#popin-rename-list';

$(document).ready(function () {
    $('.btn-rename-list').on("click", function () {
        $('.input-error, .input-error-text').addClass('hide');
        var oldListname = $('#select-input-selected').text().trim();
        $('.create-new-list input[name=listname]').val(oldListname);
        $(POPIN_RENAME_LIST).modal('show');
        return false;
    });

    $('.popin_close').on("click", function () {
        $(POPIN_RENAME_LIST).modal('hide');
    });

    $('.listname_change').on("click", function () {
        var newName = $('.create-new-list input[name=listname]').val();
        const codList = $('#selected-list-id').val();
        $.ajax({
            url: Routing.generate('autossimo_peintures_rename_favorite_list'),
            type: 'POST',
            data: {
                codList: codList,
                name: newName
            },
            success: function (data) {
                if (data.exist === 1) {
                    $('.input-error, .input-error-text').removeClass('hide');
                } else {
                    $('#select-input-selected').text(newName);
                    $('#list-'+codList).text(newName);
                    $(POPIN_RENAME_LIST).modal('hide');
                }
            },
            error : function (error) {
                if (403 === error.status) {
                    $(POPIN_RENAME_LIST).modal('toggle')
                    alertForbidden();
                }
            }
        });
    });

    // Favoris selector toggle modals
    $(document).on('click', '.toggle-favorite-action', function (event) {
        event.preventDefault();
        if ($(this).hasClass(STAR_FAV_ENABLED)) {
            delFavoris(this, 'popin-fav-supp')
        } else {
            addFavoris(this, 'popin-fav-add');
            GaEventHandler.addToWishList($(this), 'favorite');
        }
    });
    $(document).on('click', FAV_BUTTON, function (event) {
        event.preventDefault();
        const row = '#favButton'+$(this).attr('data-favid');
        if ($(this).hasClass(STAR_FAV_ENABLED)) {
            delFavoris(this, 'popin-fav-supp-fp');
            $(row).find('i').removeClass("hide");
        } else {
            addFavoris(row, 'popin-fav-add-fp', this);
            GaEventHandler.addToWishList($(this), 'favButton');
        }
    });

    // To change front from list creation/add to add/creation in addFavs popins

    $(document).on('click', '.change-add-create1', function (event) {
        event.preventDefault();
        changeAddOrCreate(1);
    });
    $(document).on('click', '.change-add-create2', function (event) {
        event.preventDefault();
        changeAddOrCreate(2);
    });

    // button hide reference not found
    $(document).on('click', ".hide-not-found", function (event) {
        event.preventDefault();
        hideNotFound();
        var popinTableBody = $("#table-body-popin-add-fav-to-list");
        if(popinTableBody[0].childElementCount > 0){
            enableValidateBtn();
        }
    });

    $(document).on('click', '#suppress-from-list', function (event) {
        if($(CHECKED_ITEM).length > 0) {
            $('#delete_favorites_empty_msg').addClass('hidden')
            $('#delete_favorites_confirmed').removeClass('hidden');
            $('#do-del-fav-from-list').removeClass('hidden');
        } else {
            $('#delete_favorites_confirmed').addClass('hidden')
            $('#delete_favorites_empty_msg').removeClass('hidden');
            $('#do-del-fav-from-list').addClass('hidden');
        }
        $(POPIN_FAV_SUPP_LIST).modal("show");
    });

    $(document).on('click', '#do-del-fav-from-list', function() {
        GaEventHandler.removeFromWishList($(this), 'del-fav-from-list');
        doDelFavsFromList();
    });
    $(document).on('click', '#close-error-fav', function() {
        $(this).parents('.modal').modal('hide')
    });

    $(document).on('click', '.do-del-fav', function (event) {
        event.preventDefault();
        const idFavOnPage = this.getAttribute("data-idfavonpage");
        var data = {
            'codfou': this.getAttribute("data-codfou"),
            'reffab': this.getAttribute("data-reffab"),
            'codarbo': this.getAttribute("data-codarbo"),
            'idfavonpage' : idFavOnPage,
            'favslide' : this.getAttribute("data-favslide"),
            'recurrentslide' : this.getAttribute("data-recurrentslide"),
        };
        GaEventHandler.removeFromWishList($(this), 'do-del-fav');
        doDelFavoris(
            data,
            this.getAttribute("data-idButton"),
            this.getAttribute("data-idPopin")
        );
    });

    // To disable event on clic ( replace the inline javascript:void(0) )
    $(document).on('click', '.void-clic-event', function(event){
        event.preventDefault();
        return false;
    });

    $(document).on('click', '.close-this-modal', function(event){
        $(this).closest('.modal').modal('hide');
    });

    $(document).on('submit', 'form[name="favorites"]', function (event) {
        event.preventDefault();
        addRefsToFavList($(this).serializeArray());
    });
});

// pour l'ajout a une liste de favoris
// cacher le bloc de ref non trouvé dans la popin
function hideNotFound()
{
    $('#ref-not-found').addClass('hide');
    $('#msgNotFound').removeClass('hide');
}

// pour rendre le btn 'valider' actif
// si les ref inconnues ont été supprimées
function enableValidateBtn ()
{
    $('#btn-validate-popin-add-to-list')
        .removeClass('btn-disable void-clic-event');
}

// pour l'ajout a une liste de favoris
// gere le changement de l'ajout a une liste exitante/créé
function changeAddOrCreate($param)
{
    if ($param === 1)
    {
        $('#add-to-list').addClass('hide');
        $('#create-new-list').removeClass('hide');
        $('#action').val("create");
        $('#favorites_listName').removeAttr('disabled')
        $('#favorites_listID').attr('disabled', 'disabled');

        cleanFormErrors();
    } else if ($param === 2)
    {
        $('#create-new-list').addClass('hide');
        $('#add-to-list').removeClass('hide');
        $('#action').val("add");
        $('#favorites_listID').removeAttr('disabled')
        $('#favorites_listName').attr('disabled', 'disabled');

        cleanFormErrors();
    }
}

function cleanFormErrors() {
    $(C_INPUT_ERROR_TEXT).text();
    $(C_INPUT_ERROR_TEXT).addClass('hide');
    $(C_INPUT_ERROR_TEXT).parent().find('input').removeClass('error');
    $('.input-error').addClass('hide');
}

// pour l'ajout a une liste de favoris
// envoi les data pour ajouter les refs a la liste de favoris selectionné
function addRefsToFavList(data)
{
    const url = Routing.generate('autossimo_peintures_popins_do_add_favoris_to_list');
    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: data,
        beforeSend: function () {
            $('form[name="favorites"] input').removeClass('error');
            $('form[name="favorites"]').find('.input-error-text, .input-error').addClass('hide');
        },
        success: function (response, statut) {
            if (response.status === 1) {
                $('#popin-fav-add, #popin-fav-add-fp').modal("hide");
            } else if (response.status === 0) {
                $(C_INPUT_ERROR_TEXT).text(response.msg);
                $(C_INPUT_ERROR_TEXT).removeClass('hide');
                if (response.msg.search('token') === -1) {
                    $('.input-error').removeClass('hide');
                    $(C_INPUT_ERROR_TEXT).parent().find('input').addClass('error');
                }
            } else {
                alert(ERROR_MSG);
            }
        },
        error: function (error) {
            if (403 === error.status) {
                alertForbidden();
            } else {
                alert(ERROR_MSG);
            }
        }
    });
}

function addFavoris(rowDom, idPopin, ps) {
    var idButton = $(rowDom).attr('id');
    const isRecurrent = $(rowDom).hasClass('recurrent-paints');
    rowDom = $(rowDom).closest(".data-row");
    var product = {
        'codfou': $(rowDom).attr("data-codfou"),
        'libfou': $(rowDom).attr("data-libfou"),
        'reffab': $(rowDom).attr("data-reffab"),
        'codarbo': $(rowDom).attr("data-codarbo"),
        'designation': $(rowDom).attr("data-title"),
        'refcom': $(rowDom).attr("data-refcom"),
        'codref': $(rowDom).attr("data-codref"),
        'codfam': $(rowDom).attr("data-codfam"),
        'codsfam': $(rowDom).attr("data-codsfam"),
        'technicalsheet': '',
        'securitysheet': '',
        'description' : $(rowDom).attr("data-description"),
        'status': '',
        'images' : $(rowDom).find('#consumable-images-list').val(),
        'favid' : $(rowDom).attr("data-favid")
    };
    var url = Routing.generate('autossimo_peintures_popins_add_favoris');
    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'html',
        data: {product : product},
        success: function (data, statut) {
            $("#" + idPopin).html(data);
            $("#" + idPopin).parent().show();
            $("#" + idPopin).modal({
                backdrop: true
            });

            if (typeof ps !== 'undefined') {
                    $(ps).addClass('star-favorites-enabled');
            }
            $("#" + idButton).addClass(STAR_FAV_ENABLED);
            ProductAttributes.fromFavorites(rowDom,true);
            $("#".concat(idButton).concat(' i')).addClass("hide");
            $("#favButtonFp i").addClass("hide");

            if (isRecurrent) {
                refreshFavoriteSlide();
            }
        },
        error: function () {
            alert(ERROR_MSG);
        }
    });
}

export function delFavoris(rowDom, idPopin) {
    var idButton = $(rowDom).attr('id');
    const isRecurrent = $(rowDom).hasClass('recurrent-paints');
    rowDom = $(rowDom).closest(".data-row");
    var elem = $('#'+idPopin).find('.do-del-fav');
    const manufacturerId = $(rowDom).attr("data-codfou");
    const referenceEquipment = $(rowDom).attr("data-reffab");
    var idfavonpage = manufacturerId + referenceEquipment;
    idfavonpage = '#favButton' + idfavonpage.replace(/[^a-zA-Z0-9]/g, '');
    elem.attr('data-codfou', manufacturerId);
    elem.attr('data-reffab', referenceEquipment);
    elem.attr('data-codarbo', $(rowDom).attr("data-codarbo"));
    elem.attr('data-idButton', idButton);
    elem.attr('data-idPopin', idPopin);
    elem.attr('data-idfavonpage', idfavonpage);
    elem.attr('data-favslide', $(rowDom).parents('.block-slider.favorites').length > 0);
    elem.attr('data-recurrentslide', isRecurrent);
    $('#'+idPopin).modal("show");
}

function doDelFavoris(data, idButton, idPopin) {
    var params = data;
    var url = Routing.generate('autossimo_peintures_popins_do_del_favoris');
    const isSliderPaintFav = data.favslide === 'true';
    const isSliderPaintRecurrent = data.recurrentslide === 'true';
    delete data.favslide;
    delete data.recurrentslide;
    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: data,
        success: function () {
            const isFavoritesPage = $('#favoris-ref-results').length > 0;
            const displaySuggestion = $('#display_suggestion');
            const hideSuggestDistrib = displaySuggestion.length > 0 ? displaySuggestion.prop('checked') : true;
            const isSuggestDistrib = $('#' + idButton).closest('.consumable-inner').hasClass('suggestions-bar');
            const idfavonpage = params.idfavonpage;
            const productSheetElem = idPopin.substr(idPopin.length - 2) === 'fp' ? idfavonpage : "#" + idButton;

            $('#'.concat(idButton).concat(' > a.premier')).addClass('hide');
            $('#'.concat(idButton).concat(' > a.deuxieme')).removeClass('hide');
            $("#" + idButton).removeClass(STAR_FAV_ENABLED);
            $("#".concat(idButton).concat(' i')).removeClass("hide");
            $(idfavonpage).removeClass(STAR_FAV_ENABLED);
            ProductAttributes.fromFavorites($("#" + idButton),false);
            if (isFavoritesPage) {
                if (hideSuggestDistrib || !isSuggestDistrib) {
                    removeRefNonFavFromList(productSheetElem);
                }
            } else if (isSliderPaintFav) {
                removeRefNonFavFromPaintSlider(productSheetElem);
            } else if (isSliderPaintRecurrent) {
                refreshFavoriteSlide();
            }
            $('#' + idPopin).modal("hide");
        },
        error: function (resultat, statut, erreur) {
            alert(erreur);
        }
    });
}

function doDelFavsFromList() {
    var favsToDel = [];
    $(CHECKED_ITEM).each(function(index, value) {
        favsToDel.push({
            providerCode: $(value).attr('data-codfou'),
            treeCode: $(value).attr('data-codarbo'),
            manufacturerCode: $(value).attr('data-ref'),
        });
    });
    $.ajax({
        type: 'POST',
        dataType:'JSON',
        url: Routing.generate('autossimo_delete_multiple_from_list_favorites'),
        data: {
            idList: $('#selected-list-id').val(),
            products: JSON.stringify(favsToDel)
        },
        async: false,
        success: function (data) {
            $.each(data, function(index, value) {
                var id = (value.providerCode + value.manufacturerCode).replace(/[^a-zA-Z0-9]/g, '');
                removeRefNonFavFromList('#favButton' + id)
            });
            $(POPIN_FAV_SUPP_LIST).modal("hide");

        }, error : function(error){
            if (403 === error.status) {
                $(POPIN_FAV_SUPP_LIST).modal('toggle');
                alertForbidden();
            } else {
                $('#deletion-error').modal("show");
            }
        }
    });
}

function removeRefNonFavFromList(idFavOnPage) {
    var rowDom = $(idFavOnPage);
    while (!rowDom.hasClass('data-row')) {
        rowDom = rowDom.parent();
    }
    rowDom.remove();
    var nb = $('.nb_refs').html();
    $('.nb_refs').html(nb - 1);

    if (($('.data-row').length === 0 && $(FAV_BUTTON).length === 0) ||
        ($('.data-row').length === 1 && $(FAV_BUTTON).length === 1)
    ) {
        if (rowDom.attr('data-codarbo').lastIndexOf('2', 0) === 0) {
            window.location.href = Routing.generate('autossimo_peintures');
        } else {
            window.location.href = Routing.generate('autossimo_consumables');
        }
    }

    ProductAttributes.fromFavorites($(idFavOnPage),false);
}
function formatParamsForFavoritesRequest(reference) {
    var codref = reference.attr('data-codref');
    var codarbo = reference.attr('data-codarbo');
    var reffab = reference.attr('data-reffab');
    var codfam = reference.attr('data-codfam');
    var codsfam = reference.attr('data-codsfam');
    var codfou = reference.attr('data-manufacturer');

    return {
        "CODREF": codref,
        "CODARBO": codarbo,
        "REFFAB": reffab,
        "CODFOU": codfou,
        "REF": reffab,
        "CODFAM": codfam,
        "CODSFAM": codsfam,
    };
}
/**
 * get getFavorites
 */
export function getFavorites() {
    var itemsNotFavoritesLoadedSelector = $('.consumable:not(.hidden):not(.priceLoaded)');
    if ($('.search-by-filter').length > 0) {
        itemsNotFavoritesLoadedSelector = $('.consumable:not(.hidden,.search-by-filter)');
    }
    var itemsNotFavoritesLoadedCount = parseInt($(itemsNotFavoritesLoadedSelector).length);

    var  reffabData = [];

    if (itemsNotFavoritesLoadedCount > 0) {

        $(itemsNotFavoritesLoadedSelector).each(function (index, value) {
            reffabData.push(formatParamsForFavoritesRequest($(value)));
        });

    }
    $.ajax({
        type: 'POST',
        dataType:'JSON',
        url: Routing.generate('autossimo_consumables_get_favorites'),
        data: {
            references: JSON.stringify(reffabData)
        },
        async: false,
        success: function (data) {
            $.each(data, function (index, value) {
                var id = 'favButton'.concat(value.manufacturerId).concat(value.referenceEquipment);
                id = id.replace(/[^a-zA-Z0-9]/g, '');
                $('#'+id).addClass(STAR_FAV_ENABLED);
                $('#'.concat(id).concat(' i')).addClass('hide');
                ProductAttributes.fromFavorites($('#'+id),true);
            })
        }
    });
}
