import {initTooltip} from './pubtech-main-v2';
import {
    addRefManuelle,
    checkElemNonFact,
    hideRefMan,
    recalculateTotals
} from './pubtech-devis-step4';



$(document).on('click', 'div#bill-euro', function () {
    if ($(this).parent().parent().hasClass('delete-line') == false && $(this).parent().parent().hasClass('free') == false) {
        var id = $(this).attr('data-id');
        $('#bill-line-' + id).find('.dispo-details').toggleClass('delete-line');
        $('#bill-line-' + id).find('.info-oil').toggleClass('delete-line');
        $('#bill-line-' + id).find('.DEprixVenteEF').toggleClass('delete-line');
        $('#bill-line-' + id).find('.modal-equipref').toggleClass('delete-line');
        $('#bill-line-' + id).find('.modal_dErefOrigine').toggleClass('delete-line');
        $('#bill-line-' + id).find('.quant-cart').toggleClass('delete-line');
        $('#bill-line-' + id).find('.trush').toggleClass('unbilled');
        $('#bill-line-' + id).addClass('free');
        $('#bill-line-' + id).find("input:not(.enable-jquery-datepicker), select").toggleDisabled();
        var name = $('#bill-name-' + id).html();
        var operation = ($('#operations-' + id).length > 0) ? $('#operations-' + id).html() : '';
        var noBillLine = '<tr id="no-bill-line-' + id + '"><td class="no-bill-name">' + name + '</td>';
        noBillLine += '<td id="no-bill-operations-' + id + '" class="no-bill-operation">' + operation + '</td>';
        noBillLine += '<td class="no-bill-actions"><div data-id="' + id + '" class="euro inline-block" id="no-bill-currency" data-toggle="tooltip" title="Facturer"></div><div class="trush inline-block" id="no-bill-trash-' + id + '" data-toggle="tooltip" title="Supprimer"></div></td></tr>';
        if ($('#no-bill-line-' + id).length == 0) {
            $('#no-bill-table').append(noBillLine);
        }
        $(this).closest('tr').find('input.DEprixVenteEF').css('border-color', '#e6e6e6');
        $(this).closest('tr').find('.mySelect').css('border-color', '#e6e6e6');
        recalculateTotals();
        initTooltip();
        checkElemNonFact();
        initNoBillTrashBtn($(this).closest('td').find('.trush').attr('data-id'));
    }
});



initNoBillTrashBtn();

function initNoBillTrashBtn(elem) {
    var selector = (typeof elem != 'undefined') ? '#no-bill-trash-' + elem : 'div[id^=no-bill-trash-]';
    $(selector).on('click', function () {
        $(this).closest('tr').toggleClass('deleted');
        var label = $(this).closest('tr').hasClass('deleted') ? 'Restaurer' : 'Supprimer';
        $(this).attr('title', label);
        $(this).attr('data-original-title', label);
        var id = $(this).parent().find('.euro').attr('data-id');
        $('#bill-line-'+ id).toggleClass('delete-line');
    });
}

$(document).on('click', 'div#no-bill-currency', function () {
    var id = $(this).attr('data-id');
    $('#no-bill-line-' + id).remove();
    $('#bill-line-' + id).removeClass('free');
    $('#bill-line-' + id).find("input:not(.enable-jquery-datepicker), select").removeAttr('disabled');
    $('#bill-line-' + id).find('.dispo-details').removeClass('delete-line');
    $('#bill-line-' + id).find('.info-oil').removeClass('delete-line');
    $('#bill-line-' + id).find('.DEprixVenteEF').removeClass('delete-line');
    $('#bill-line-' + id).find('.modal-equipref').removeClass('delete-line');
    $('#bill-line-' + id).find('.modal_dErefOrigine').removeClass('delete-line');
    $('#bill-line-' + id).find('.quant-cart').removeClass('delete-line');
    $('#bill-line-' + id).find('.trush').removeClass('unbilled');
    checkElemNonFact();
    recalculateTotals();
});
// à mettre dans fichier apart
// Soumission du formulaire d'ajout de ref Origine
// Soumission du formulaire d'ajout de ref Origine
export function validateManualReference(element) {
    var line = element.closest('.step5.name').parent();
    var fomData = element.parent().parent();

    $('.step5.name').removeClass('modal-content');

    var dataId = $(line).attr("data-id");

    var pieceMarge = parseFloat($('#piece-marge').val()) / 100;
    var selectedRefMan = $(fomData).find('.refManDispo-ref option:selected');

    if ($(selectedRefMan).attr('id') == '') {
        var prixAchat = parseFloat($(fomData).find('.prixht-ref').val().replace(',', '.').trim());
    } else {
        var prixAchat = parseFloat($(selectedRefMan).attr('code-price_ht'));
    }

    var capacity = 1;

    if ($(line).attr('capacity') > 0) {
        capacity = $(line).attr('capacity');
        capacity = parseFloat(capacity.replace(',', '.'));
    }

    var prixAchatTotal = prixAchat * capacity;
    // ticket 1993 calcul appoint
    var appointData = $('#appoint-' + dataId).val();
    if (appointData.length > 0 && appointData.indexOf('app') >= 0) {
        prixAchatTotal = prixAchatTotal * (parseFloat($('#appoint').val()) / 100);
    }

    if (prixAchatTotal > 0) {
        var prixVente = (prixAchatTotal * pieceMarge) + prixAchatTotal;
        $('#quantity-' + dataId + ' .quant-cart').css('display', 'block');
        $('#selling-' + dataId + ' .calculate2').val(prixVente.toFixed(2).replace('.', ','));
        $('#selling-' + dataId + ' .calculate2').attr('data-value', prixVente);
        $('#dispo-' + dataId).html('');
        if (appointData.length > 0 && appointData.indexOf('app') >= 0) {
            $('#purchase-unity-' + dataId).val(prixAchatTotal);
        } else {
            $('#purchase-unity-' + dataId).val(prixAchat);
        }
        $('#purchase-unity-' + dataId).attr('data-ref-manuelle', 'oui');
        addRefManuelle(line, fomData);

        $(line).find('td.prixAchat').attr('data-purchase-refman', prixAchat);
        $(line).find('.prixAchat').html('<span>' + parseFloat(prixAchatTotal).toFixed(2).replace('.', ',') + ' € </span>');
        $('.DErefMan .close').click();
        hideRefMan();

        $('.DErefMan').hide();
        $('.erreurRefMan').addClass('hidden');

        $('.modal-backdrop').hide();
        $('.overlay').hide();
    } else {
        $('.erreurRefMan').removeClass('hidden');
    }

}
