import { AMPLITUDE_CLASS, ATTR_AVAILABILITY, ATTR_PURCHASE_PRICE, ATTR_QUANTITY, ATTR_SALE_PRICE, DATA_AVAILABILITY, DATA_PURCHASE_PRICE, DATA_QUANTITY, DATA_SALE_PRICE } from "../../../constants";
/**
 * update attributes after ajax response
 */
export class ProductAttributes {
    static fromPrices(item, values) {
        const salePrice = values.sale_price !== undefined ? values.sale_price.replace(',', '.') : "--.--";
        const purchasePrice = values.purchase_price !== undefined ? values.purchase_price.replace(',', '.') : "--.--";
        const availability = values.availability !== undefined ? values.availability : "--";
        const quantity = values.quantity;
        const codref = $(item).data('codref');
        const amplitudeDiv = $(item).find('#amplitude-' + codref);
        $(amplitudeDiv).attr(ATTR_SALE_PRICE, salePrice).data(DATA_SALE_PRICE, salePrice);
        $(amplitudeDiv).attr(ATTR_PURCHASE_PRICE, purchasePrice).data(DATA_PURCHASE_PRICE, purchasePrice);
        $(amplitudeDiv).attr(ATTR_QUANTITY, quantity).data(DATA_QUANTITY, quantity);
        $(amplitudeDiv).attr(ATTR_AVAILABILITY, availability).data(DATA_AVAILABILITY, availability);
    }
    static fromCheckDispo(parent, result) {
        const delaiSimplifie = result.DELAI_SIMPLIFIE !== undefined ? result.DELAI_SIMPLIFIE : "--";
        const parentDiv = $(parent).closest(':has(.amplitude)');
        const amplitudeDiv = $(parentDiv).find(AMPLITUDE_CLASS);
        $(amplitudeDiv).attr(ATTR_AVAILABILITY, delaiSimplifie).data(DATA_AVAILABILITY, delaiSimplifie);
        const quantity = $(parent).val().toString();
        $(amplitudeDiv).attr('data-quantity', quantity).data('quantity', quantity);
    }
    static fromFavorites(element, isFavorite) {
        const parentDiv = $(element).closest(':has(.amplitude)');
        const amplitudeDiv = $(parentDiv).find(AMPLITUDE_CLASS);
        const amplitudeId = $(amplitudeDiv).attr('id');
        let selector = "";
        selector = selector.concat("[id=", amplitudeId, "]");
        $(selector).each(function (index, value) {
            $(value).attr('data-is-favorite', isFavorite).data('is-favorite', isFavorite);
        });
    }
    static fromLoadPrice(item, value) {
        const salePrice = value.PVHT !== undefined ? value.PVHT.replace(',', '.') : "--.--";
        const purchasePrice = value.PAHT !== undefined ? value.PAHT.replace(',', '.') : "--.--";
        const availability = value.DELAI_SIMPLIFIE !== undefined ? value.DELAI_SIMPLIFIE : "--";
        const quantity = value.QUANTITE;
        const parentDiv = $(item).closest(':has(.amplitude)');
        const amplitudeDiv = $(parentDiv).find(AMPLITUDE_CLASS);
        $(amplitudeDiv).attr(ATTR_SALE_PRICE, salePrice).data(DATA_SALE_PRICE, salePrice);
        $(amplitudeDiv).attr(ATTR_PURCHASE_PRICE, purchasePrice).data(DATA_PURCHASE_PRICE, purchasePrice);
        $(amplitudeDiv).attr(ATTR_QUANTITY, quantity).data(DATA_QUANTITY, quantity);
        $(amplitudeDiv).attr(ATTR_AVAILABILITY, availability).data(DATA_AVAILABILITY, availability);
    }
    static fromQuoteGetPrice(element, result) {
        const amplitudeDiv = $(element).closest('td').find('.amplitude');
        if (1 === $(amplitudeDiv).length) {
            $(amplitudeDiv).attr(ATTR_AVAILABILITY, result.delai_simplifie).data(DATA_AVAILABILITY, result.delai_simplifie);
        }
    }
    static fromLayerGetPrice(amplitudeDiv, result) {
        if (1 === $(amplitudeDiv).length) {
            $(amplitudeDiv).attr(ATTR_AVAILABILITY, result.delai_simplifie).data(DATA_AVAILABILITY, result.delai_simplifie);
        }
    }
    static updateLayerAvailabilityForAmplitude(element, amplitudeDiv) {
        const hasQuotationAccess = $('#quotation_access').val();
        if (hasQuotationAccess == 1) {
            const data = {
                codequ: $(element).attr('data-equip'),
                refeq: $(element).attr('data-refeq'),
                typeligne: $(element).attr('data-typeline'),
                qte: $(amplitudeDiv).attr('data-quantity'),
                codfam: $(amplitudeDiv).attr('data-cod-fam')
            };
            return $.ajax({
                url: Routing.generate('autossimo_quotes_get_availabilty'),
                type: "POST",
                data: data,
                async: false,
                success: function (result) {
                    ProductAttributes.fromLayerGetPrice(amplitudeDiv, result);
                }
            });
        }
    }
}
