export function trimString(str)
{
    return str.replace(/^\s+/g, '').replace(/\s+$/g, '');
}

//Arrondi � X chiffres �pr�s la virgule
export function round(number, X)
{
// rounds number to X decimal places, defaults to 2
    X = (!X ? 2 : X);
    return Math.round(number * Math.pow(10, X)) / Math.pow(10, X);
}
