import { AMPLITUDE_CLASS } from "../../constants";
import RemoveFromCartEvent from "./RemoveFromCartEvent";
import { ProductFactory } from "../dto/product/ProductFactory";
export default class RemoveFromCartNormalizer {
    normalize(elements, origin) {
        const removeFromCartEvent = new RemoveFromCartEvent(origin);
        $(elements).each(function (index, element) {
            const parentDiv = $(element).closest('.lineItem,td');
            if (0 === parentDiv.length) {
                return;
            }
            const amplitudeDiv = $(parentDiv).find(AMPLITUDE_CLASS);
            if (0 === amplitudeDiv.length) {
                return;
            }
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            removeFromCartEvent.productCollection.addProduct(product);
            // ProductAttributes.updateLayerAvailabilityForAmplitude(parentDiv, amplitudeDiv).done(() => {
            //     const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            //     removeFromCartEvent.productCollection.addProduct(product);
            // });
        });
        return removeFromCartEvent;
    }
    support(element, origin) {
        return true;
    }
}
