import { getFamily, getSubFamily, getLibRep } from "../../utilities";
import ApplyFiltersEvent from './ApplyFiltersEvent';
export default class ApplyFiltersNormalizer {
    normalize(element, context) {
        var _a, _b, _c, _d, _e;
        const applyFiltersEvent = new ApplyFiltersEvent();
        applyFiltersEvent.catalog = (_a = context.catalog) !== null && _a !== void 0 ? _a : '';
        applyFiltersEvent.filtersCategories = (_b = context.filterType) !== null && _b !== void 0 ? _b : '';
        applyFiltersEvent.family = (_c = getFamily()) !== null && _c !== void 0 ? _c : '';
        applyFiltersEvent.subfamily = (_d = getSubFamily()) !== null && _d !== void 0 ? _d : '';
        applyFiltersEvent.markerName = (_e = getLibRep()) !== null && _e !== void 0 ? _e : '';
        const selectedEquipementiers = [];
        if ($('#selectEquip').length) {
            $('#selectEquip option:selected').each((i, option) => {
                selectedEquipementiers.push($(option).text());
            });
        }
        else if ($('#equipementiers').length) {
            $('#equipementiers option:selected').each((i, option) => {
                selectedEquipementiers.push($(option).text());
            });
        }
        applyFiltersEvent.manufacturerBrand = selectedEquipementiers.join((', '));
        return applyFiltersEvent;
    }
    support(element, context) {
        return 'pieces' === context.catalog;
    }
}
