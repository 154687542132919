import {fillInPriceDispo} from "./pubtech-devis-step4";

require('bootstrap-select');
const OPTION_SELECTED = 'option:selected';
const MYSELECT_OIL = '#mySelectOil';
const DATA_OIL_TYPE = 'data-oiltype';
const ENGINE_OIL_SELECTOR = 'tr[type-objet="Engine oil"]';
const SELECT_VISCO = 'select.selectVisco';
const ALL_ENGINE_OILS = '#allEngineOils';
const ENGINE_OIL_CODFAM = 'MLI';
const ALL_FILTERS_LANDMARK = '12-10-6';

$(document).ready(function () {
    $(document).on('change', MYSELECT_OIL, function () {
        getOilPrice($(this));
    })
});

export function getPriceOil(id, pvht) {
    const sellPrice = $(`#selling-${id} .DEprixVenteEF`);
    let priceOil = sellPrice.val();
    const refName = $('#bill-name-' + id).find('.ref_name').text().trim();
    const featureFlagOil = $('#oil_price_feature_flag').data('value');
    if (refName === 'Huile moteur' && featureFlagOil === true) {
        priceOil = parseFloat(priceOil) * parseFloat($(`#appoint-${id} + input.capacity_value`).val());
        priceOil = Math.ceil(priceOil);
        priceOil = priceOil.toFixed(2).replace('.', ',');
        sellPrice.val(priceOil);
        sellPrice.attr('data-value', parseFloat(priceOil).toFixed(2).replace(',', '.'));
    } else {
        sellPrice.val(pvht.toFixed(2).replace('.', ','));
        sellPrice.attr('data-value', pvht.toFixed(2).replace(',', '.'));
    }
}

export function getOilPrice(element) {
    const oilType = $("#mySelectOil :selected").attr(DATA_OIL_TYPE);
    const codref = element.val().split(' ');
    $.ajax({
        url: Routing.generate('autossimo_get_oil_price'),
        type: "GET",
        data: {
            codrep: element.attr('data-codrep'),
            codref: codref[1],
            oilType: oilType,
        },
        success: function (data) {
            showPreferred(element);
            if (!data.length) {
                const line = $(MYSELECT_OIL).parent().parent().attr('data-id');
                $(`#selling-${line} .DEprixVenteEF`).val('0,00');
            } else {
                $.each(data, function (index, value) {
                    if (value.oilType === element.find(OPTION_SELECTED).attr(DATA_OIL_TYPE)) {
                        element.closest(ENGINE_OIL_SELECTOR).find('.DEprixVenteEF').val(value.literPrice);
                        let capacity = parseFloat(element.closest(ENGINE_OIL_SELECTOR).find('.capacity_value').val());
                        capacity = Math.ceil(capacity * value.literPrice);
                        element.closest(ENGINE_OIL_SELECTOR).find('.DEprixVenteEF').attr(
                            'data-oldPrice',
                            capacity.toFixed(2)
                        );
                        fillInPriceDispo(element.closest(ENGINE_OIL_SELECTOR).attr('data-id'), element.val());
                    }
                })
            }
        },
    });
}

export function updateOilPrice(element) {
    const featureFlagOil = $('#oil_price_feature_flag').data('value');
    if (featureFlagOil && element.length > 0) {
        const line = $(MYSELECT_OIL).parent().parent().attr('data-id');
        let priceOil = $(`#selling-${line} .DEprixVenteEF`).val();
        const capacity = parseFloat($(`#appoint-${line} + input.capacity_value`).val());
        const oilType = element.find(OPTION_SELECTED).attr(DATA_OIL_TYPE);
        priceOil = priceOil.replace(',', '.');
        let sellPrice = parseFloat(priceOil) / parseFloat(capacity);
        sellPrice = sellPrice.toFixed(3).slice(0, -1)
        const viscosity = element.val().split(' ');

        $.ajax({
            url: Routing.generate('autossimo_update_oil_price'),
            type: "POST",
            dataType: "json",
            data: {
                'dataOil': {
                    oilType: oilType,
                    viscosity: viscosity[1],
                    literPrice: sellPrice,
                }
            },
        });
    }
}

function showPreferred(element) {
    // Store the parent of the current element
    const $parent = element.parent();
    // Clear the strong text
    $parent.find('.oil-preferred').remove();
    $parent.find('.oil-infos').remove();
    // displaying the preferred label from the data-preferred attribute
    $parent.prepend(`<strong class='oil-preferred'>${element.find(OPTION_SELECTED).data('preferred')}</strong>`);
    // displaying the oil type from the data-oiltype
    element.parent().append(`<strong class='oil-infos'>${element.find(OPTION_SELECTED).data('oiltype')}</strong>`);
}

/**
 * Test if equipment Principal match && equipment parent match && viscosity match && && Capacity match with the element.
 */
export function isProductDisplayed(element) {
    const selectedItemsViscosity = $(SELECT_VISCO).val();
    const selectedItemsCapacity = $('select.select-capacity').val();
    const selectedItemsEquipementParent = element.parent().parent().find('select#equipementiers').val();
    const selectedItemsEquipementPrincipal = $('#selectEquip').val();
    let viscoSelected = true, equipementParentSelected = true, equipementPrincipalSelected = true,
        capacitySelected = true;
    const isAllFilters = $('.blocFilters').data('landmark') === ALL_FILTERS_LANDMARK;
    const codFam = $(element).find('td div[id^="quote-data-"]').data('fam');
    if (selectedItemsViscosity != null) {
        viscoSelected = selectedItemsViscosity.some(function (value) {
            if ((true === isAllFilters && codFam === ENGINE_OIL_CODFAM) || false === isAllFilters) {
                return element.attr('data-visco') === value;
            }
            return true;
        });
    }

    if (selectedItemsEquipementParent != null) {
        equipementParentSelected = selectedItemsEquipementParent.some(function (value) {
            return element.attr('data-equip') === value;
        });
    }

    if (selectedItemsCapacity != null) {
        capacitySelected = selectedItemsCapacity.some(function (value) {
            if ((true === isAllFilters && codFam === ENGINE_OIL_CODFAM) || false === isAllFilters) {
                return element.attr('data-capacity').replace(' ', '') === value.replace(' ', '');
            }
            return true;
        });
    }

    if (selectedItemsEquipementPrincipal != null) {
        equipementPrincipalSelected = selectedItemsEquipementPrincipal.some(function (value) {
            return element.attr('data-equip') === value;
        });
    }

    return equipementPrincipalSelected && viscoSelected && equipementParentSelected && capacitySelected;
}

function onChangeSelectFilterOil(selectedCriteria, selectId, isEngineOil = false) {
    const subResultTrs = $('div.sub_result tbody tr');
    selectMultiDisplayedText(selectedCriteria, selectId);
    subResultTrs.each(function () {
        const element = $(this);
        const codFam = $(element).find('td div[id^="quote-data-"]').data('fam');
        if ((codFam === ENGINE_OIL_CODFAM && isEngineOil ) || !isEngineOil) {
            (isProductDisplayed(element) ? element.removeClass('hide') : element.addClass('hide'));
        }
        if ($('div.results-container.noequip').not('.hide').length > 0) {
            $('div.results div.noresults').removeClass('hide').insertAfter($('div.results-container').not('.noequip').last());
        }
    });

    toggleDisplaySubResult(isEngineOil);
}

function selectMultiDisplayedText(selectedCriteria, selectId) {
    if (selectedCriteria != null && selectedCriteria.length > 1) {
        $(`button[data-id="${selectId}"]`).attr('title', selectedCriteria.length + " sélectionnés");
        $(`button[data-id="${selectId}"] > span:first-child`).html(selectedCriteria.length + " sélectionnés");
    }
}

function toggleDisplaySubResult(isEngineOil = false) {
    $('div.results-container').each(function () {
        const isElementDisplayedAndHasntNoequip = !$(this).hasClass('hide') && !$(this).hasClass('noequip');
        const hasntNoEquipementiers = $(this).find('.choix-equipementiers > .noEquipementiers').length === 0;
        const isAllFilters = $('.blocFilters').data('landmark') === ALL_FILTERS_LANDMARK;
        const displayEngineOil =
            isEngineOil && !isAllFilters && isElementDisplayedAndHasntNoequip && hasntNoEquipementiers;
        $(this).find('.sub_result').toggle(
            (isElementDisplayedAndHasntNoequip && hasntNoEquipementiers && !isEngineOil) || displayEngineOil
        );
    });
}

//Filtrer par viscosity
$('.selectVisco').selectpicker({
    showIcon: true,
});

$(document).on('change', SELECT_VISCO , function () {
    onChangeSelectFilterOil($(this).val(), 'selectVisco');
});

//Filtrer par Capacity
$('.select-capacity').selectpicker({
    showIcon: true,
});

$(document).on('change', 'select.select-capacity', function () {
    onChangeSelectFilterOil($(this).val(), 'select-capacity')
});

export function updateSelectViscosity()
{
    const selectVisco = $(SELECT_VISCO);
    const oilEngineClass = $('.infosOilEngineDouble').length > 0 ? '.infosOilEngineDouble' : '.infosOilEngine';
    const oilBloc = $('#shelter22').find('.blocOilEngine');
    const blocViscosity = $(oilBloc).find(oilEngineClass.concat(' tbody span.viscosity'));
    if (selectVisco.length > 0) {
        selectOptionVisco(blocViscosity);
        if ($(ALL_ENGINE_OILS).length > 0) {
            const mid = $(ALL_ENGINE_OILS).data('mid');
            const errorAutodata = $(ALL_ENGINE_OILS).data('error');
            $.ajax({
                url: Routing.generate('autossimo_popin_engine_oils'),
                type: "GET",
                async: false,
                data: {
                    mid : mid
                },
                success: function (data) {
                    if(!errorAutodata) {
                        $('#popinEngineOils .modal-body table tbody').html(data);
                        const blocVisco = $('#listOilEngine').find('tbody span.viscosity');
                        selectOptionVisco(blocVisco);
                    }
                }
            });
        }
        if (null !== selectVisco.val() && selectVisco.val().length !== 0) {
            onChangeSelectFilterOil(selectVisco.val(), 'selectVisco', true);
        }
    }
}

function selectOptionVisco(blocVisco)
{
    $(blocVisco).each(function () {
        let viscosity = $(this).text();
        if (viscosity.trim().length > 0) {
            viscosity = viscosity.replace(/[\r\n\t\--]+/g,'').trim();
            const regex = new RegExp('[0-9]{1,2}W[0-9]{1,2}');
            viscosity = viscosity.match(regex)[0];
            $('select.selectVisco option[value="'.concat(viscosity).concat('"]')).attr('selected','selected');
            $('.selectVisco').selectpicker('refresh');
        }
    });
}
