import { exportVehicles } from "../../utilities";
export default class ViewVehicleResultsEvent {
    constructor() {
        this.catalog = '';
        this.searchType = '';
        this.immatInput = false;
        this.resultsMultipleVehicles = false;
        this.searchTerms = '';
        this.vehicles = [];
    }
    export() {
        return {
            catalog: this.catalog,
            search_type: this.searchType,
            immat_input: this.immatInput,
            results_multiple_vehicles: this.resultsMultipleVehicles,
            search_terms: this.searchTerms,
            vehicles: exportVehicles(this.vehicles),
        };
    }
}
