import StartSearchEvent from "./StartSearchEvent";
export default class TireStartSearchNormalizer {
    normalize(element, origin) {
        const startSearchEvent = new StartSearchEvent();
        startSearchEvent.catalog = 'pneus';
        startSearchEvent.searchType = 'recherche par texte';
        startSearchEvent.searchTerms = element.find('input#pneusAutocomplete').val();
        startSearchEvent.searchMarker = false;
        startSearchEvent.immatInput = false;
        startSearchEvent.referenceType = '';
        startSearchEvent.referenceCode = '';
        startSearchEvent.markerName = '';
        return startSearchEvent;
    }
    support(element, origin) {
        return 'pneus' === origin;
    }
}
