import { checkCons, getFamily, getSubFamily, getLibRep, } from '../../utilities';
import Flash from '../../Flash';
import ViewItemResultsEvent from './ViewItemResultsEvent';
import { ProductFactory } from "../dto/product/ProductFactory";
export default class TireViewItemResultsNormalizer {
    normalize(blockDataLayer, context) {
        var _a, _b, _c, _d, _e, _f;
        const origin = (_a = Flash.getViewItemResultOrigin()) !== null && _a !== void 0 ? _a : ''; // à vérifier
        const viewItemResultsEvent = new ViewItemResultsEvent();
        const appliedFilters = context.filters.filter((item) => false === ["searchref", "bCount", "page"].includes(item.name));
        viewItemResultsEvent.origin = origin !== null && origin !== void 0 ? origin : '';
        viewItemResultsEvent.catalog = (_b = checkCons(context.catalog)) !== null && _b !== void 0 ? _b : '';
        viewItemResultsEvent.family = (_c = getFamily()) !== null && _c !== void 0 ? _c : '';
        viewItemResultsEvent.subfamily = (_d = getSubFamily()) !== null && _d !== void 0 ? _d : '';
        viewItemResultsEvent.markerName = (_e = getLibRep()) !== null && _e !== void 0 ? _e : '';
        viewItemResultsEvent.filtersApplied = 0 !== appliedFilters.length;
        viewItemResultsEvent.totalIamResults = (_f = $('#afficher').data('nbtires')) !== null && _f !== void 0 ? _f : '';
        viewItemResultsEvent.totalOemResults = '';
        const amplitudeDivs = blockDataLayer.find('.amplitude');
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            viewItemResultsEvent.productCollection.addProduct(product);
        });
        return viewItemResultsEvent;
    }
    support(element, context) {
        return 'pneus' === context.catalog;
    }
}
