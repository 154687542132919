import { ProductCollection } from "../dto/product/ProductCollection";
export default class ViewCampaignDetailEvent {
    constructor(campaignJson) {
        this.campaign = campaignJson;
        this.productCollection = new ProductCollection();
    }
    export() {
        return {
            campaign: this.campaign,
            products: this.productCollection.export(),
        };
    }
}
