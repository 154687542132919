import { ProductCollection } from "../dto/product/ProductCollection";
export default class ViewItemResultsEvent {
    constructor() {
        this.origin = '';
        this.catalog = '';
        this.family = '';
        this.subfamily = '';
        this.markerName = '';
        this.filtersApplied = false;
        this.totalIamResults = '';
        this.totalOemResults = '';
        this.consumablesPaintSubfamily = '';
        this.productCollection = new ProductCollection();
    }
    export() {
        return {
            origin: this.origin,
            catalog: this.catalog,
            family: this.family,
            subfamily: this.subfamily,
            marker_name: this.markerName,
            filters_applied: this.filtersApplied,
            total_iam_results: this.totalIamResults,
            total_oem_results: this.totalOemResults,
            consumables_paint_subfamily: this.consumablesPaintSubfamily,
            products: this.productCollection.export(),
        };
    }
}
