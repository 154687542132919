import { getVehiclesData } from '../../utilities';
import ViewVehicleTechnicalDataEvent from './ViewVehicleTechnicalDataEvent';
export default class ViewVehicleTechnicalDataNormalizer {
    normalize(element, origin) {
        const viewVehicleTechnicalDataEvent = new ViewVehicleTechnicalDataEvent();
        viewVehicleTechnicalDataEvent.vehicles = getVehiclesData(element.vehicleDataBlock, null, origin);
        viewVehicleTechnicalDataEvent.technicalOrigin = origin;
        return viewVehicleTechnicalDataEvent;
    }
    support(element, origin) {
        return true;
    }
}
