import { checkCons } from '../../utilities';
import Flash from '../../Flash';
import ViewItemResultsEvent from './ViewItemResultsEvent';
import Context from "../../Context";
import { ProductFactory } from "../dto/product/ProductFactory";
export default class ConsumableViewItemResultsNormalizer {
    normalize(blockDataLayer, catalog) {
        var _a, _b, _c;
        const origin = (_a = Flash.getViewItemResultOrigin()) !== null && _a !== void 0 ? _a : ''; // à vérifier
        const viewItemResultsEvent = new ViewItemResultsEvent();
        viewItemResultsEvent.origin = origin !== null && origin !== void 0 ? origin : '';
        viewItemResultsEvent.catalog = (_b = checkCons(catalog)) !== null && _b !== void 0 ? _b : '';
        viewItemResultsEvent.family = '';
        viewItemResultsEvent.subfamily = '';
        viewItemResultsEvent.markerName = '';
        viewItemResultsEvent.filtersApplied = Flash.isFiltredViewItemResult();
        viewItemResultsEvent.totalIamResults = (_c = $('.nbr-item > strong').text()) !== null && _c !== void 0 ? _c : '';
        viewItemResultsEvent.totalOemResults = '';
        viewItemResultsEvent.consumablesPaintSubfamily = Context.getLastConsumablesPaintSubfamily();
        const amplitudeDivs = $('.amplitude');
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            viewItemResultsEvent.productCollection.addProduct(product);
        });
        return viewItemResultsEvent;
    }
    support(element, catalog) {
        return 'consumables' === catalog || 'peintures' === catalog || 'chaine' === catalog;
    }
}
