import { AMPLITUDE_CLASS } from "../../constants";
import { ProductFactory } from "../dto/product/ProductFactory";
import PdfQuotationEvent from "./PdfQuotationEvent";
export default class PdfQuotationNormalizer {
    normalize(elements, origin) {
        const pdfQuotationEvent = new PdfQuotationEvent();
        const amplitudeDivs = $(AMPLITUDE_CLASS);
        if (0 === amplitudeDivs.length) {
            return pdfQuotationEvent;
        }
        $(amplitudeDivs).each(function (index, amplitudeDiv) {
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            pdfQuotationEvent.productCollection.addProduct(product);
        });
        return pdfQuotationEvent;
    }
    support(element, origin) {
        return true;
    }
}
