/**
 * Format price.
 *
 * @param price  The price to format
 * @param locale The locale to use for format (optional)
 * @param digits Minimum number of digits (optional)
 *
 * @returns string
 */
export function priceFormatter(price, locale = 'fr-FR', digits = 2) {
    // Create number formatter.
    const formatter = new Intl.NumberFormat(locale, {
        minimumFractionDigits: digits,
    });

    return formatter.format(price);
}
