require('owl.carousel/dist/owl.carousel.min');
const $ = require('jquery');
const carouselSelector = '.owl-carousel:not(.bandeau-carousel)'
$(carouselSelector).owlCarousel({
    nav: true,
    items: 1,
    autoHeight: true
});
initOwlCarousel();

/*** Fonction d'initialisation du carousel et d'ajout des btn de défilement
 * @Param : (optional) jQuery object containing owl carousel
 ***/

export function initOwlCarousel (carousel) {
    const carouselSelect = !carousel ? $(carouselSelector) : carousel.find(carouselSelector);
    carouselSelect.owlCarousel({
        nav:true,
        items:1,
        lazyLoad:true,
        autoHeight:true
    });
    carouselSelect.find('.owl-nav .owl-prev').html('').append('<img alt="Previous" src="/build/images/arrow_owl_left_white.png">');
    carouselSelect.find('.owl-nav .owl-next').html('').append('<img alt="Next" src="/build/images/arrow_owl_right_white.png">');
}

$(function () {
    if ($(".bandeau-carousel").length > 0) {
        $(".bandeau-carousel").owlCarousel({
            nav:false,
            dots: true,
            items:1,
            lazyLoad:true,
            autoHeight:false,
            autoplay:true,
            autoplayTimeout:5000,
            autoplayHoverPause:true,
            loop:true
        });
    }
})
