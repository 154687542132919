export default class ViewCampaignEvent {
    constructor(campaign) {
        this.campaign = campaign;
    }
    export() {
        return {
            campaign: this.campaign,
        };
    }
}
