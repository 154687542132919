import NormalizersResolver from "./NormalizersResolver";
import AbstractDataBuilder from "../../core/AbstractDataBuilder";
export default class ViewVehicleResultsDataBuilder extends AbstractDataBuilder {
    static create() {
        if (undefined === this.builderInstance) {
            this.builderInstance = new this(new NormalizersResolver());
        }
        return this.builderInstance;
    }
}
