import DisplayIamForOemEvent from './DisplayIamForOemEvent';
import { ProductFactory } from "../dto/product/ProductFactory";
import { PATH } from '../../constants';
export default class DisplayIamForOemNormalizer {
    normalize(element, origin) {
        if (-1 === PATH.indexOf('/pieces/')) {
            return null;
        }
        const displayIamForOemEvent = new DisplayIamForOemEvent(origin);
        let resultsContainer = $(element).closest('.reference-origine-results');
        resultsContainer = 1 === $(resultsContainer).length ? resultsContainer : $(element).closest('.results-container');
        if (1 !== $(resultsContainer).length) {
            return null;
        }
        const subResult = $(resultsContainer).find('.sub_result');
        if ($(subResult).css('display') === 'none') {
            return null;
        }
        const loading = resultsContainer.find('.loading-progression');
        if (loading.is(':visible')) {
            return null;
        }
        const amplitudeDivs = $(resultsContainer).find('tr:not(.hide) .amplitude');
        $(amplitudeDivs).each((index, amplitudeDiv) => {
            const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
            displayIamForOemEvent.productCollection.addProduct(product);
        });
        displayIamForOemEvent.totalIamForThisOem = $(resultsContainer).find('.choix-equipementiers strong:nth-child(1)').text().trim();
        displayIamForOemEvent.oemReferenceCode = $(resultsContainer).find('.origin_piece strong:nth-child(1)').text().trim();
        displayIamForOemEvent.oemName = $(resultsContainer).find('.caracteristic_piece strong:nth-child(1), #label_oem_ref').text().trim();
        return displayIamForOemEvent;
    }
    support(element, origin) {
        return true;
    }
}
