import AddPartToQuotationNormalizersResolver from "./AddPartToQuotationNormalizersResolver";
import AbstractDataBuilder from "../../core/AbstractDataBuilder";
export default class AddPartToQuotationDataBuilder extends AbstractDataBuilder {
    static create() {
        if (undefined === this.builderInstance) {
            this.builderInstance = new this(new AddPartToQuotationNormalizersResolver());
        }
        return this.builderInstance;
    }
}
