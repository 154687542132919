import {initImgWithLazyLoading} from './init-images-carousel';
import {initOwlCarousel} from './carousel';

const REQUIRED_ERROR = '.required_error';
$('#popinFicheVehicule').addClass('devis-fiche');

$('#condition-status').click(function () {
    if ($(this).attr('data-index') == 1) {
        $('.question-left').toggleClass('active');
        $('.question-right').toggleClass('active');
        $('*[id*=radio_on]').each(function () {
            $(this).parent().toggle();
        });

        $('*[id*=radio_off]').each(function () {
            $(this).parent().toggle();
        });
    }
});

var activeSsids = $("input:radio[name=ssids]:checked").val();
if (activeSsids != undefined) {
    showRadioDatas(activeSsids);
}

$(document).on('click', "input:radio[name=ssids]", function () {
    $("input:radio[name=schedule]").each(function () {
        $(this).parent().hide();
        $(this).prop('checked', '');
    });
    var ssids = $(this).val();
    showRadioDatas(ssids);
});

function showRadioDatas(ids) {
    var datas = ids.split(",");
    if (datas.length == 1) {
        $('#radio_' + datas).attr('checked', 'checked');
    } else if (datas.length > 1) {
        for (var i = 0; i < datas.length; i++) {
            $('#radio_' + datas[i]).parent().show();
        }
    }
}

$(document).on('toggle', '.toggle-condition .toggle', function (e, active) {
    $("input:radio[name=schedule]").each(function () {
        $(this).parent().hide();
    });
    $('.question-left').toggleClass('active');
    $('.question-right').toggleClass('active');
    if (active) {
        var datas = $(this).attr('data-off-datas');
        showRadioDatas(datas);
    } else {
        var datas = $(this).attr('data-on-datas');
        showRadioDatas(datas);
    }
});

$(document).on('click', 'div.change-vehicle div.vehicle', function () {
    $('div.change-vehicle div.vehicle').removeClass('active');
    $(this).addClass('active');
});

$(document).on("click", "#operation-list-btn", function () {
    var index = $(this).attr('data-index');
    $("#pieces-list-" + index).toggleClass("hide");
    if (!$(this).find('i.dropdown_arrow_down').hasClass('rotate')) {
        $(this).find('i.dropdown_arrow_down').addClass('rotate');
    } else {
        $(this).find('i.dropdown_arrow_down').removeClass('rotate');
    }
});

$(document).on("click", "#operation-list-info", function () {
    var index = $(this).attr('data-index');
    $("#message-list-" + index).toggleClass("hide");
});

$(document).on("click", "#btn-select-prestation", function () {
    var index = $(this).attr('data-index');
    $('#prestations-list li').each(function () {
        $(this).removeClass('selected');
    });
    $('a#btn-select-prestation').each(function () {
        $(this).html('sélectionner');
    });
    $('#prestations-item-' + index).addClass('selected');
    $(this).html('sélectionnée');
});

$(document).on('click', '.interval', function () {
    $('#other-prestations div.tr').each(function () {
        $(this).removeClass('selected');
    });
    $(this).parent().parent().toggleClass('selected');
});

export function callAutoData(ajaxUrl, data = {}) {
    $('div[id^=bill-trash-]').off('click');
    $('div[id^=no-bill-trash-]').off('click');
    jQuery('.content-bottom i').removeClass('arrow-next-step').addClass('fa fa-spinner fa-spin right-spinner');
    jQuery('.validate.btn-red i').removeClass('i-validate').addClass('fa fa-spinner fa-spin left-spinner');
    jQuery('.revision-back.btn-blue i').removeClass('arrow-before').addClass('fa fa-spinner fa-spin left-spinner');
    jQuery.ajax({
        type: 'POST',
        url: ajaxUrl,
        data: data,
        async: true,
        dataType: 'html',

        success: function (data) {
            jQuery("#devis-maintain-content").html(data);
            jQuery('#steps-picture').removeClass('step-1');
            jQuery('#steps-picture').addClass('step-2');
            jQuery('.step-2').addClass('active');
            jQuery('#popin-other-prestation').modal('hide');
            jQuery('body').removeClass('modal-open');
            jQuery('.modal-backdrop').hide();
            jQuery('.validate.btn-red i').removeClass('fa fa-spinner fa-spin left-spinner').addClass('i-validate');
        },
        onFailure: function () {      // define call back function
            jQuery('.devis-error').html("Une erreur s'est produite, veuillez réessayer.");
            jQuery('.content-bottom i').removeClass('fa fa-spinner fa-spin right-spinner').addClass('arrow-next-step');
        },
        error: function(xhr){
            if (422 === xhr.status) {
                $(REQUIRED_ERROR).html(JSON.parse(xhr.responseText).message);
                $(REQUIRED_ERROR).show();
                jQuery('.content-bottom i').removeClass('fa fa-spinner fa-spin right-spinner').addClass('arrow-next-step');
            } else {
                callAutoData(ajaxUrl);
            }
        },

    });
}

export function callAutoDataWithMileage(url) {
    var mileage = $("#mileage").val();
    if (!$.isNumeric(mileage) || mileage < 0 || (mileage.indexOf(',') > -1) || (mileage.indexOf('.') > -1)) {
        $('.kilometer--text').text('Il faut saisir un nombre positive non décimal');
        $('.kilometer--text').css({
            color: '#e10f21',
        })
        $('#mileage').css({
            'border-color': '#e10f21',
        })

        return;
    }
    mileage = parseInt(mileage);
    var ssid = $("input:radio[name=schedule]:checked").val();
    url += "&ssid=" + ssid + "&mileage=" + mileage;
    url = url.replace('Km', '');
    var nbStateChecked = 0;
    $('input[name="vehicleStates"]').each(function(){
       if ($(this).is(':checked')) {
           nbStateChecked++;
       }
    });
    const data = {
        'token' : $('#__quote_schedules_token').val(),
        'mileage' : mileage,
        'stateChecked' : nbStateChecked,
        'ssid' : ssid,
    };
    if (ssid != undefined || nbStateChecked > 0) {
        if ($('#step1ValidError').length > 0) {
            $('#step1ValidError').addClass('hide');
        }
        callAutoData(url, data);
    } else {
        if ($('#step1ValidError').length === 0) {
            $('.schedules').append('<span class="red_text" id="step1ValidError"><strong>Veuillez sélectionner un état pour le véhicule</strong></span>');
        }
    }
}

$(function () {
    var el, newPoint, newPlace, offset;
    $("input[type='range']").change(function () {
        el = $(this);
        let width = el.width();
        newPoint = (el.val() - el.attr("min")) / (el.attr("max") - el.attr("min"));
        offset = -1.3;
        if (newPoint < 0) {
            newPlace = 0;
        } else if (newPoint > 1) {
            newPlace = width;
        } else {
            newPlace = width * newPoint + offset;
            offset -= newPoint;
        }
        $("#mileage").attr("value", el.attr('value'));
        $("#o").css('left', newPlace).text(el.val() + ' Km');
    }).trigger('change');
});

export function changeVehicle(url) {
    var key = $('div.change-vehicle div.vehicle.active').attr('data-key');
    if (key != undefined) {
        jQuery('.validate i').removeClass('i-validate').addClass('fa fa-spinner fa-spin left-spinner');
        window.location = url + '&vehicle=' + key;
    }
}

export function changePrestation(url) {
    var service = $('input[name=service]').filter(':checked').val();
    url += "&service=" + service;
    callAutoData(url);
}

function isIE() {
    return document.documentMode;
}

$(document).on('click', '.DEinfosTech.operations', function () {
    if ($(this).parent().parent().hasClass('delete-line') == false && $(this).parent().parent().hasClass('free') == false) {
        var id = $(this).attr('data-id');
        $('#operation-bill-' + id + ' div#myOperations').modal();
    }
});


$(document).on('click', '.close-popin-oil', function () {
    $('#popin-list-operation').modal('hide');
});

function selectSSD(countSelected) {
    if (countSelected > 0) {
        var ssid = $("input:radio[name=vehicleStates]:checked").val();
        var ssd = $("input:radio[name=vehicleStates]:checked").attr('dataSSD');

        $('.schedules div.radio-schedule.radio-red').show();
        $('.schedules div.radio-schedule').not('.radio-red:first').remove();
        $('.schedules div.radio-schedule.radio-red input.pubtech-inputradio').attr('id', 'radio_' + ssid);
        $('.schedules div.radio-schedule.radio-red input.pubtech-inputradio').attr('value', ssid);
        $('.schedules div.radio-schedule.radio-red input.pubtech-inputradio').attr('value', ssid);
        $('.schedules div.radio-schedule.radio-red input.pubtech-inputradio').attr("checked", "checked");
        $('.schedules div.radio-schedule.radio-red label').html(ssd);
        $('.schedules div.radio-schedule.radio-red label').attr('for', 'radio_' + ssid);

        $('#vehicleStates').modal('hide');
        $('.conditions').hide();
        $('.schedules').removeClass('hideSchedule');
        $('#radio_' + ssid).click();
    }
}
$(document).on('change', '.pubtech-inputradio2', function () {
    $('.DEvehicleStates').css('cursor', 'pointer');
});

$(document).on('click', '.DEvehicleStates', function(){
    var inputSelectedLength = $('input:radio[name=vehicleStates]:checked').length;
    selectSSD(inputSelectedLength);
});

/*** Initialisation des img de la popin RefEquip devis entretien ***/
$(document).on('shown.bs.modal', '.DEPopinRefEqui', function () {
    //Si pas d'image, on initialise l'image par défaut
    if($(this).find('.lazy-loading').length != 0){
        initImgWithLazyLoading();
    }
});

/*** Initialisation du handler d'init du carousel pour la fiche produit pneus ***/
$(document).on('show.bs.modal', '#popinFicheProduit', function () {
    //Si pas d'image, on initialise l'image par défaut
    if($('#popinFicheProduit').find('.lazy-loading').length != 0){
        initImgWithLazyLoading();
    }
    //Et dans tous les cas on initialise le carousel (uniquement de la vignette)
    initOwlCarousel($('#popinFicheProduit').find('.productDetail').first());
});

$(document).on('keyup', '#mileage', function (e) {
    $('.kilometer--text').text('Saisir le kilométrage du véhicule');
    $('.kilometer--text').css({
        color: 'initial',
    })
    $('#mileage').css({
        'border-color': 'initial',
    });
});
