export default class ViewLaborTimeEvent {
    constructor() {
        this.laborOrigin = '';
        this.family = '';
        this.subfamily = '';
        this.markerName = '';
    }
    export() {
        return {
            labor_origin: this.laborOrigin,
            family: this.family,
            subfamily: this.subfamily,
            marker_name: this.markerName,
        };
    }
}
