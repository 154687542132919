import PaintStartSearchNormalizer from "./PaintStartSearchNormalizer";
import Flash from "../../Flash";
export default class ConsumablesStartSearchNormalizer extends PaintStartSearchNormalizer {
    normalize(element, origin) {
        const startSearchEvent = super.normalize(element, origin);
        startSearchEvent.catalog = 'consommables';
        Flash.setConsumableCachedStartSearchEvent(startSearchEvent);
        return startSearchEvent;
    }
    support(element, origin) {
        return 'consumable' === origin;
    }
}
