import {initImgWithLazyLoading, initThumbnailsAndFPHandlerResults} from "./init-images-carousel";
import {getConsumablesPrices} from "./autossimo-consumables";
import {getFavorites} from "./autossimo-consumables-favoris";
import {handleConsumableImageError} from "./imageErrors";
import EventHandler from "./analytics/amplitude/EventHandler";

/**
 * Empêche d'avoir plusieurs call ajax en même temps
 * Utilisé dans pubtech-favoris-all.js et pubtech-favoris-listes.js
 * @param aNodes array : tableau d'objets contenant les inputs à modifier pour désactiver l'autoload pendant l'appel ajax
 */
export function stopAjaxCalls (aNodes) {
    for (var i = 0; i < aNodes.length; i++) {
        aNodes[i].val(1);
    }
}

/**
 * Charge en ajax les référénces, dans la page.
 * Utilisé dans pubtech-favoris-all.js et pubtech-favoris-listes.js
 * @param url string : url à  interroger
 * @param page int : page à charger
 * @param favcount int : nb max de favoris à afficher dans la famille, pour savoir s'il en reste ou non
 * @param aNodes array : tableau d'objets contenant les inputs à modifier pour réactiver l'autoload après l'appel ajax
 *
 **/
export function loadReferences (url, page, favcount, aNodes) {
    $.ajax({ type: "GET",
        url: url,
        async: true,
        success : function(data) {

            //On masque le loader
            $('#loading-block').css('display', 'none');

            //On affiche les données
            $('#favoris-ref-results').append(data);
            handleConsumableImageError();

            //On réautorise le switch et le changement de famille
            for (var i = 0; i < aNodes.length; i++) {
                aNodes[i].val(-1);
            }

            //On init le datepicker pour choisir la dispo
            $('.enable-jquery-datepicker').datepicker(jQuery.datepicker.regional[ "fr" ]);

            //Init des thumbnail et du handler pour les img/carousel des fiches produits
            initThumbnailsAndFPHandlerResults();
            //S'il y a encore des données, on incrémente la page, et réactive l'autoload
            if(favcount > page * 10) {
                $("#page-to-load").val(page + 1);
                $("#stop-autoload").val(-1);
            }
            initImgWithLazyLoading();
            getConsumablesPrices();

        },
        error : function(xhr, status, error) {
            $('#favoris-ref-results').html("Erreur");
            $('#loading-block').css('display', 'none');
        }
    });
}

$(document).ready(function () {
    const REFERENCE = '.reference';
    $(document).on('click', '.ref-results-input .reference i', function () {
        const value = $(this).closest(REFERENCE).find('span').html();
        const id = $(this).closest(REFERENCE).find('span').attr('data-id');
        $(this).closest(REFERENCE).remove();
        if ($('.autocomplete_paint').length > 0) {
            $('.autocomplete_paint[value="'.concat(value).concat('"]')).remove();
        }
        $('input[value='.concat(id).concat(']')).remove();
        $('div.row.data-row').each(function () {
            if ($(this).attr("data-reffab").trim() == value.trim()) {
                $(this).remove();
            }
        });
        $('span.nb_refs').html($('div.row.data-row').length);

        if ($('.ref-results-input .reference').length === 0) {
            window.location.href = Routing.generate('autossimo_peintures');
        }
    });

    $('.DiaporamaVignette').hide();

    $(document).on('click', '.zoom-icon', function (e) {
        e.preventDefault();
        const target = $(this).closest('.row').find('.DiaporamaVignette');
        initOwlCarousel(target);
        $(target).fadeIn();
        $(target).find('.caroufredSel img').css('max-width', '230px');
        $(target).find('.caroufredSel img').css('display', 'block');
        $(target).find('.caroufredSel img').css('margin', 'auto');
        $('.caroufredSel, .caroufredsel_wrapper, .caroufredSel li').removeAttr('style');
    });

    $(document).on('click', '.DiaporamaVignette .close-diap', function () {
        $('.DiaporamaVignette').fadeOut();
    });

    //breadcrumb page peintures
    $(document).on('click', '.select-panel-options li a', function (event) {
        event.preventDefault();
    });

    // checkbox select mecanism
    $(document).on('click', 'label[for="select-all-list-items"]', function () {
        // Iterate each checkbox
        $('input[name="checkbox-product"]').each(function () {
            $(this).prop('checked', !$('input[name="checkbox-product-all"]').is(':checked'))
        });
    });

    $(document).on('click', 'input[name="checkbox-product"]', function () {
        if ($('input[name="checkbox-product-all"]').is(':checked')) {
            $('input[name="checkbox-product-all"]').prop('checked', false);
        }
    });

    // Toggle Suggests distribs
    $(document).on('click', '.search-paints-block+.ref-results .navarbo-switch.material-switch', function () {
        if ($('#display_suggestion').prop('checked') === false) {
            $('#display_suggestion').prop('checked', 'checked');
            if($(this).hasClass('navarbo-switch')){
                $('.nb_refs').text($('.favProd_count').val());
            }
        } else {
            $('#display_suggestion').removeProp('checked');
            if($(this).hasClass('navarbo-switch')){
                $('.nb_refs').text($('.nb_refs_init').text());
            }
        }
        const codrub = $('.breadcrumb_rub').attr('data-codrub');
        const codfam = $('.breadcrumb_fam_empty').hasClass('hide') ? $('.breadcrumb_fam').attr('data-codfam') : $('.breadcrumb_fam_empty ').attr('data-codfam');
        const codsfam = $('.breadcrumb_sfam_empty').hasClass('hide') ? $('.breadcrumb_sfam ').attr('data-codsfam') : $('.breadcrumb_sfam_empty ').attr('data-codsfam');
        const filters = $('.filters-data').val();
        $.ajax({
            type: 'POST',
            url: Routing.generate('autossimo_peintures_load_refs'),
            data: {
                codArbo: codrub + codfam + codsfam,
                filters: filters,
                page: 1,
                multi_results: false,
                loadFromSession : true,
                hideSuggestDist : $('#display_suggestion').prop('checked') == false ? false : true
            },
            beforeSend: function (data) {
                $('.loading-block').remove();
                $('.ref-results-items').not('div.associated-block .ref-results-items').html('');
                $('.ref-results-items').not('div.associated-block .ref-results-items')
                    .append('<div class="loading-block"> <img src="/build/images/loading.gif" height="28" width="28" alt="" /> Chargement </div>');
            },
            async: true,
            success: function (data) {
                $('.loading-block').remove();
                $('.ref-results-items').not('div.associated-block .ref-results-items').attr('data-page', 1);
                $('.ref-results-items').not('div.associated-block .ref-results-items').append(data);
                initImgWithLazyLoading();
                getFavorites();
                getConsumablesPrices();
            },
            onFailure: function (data) {
                $('.loading-block').remove();
            }
        });

        return false;
    });

    $(document).on('click', '.associated-show', function () {
        const coderef = $(this).attr('data-id');
        if($(this).hasClass('retrieved')){
            $(this).find('span').toggleClass('active');
            $('#associated-' + coderef).toggle();
        }else{
            const that = $(this);
            $.ajax({
                type: 'POST',
                url: Routing.generate('autossimo_peintures_load_template'),
                data: {
                    template: 'associated_products',
                    process: 'load-associated-products',
                    coderef: coderef,
                },
                success: function (data) {
                    const container = that.closest('.associated-list_products');
                    container.html(data);
                    setTimeout(function(){
                        $('#associated-' + coderef).toggle();
                    }, 100);

                }
            });
        }
    });

    initThumbnailsAndFPHandlerResults();

});

// Event on click to "show-essential" bloc
$(document).on('click', '.show-essential', function (event) {
    const codArbo = $(this).attr('data-codArbo');
    const popin = $('#popinInfoEssential');

    // Disable default event
    event.preventDefault();

    if ($(this).hasClass('disabled-tree-code')) {
        // Show modal informative when selected paint tree code is not allowed in current repairer paint collection
        popin.show();
        popin.modal();
    } else {
        const blockData = $('#dataGa');
        EventHandler.startSearch($(this), 'paints_essentials')
        // Handle redirection to essentials paint
        const $form = $('#essentials-'+ codArbo);
        const breadCrumbEnabled = $('.breadCrumb-favoris-wrapper-consumables').is(':visible');
        const searchType = breadCrumbEnabled ? 'family' : 'reference';
        $form.find('input[name="breadCrumbActived"]').val(breadCrumbEnabled);
        $form.find('input[name="searchType"]').val(searchType);
        $form.submit();
    }
});
