import {initOwlCarousel} from "./carousel";
import {initImgWithLazyLoading} from "./init-images-carousel";
import {getFavorites} from "./autossimo-consumables-favoris";
import {stopAjaxCalls, loadReferences} from './pubtech-peintures-reference';
import {formatAvailability} from "./manage-ga";
import {updateBlocPrice, formatParamsForErpRequest, updateItemAvailability, canDisplayLimitedStockMsg} from "./consumable-availability-price";
import {emptySearch, cancelRequest} from './autocomplete-search';
import {handleConsumableImageError, handleFicheProduitImageError} from './imageErrors';
import {hideLoadingSpinnerWhenNoProductsToShow} from "./consumable-functions";
import {compareElementsAvailability} from "./pubtech-dispo-price";
import {handleQuantityUpdate} from "./quantityManager";
import {showPromoBands} from "./promotion-bands";
import GaEventHandler from "./analytics/ga/GaEventHandler";
import Flash from "./analytics/Flash";

const PRODUCT_CONSU = '.products-consumables';
const DROPDOWN_TOGGLE = '.dropdown-toggle';
const DROPDOWN_DIV = 'div.dropdown';
const BREADCRUMB_WRAPPER= '.breadCrumb-favoris-wrapper-consumables';
const DATA_AVAILABILITY= 'data-availability';
const SELECT_TYPE_SEARCH = '#select-type';
const SEARCH_TYPE_OPTION = '.search-type';
const SEARCH_BY_ARBO = 'Recherche par arborescence';
const SEARCH_BY_KEY = 'Recherche par mots-clés';
const CONSUMABLE_DETAIL_PROVIDER = '.consumable-detail-provider';
const BREAD_CRUMB_ACTIVATED = '#breadCrumbActived';
const POPIN_FP_TYRE = '#popinFpPneus';
const TITLE_TRUNCATE = 'title-truncate';
const CONSUMABLE_DETAIL_NAME = '.consumable-detail-name';
const FILTER_BY_DESIGN = '#filter-by-desg';
const CONSUMABLE_LOADING = '#consumables-loading';
const CONSUMABLE_DETAIL_REFERENCE = '.consumable-detail-reference';
const FILTER_SWITCH_FAVORITES = '#display_favoris';
const FILTER_SWITCH_RECURRENT = '#display_recurrent';
const CODARBO_SELECTED = 'data-code-selected-family';
const MANUFACTURER = '#manufacturer';
const MANUFACTURERTYPE = '#manufacturer-type';
const SEGMENT_TYPE = '#segment-type';
const SORT_AVAILABILITY_POPULARITY_PRICE = '#sort-availability-popularity-price';
const IS_ESSENTIAL = '#is-essential';
const END_OF_LIFE = 'END_OF_LIFE';
const OUT_OF_STORE = 'OUT_OF_STORE';
const COUNTER_SELECTOR = '.nbr-item strong';
const ADVERTISING_BANNER = '.advertising-banner-limitation-label';

var activateSubSelection = function (classListe, classElemToShow) {
    var oListe = $(classListe).first();
    //active le bouton pour toggle la liste
    oListe.children(DROPDOWN_TOGGLE).first().removeClass('disabled');
    oListe.find("li" + classElemToShow).show();
    oListe.find("li:not(".concat(classElemToShow).concat(")")).hide();

    //affiche la liste (fam ou sfam). Le setTimeout est obligatoire, impossible d'ajouter la classe "open" autrement....
    setTimeout(function() {
        oListe.addClass('open');
    },10);
};

$(document).ready(function () {
    //Update icon button.
    var _typeSearched = $(PRODUCT_CONSU).data('searchtype');
    if ( typeof _typeSearched === 'undefined' || _typeSearched === 'reference') {
        $("#btnExpress .icon-search-form-lg").addClass('active-btn');
    }

    //handler selection d'un item du breadcrumb
    $(document).on('click', '.search-consumables-block .dropdown-item:not(.theme)', function (e) {
        e.preventDefault();

        var oNode = $(e.currentTarget);

        if (!oNode.hasClass('disabled')) {
            //Bouton qui sert à dérouler la liste dans laquelle se trouve la famille sélectionnée (rub, fam ou sfam)
            var oDisplayBtn = oNode.closest(DROPDOWN_DIV).children(DROPDOWN_TOGGLE).first();
            //Codarbo de la famille demandée
            var codarbo = oNode.data('codarbo');
            //Label de la famille demandée
            var label = oNode.html();

            //cas où on sélectionne une autre rubrique ou famille alors qu'on a déjà déplié les familles et/ou sous-familles.
            //On s'assure que les familles et sous-familles soient repliées et disabled, puis on les réactive au besoin.
            if (oNode.hasClass('reset-fam')) {
                $('.breadcrumb-favoris-famille-select-btn').addClass('disabled').html('<span>Familles</span>');
            }

            if (oNode.hasClass('reset-sfam')) {
                $('.breadcrumb-favoris-sous-famille-select-btn').addClass('disabled').html('<span>Sous-familles</span>');
            }

            //On modifie le label du breadcrumb en fonction de l'élément sélectionné
            oDisplayBtn.html("<span>".concat(label).concat("<span>"));

            //Si on a besoin de déplier la liste des familles
            if (oNode.hasClass('display-fam')) {
                activateSubSelection('.breadcrumb-favoris-famille-select', '.rubrique-' + codarbo);
            } else if (oNode.hasClass('display-sfam')) {
                //Si on a besoin de déplier la liste des sous-familles
                activateSubSelection('.breadcrumb-favoris-sous-famille-select', '.famille-' + codarbo);
            } else {
                //if we are in search consumables page (not Favoris page), the click on a sub-family launch the search
                if (oNode.hasClass('display-references')) {
                    $('#codeArboOfSelectedFamily').val(oNode.data('codarbo'));
                    $('#searchType').val('family');
                    $(BREAD_CRUMB_ACTIVATED).val('true');
                    $('#searchForm').submit();
                    return true;
                }
                // Sinon --> on va chercher et afficher les références
                if ($("#stop-autoload-by-breadcrumb").val() != 1) {
                    //passé ce point, on sait qu'on va lancer un call ajax, donc on active l'anti-monkey
                    stopAjaxCalls([$("#stop-autoload"), $("#stop-autoload-by-switch"), $("#stop-autoload-by-breadcrumb")]);

                    // On retire les références déjà affichées
                    $('#favoris-ref-results').html('');

                    //On affiche un loader
                    $('#loading-block').css('display', 'block');

                    //catalogue demandé
                    var catalogue = oNode.data('catalogue');

                    //nb de favoris, sans les suggestions distrib
                    var favOnlyCount = parseInt(oNode.data('favcount')) - parseInt(oNode.data('suggdis'));
                    var favcount = 0;
                    //nb de références à afficher
                    if ($('#display_suggestion').prop('checked')) {
                         favcount = favOnlyCount;
                    } else {
                         favcount = parseInt(oNode.data('favcount'));
                    }
                    //On init les inputs qui seront utilisés dans les autres cas (autoload et switch)
                    $("#nb_refs").html(favcount);
                    $("#nb_refs_not_only_sugg_distrib").html(favOnlyCount);
                    $('#page-to-load').val(1);
                    $('#codarbo-to-load').val(codarbo);
                    $('#catalogue-to-load').val(catalogue);

                    //On affiche le block avec les filtres
                    //On ne l'affiche pas avant, car on ne sait pas quoi afficher dans #nb_refs vu qu'aucune famille n'est selected
                    $(".ref-results-controls").css({"display" : "block"});

                    //on génère l'url pour récup les refs
                    var page = 1;

                    var hideSuggDis = "";
                    if ($('#display_suggestion').prop('checked')) {
                        $('#display_suggestion').prop('checked').toString();
                    }

                    var url = Routing.generate('autossimo_get_favoris_references', {'catalogue' : catalogue, 'codarbo' : codarbo, 'page' : page, 'hideSuggDis' : hideSuggDis});
                    var aNodes = [$("#stop-autoload-by-switch"), $("#stop-autoload-by-breadcrumb")];

                    loadReferences(url, page, favcount, aNodes);
                }
            }
        }
    });
});

$(document).ready(function () {
    const isPaint = $("#paintAutocomplete").length !== 0;

    $(SEARCH_TYPE_OPTION).one('click', function () {
        if ($(BREADCRUMB_WRAPPER).is(':hidden')) {
            jQuery(SELECT_TYPE_SEARCH).html(SEARCH_BY_ARBO);
            jQuery(SEARCH_TYPE_OPTION).html(SEARCH_BY_KEY);
            var blockChargement = '<div class="loading-block blank" id="loading-block"> <img src="/build/images/loading.gif"> Chargement </div>';
            $('section.arbo-results-input').html(blockChargement);
            $.ajax({
                type: 'POST',
                url: Routing.generate('autossimo_consumables_setup_breadcrumb_ajax'),
                data: {
                    type: isPaint ? 'paint' : null,
                },
                complete: function () {
                    $('section.search-consumables-block').fadeIn(300);
                    $('.loading-block').fadeOut(300);
                },
                success: function (data) {
                    $('section.arbo-results-input').html(data);
                    var codArbo = $('.types').attr('id');
                    if (typeof codArbo !== 'undefined') {
                        var rubric = codArbo.substr(0, 2) + "0000";
                        var family = codArbo.substr(0, 4) + '00';
                        var classRubric = $('.breadcrumb-favoris-rubrique-select');
                        var classFamily = $('.breadcrumb-favoris-famille-select');
                        var classSubFamily = $('.breadcrumb-favoris-sous-famille-select');
                        var textRubric = classRubric.find("[data-codarbo='".concat(rubric).concat("']")).text();
                        var textFamily = classFamily.find("[data-codarbo='".concat(family).concat("']")).text();
                        var textSubFamily = $('.breadcrumb-favoris-sous-famille-select').find("[data-codarbo='".concat(codArbo).concat("']")).text();

                        $('.breadcrumb-favoris-rubrique-select > .dropdown-toggle > span').text(textRubric);
                        $('.breadcrumb-favoris-famille-select > .dropdown-toggle > span').text(textFamily);
                        classFamily.find("li:not(.rubrique-".concat(rubric).concat(")")).hide();
                        $('.breadcrumb-favoris-famille-select > .dropdown-toggle').removeClass('disabled');
                        $('.breadcrumb-favoris-sous-famille-select > .dropdown-toggle > span').text(textSubFamily);
                        classSubFamily.find("li:not(.famille-".concat(family).concat(")")).hide();
                        $('.breadcrumb-favoris-sous-famille-select > .dropdown-toggle').removeClass('disabled');
                    }
                }
            });
        }
    }).bind('click', function () {
        if ($(BREADCRUMB_WRAPPER).is(':hidden')) {
            if (true === isPaint) {
                $('form[id^=paints] input[name=searchType]').val('family')
                $('form[id^=paints] input[name=breadCrumbActived]').val('true')
            }
            cancelRequest();
            emptySearch();
            displaySearchBreadCrumb();
        } else if ($('.search-consumables-block').length > 0) {
            if (true === isPaint) {
                $('form[id^=paints] input[name=searchType]').val('reference');
                $('form[id^=paints] input[name=breadCrumbActived]').val('');
            }
            displaySearchKeyWord();
        }
    });

    $('.send-search-by-desig').click(function () {
        consoFilterEvent($(FILTER_BY_DESIGN))
    });

    $(FILTER_BY_DESIGN).keyup(function (event) {
        if ($(this).val().length > 0) {
            $('.reset-search-refinement').show();
        } else {
            $('.reset-search-refinement').hide();
        }

        if (event.keyCode == 13) {
            $('.ui-autocomplete').hide();
            consoFilterEvent($(this))
        }
    });

    $('.reset-search-refinement').click(function () {
        $(FILTER_BY_DESIGN).val('');
        $(this).hide();
        $('.ui-autocomplete').hide();
        consoFilterEvent($(FILTER_BY_DESIGN));
    });

    $('.search-consumables-block .types').mouseout(function(){
        $('.dropdown-menu.searchType').hide();
    })

    $('.search-consumables-block .types').mouseover(function(){
        $('.dropdown-menu.searchType').show();
    })
});

$(document).ready(function () {
    /****************************************************/
    var autoCompleteData = $(FILTER_BY_DESIGN).autocomplete({
        minLength: 3,
        source: function (request, response) {
            var selectedElemt = [];
            $("#manufacturer option:selected").each(function (index, value) {
                selectedElemt.push($(value).val());
            });
            var autoCompleteSources = [];
            var listDesignation = getListDesignation();
            $.each(listDesignation[0], function(index, value) {
                if (selectedElemt.length > 0) {
                    if (selectedElemt.indexOf(value['manufacturer']) !== -1) {
                        autoCompleteSources.push(value['designation']);
                    }
                } else {
                    autoCompleteSources.push(value['designation']);
                }
            });
            var datamap = autoCompleteSources.map(function (i) {
                return {
                    label: i,
                    value: i,
                }
            });
            var key = request.term;

            datamap = datamap.filter(function (i) {
                var keyWords = key.split(" ");
                var returnBool = true;
                executeIfNotFound(keyWords, i.label, function () {
                    returnBool = false;
                });
                return returnBool;
            });
            response(datamap.slice(0, 10));
        },
        open: function (event, ui) {
            var position = $(this).autocomplete("widget").position();
            var top = position.top;
            var height = $(FILTER_BY_DESIGN).outerHeight(true);
            $(this).autocomplete("widget").css({"top": height + top});
            $('.display-all-result').click(function () {
                $(".consumable").show()
            })
        },
        select: function (event, ui) {
            if (!ui.item.search) {
                $(FILTER_BY_DESIGN).val(ui.item.value);
            }
            getProductByDesignation();
            GaEventHandler.filter('designation', 'designation');

            return false;
        }
    })
        .data("autocomplete");

    if (autoCompleteData) {
        autoCompleteData._renderMenu = function (ul, items) {
            $.each(items, function (index, item) {
                // renderItem :
                var designationWords = $(FILTER_BY_DESIGN).val().split(" ");
                var label = item.value.toUpperCase()
                // On met chaque mot de la designation en gros dans les resultats
                $.each(designationWords, function (_key, word) {
                    label = label.replace(word.toUpperCase(), '<b>' + word.toUpperCase() + '</b>');
                });
                var itemClass = '';
                if (item) {
                    ul.append(
                        $("<li>")
                            .append("<a class='".concat(itemClass).concat("'>").concat(label).concat("</a>"))
                            .data("item.autocomplete", item)
                    );
                }
            })
            ul.append(
                $("<li class='display-all-result'>")
                    .append('<a class="">Afficher tous les résultats</a>')
                    .data("item.autocomplete", {label: '', value: ''})
            );
        }
    }

    $(FILTER_BY_DESIGN).autocomplete("widget").addClass("search-expendable-autocomplete");
});

$(document).ready(function () {

    //Affiche la fiche produit Consommables
    $(document).on("click", "a[data-product-sheet]", function(e) {
        e.preventDefault();
        getProductSheet($(this));
    });
    //Affiche la fiche produit Consommables
    $(document).on("click", "a[data-new-tyre-labeling]", function(e) {
        e.preventDefault();
        var url = $(this).data('new-tyre-labeling');
        // Call the product sheet of the tyre.
        getFpPneusAjax(url, false);
    });
    //Fermer la fiche produit Consommables
    $(document).on("click", "#popinProductSheet .close", function(e) {
        e.preventDefault();
        $('#popinFicheProduit').modal('hide');
        $('body').css('overflow','auto');
    });
    $(document).on("click", ".consumable-image:not(.consumable-image-failed)", function(e) {
        var url = $(this)
            .find(".product-image")
            .data("optimized") ;
        $('#zoom-image-consumable').html(
            '<div class="zoom-image-container"><a class="close-zoom-consumable"></a><img src="'.concat(url).concat('"></div>')
        );
        $('#zoom-image-consumable').modal({});
    });
    $(document).on("click", ".close-zoom-consumable", function() {
        $('#zoom-image-consumable').modal('toggle');
        $('body').css('overflow','auto');
        $('body').removeClass('modal-open');
    })
});

$(window).on("load", function() {
    //Initialize the breadcrumb if the search is launched with family
    var codArboSearched = $(PRODUCT_CONSU).data('code-selected-family');
    var typeSearched = $(PRODUCT_CONSU).data('searchtype');
    var breadCrumbActived = $(BREAD_CRUMB_ACTIVATED).val();

    if (codArboSearched != '' && typeSearched == 'family' && breadCrumbActived == "true") {
        displaySearchBreadCrumb();
        codArboSearched = codArboSearched.toString();
        var rubric = codArboSearched.substr(0, 2)+'0000';
        var family = codArboSearched.substr(0, 4)+'00';

        $('.breadcrumb-favoris-rubrique-select.breadcrumb-dropdown').click();
        var currentRubric = $( 'a[data-codarbo="'.concat(rubric).concat('"]'));
        var oDisplayBtnRubric = currentRubric.closest(DROPDOWN_DIV).children(DROPDOWN_TOGGLE).first();
        var rubricLabel = currentRubric.html();
        oDisplayBtnRubric.html("<span>".concat(rubricLabel).concat("<span>"));
        activateSubSelection('.breadcrumb-favoris-famille-select', '.rubrique-' + rubric);

        var currentFamily = $( 'a[data-codarbo="'.concat(family).concat('"]'));
        var oDisplayBtnFamily = currentFamily.closest(DROPDOWN_DIV).children(DROPDOWN_TOGGLE).first();
        var familyLabel = currentFamily.html();
        oDisplayBtnFamily.html("<span>".concat(familyLabel).concat("<span>"));
        activateSubSelection('.breadcrumb-favoris-sous-famille-select', '.famille-' + family);

        var currentSubFamily = $( 'a[data-codarbo="'.concat(codArboSearched).concat('"]'));
        var oDisplayBtnSubFamily = currentSubFamily.closest(DROPDOWN_DIV).children(DROPDOWN_TOGGLE).first();
        var subFamilyLabel = currentSubFamily.html();
        oDisplayBtnSubFamily.html("<span>".concat(subFamilyLabel).concat("<span>"));
        setTimeout(function(){
            $('.breadcrumb-favoris-famille-select.breadcrumb-dropdown').removeClass('open');
            $('.breadcrumb-favoris-sous-famille-select.breadcrumb-dropdown').removeClass('open');
        }, 100);
    }
});

export function getProductSheet(popin) {
    const popinProductSheet = '#popinProductSheet';
    let url = popin.attr('data-product-sheet');
    const source = $('#source').val();
    if (source !== undefined && '' !== source) {
        url = url.concat('?source=').concat(source);
    }
    $(popinProductSheet).load(url, function () {
        $(popinProductSheet).parent().show();
        $(popinProductSheet).modal({});
        var overlayDIV = $(popinProductSheet).closest('body').find('.overlay');
        $(overlayDIV[1]).css('display', 'none');
        if ($('.products-consumables .consumable').length > 0) {
            var node = $(".carousel-product");
            initImgWithLazyLoading(node, true);
        }
        var priceLevel = $("div.ps-price-block.keep-price").find('.price-level-value').val()
        if (priceLevel !== '0' ) {
            $("div.ps-price-block.keep-price").find('.price-level-bloc').removeClass("hidden");
        }
        var quantity = $('input[type=radio][name=priceLevel]:checked');
        var inputQuantity = $('div.containerDispo.ps-price').find('.cart-action .input-quantity');
        inputQuantity.val(quantity.val());
        inputQuantity.attr('data-quantity', quantity.val());
        inputQuantity.attr('value', quantity.val());
        const blockAvailability = $(popinProductSheet).find('#blocDispo .dispo-details');
        let availability = blockAvailability.children(':not(.tooltips)').text();
        if (blockAvailability.find('input.datepicker-pricing').length > 0) {
            availability = blockAvailability.find('input.datepicker-pricing').attr('value');
        } else if (blockAvailability.find('.available-on-demand').length > 0) {
            availability = blockAvailability.find('.available-on-demand').text();
        } else if (blockAvailability.find('.available-on-order').length > 0) {
            availability = blockAvailability.find('.available-on-order').text();
        }
        availability = formatAvailability(availability);
        $(popinProductSheet).find('#blocDispo').attr('data-available-value', availability);
        GaEventHandler.viewItem($(this),'consummable');
        handleFicheProduitImageError();
    });
    return false;
}

function displaySearchBreadCrumb(){
    jQuery(BREADCRUMB_WRAPPER).show();
    jQuery('section.search').hide();
    jQuery(SELECT_TYPE_SEARCH).html(SEARCH_BY_ARBO);
    jQuery(SEARCH_TYPE_OPTION).html(SEARCH_BY_KEY);
}

function displaySearchKeyWord(){
    jQuery(BREADCRUMB_WRAPPER).hide();
    $(BREAD_CRUMB_ACTIVATED).val('');
    jQuery('section.search').show();
    jQuery(SELECT_TYPE_SEARCH).html(SEARCH_BY_KEY);
    jQuery(SEARCH_TYPE_OPTION).html(SEARCH_BY_ARBO);
}

var delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

function putInSearchZone(label, codarbo, type, codeRef)
{
    $('#paintAutocomplete').val(label);
    $('#consumablesAutocomplete').val(label);
    $('#codeArboOfSelectedFamily').val(codarbo);
    $('#searchType').val(type);
    $('#codeRef').val(codeRef);

    const form = jQuery('#searchForm');
    if(codarbo === 120101){
        const url = Routing.generate('autossimo_pneus_from_consumable');
        form.attr('action',url);
    }
    form.submit();
}
$(document).on('click', 'div.search-consumables-block a.ui-menu-item.family', function(e) {
    const span = $(this);
    putInSearchZone(span.data('label'), span.data('codarbo'),"family", "");
});
$(document).on('click', 'div.search-consumables-block a.ui-menu-item.reference', function(e) {
    const a = $(this);
    putInSearchZone(a.data('label'), a.data('codarbo'), "reference", a.data('codref'));
});
var isLoadingPrices = false;

//items infinite scroll display + ajax more items call
$(document).ready(function () {
    var listRefProducts = $(PRODUCT_CONSU);
    var ajaxLoad = true;
    var itemsPerScroll  = parseInt($(listRefProducts).attr('data-itemsPerScroll'));
    isLoadingPrices = true;

    //load Prices when page is ready before scrolling
    if ($('.products-consumables .consumable:not(.slider-product)').length > 0) {
        const node = $(".consumable:not(.hidden):not(.imageLoaded)");
        const isSliderPaintFav = $('.block-slider').length > 0;
        initImgWithLazyLoading(node, true);
        if (!isSliderPaintFav) {
            getFavorites();
        }
        getConsumablesPrices(true);
    }

    $(window).scroll(function () {
        if (ajaxLoad === false) {
            return false;
        }
        var scrollTop           = $(document).scrollTop();
        var windowHeight        = $(window).height();
        var bodyHeight          = $(document).height() - windowHeight;
        var scrollPercentage    = (scrollTop / bodyHeight);

        if (scrollPercentage > 0.95 && ($('.search-by-filter').length === 0 || isLoadingPrices === false)) {
            var totalItemsCount     = parseInt($('#countArticles').attr('data-count'));
            var visibleItemsCount   = parseInt($('.list-products-conso >.consumable-product').length);
            if (totalItemsCount > visibleItemsCount) {
                isLoadingPrices = true;
                if ($('.search-by-filter').length) {
                    $(".consumable.hidden:not(.search-by-filter):lt(".concat(itemsPerScroll).concat(")")).each(function(){
                        $(this).removeClass('hidden')
                    });
                } else {
                    $(".consumable.hidden:lt(".concat(itemsPerScroll).concat(")")).each(function(){
                        $(this).removeClass('hidden')
                    });
                }
                productListSession();
                $(listRefProducts).attr('data-visibleItemsCount', visibleItemsCount + itemsPerScroll);
            } else {
                $(CONSUMABLE_LOADING).addClass('hidden');
            }
        }
    });
});

export function getConsumablesPrices(firstLoad = false){
    var itemsNotPriceLoadedSelector = $('.consumable:not(.hidden):not(.priceLoaded)');
    if ($('.search-by-filter').length > 0) {
        itemsNotPriceLoadedSelector     = $('.consumable:not(.hidden,.search-by-filter)');
    }
    var itemsNotPriceLoadedCount    = parseInt($(itemsNotPriceLoadedSelector).length);

    var  reffabData = [];

    if (itemsNotPriceLoadedCount > 0) {
        isLoadingPrices = true;

        $(itemsNotPriceLoadedSelector).each(function(){
            reffabData.push(formatParamsForErpRequest($(this)));
        });
    }
    $.ajax({
        type: 'POST',
        url: Routing.generate('autossimo_consumables_get_prices'),
        data: {
            references: JSON.stringify(reffabData)
        },
        async: true,
        beforeSend: function() {
            $('.consumable-inner').each(function(ind,val){
                if($(val).find('.consumable-detail-availability').attr('class').indexOf('hidden') > -1){
                    $(val).find('loading-price').removeClass("hidden");
                }

            });
        },
        success: function (data) {
            var updatedRef = data.data.results;
            var errorCode = data.data.error;

            if (errorCode !== null && errorCode !== undefined) {
                var erpNOK = errorCode['erp-nok-distrib'];
                $('.erp_nok_distributor').attr('value', erpNOK);
            }

            if (typeof updatedRef !== 'undefined' && updatedRef.length > 0) {
                const searchType = $('#search_type').data('type-search');
                jQuery.each(updatedRef, function (i, value) {
                    if (value !== undefined) {
                        const blockItemToUpdate = $('.consumable:not(.hidden)[data-codref="'.concat(value.CODREF).concat('"]'));
                        const itemToUpdate = blockItemToUpdate[0];

                        if(itemToUpdate?.dataset?.promo === "flash_24" &&
                            value["DISPO"]  === "A" && 
                            Number(value["PAHT"])
                        ) 
                        {
                            const availabilityTextElement = $(itemToUpdate).find(
                                "#blocDispoConso > .dispoA.with-contact-us > span[class*=text-description][class*=available-on-order]"
                            );
                            availabilityTextElement.parent().find(":not(.available-on-order)").attr("style", "display: none !important");
                            availabilityTextElement.html(`<i class="fa fa-check-circle float-left mR-3"></i> Disponible`);
                        };

                        const updatedRefCount = updatedRef.length;
                        updateItemStatusAndPrice(value, itemToUpdate, searchType, updatedRefCount, blockItemToUpdate);
                        displayDestockingProduct(value, itemToUpdate, blockItemToUpdate);
                    }
                });
            }
            consumableSort(firstLoad);
            // AD-5925 : comment the updateBlocEcotaxes while waiting the changes in environmental taxes
            //updateBlocEcotaxes(updatedRef);
            GaEventHandler.viewItemList($('.consumable-product'), 'consummable');
            showPromoBands(updatedRef);
        },
        complete: function() {
            $('.loading-price').addClass("hidden");
            $('.consumable-detail-availability').removeClass("hidden");
            $('.price-conso').removeClass("hidden");
            $(FILTER_SWITCH_FAVORITES).prop("disabled", false);
            $(FILTER_SWITCH_RECURRENT).prop("disabled", false);
            const countProducts = $('.consumable-product:not(:hidden)').length;
            if (0 === countProducts) {
                $('.loading-block').remove();
                $(COUNTER_SELECTOR).html(0);
            }
            hideLoadingSpinnerWhenNoProductsToShow({
                selector: '.consumable-product:not(:hidden)',
                blockToRemove: '.loading-block',
                counter: COUNTER_SELECTOR
            });
        }
    });
}

function updateItemStatusAndPrice(value, itemToUpdate, searchType, updatedRefCount, blockItemToUpdate) {
    if (value.PRODUCT_STATUS === END_OF_LIFE || (value.STOCK === 0 && canDisplayLimitedStockMsg(value))) {
        updateItemAvailability(itemToUpdate, searchType, updatedRefCount, blockItemToUpdate);
    } else {
        if (value.PRODUCT_STATUS === OUT_OF_STORE) {
            handleQuantityUpdate(blockItemToUpdate, value.STOCK);
        }
        updateBlocPrice(itemToUpdate, value);
        $(itemToUpdate).find('.price-container .loader-filter-result-piece').addClass('hidden');
        $(itemToUpdate).find('.item-product').attr(DATA_AVAILABILITY,  value.DISPO);
        isLoadingPrices = false;
    }
}

function displayDestockingProduct(value, itemToUpdate, blockItemToUpdate)
{
    const destocking = itemToUpdate.dataset.destocking;
    if (undefined !== destocking && '1' === destocking && (['D','C'].indexOf((value.DISPO).slice(0, 1)) === -1)) {
        blockItemToUpdate.hide().addClass('end-of-life');
    }
}
function updateBlocEcotaxes(items) {
    items.forEach(function (item) {
        if (item.CODREF && item.ECOTAXES.total && JSON.parse(item.ECOTAXES.total) > 0) {
            var ecotaxes = item.ECOTAXES;
            var id = 'popin-' + item.CODREF;
            var ecotaxesTotal = String(ecotaxes.total).replace('.', ',');
            var paht = item.PAHT.toString().replace('.', ',');

            var htmlConsigne = '';
            if (ecotaxes.items.CONSIGNE.length > 0) {
                ecotaxes.items.CONSIGNE.forEach(function (itemCONS) {
                    htmlConsigne += '<li>' + itemCONS.libelle + ': <span class="strong">' + itemCONS.valeur.replace('.', ',') + ' € HT</span></li>';
                });
            } else {
                htmlConsigne += '<li>Non disponible</li>';
            }

            var htmlEco = '';
            if (ecotaxes.items.ECO_PART.length > 0) {
                ecotaxes.items.ECO_PART.forEach(function (itemECO) {
                    htmlEco += '<li>' + itemECO.libelle + ': <span class="strong">' + itemECO.valeur.replace('.', ',') + ' € HT</span></li>';
                });
            } else {
                htmlEco += '<li>Non disponible</li>';
            }

            var parent = $('#ecotaxes_parent_' + item.CODREF);

            parent.find('.ecotaxe_container--link').attr('data-idPopin', id);
            parent.find('.ecotaxe_container--popin').attr('id', id);
            parent.find('.ecotaxe_container--link-value').text(ecotaxesTotal);
            parent.find('.ecotaxe_container--popin-price--value').text(paht);
            parent.find('.ecotaxe_container--popin--consigne').html(htmlConsigne);
            parent.find('.ecotaxe_container--popin--eco').html(htmlEco);
            parent.find('.ecotaxe_container--link').show();
        }
    });
}

function consumableSort(firstLoad = false, mustSorted = false)
{
    var selectedSort    = $('#consumableSortSelect').find(':selected').val();
    var list            = $('.products-consumables section')[0];
    var itemsToSort     = $('.consumable');

    if (selectedSort == 'popularity') {
        itemsToSort.sort(function(a, b) {
            const elemA = $(a).find('.out-of-store:not(.hasSorted)').length > 0 ;
            const elemB= $(b).find('.out-of-store:not(.hasSorted)').length > 0;
            if ((elemA && elemB) || mustSorted) {
                var av  = parseInt(a.getAttribute('data-popularity'));
                var bv  = parseInt(b.getAttribute('data-popularity'));

                if (av > bv) {
                    return 1;
                }
                if (av < bv) {
                    return -1;
                }
            }

            return 0;
        });

        itemsToSort.detach().prependTo(list);
    } else if (selectedSort == 'availability') {
        itemsToSort     = $('.consumable:not(.hidden)');
        itemsToSort.sort(function(a, b) {
            var av  = availabilityLegacySort($(a).find('.dispo-details:not(.hidden)'));
            var bv  = availabilityLegacySort($(b).find('.dispo-details:not(.hidden)'));

            return av.localeCompare(bv);
        });

        itemsToSort.detach().prependTo(list);
    } else if (selectedSort == 'price_asc') {
        itemsToSort.sort(function(a, b) {
            var av  = parseInt(a.getAttribute('data-price')) || 0;
            var bv  = parseInt(b.getAttribute('data-price')) || 0;

            if (av > bv) {
                return 1;
            }
            if (av < bv) {
                return -1;
            }
            return 0;
        });

        itemsToSort.detach().prependTo(list);
    } else if (selectedSort == 'price_desc') {
        itemsToSort.sort( function(a, b) {
            var av  = parseInt(a.getAttribute('data-price')) || 0;
            var bv  = parseInt(b.getAttribute('data-price')) || 0;

            if (av < bv) {
                return 1;
            }
            if (av > bv) {
                return -1;
            }
            return 0;
        });

        itemsToSort.detach().prependTo(list);
    }

    if (true === firstLoad) {
        itemsToSort.sort(function(elementA, elementB) {
            const firstElement = $(elementA);
            const secondElement = $(elementB);
            const outOfStoreElements = firstElement.find('.out-of-store:not(.hideStore)');
            const isDisplayedA = outOfStoreElements.length > 0;
            outOfStoreElements.addClass(isDisplayedA ? 'hasSorted': '')
            const isDisplayedB = secondElement.find('.out-of-store:not(.hideStore)').length > 0;
            secondElement.find('.out-of-store:not(.hideStore)').addClass(isDisplayedB ? 'hasSorted': '')

            return compareElementsAvailability(firstElement, secondElement, isDisplayedA, isDisplayedB);
        });

        itemsToSort.detach().prependTo(list);
    }
}
$(document).on('click', "div.products-consumables div.result-tri a", function(){
    consumableSort(false, true)
});

function changeMode(mode) {
    if (mode === "list") {
        $('div.products-consumables').removeClass('products-consumables--grid')
            .addClass('products-consumables--line');

        $('.consumable-detail').each(function () {
            var detailReference = $(this).find(CONSUMABLE_DETAIL_REFERENCE).data('title');
            $(this).find(CONSUMABLE_DETAIL_REFERENCE).html(detailReference);

            var detailName = $(this).find(CONSUMABLE_DETAIL_NAME).data('title');
            $(this).find(CONSUMABLE_DETAIL_NAME).html(detailName);

            var providerTitle = $(this).find(CONSUMABLE_DETAIL_PROVIDER).data('title');
            $(this).find(CONSUMABLE_DETAIL_PROVIDER).html(providerTitle);
        });

        $('.title-rate').removeClass("hidden");
        $(".consumable-detail-reference-pricing").css("display", "inline");
        $(".consumable-detail-provider-pricing").css("display", "inline");
        $('.list-new-tyre-label').removeClass('hidden');
        $('.grid-new-tyre-label').addClass('hidden');
        $('.out-of-store').removeClass('grid-block-store');
        $('.out-of-store').addClass('list-block-store');
        $(ADVERTISING_BANNER).removeClass('grid-advertising-banner');
        $(ADVERTISING_BANNER).addClass('list-advertising-banner left-15');
    } else {
        $('div.products-consumables').removeClass('products-consumables--line')
            .addClass('products-consumables--grid');

        $('.consumable-detail').each(function () {
            var titleDetailReference = $(this).find(CONSUMABLE_DETAIL_REFERENCE).data(TITLE_TRUNCATE);
            $(this).find(CONSUMABLE_DETAIL_REFERENCE).html(titleDetailReference);

            var titleDetailName = $(this).find(CONSUMABLE_DETAIL_NAME).data(TITLE_TRUNCATE);
            $(this).find(CONSUMABLE_DETAIL_NAME).html(titleDetailName);

            var providerTitle = $(this).find(CONSUMABLE_DETAIL_PROVIDER).data(TITLE_TRUNCATE);
            $(this).find(CONSUMABLE_DETAIL_PROVIDER).html(providerTitle);
        });

        if (! $('#material-switch-price').length) {
            $('.title-rate').addClass("hidden");
        }
        $(".consumable-detail-reference-pricing").css("display", "block");
        $(".consumable-detail-provider-pricing").css("display", "block");
        $('.list-new-tyre-label').addClass('hidden');
        $('.grid-new-tyre-label').removeClass('hidden');
        $('.out-of-store').addClass('grid-block-store');
        $('.out-of-store').removeClass('list-block-store');
        $(ADVERTISING_BANNER).addClass('grid-advertising-banner');
        $(ADVERTISING_BANNER).removeClass('list-advertising-banner left-15');
    }
}

$(document).on('click', 'div.switch-to-list a.toggle-line', function(){
   changeMode('list');
});

$(document).on('click', 'div.switch-to-list a.toggle-grid', function(){
    changeMode('grid');
});
function availabilityLegacySort(element)
{
    var text = element.text().trim();

    if (text.substr(0, 2) === 'Di') {
        text = '1'+text;
    } else if (text.substr(0, 1) === 'H') {
        text = '2'+text;
    } else if (text.substr(0, 1) === 'J') {
        text = '3'+text;
    } else if (text.substr(0, 1) === 'I') {
        text = '4'+text;
    } else if (text.substr(0, 2) === 'Da') {
        text = '5'+text;
    } else if (text.substr(0, 1) === 'S') {
        text = '6'+text;
    }

    return text;
}

function productListSession() {
    $.ajax({
        type: 'POST',
        url: Routing.generate('autossimo_consumables_list_session'),
        async: false,
        success: function (data) {
            $('.row.list-products-conso').append(data)
            handleConsumableImageError();
            initProductConsumableValue();
        },
    });
}
function getListDesignation() {
    var list = [];
    $.ajax({
        type: 'POST',
        url: Routing.generate('autossimo_consumables_list_designation'),
        async: false,
        success: function (data) {
            list.push(data.data);
        },
    });

    return list
}

function getProductByDesignation() {
    let designation='';
    if ($(FILTER_BY_DESIGN).length) {
        designation=$(FILTER_BY_DESIGN).val();
    }
    const valueEquipment = $(MANUFACTURER).val();
    const valueEquipmentType = $(MANUFACTURERTYPE).val();
    const valueSegmentType = $(SEGMENT_TYPE).val();
    const valueAvailabilityPopularityPrice = $(SORT_AVAILABILITY_POPULARITY_PRICE).val();
    const searchType = $(PRODUCT_CONSU).data('searchtype');
    const codeArbo = $(PRODUCT_CONSU).attr('data-code-selected-family');
    let source = $('#source').val();
    let sourceFilterSwitch = null;
    let reference = null;

    if ('paint' === source) {
        const filterFavorites = $(FILTER_SWITCH_FAVORITES).prop('checked');
        const filterRecurrent = $(FILTER_SWITCH_RECURRENT).prop('checked');
        if (filterFavorites) {
            sourceFilterSwitch = 'favorites';
        } else if (filterRecurrent) {
            sourceFilterSwitch = 'recurrents';
        }
    }
    if (searchType === 'snow_chain') {
        reference = $('input#chainsSocksAutocomplete').val();
        source = searchType
    }

    $.ajax({
        type: 'POST',
        url: Routing.generate('autossimo_consumables_resultat_by_codearbo'),
        data: {
            codeArboOfSelectedFamily: codeArbo,
            searchType: searchType,
            valueEquipment: valueEquipment,
            valueEquipmentType: valueEquipmentType,
            valueSegmentType: valueSegmentType,
            valueAvailabilityPopularityPrice: valueAvailabilityPopularityPrice,
            designation: designation,
            source : source,
            sourceFilterSwitch : sourceFilterSwitch,
            reference : reference,
        },
        success: function (data) {
            refreshConsumable(data)
        },
    });
}
/**
 * Events on change manufacturer.
 */
$(MANUFACTURER).on('change', function () {
    getReferences();
});

/**
 * Events on change manufacturer type.
 */
$(MANUFACTURERTYPE).on('change', function () {
    getReferences();
});

/**
 * Events on change segment type.
 */
$(SEGMENT_TYPE).on('change', function () {
    getReferences();
});

/**
 * Events on change AVAILABILITY_POPULARITY_PRICE.
 */
$(SORT_AVAILABILITY_POPULARITY_PRICE).on('change', function () {
    getReferences();
});

$(FILTER_SWITCH_FAVORITES).on('click', function () {
    switchFilterProducts($(this), FILTER_SWITCH_RECURRENT, 'favorites');
});

$(FILTER_SWITCH_RECURRENT).on('click', function () {
    switchFilterProducts($(this), FILTER_SWITCH_FAVORITES, 'recurrents')
});


function switchFilterProducts(filterInput, uncheckedInput, sourceClick)
{
    const valueEquipment = $(MANUFACTURER).val();
    const searchType = $(PRODUCT_CONSU).data('searchtype');
    $(FILTER_SWITCH_RECURRENT).prop("disabled", true);
    $(FILTER_SWITCH_FAVORITES).prop("disabled", true);
    let designation = '';
    if ($(FILTER_BY_DESIGN).length > 0) {
        designation = $(FILTER_BY_DESIGN).val();
    }

    if ($(filterInput).prop('checked')) {
        $(uncheckedInput).prop("checked", false);
        $.ajax({
            type: 'POST',
            url: Routing.generate('autossimo_reference_recurrent'),
            data: {
                sourceClick: sourceClick,
                searchType: searchType,
                valueEquipment: valueEquipment,
                designation: designation,
            },
            success: function (data) {
                refreshConsumable(data);
                },
        });
    } else {
        getReferences();
    }
}

function getReferences()
{
    let reference = $('input.search-consumables').val();
    const searchType = $(PRODUCT_CONSU).data('searchtype');
    const valueEquipment = $(MANUFACTURER).val();
    const valueEquipmentType = $(MANUFACTURERTYPE).val();
    const valueSegmentType = $(SEGMENT_TYPE).val();
    const valueAvailabilityPopularityPrice = $(SORT_AVAILABILITY_POPULARITY_PRICE).val();
    const codeArbo = $(PRODUCT_CONSU).attr(CODARBO_SELECTED);
    const isEssential = $(IS_ESSENTIAL).val() ;
    let source = $('#source').val();
    let codeRef = null;
    if (searchType !== 'family')
    {
        codeRef =$(PRODUCT_CONSU).data('codref-initial')
    }
    let designation = '';
    if ($(FILTER_BY_DESIGN).length > 0) {
        designation = $(FILTER_BY_DESIGN).val();
    }

    //switch
    let sourceFilterSwitch = null;
    if ('paint' === source) {
         const filterFavorites = $(FILTER_SWITCH_FAVORITES).prop('checked');
         const filterRecurrent = $(FILTER_SWITCH_RECURRENT).prop('checked');
        if (filterFavorites) {
            sourceFilterSwitch = 'favorites';
        } else if (filterRecurrent) {
            sourceFilterSwitch = 'recurrents';
        }
    }
    if (searchType === 'snow_chain') {
        reference = $('input#chainsSocksAutocomplete').val();
        source = searchType;
    }

    $.ajax({
        type: 'POST',
        url: Routing.generate('autossimo_consumables_resultat_by_codearbo'),
        data: {
            codeArboOfSelectedFamily: codeArbo,
            searchType: searchType,
            valueEquipment: valueEquipment,
            valueEquipmentType: valueEquipmentType,
            valueSegmentType: valueSegmentType,
            valueAvailabilityPopularityPrice: valueAvailabilityPopularityPrice,
            designation: designation,
            reference: reference,
            codeRef: codeRef,
            source: source,
            sourceFilterSwitch: sourceFilterSwitch,
            essential : isEssential,
        },
        success: function (data) {
            refreshConsumable(data);
            const filters = valueEquipment ?? valueEquipmentType ?? valueSegmentType;
            Flash.setFiltredViewItemResult(filters ? filters.length !== 0 : false);
        },
    });
}

function refreshConsumable(data)
{
    refreshProductConsumable(data);
    const count = parseInt($("#countArticles").attr('data-count'));
    const countProducts = 20;
    if(count < countProducts) {
        $(CONSUMABLE_LOADING).addClass('hidden');
    } else {
        $(CONSUMABLE_LOADING).removeClass('hidden');
    }
    initProductConsumableValue();
}

function refreshProductConsumable(data)
{
    $('.row.list-products-conso').html(data);
    $(COUNTER_SELECTOR).html($("#countArticles").attr('data-count'));
}

export function initProductConsumableValue(isPaintFavorites = false)
{
    var nodeImage = $(".consumable:not(.hidden):not(.imageLoaded)");
    initImgWithLazyLoading(nodeImage, true);
    if (!isPaintFavorites) {
        getFavorites();
    }
    getConsumablesPrices();
}

function consoFilterEvent(elem){
    const designation = elem.val();
    const elements = [];
    const elementsType = [];
    const elementsSegment = [];
    const elementsAvailabilityPopularityPrice = [];
    const minLength = 2;

    $("#manufacturer option:selected").each(function(index,value){
        elements.push($(value).val());
    });

    $("#manufacturer-type option:selected").each(function(index,value){
        elementsType.push($(value).val());
    });

    $("#segment-type option:selected").each(function(index,value){
        elementsSegment.push($(value).val());
    });

    $("#sort-availability-popularity-price option:selected").each(function(index,value){
        elementsAvailabilityPopularityPrice.push($(value).val());
    });

    if (designation.length > minLength) {
        getProductByDesignation()
    } else if( (elements.length > 0) || (elementsType.length > 0) || (elementsSegment.length > 0)
        || (elementsAvailabilityPopularityPrice.length > 0)) {
        getProductByDesignation();
    } else {
        $(".consumable").show();
        $('.actions-btn-switch').click();
        $('.actions-btn-switch').click();
    }
}

/**
 * performs a case-insensitive search for condition in array_words(array) and calls function_to_execute
 * if condition not found in array_words or (optionally) extra_condition returns true
 * returns the output of function_to_execute()
 * NB: used to mitigate code repitition
 *
 * @param arrayWords
 * @param condition
 * @param functionToExecute
 * @param extraCondition (optional)
 */
function executeIfNotFound(arrayWords,condition,functionToExecute, extraCondition){
    // pour compatibilité pre-ES6 du parametre par defaut function_to_execute
    extraCondition =  (typeof extraCondition !== 'undefined' ? extraCondition : false);
    $.each(arrayWords, function (key,value) {
        if (condition.toLowerCase().indexOf(value.toLowerCase()) == -1 || extraCondition == true) {
            return functionToExecute();
        }
    });
}
$(document).on('show.bs.modal', POPIN_FP_TYRE, function () {
    //Si pas d'image, on initialise l'image par défaut
    if ($(POPIN_FP_TYRE).find('.lazy-loading').length !== 0) {
        initImgWithLazyLoading();
    }
    //Et dans tous les cas on initialise le carousel (uniquement de la vignette)
    initOwlCarousel($(POPIN_FP_TYRE).find('.productDetail').first());
});

handleConsumableImageError();
