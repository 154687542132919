
jQuery(document).ready(function () {
    calculTotal();
    jQuery('.footer-references').css('visibility','hidden');

    jQuery(window).scroll(function () {
        setFooterOffset();
    });

    jQuery('div.filtreEquipementiers #equipementiers').hide();

    jQuery(document).on('click', 'div.filtreEquipementiers ul li', function (e) {

        var tabRef = jQuery(this).parents().find('select').val();
        var dataRef = tabRef.split(":");

        var reforigine = dataRef[0];
        var codequip = dataRef[1];

        if (codequip == 'tous') {
            jQuery("div.produits[reforigine='" + reforigine + "'] tr.panier-input").show();
            jQuery("div.produits[reforigine='" + reforigine + "'] tr.spacing-line").show();
        }
        else {
            jQuery("div.produits[reforigine='" + reforigine + "'] tr.panier-input").show();
            jQuery("div.produits[reforigine='" + reforigine + "'] tr.spacing-line").show();
            jQuery("div.produits[reforigine='" + reforigine + "'] tr.panier-input[data-equip!='" + codequip + "']").toggle();
            jQuery("div.produits[reforigine='" + reforigine + "'] tr.spacing-line[data-equip!='" + codequip + "']").toggle();
        }
    });
});

export function calculTotal() {
    var total = 0;
    var allselectedref = '.devis_sidexa' + ':checked';
    jQuery.each(jQuery(allselectedref), function (index, value) {
        var selection = jQuery(value).parents('.panier-input');
        var prixdetail = selection.find('.ht span');
        var panierdetail = selection.find('.cart-action input');
        var Aprice = prixdetail.html().replace(',', '.');
        if (Aprice === '--' || Aprice === '-') {
            Aprice = 0.0;
        }

        Aprice = Number(Aprice);
        var Aquantite = panierdetail.val();
        if (jQuery(selection).parents('form').find('.bloc-reference-disabled').is(":hidden")) {
            total = parseFloat(total) + (parseFloat(Aprice) * parseFloat(Aquantite));
        }
    });

    jQuery.each(jQuery('.import-montant .prix span'), function (index, value) {
        jQuery(value).html(total.toFixed(2));
    });
    jQuery('.container-footer .detail span').html(total.toFixed(2));

}

$('.sidexaFilter .arrows').on('click', function () {
    const blockData = $(this).find('a');
    const refCon = blockData.data('refcon');
    const tri = blockData.data('tri');
    const order = blockData.data('order');

    GaEventHandler.sortOut($(this),'sort-type');
    trierRefsBy(refCon, tri, order);
})

function trierRefsBy(refcon, tri, ordre) {
    var tabRefs = jQuery("div.produits[reforigine='" + refcon + "'] tr.panier-input");
    jQuery("div.produits[reforigine='" + refcon + "'] tr.spacing-line").remove();

    var triTab = new Array();

    jQuery.each(tabRefs, function (index, value) {
        var valueTri = jQuery(value).attr('data-' + tri);
        if (tri !== 'equip') {
            if (valueTri == '' || valueTri == 0 || valueTri == '--') {
                valueTri = '0';
            }
            valueTri = parseFloat(valueTri);
        }
        triTab.push({key: index, value: valueTri});
    });

    if (ordre === 'desc') {
        triTab.sort(function (a, b) {
            if (a.value > b.value) {
                return -1;
            }
            else if (a.value < b.value) {
                return 1;
            }
            return 0;
        });
    }
    else {
        triTab.sort(function (a, b) {
            if (a.value < b.value) {
                return -1;
            }
            else if (a.value > b.value) {
                return 1;
            }
            return 0;
        });
    }

    triTab.forEach(function (value) {
        jQuery("div.produits[reforigine='" + refcon + "'] .trier-refs-table").append(jQuery(tabRefs[value.key]));
        var spacingline = '<tr class="spacing-line" data-equip="' + jQuery(tabRefs[value.key]).attr('data-codequip') + '"></tr>';
        jQuery("div.produits[reforigine='" + refcon + "'] .trier-refs-table").append(spacingline);
    });
}

function getWindowHeight() {
    return jQuery(window).height();
}

function getHeaderOffset() {
    return jQuery('.top-resume').offset();
}

export function setFooterOffset() {
    var documentScrollTop = jQuery(document).scrollTop();
    var windowHeight = jQuery(window).height();
    var windowBottomPosition = windowHeight + documentScrollTop;

    var bottomOffset = jQuery('.bottom-resume').offset();
    var bottomTopPosition = null;
    if (typeof bottomOffset !== 'undefined') {
        bottomTopPosition = bottomOffset.top;
    }

    var footerInnerHeight = jQuery('.footer-references').innerHeight();
    var footerTopPosition = windowBottomPosition - footerInnerHeight;

    if (bottomTopPosition !== null && footerTopPosition < bottomTopPosition - 68) {
        jQuery('.footer-references').css('visibility','visible');
    } else {
        jQuery('.footer-references').css('visibility','hidden');
    }
}
