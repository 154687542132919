import StartSearchNormalizersResolver from "./StartSearchNormalizersResolver";
import AbstractDataBuilder from "../../core/AbstractDataBuilder";
export default class StartSearchDataBuilder extends AbstractDataBuilder {
    static create() {
        if (undefined === this.builderInstance) {
            this.builderInstance = new this(new StartSearchNormalizersResolver());
        }
        return this.builderInstance;
    }
}
