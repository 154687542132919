import {alertPackage} from './pubtech-popin.js';
import $ from 'jquery';
const MANUEL = '#manuels-1';
const POPIN_FICHE_VEHICLE = '#popinFicheVehicule';

export function getFicheVehiculeAjax(url, patternAC) {
    if (!patternAC) {
        var patternAC = '';
    }

    jQuery(POPIN_FICHE_VEHICLE).load(url, function () {
        $(POPIN_FICHE_VEHICLE).removeClass('devis-fiche');
        var sidexa = jQuery(POPIN_FICHE_VEHICLE).parent().parent().parent().attr('class');
        var parentClass = jQuery(POPIN_FICHE_VEHICLE).parent().attr('class');
        var searchmodele = jQuery('.' + parentClass).children().attr("class");
        var hauteur = 0;

        if (sidexa != undefined && sidexa.indexOf('sidexa2') == '-1') {
            hauteur = -168;
        }
        if (searchmodele != undefined) {
            hauteur = 100;
        }
        if (jQuery(POPIN_FICHE_VEHICLE).hasClass("devis-fiche")) {
            jQuery('#popinFicheVehicule div.modal-content').css('position', 'absolute').css('margin-top', '200px');
            jQuery('#popinFicheVehicule div.close').css('top', '171px');
        }
        jQuery(POPIN_FICHE_VEHICLE).parent().show();
        jQuery(POPIN_FICHE_VEHICLE).css({top: hauteur});
        jQuery(POPIN_FICHE_VEHICLE).modal({
            backdrop: true
        });

        if (patternAC == 'hideAccessCatalog') {
            $('.accessCatalog').css('display', 'none');
        }

        $(document).on('click', '#themeManuel', function () {
            var mgid = $(this).attr('data-group');
            jQuery("div#manuels").html('');
            jQuery("div#subgroups").html(jQuery("div#mainGroup_"+mgid).html());
        });

        $(document).on('click', '#subThemes', function () {
            var sgid = $(this).attr('data-sub-group');
            jQuery("div#manuels").html('');
            jQuery("div#manuels").html(jQuery("div#subGroup_"+sgid).html());
        });
    });

    return false;
}

var ImageClicked = null;
var NumImageClicked = null;

function ImageResize (num){
    const img = $(this);
    if (img == null){
        return;
    }
    if (ImageClicked != null) {
        ImageClicked.src = '/build/images/60x60/'.concat(NumImageClicked).concat('.jpg');
    }
    img.src = '/build/images/85x85/'.concat(num).concat('.jpg');
    ImageClicked = img;
    NumImageClicked = num;
}

function Zoom (image){
    var width = 500;
    var height = 500;
    var left = (screen.width / 2) - (width / 2);
    var top = (screen.height / 2) - (height / 2);
    var strWindowFeatures = 'location=no, height=' + height + ', width=' + width + ', scrollbars=yes, status=yes, top=' + top + ', left=' + left;
    var w = window.open (image, 'ZOOM', strWindowFeatures);
    w.focus();
}

function openManuel(manuelId, TypeId){
    var url = "{{ path('autossimo_fiche_vehicule_zoom',{'manualId': 'manuel', 'typeId': 'type' } ) }}".replace('manuel', manuelId).replace('type', TypeId);
    window.open(url, '_blank');
}
function getDonneesTechnique(url, codmar, numord, ktypenr) {
    $('#donnees-1').html('<img class="loaderrore img22" style="margin-left: 430px;margin-top: 60px;" src="/build/images/loading.gif">');
    $('#donnees-1').load(url, {codmar: codmar, numord: numord, ktypenr: ktypenr});
    return false;
}

function getManuelTechnique(url, codmar, numord, ktypenr) {
    $(MANUEL).html('<img class="loaderrore img22" style="margin-left: 430px;margin-top: 60px;" src="/build/images/loading.gif">');
    $(MANUEL).load(url, {codmar: codmar, numord: numord, ktypenr: ktypenr});
    return false;
}

export function getDetail(mgId, typeId, mgName)
{
    if (mgId != 18 || (mgId == 18 && mgName != 'Couples de serrage')) {
        $('#printDonneesTech').removeClass('hide');
        $('#printDonneesTech').attr('data-mgid',mgId);
        $('#printDonneesTech').attr('data-typeid',typeId);
        $('#printDonneesTech').attr('data-typedonnee',mgName);
        $('#datatech').empty();
        $('#Theme').find('#extended').hide();
        $('img.loaderrore').removeClass('hide');
        const url = Routing.generate('autossimo_popin_fiche_vehicule_theme_html');
        $('#Theme').find('#datatech').load(url, {mainGroupId: mgId, typeId: typeId}, function() {
            $('img.loaderrore').addClass('hide');
        });
    } else if (mgId == 18 && mgName == 'Couples de serrage') {
        $('#printDonneesTech').addClass('hide');
        $('#datatech').empty();
        $('#Theme').find('#extended').css('display','block');
        $('img.loaderrore').addClass('hide');
    }

    return false;
}

function printFicheVehicule(codmar, numord, modele, immat, cnit, typemine, typerecherche, typeaction) {
    var req = new XMLHttpRequest();
    var url = Routing.generate('autossimo_popin_fiche_vehicule_download');
    var data = '?codmar='+codmar+'&numord='+numord+'&immat='+immat+'&cnit='+cnit+'&typemine'+typemine+'&typerecherche='+typerecherche+'&typeaction='+typeaction;
    req.open("GET", url+data, true);
    req.responseType = "blob";

    req.onreadystatechange = function () {
        if (req.readyState === 4 && req.status === 200) {
            var filename = "fiche-vehicule-" + codmar + "-" + modele + "-" + immat + ".pdf";

            if (typeof window.chrome !== 'undefined') {
                // Chrome version
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(req.response);
                link.download = "fiche-vehicule-" + codmar + "-" + modele + "-" + immat + ".pdf";
                link.click();
            } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
                // IE version
                var blob = new Blob([req.response], { type: 'application/pdf' });
                window.navigator.msSaveBlob(blob, filename);
            } else {
                // Firefox version
                var file = new File([req.response], filename, { type: 'application/force-download' });
                window.open(URL.createObjectURL(file));
            }
        }
    };
    req.send(null);
}

function printDonneesTechniques(mainGroupId, typeId, codmar, modele, typedonnee) {
    var req = new XMLHttpRequest();
    var url = Routing.generate('autossimo_popin_donnees_techniques_download');
    var data = "?mainGroupId="+mainGroupId+"&typeId="+typeId;
    req.open("GET", url+data, true);
    req.responseType = "blob";

    req.onreadystatechange = function () {
        if (req.readyState === 4 && req.status === 200) {
            typedonnee = typedonnee.toLowerCase();
            var filename = "donnees-techniques-" + typedonnee + "-" + codmar + "-" + modele + ".pdf";

            if (typeof window.chrome !== 'undefined') {
                // Chrome version
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(req.response);
                link.download = "donnees-techniques-" + typedonnee + "-" + codmar + "-" + modele + ".pdf";
                link.click();
            } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
                // IE version
                var blob = new Blob([req.response], { type: 'application/pdf' });
                window.navigator.msSaveBlob(blob, filename);
            } else {
                // Firefox version
                var file = new File([req.response], filename, { type: 'application/force-download' });
                window.open(URL.createObjectURL(file));
            }
        }
    };
    req.send(null);
}

$(document).on('click', '.resize-image', function (e){
    ImageResize($(this).attr('data-main-group'));
});

$(document).on('click', '.zoomImage', function (e){
    const image = $(this).data('image');
    Zoom(image);
});

$(document).on('click', '.dataPiece22', function (e){
    const element = $(this),
        mainGroup = element.attr('data-main-group'),
        typeId = element.attr('data-type-id'),
        groupId = element.attr('data-main-group-name');
    $('.img22').show();
    getDetail(mainGroup, typeId, groupId);
});


$('.carte_identite').unbind('click');
$(document).on('click', '.carte_identite', function (e) {
    e.preventDefault();
    $('#identite-1').show();
    $('#donnees-1').hide();
    $(MANUEL).hide();
});

$('.donnee_tech').unbind('click');
$(document).on('click', '.donnee_tech', function (e) {
    e.preventDefault();
    const element = $(this);
    const url = element.data('url'),
        codmar = element.data('codmar'),
        numord = element.data('numord'),
        ktypenr = element.data('ktypenr');
    $('#identite-1').hide();
    $(MANUEL).hide();
    $('#donnees-1').show();
    getDonneesTechnique(url, codmar, numord, ktypenr);
});

$('.manuel_tech').unbind('click');
$(document).on('click', '.manuel_tech', function (e) {
    e.preventDefault();
    const element = $(this);
    const url = element.data('url'),
        codmar = element.data('codmar'),
        numord = element.data('numord'),
        ktypenr = element.data('ktypenr');
    $('#identite-1').hide();
    $('#donnees-1').hide();
    $(MANUEL).show();
    getManuelTechnique(url, codmar, numord, ktypenr);
});

$(document).on("click", "#printIdentityCard", function(e) {
    e.preventDefault();
    var codmar = $(this).attr('data-codmar');
    var numord = $(this).attr('data-numord');
    var modele = $(this).attr('data-modele');
    var immat = $(this).attr('data-immat');
    var cnit = $(this).attr('data-cnit');
    var typemine = $(this).attr('data-typemine');
    var typerecherche = $(this).attr('data-typerecherche');
    var typeaction = 'impression';

    printFicheVehicule(codmar, numord, modele, immat, cnit, typemine, typerecherche, typeaction);
});

$(document).on("click", "#printDonneesTech", function(e) {
    e.preventDefault();
    var mgId = $(this).attr('data-mgid');
    var typeId = $(this).attr('data-typeid');
    var codmar = $(this).attr('data-codmar');
    var modele = $(this).attr('data-modele');
    var typedonnee = $(this).attr('data-typedonnee');

    printDonneesTechniques(mgId, typeId, codmar, modele, typedonnee);
});

$(document).on("click", "#closePopinFicheVehicule", function() {
    //On supprime du dom le css récupéré du ws Tecalliance
    //à la suite de la consultation d'une donnée technique
    //pour éviter l'application de leur css à la fermeture
    //de la popin (Ex: classe disabled)
    $(document).find('link[href="https://tecrmi-services.tecalliance.net/Content/res/ais.css"]').remove();
    $(POPIN_FICHE_VEHICLE).modal('hide');
});

$(document).on("click", ".open-fiche-vehicle", function(event) {
    if ($(this).hasClass('disable_package')) {
        alertPackage();
        event.preventDefault();
    } else {
        getFicheVehiculeAjax(Routing.generate('autossimo_popin_fiche_vehicule', {
            codmar: $(this).data('codmar'),
            numord: $(this).data('numord'),
            immat: $(this).data('immat'),
            typerecherche: 'immat',
        }));
    }
});

