import $ from "jquery";

/**
 * handle displaying promos banners according to current tab.
 *
 * @param currentTab
 */
export function handlePromoBanners(currentTab) {
    let offers = $('#tire_list_promo').val();
    offers = offers.split(';');
    const promoMaxLength = 2;

    switch (currentTab) {
        case 'stock':
            if (offers[0] === '1') {
                $('.promo-banner.hidden').removeClass('hidden');
                $('.promo-autossimo-banner.hidden:not(.hidden)').addClass('hidden');
            } else if (offers[1] === '1') {
                $('.promo-autossimo-banner.hidden').removeClass('hidden');
                $('.promo-banner.hidden:not(.hidden)').addClass('hidden');
            } else {
                hideAllPromos();
            }
            break;
        case 'deadline':
            if (offers[promoMaxLength] === '1') {
                $('.promo-banner.hidden').removeClass('hidden');
                $('.promo-autossimo-banner:not(.hidden)').addClass('hidden');
            } else {
                hideAllPromos();
            }
            break;
        default:
            break;
    }
}

/**
 * Hide all promos banners.
 */
function hideAllPromos() {
    $('.promo-banner:not(.hidden)').addClass('hidden');
    $('.promo-autossimo-banner:not(.hidden)').addClass('hidden');
}


/**
 * Init displaying promo banner after opening product sheet.
 */
export function initPromoBanner() {
    const isStockDisabled = $('.offerStock').hasClass('disabledOnglet');
    isStockDisabled ? handlePromoBanners('deadline') : handlePromoBanners('stock')
}

export function checkPromoBeforeInitPromoBanner() {
    let promoActionElement = $('.promo-band.promo-autossimo-action');

    if (promoActionElement.length > 0 && !promoActionElement.hasClass('hidden')) {
        let promoAutossimo = $('.ps-promo-band.promo-autossimo-banner');

        if (promoAutossimo.length > 0 && promoAutossimo.hasClass('hidden')) {
            promoAutossimo.removeClass('hidden');
        }
    } else {
        initPromoBanner();
    }
}
