import {is_immatriculation} from "./pubtech-main-v2";
const POPIN_LINK_SHEET_MSG = '#popinToutMsgFicheLiaison';
let $ = require('jquery');

$(document).on('click', '.modal#popinFicheLiaison #Fermer', function(){
    $('#popinFicheLiaison').modal('hide');
    removeImageNotUsed();
})
$(document).on('click', '.modal#popinFicheLiaison button.close', function(e){
    removeImageNotUsed();
})
//Fermer la fenêtre message fiche envoyée fiche de liaison à partir du bouton fermer
export function FermerPopinFicheLiaisonMsg() {
    $(POPIN_LINK_SHEET_MSG).attr('style', 'display:none');
}

function AfficherPopinFicheLiaisonMsg(msg) {
    document.querySelector(POPIN_LINK_SHEET_MSG + ' .modal-body > #link-sheet-submit-msg').textContent = msg;
    document.querySelector(POPIN_LINK_SHEET_MSG).style.display = 'block';
}

$(document).on('click', 'div.fiche-liaison div.more-filters', function(){
    const $content = $('#shelter22');
    $content.slideToggle(500, function () {
    });
})
function Reply()
{
    const errorMsgResponseMaxLength = 'Le texte saisi dans le champ REPONSE est trop long.\nMerci de modifier le texte.';
    const errorMsgResponseNotSent = 'Une erreur est survenue. Veuillez réessayer.';
    const replyButtonId = '#ajax_url_fiche_liaison_reply';
    const forbidden = 'Vous n\'êtes pas autorisé à effectuer cette opération';
    if (jQuery('#response').val().length > 1000) {
        AfficherPopinFicheLiaisonMsg(errorMsgResponseMaxLength);
        return false;
    }

    var numFiche = $('#numFiche').val();
    var response = $('#response').val();

    var url = $(replyButtonId).attr('ajax_url');

    var data = {
        'numFiche': numFiche,
        'response': response,
    };

    $.ajax(
        {
            type: 'POST',
            url: url,
            data: data,
            async: true,
            dataType: 'json',
            beforeSend: function () {
                $('#response').attr('disabled', 'disabled'); //disable response textarea
                $(replyButtonId).attr('disabled', 'disabled'); //disable responde button
                $('#loaderResponse').css('display', 'block'); //show loading
            },
            success: function (replyActionResponse) {
                if (replyActionResponse['success']) {
                    const filterFormId = '#formfiltrermec';
                    $.ajax({ // create an AJAX call...
                        data: $(filterFormId).serialize(), // get the form data
                        type: $(filterFormId).attr('method'), // GET or POST
                        url: $(filterFormId).attr('action'), // the root to call
                        success: function(filterActionResponse) { // on success..
                            // Update list link sheets
                            $('#fiches-liaison').html($( filterActionResponse ).find( "#fiches-liaison" ).html());

                            // Update popin link sheet
                            $('#popinFicheLiaisonConsult').load("/init-fiche-liaison-consult/"+numFiche);
                        }
                    });
                } else {
                    if (data['error'] === 'response_length_max') {
                        AfficherPopinFicheLiaisonMsg(errorMsgResponseMaxLength);
                    } else {
                        AfficherPopinFicheLiaisonMsg(errorMsgResponseNotSent);
                    }

                    $('#response').removeAttr('disabled'); //enable response textarea
                    $(replyButtonId).removeAttr('disabled'); //enable responde button
                    $('#loaderResponse').css('display', 'none'); //hide loading
                }
            },
            error: function (error) {
                switch (error.status) {
                    case 403:
                        AfficherPopinFicheLiaisonMsg(forbidden);
                        $('#popinFicheLiaisonConsult').load("/init-fiche-liaison-consult/"+numFiche);
                        break;
                    default:
                        AfficherPopinFicheLiaisonMsg(a.statusText);
                }
            }
        });

    return null;
}
$(document).on('click', '#ajax_url_fiche_liaison_reply', function(){
    Reply();
})
function Envoyer()
{
    var IMMATRICULATION = 'IMMATRICULATION';
    var IMMAT = 'Immat.';
    var VIN = 'vin';
    var Titre = $('#titre').val();
    var RaiSoc = $('#societe').val();
    var NomPrenom = $('#nom').val();
    var Email = $('#email').val();
    var Telephone = $('#telephone').val();
    var Distributeur = $('#distributeur').val();
    var Marque = $('#marque').attr('value');
    var Modele = $('#modele').attr('value');
    var Version = $('#version').attr('value');
    var TypeMine = $('#typemine').attr('value');
    var Commentaires = $('#commentaire').val();
    var NomEmetteur = $('#emetteur').val();
    var Chaine = $('#appellation').val();
    var TypeRecherche = $('#typeRecherche').val();
    var TypeCata = $('#typeCata').val();
    var Immat = $('#immat').attr('value');
    var Observation = $('.pubtech-inputradio:checked').val();
    var Vin = $('#vin').attr('value');
    var Dmec = $('#mec').attr('value');
    var Immatsiv = $('#immatsiv').attr('value');
    var data = null;
    var manufacturers = getVisibleManufacturers(TypeCata);

    const blockLinkSheetImages = $('.modal#popinFicheLiaison .block-image-linkSheet');
    const images = [];
    if (blockLinkSheetImages.length > 0) {
        blockLinkSheetImages.find('.block-image').each(function(index, value){
            let srcImage = $(value).find('img').attr('src');
            srcImage = srcImage.substring(
                srcImage.lastIndexOf("temporary"),
                srcImage.lastIndexOf("jpg") + 3
            );
            images.push(srcImage);
        });
    }

    var recherche = $.trim($('.type-immatriculation').eq(1).html());
    if (IMMAT != recherche && IMMATRICULATION != recherche && VIN != recherche) {
        if ($('#titre').val().length > 49) {
            AfficherPopinFicheLiaisonMsg('Le texte saisi dans le champ TITRE est trop long.\nMerci de modifier le texte.');
            return false;
        }
    }
    if ($('#titre').val().length === 0) {
        AfficherPopinFicheLiaisonMsg('Le champ TITRE est obligatoire.\nMerci de modifier le texte.');
        return false;
    }

    if ($('#commentaire').val().length > 999) {
        AfficherPopinFicheLiaisonMsg('Le texte saisi dans le champ COMMENTAIRES est trop long.\nMerci de modifier le texte.');
        return false;
    }

    if ("" === $('#emetteur').val()) {
        AfficherPopinFicheLiaisonMsg('Vous devez impérativement remplir le champ \"Emetteur de la fiche\".');
        return false;
    }
    if (IMMAT == recherche || IMMATRICULATION == recherche || VIN == recherche) {
        var Date1ercir = $('#date1ercir').val();
        var NumOrd = $('#numord').attr('value');
        var NumOrds = $('#numords').val();
        var Ktypnr = $('#ktypnr').attr('value');

         data = {
            'Titre': Titre,
            'RaiSoc': RaiSoc,
            'NomPrenom': NomPrenom,
            'Email': Email,
            'Telephone': Telephone,
            'Distributeur': Distributeur,
            'Marque': Marque,
            'Modele': Modele,
            'Version': Version,
            'Immat': Immat,
            'Vin': Vin,
            'Date1ercir': Date1ercir,
            'Observation': Observation,
            'Commentaires': Commentaires,
            'NomEmetteur': NomEmetteur,
            'NumOrd': NumOrd,
            'NumOrds': NumOrds,
            'Ktypnr': Ktypnr,
            'TypeMine': TypeMine,
            'Chaine': Chaine,
            'Typerech': TypeRecherche,
            'TypeCata': TypeCata,
            'Immatsiv': Immatsiv,
            'Dmec': Dmec,
            'imageLinkSheet': images
        };
    } else {
        var Vehicule = $('#vehicule').val();
        var TempsBaremes = $('#tempsbaremes').val();
        var Famille = $('#famille').attr('name');
        var SousFam = $('#sfam').attr('name');
        var Piece = $('#piece').attr('name');
        var Equip = $('#equip').val();
        var NumOrd = $('#numord').val();
        var NumOrds = $('#numords').val();
        var Ktypnr = $('#ktypnr').val();
        const ImmatFl = $('#immatFl').val();

        data = {
            'Titre': Titre,
            'ImmatFl': ImmatFl,
            'RaiSoc': RaiSoc,
            'NomPrenom': NomPrenom,
            'Email': Email,
            'Telephone': Telephone,
            'Distributeur': Distributeur,
            'Vehicule': Vehicule,
            'Marque': Marque,
            'Modele': Modele,
            'Version': Version,
            'TypeMine': TypeMine,
            'TempsBaremes': TempsBaremes,
            'Famille': Famille,
            'SousFam': SousFam,
            'Piece': Piece,
            'Equip': Equip,
            'NumOrd': NumOrd,
            'NumOrds': NumOrds,
            'Ktypnr': Ktypnr,
            'Chaine': Chaine,
            'Commentaires': Commentaires,
            'NomEmetteur': NomEmetteur,
            'Typerech': TypeRecherche,
            'TypeCata': TypeCata,
            'Immatsiv': Immatsiv,
            'Dmec': Dmec,
            'Immat': Immat,
            'Vin': Vin,
            'Observation': Observation,
            'imageLinkSheet': images
        };
        var isImmatValid = checkImmatLinkSheet(ImmatFl);
        if (!isImmatValid) {
            return false;
        }
    }

    // Send manufacturers of displayed results if available
    if (Object.keys(manufacturers).length) {
        data.manufacturers = manufacturers;
    }

    const dialog = document.querySelector('#popinFicheLiaison .modal-dialog');
    if (dialog.dataset.ouijai) {
        data.linkSheetCategory = 'OUIJAI';
    }

    var ajaxUrl = $('#ajax_url_update_fiche_liaison').attr('ajax_url');
    $.ajax(
            {
                type: 'POST',
                url: ajaxUrl,
                data: data,
                async: true,
                dataType: 'json',
                success: function (responseData) {     // define call back function
                    popinMsgLinkSheet(responseData);
                }, // end function
                onFailure: function (errorData) {      // define call back function
                } // end function
            });
}
$(document).on('click', '#ajax_url_update_fiche_liaison', function(){
    Envoyer();
})

$(document).on('click', '.sectionFL .unflod', function (){
    unfoldFl($(this));
});

function unfoldFl(target)
{
    const unfoldValue = '#'+target.data('block');
    if (!$(unfoldValue).is(':visible')) {
        $(unfoldValue).show(250);
        target.removeClass('deplie');
        target.addClass('plie');
    } else {
        $(unfoldValue).hide(250);
        target.removeClass('plie');
        target.addClass('deplie');
    }
}
/**
 * function to check the validation of immat
 */
function checkImmatLinkSheet (ImmatFl) {
    if (ImmatFl !== undefined && ImmatFl !== '')
    {
        const isImmatValid = is_immatriculation(ImmatFl+ ' ');
        if (false === isImmatValid) {
            $('.immat_fl_error').html('<p>immatriculation incorrecte</p>')
            return false;
        }
    }
    return true
}

function popinMsgLinkSheet(responseData) {
    const title = document.querySelector(POPIN_LINK_SHEET_MSG + ' h1.title');
    if (responseData['update_fiche_liaison'] || responseData['mail_fiche_liaison']) {
        $('#popinFicheLiaison').modal('hide');
        const linkSheetType = responseData['type'] === 'OUIJAI'
            ? `OUI J'AI`
            : 'de liaison';

        title.textContent = `Fiche ${linkSheetType}`;

        const sentMessage = `Votre fiche ${linkSheetType} n°${responseData['id']} a bien été envoyée.`;

        AfficherPopinFicheLiaisonMsg(sentMessage);
    } else {
        $('.popin').attr('style', 'display:block');
        $(POPIN_LINK_SHEET_MSG).attr('style', 'display:none');
    }
}

function removeImageNotUsed()
{
    $.ajax({
        type: 'POST',
        url: Routing.generate('autossimo_link_sheet_remove_image_not_used')
    });
}

/**
 * Get the list of manufacturers of the visible products in search results
 */
function getVisibleManufacturers(catalogue) {
    var manufacturers = {};
    const selector = catalogue === 'Pièces' ? '.origin-datas' : '.data-row';
    let codeKey = 'equip';
    let labelKey = 'libequip';
    if(catalogue !== 'Pièces') {
        codeKey = 'codfou';
        labelKey = 'libfou';
    }

    $(selector).each(function () {
        if($(this).is(':visible')) {
            const line = catalogue === 'Pièces' ? $(this).find("div:first-child") : $(this);
            let code = line.data(codeKey) ? line.data(codeKey) : line.data('manufacturer-code');
            let label = line.data(labelKey);
            const priority = $(this).data('pr') ? $(this).data('pr') : $(this).data('dispo-priority');
            let hasPriority = priority === '1';
            // Manufacturer reference case
            if(code === undefined) {
                code = $(this).find('.product-content').data(codeKey);
                label = $(this).find('.product-content').data(labelKey);
                hasPriority = $(this).data('dispo-priority') === '1';
            }
            manufacturers[code] = {label, hasPriority};
        }
    });

    return manufacturers;
}
