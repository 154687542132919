const REF_CONTAINER = '.ref-oe-container';
const DATA_QUANTITY = 'data-quantity';
const CHEVRON_UP = 'i.fa-chevron-up';
const DISABLE_INCREMENT = 'disable-increment';

export function checkQuantityPricing(elem, newValue)
{
    const isCart = $('.cart.section_cart').length > 0 && !$('#popinFicheProduit').is(":visible") && !$('#popinProductSheet').is(':visible');
    let container = isCart ? '.section_cart--body-item--info_price' : '.containerDispo';
    if ($(elem).closest('.dropshipping-availability').length === 0) {
        const isPopinFpPneusVisible = isCart && $("#popinFpPneus").is(':visible');
        if (isPopinFpPneusVisible ) {
            container = '.containerDispo';
        }
        const outOfStoreBlock = $(elem).closest(container).find('.out-of-store');
        let stock = outOfStoreBlock.find('.nbr-of-store');
        if (outOfStoreBlock.is(":visible") && stock.length > 0) {
            stock = parseInt(stock.text());
            const blockQuantityPlus = getQuantityPlusElement(isCart, elem, isPopinFpPneusVisible);
            if (parseInt(newValue) >= stock) {
                blockQuantityPlus.addClass(DISABLE_INCREMENT);
                $(blockQuantityPlus).find(CHEVRON_UP).addClass('disabled');
                return stock;
            } else {
                blockQuantityPlus.removeClass(DISABLE_INCREMENT);
                $(blockQuantityPlus).find(CHEVRON_UP).removeClass('disabled');
            }
        }
    }

    return null;
}

function getQuantityPlusElement(isCart, elem, isPopinFpPneusVisible)
{
    let blockQuantityPlus = isCart ? $(elem).closest('.custom-cart-action').find('.cart-plus'):
        $(elem).closest('.cart-action').find('.plus');
    if (isPopinFpPneusVisible) {
        blockQuantityPlus = $(elem).closest('.cart-action').find('.plus');
    }

    return blockQuantityPlus;
}

export function handleQuantityUpdate(blockItemToUpdate, stock)
{
    const blockQuantityPlus = $(blockItemToUpdate).find('.cart-action .plus');
    const inputQuantity = $(blockItemToUpdate).find('input.input-quantity');
    if (parseInt(inputQuantity.val()) >= stock) {
        blockQuantityPlus.addClass(DISABLE_INCREMENT);
        $(blockQuantityPlus).find(CHEVRON_UP).addClass('disabled');
        inputQuantity.val(stock);
        inputQuantity.attr(DATA_QUANTITY, stock);
        inputQuantity.attr('value', stock);
    } else {
        blockQuantityPlus.removeClass(DISABLE_INCREMENT);
        $(blockQuantityPlus).find(CHEVRON_UP).removeClass('disabled');
    }
}

export function adjustQuantity(blockOrigin, value, folderGlassMakers)
{
    const blockQuantityPlus = $(blockOrigin).find('.cart-action a.plus');
    const inputQuantity = $(blockOrigin).find('input.input-quantity');
    if (null === value.GLOBAL_STATUS_DROP && parseInt(inputQuantity.val()) >= value.STOCK) {
        blockQuantityPlus.addClass(DISABLE_INCREMENT);
        $(blockQuantityPlus).find(CHEVRON_UP).addClass('disabled');
        inputQuantity.val(value.STOCK);
        inputQuantity.attr(DATA_QUANTITY, value.STOCK);
        inputQuantity.attr('value', value.STOCK);
    } else {
        blockQuantityPlus.removeClass(DISABLE_INCREMENT);
        $(blockQuantityPlus).find(CHEVRON_UP).removeClass('disabled');
    }

    if ($(folderGlassMakers).length > 0) {
        const quantity = $(blockOrigin).closest(REF_CONTAINER).data('quantity');
        if (quantity >= value.STOCK) {
            $(blockOrigin).closest(REF_CONTAINER).attr(DATA_QUANTITY, value.STOCK);
        }
    }
}
