import { AMPLITUDE_CLASS } from "../../constants";
import EditPartQuantityEvent from "./EditPartQuantityEvent";
import { ProductFactory } from "../dto/product/ProductFactory";
export default class EditPartQuantityNormalizer {
    normalize(element, origin) {
        const editPartQuantityEvent = new EditPartQuantityEvent(origin);
        const parentDiv = $(element).closest(':has(.amplitude)');
        if (1 !== parentDiv.length) {
            return editPartQuantityEvent;
        }
        const amplitudeDiv = $(parentDiv).find(AMPLITUDE_CLASS);
        if (1 !== amplitudeDiv.length) {
            return editPartQuantityEvent;
        }
        const quantity = $(element).val().toString();
        amplitudeDiv.attr('data-quantity', quantity).data('quantity', quantity);
        const product = ProductFactory.createFromDataAttribute($(amplitudeDiv));
        editPartQuantityEvent.productCollection.addProduct(product);
        return editPartQuantityEvent;
    }
    support(element, origin) {
        return true;
    }
}
