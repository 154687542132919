import EventHandler from "./analytics/amplitude/EventHandler";

$(document).ready(function () {
    $("form[name=searchModelForm]").bind('submit', function () {
        EventHandler.startSearch({}, 'searchByModel');
        var model = $('select[name=sch_modele]').val();
        if ('' == model || null == model) {
            return false;
        } else {
            var filterValues = $('.form-search-modele').serializeArray();
            $.ajax({
                type: 'POST',
                data: {filterValues: filterValues},
                url: Routing.generate('autossimo_ajax_load_results'),
                success: function (response) {
                    $('.main').remove();
                    $('.table-result-search').html(response);
                    $('#infos-generales').hide();
                    $('.references-produit-list').remove();
                    if (0 < $('div.produits').length) {
                        $('div.produits').remove(); //Pour affichage suite recherche equipementier
                    }
                    $('.table-result-search').show();
                    if ($('.more-criteres').hasClass('hide')) {
                        $('.less-criteres').trigger('click');
                    }

                    EventHandler.viewVehicleResults($('.table-result-search .space_btn_tr'), 'searchModel');
                     $('#result-model-vehicle').find('a.vehicle-technical-vehicle-history').each(function () {
                    $(this).bind('click', function () {
                        EventHandler.vehicleTechnicalData('search_vehicle_history', $(this).closest('tr').find('.catalogue_cel > a.pubtech-open-catalogue'));
                    })
                })
                }
            });
            return false;
        }
    });

    $('select[name=sch_modele]').change(function () {
        reset($('select[name=sch_version]'));
        reset($('select[name=sch_annee]'));
        reset($('select[name=sch_energie]'));
        reset($('select[name=sch_cylindree]'));
        reset($('select[name=sch_puissancereelle]'));
        resetSecondaryFilters();
        loadFilters();
    });

    $('select[name=sch_version]').change(function () {
        reset($('select[name=sch_annee]'));
        reset($('select[name=sch_energie]'));
        reset($('select[name=sch_cylindree]'));
        reset($('select[name=sch_puissancereelle]'));
        resetSecondaryFilters();
        loadFilters();
    });

    $('select[name=sch_annee]').change(function () {
        loadFilters();
    });

    $('select[name=sch_energie]').change(function () {
        loadFilters();
    });

    $('select[name=sch_cylindree]').change(function () {
        loadFilters();
    });

    $('select.critere-supplementaire').change(function () {
        loadFilters();
    });
});

export function initModelSearch() {
    reset($('select[name=sch_modele]'));
    reset($('select[name=sch_version]'));
    reset($('select[name=sch_annee]'));
    reset($('select[name=sch_energie]'));
    reset($('select[name=sch_cylindree]'));
    reset($('select[name=sch_puissancereelle]'));
    resetSecondaryFilters();
    loadFilters();
}

function resetSecondaryFilters() {
    $('select.critere-supplementaire').each(function () {
        reset($(this));
    });
}

function loadFilters() {
    var filterValues = $('.form-search-modele').serializeArray();
    $.ajax({
        type: 'POST',
        data: {filterValues: filterValues},
        url: Routing.generate('autossimo_ajax_load_filters'),
        success: function (response) {
            loadFormOptions(response.formOptions);
        }
    });
}

function reloadModelForm(uid) {
    var $formModele = $('.form-search-modele');
    var $selectedBrand = $('.form-search-modele[data-uid="]' + uid + '"]');
    var imageSrc = (typeof ($selectedBrand.attr('data-image')) !== 'undefined') ? $selectedBrand.attr('data-image') : '';
    var text = $selectedBrand.text();

    if (imageSrc !== '') {
        $formModele.find('.brand img').attr('src', imageSrc).show();
    } else {
        $formModele.find('.brand img').hide();
    }

    if (typeof (text) !== 'undefined') {
        $formModele.find('.brand .name').html(text).show();
    } else {
        $formModele.find('.brand .name').hide();
    }

    $formModele.find('.brand input[type="hidden"]').attr('value', uid);

    $formModele.find('.selection-marques').addClass('hide');
    $formModele.find('.selection-criteres').removeClass('hide');
    $.fn.matchHeight._updateByGroup('search-modele-critere');

    return false;
}

function reset(input) {
    input.find('option').remove();
    input.html('<option data-content="" value=""></option>');
    if ($('.more-criteres').hasClass('hide')) {
        input.removeClass('hide');
    }
    input.selectpicker('refresh');
}

function loadFormOptions(formOptions) {
    jQuery.each(formOptions, function (name, options) {
        if ('modele' === name) {
            fillInput($('select[name=sch_modele]'), options);
        }
        if ('version' === name) {
            fillInput($('select[name=sch_version]'), options);
        }
        if ('annee' === name) {
            fillInput($('select[name=sch_annee]'), options);
        }
        if ('energie' === name) {
            fillInput($('select[name=sch_energie]'), options);
        }
        if ('cylindree' === name) {
            fillInput($('select[name=sch_cylindree]'), options);
        }
        if ('moteur' === name) {
            fillInput($('select[name=sch_moteur]'), options);
        }
        if ('carrosserie' === name) {
            fillInput($('select[name=sch_carrosserie]'), options);
        }
        if ('nbportes' === name) {
            fillInput($('select[name=sch_nbportes]'), options);
        }
        if ('boite' === name) {
            fillInput($('select[name=sch_boite]'), options);
        }
        if ('typebv' === name) {
            fillInput($('select[name=sch_typebv]'), options);
        }
        if ('typecylindree' === name) {
            fillInput($('select[name=sch_typecylindree]'), options);
        }
        if ('nbcylindres' === name) {
            fillInput($('select[name=sch_nbcylindres]'), options);
        }
        if ('puissancereelle' === name) {
            fillInput($('select[name=sch_puissancereelle]'), options);
        }
        if ('puissancefiscale' === name) {
            fillInput($('select[name=sch_puissancefiscale]'), options);
        }
        if ('alimentation' === name) {
            fillInput($('select[name=sch_alimentation]'), options);
        }
    });
}

function fillInput(input, options) {
    var currentValue = input.val();
    input.find('option').remove();
    jQuery.each(options, function (index) {
        var selected = '';
        if (currentValue === this.VALUE) {
            selected = ' selected="selected" ';
        }
        const oddeven = index % 2 ? 'even' : 'odd';
        var optionLine = '<option data-content="' + this.TEXT + '" class="' + oddeven + '" value="' + this.VALUE + '"' + selected + '>' + this.TEXT + '</option>';
        input.append(optionLine);
    });
    if ($('.more-criteres').hasClass('hide')) {
        input.removeClass('hide');
    }
    input.selectpicker('refresh');
}
