import { ProductCollection } from "../dto/product/ProductCollection";
export default class RemoveFromCartEvent {
    constructor(origin) {
        this.origin = origin;
        this.productCollection = new ProductCollection();
    }
    export() {
        return {
            cart_remove_origin: this.origin,
            products: this.productCollection.export(),
        };
    }
}
