/* Hide a loading spinner when there are no products available to display*/
export function hideLoadingSpinnerWhenNoProductsToShow(arg)
{
    const countProducts = $(arg.selector).length;

    if (0 === countProducts) {
        $(arg.blockToRemove).remove();
        $(arg.counter).html(0);
    }
}

export function updateEndOfLifeMessage(itemToUpdate) {
    const productsConsumablesGrid = $('.products-consumables--grid');

    const node = $("<div>")
        .addClass("text-description-orange bold")
        .text("Désolé, cette référence a été arrêtée et n'est plus disponible à l'achat !");


    if (productsConsumablesGrid.length > 0) {
        const endOfLifeBlock = $(itemToUpdate).find('.consumable-detail-pricing .end-of-life-block');
        if (endOfLifeBlock.length === 0) {
            node.addClass('end-of-life-block');
            $(itemToUpdate).find('.consumable-detail-pricing').append(node);
        }
    } else {
        $(itemToUpdate).find('.consumable-availability-new').append(node);
    }
}
